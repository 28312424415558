import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function FavoriteCheckedIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        clipRule="evenodd"
        d="M19.6029 12.5129L19.6048 12.5109C20.4984 11.5794 21 10.3198 21 9.00704C21 7.694 20.4983 6.43428 19.6043 5.50265C18.7909 4.65929 17.7129 4.14456 16.565 4.05138C15.4164 3.95814 14.2735 4.2931 13.3426 4.99573L12.0033 6.00673L10.6731 4.9828C9.698 4.23217 8.48429 3.8918 7.2764 4.03023C6.06851 4.16866 4.95617 4.7756 4.16336 5.72884C3.37055 6.68208 2.95618 7.9108 3.00368 9.16758C3.05118 10.4244 3.55702 11.6158 4.41935 12.5021L4.42623 12.5091L11.4056 19.744C11.5692 19.9081 11.7883 20 12.0164 20C12.2444 20 12.4635 19.9081 12.6272 19.744L19.6029 12.5129Z"
        fill={props.fill || '#8EEAC2'}
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
