import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function PaginationNextIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M4.27203 4L3.33203 4.94L6.38536 8L3.33203 11.06L4.27203 12L8.27203 8L4.27203 4Z"
        fill="currentColor"
        transform="translate(3 0)"
      />
    </SvgIcon>
  );
}
