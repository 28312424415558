import { Box } from '@mui/material';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import { PrismicJoinUsReviewsFragment } from '../../../graphql-types';
import convinceYouBackgroundImage from '../../assets/images/arrow-loop-left.svg';

import { maxWidth } from './consts';
import CTA from './cta';
import Description from './description';
import Title from './title';

export type TReviewsData = PrismicJoinUsReviewsFragment;

function Reviews({ data: { primary, items } }: { data: TReviewsData }): JSX.Element {
  if (!primary) return <></>;

  const {
    image: _image,
    title,
    description,
    cta_text,
    convince_you_text,
    convince_you_cta_title,
    convince_you_cta_link,
  } = primary;

  const image = _image && getImage(_image);

  return (
    <Box alignItems="center" display="flex" flexDirection="column" width="100%" zIndex={1}>
      {image && (
        <Box height={`calc(100vw * ${image.height} / ${image.width})`} width="100%">
          <GatsbyImage alt={_image.alt || 'Geovelo team'} image={image} style={{ width: '100%' }} />
        </Box>
      )}
      <Box
        bgcolor="#fff"
        borderRadius={{ md: 6 }}
        display="flex"
        flexDirection="column"
        gap={8}
        marginTop={{ md: image ? '-128px' : 0 }}
        maxWidth={{ xs: 'calc(100% - 32px)', md: 'calc(100% - 64px)', lg: 'calc(100% - 128px)' }}
        paddingX={{ xs: 2, md: 4, lg: 8 }}
        paddingY={8}
        width={maxWidth}
        zIndex={1}
      >
        <Box alignItems="center" display="flex" flexDirection="column" gap={2}>
          <Title align="center" data={title} />
          <Description align="center" data={description} />
        </Box>
        <Box alignItems="center" display="flex" flexDirection="column" gap={5}>
          <Box display="flex" flexWrap="wrap" gap={3}>
            {items.map(({ review }, index) => (
              <Box
                alignItems="center"
                bgcolor="#F3F6FF"
                border="1px solid #DCE1E4"
                borderRadius={6}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                key={index}
                paddingX={3}
                paddingY={4}
                width={{ xs: '100%', sm: 'calc((100% - 24px) / 2)', md: 'calc((100% - 48px) / 3)' }}
              >
                <Description
                  align="center"
                  color="primary"
                  data={{ text: `“${review.text}”` }}
                  fontWeight={700}
                />
              </Box>
            ))}
            <Box
              alignItems="center"
              bgcolor="#F3F6FF"
              border="1px solid #DCE1E4"
              borderRadius={6}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              paddingX={3}
              paddingY={4}
              width={{ xs: '100%', sm: 'calc((100% - 24px) / 2)', md: 'calc((100% - 48px) / 3)' }}
            >
              <StaticImage
                alt="Choose my company"
                layout="fixed"
                placeholder="none"
                src="../../assets/images/choose-my-company.png"
                width={200}
              />
            </Box>
          </Box>
          <Description align="center" data={cta_text} />
        </Box>
        <Box
          alignItems={{ xs: 'flex-start', md: 'center' }}
          bgcolor="#EEF9F4"
          borderRadius={5}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          gap={3}
          paddingLeft={8}
          paddingRight={{ xs: 8, md: 16 }}
          paddingY={5}
          sx={{
            backgroundImage: { md: `url(${convinceYouBackgroundImage})` },
            backgroundPosition: 'center right',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '104px auto',
          }}
        >
          <Box flexGrow={1}>
            <Description data={convince_you_text} fontWeight={700} />
          </Box>
          <Box flexShrink={0}>
            <CTA link={convince_you_cta_link} title={convince_you_cta_title} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Reviews;
