import {
  ReportType,
  TBackendReportTypeCode,
  reportTypesCodes,
  reportTypesTitleKeys,
} from '@geovelo-frontends/commons';

import { ReportTypeData } from '../../graphql-types';

export function parseReportType({
  id,
  code: _code,
  is_bind_to_osm: isBindToOSM,
}: ReportTypeData): ReportType | null {
  const code = _code && reportTypesCodes[_code as TBackendReportTypeCode];
  if (!code) return null;

  return new ReportType(id || -1, code, Boolean(isBindToOSM), reportTypesTitleKeys[code]);
}
