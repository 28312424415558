import { BikeRoute, useTracker } from '@geovelo-frontends/commons';
import {
  Box,
  ButtonBase,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Skeleton,
  Typography,
} from '@mui/material';
import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  HomeBikeRouteDataFragment,
  PrismicHomeBikeRoutesListFragment,
  PrismicHomeDataBodyBikerouteslistItem,
  PrismicHomeDataBodyBikerouteslistPrimary,
  PrismicRidesAndTripsBikeRoutesListFragment,
  PrismicRidesAndTripsDataBodyBikerouteslistItem,
  PrismicRidesAndTripsDataBodyBikerouteslistPrimary,
} from '../../../graphql-types';
import { parseBikeRoute } from '../../utils/bike-route';
import Button from '../button';
import { ArrowRightIcon } from '../icons';

import BikeRouteStats from './bike-route-stats';
import { maxWidth } from './consts';
import Subtitle from './description';
import Title from './title';

export type TBikeRoutesListData =
  | PrismicHomeBikeRoutesListFragment
  | PrismicRidesAndTripsBikeRoutesListFragment;

function BikeRoutesList({
  bikeRoutesData,
  data: { primary, items },
}: {
  bikeRoutesData?: HomeBikeRouteDataFragment[];
  data: TBikeRoutesListData;
}): JSX.Element {
  const { trackEvent } = useTracker();
  const [bikeRoutesLogos, setBikeRoutesLogos] = useState<{
    [key: number]: IGatsbyImageData | undefined;
  }>({});
  const [bikeRoutes] = useState(() => {
    const logos: { [key: number]: IGatsbyImageData | undefined } = {};

    const bikeRoutes = (
      items as Array<
        PrismicHomeDataBodyBikerouteslistItem & PrismicRidesAndTripsDataBodyBikerouteslistItem
      >
    ).reduce<BikeRoute[]>((res, { bike_route_id, logo }) => {
      const bikeRouteData = bikeRoutesData?.find(({ id }) => bike_route_id === id);
      if (bikeRouteData) {
        res.push(parseBikeRoute(bikeRouteData, 'https://backend.geovelo.fr'));
        if (bike_route_id && logo) logos[bike_route_id] = getImage(logo);
      }

      setBikeRoutesLogos(logos);

      return res;
    }, []);

    return bikeRoutes;
  });
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const containerRef = useRef<HTMLDivElement>();

  if (!primary) return <></>;

  const {
    section_id,
    variant,
    background_image,
    background_color,
    title,
    see_all_button_label,
    subtitle,
  } = primary as PrismicHomeDataBodyBikerouteslistPrimary &
    PrismicRidesAndTripsDataBodyBikerouteslistPrimary;

  const backgroundImage = background_image && getImage(background_image);

  if (!bikeRoutes) return <></>;

  return (
    <Box
      alignItems="center"
      alignSelf="stretch"
      display="flex"
      flexDirection="column"
      id={section_id?.text || undefined}
      position="relative"
      sx={{ backgroundColor: background_color, scrollMargin: 72 }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={5}
        maxWidth="100%"
        paddingBottom={{ xs: 6, lg: 16 }}
        paddingTop={{ xs: 6, lg: 8 }}
        paddingX={{ xs: 4, md: 8, lg: 16 }}
        ref={containerRef}
        width={maxWidth}
        zIndex={1}
      >
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection="row"
          gap={2}
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <Title component="h2" data={title} fontSize={variant === 'List' ? '2em' : '1.5em'} />
            <Subtitle data={subtitle} />
          </Box>
          {variant === 'Cards' && (
            <Button
              color="primary"
              component={Link}
              endIcon={<ArrowRightIcon sx={{ '&&&': { fontSize: '12px' } }} />}
              sx={{ display: { xs: 'none', md: 'flex' } }}
              to={`/${currentLanguage}/trips`}
            >
              {see_all_button_label?.text}
            </Button>
          )}
        </Box>
        {variant === 'Cards' ? (
          <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
            {bikeRoutes &&
              bikeRoutes.map((bikeRoute) => {
                const { id, description, photos, title } = bikeRoute;
                return (
                  <Card
                    key={id}
                    sx={{
                      borderRadius: 4,
                      width: {
                        xs: 'calc(100% - 2px)',
                        sm: 'calc((100% - 16px) / 2 - 2px)',
                        md: 'calc((100% - 32px) / 3 - 2px)',
                        lg: 'calc((100% - 48px) / 4 - 2px)',
                      },
                    }}
                    variant="outlined"
                  >
                    <CardActionArea
                      component={Link}
                      onClick={() =>
                        trackEvent('Calculates a trip', 'Clicked', 'Click on discover hub travel')
                      }
                      sx={{ height: '100%' }}
                      to={`/${currentLanguage}/ride-sets/${id}`}
                    >
                      {photos[0] ? (
                        <CardMedia
                          alt=""
                          component="img"
                          height="180"
                          image={photos[0].thumbnailUrl}
                        />
                      ) : (
                        <Skeleton height="180px" variant="rectangular" />
                      )}
                      <CardContent
                        sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 2 }}
                      >
                        <Typography color="primary" fontSize="1.25em" fontWeight={600}>
                          {title}
                        </Typography>
                        <BikeRouteStats bikeRoute={bikeRoute} />
                        <Description text={description} />
                      </CardContent>
                    </CardActionArea>
                  </Card>
                );
              })}
          </Box>
        ) : (
          <Box
            alignItems="flex-start"
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            gap={8}
          >
            <Box display="flex" flexDirection="column" flexGrow={1} gap={3}>
              {bikeRoutes &&
                bikeRoutes.map((bikeRoute) => {
                  const { id, photos, title, description } = bikeRoute;
                  const logo = bikeRoutesLogos[id];

                  return (
                    <Box border="1px solid #cfcfcf" borderRadius={4} key={id} overflow="hidden">
                      <ButtonBase
                        component={Link}
                        onClick={() =>
                          trackEvent('Calculates a trip', 'Clicked', 'Click on discover hub travel')
                        }
                        sx={{ justifyContent: 'stretch', width: '100%' }}
                        to={`/${currentLanguage}/ride-sets/${id}`}
                      >
                        <Box
                          alignItems="stretch"
                          display="flex"
                          flexDirection={{ xs: 'column', sm: 'row' }}
                        >
                          <Box
                            flexShrink={0}
                            height={{ xs: 150, sm: 'auto' }}
                            position="relative"
                            sx={{
                              backgroundImage: photos[0] && `url(${photos[0].thumbnailUrl})`,
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                            }}
                            width={{ xs: 'auto', sm: 178 }}
                          >
                            {logo && (
                              <Box
                                borderRadius="50%"
                                overflow="hidden"
                                position="absolute"
                                right={8}
                                top={8}
                              >
                                <GatsbyImage alt="" image={logo} />
                              </Box>
                            )}
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            flexGrow={1}
                            gap={1}
                            padding={3}
                          >
                            <Typography color="primary">{title}</Typography>
                            <BikeRouteStats bikeRoute={bikeRoute} />
                            <Description text={description} />
                          </Box>
                        </Box>
                      </ButtonBase>
                    </Box>
                  );
                })}
            </Box>
            <ButtonBase
              component={Link}
              sx={{ borderRadius: 4, flexShrink: 0, width: { xs: '100%', md: 300, lg: 439 } }}
              to={`/${currentLanguage}/trips`}
            >
              <Box
                height={{
                  xs: 350,
                  md: 300,
                  lg: 152 * bikeRoutes.length + 24 * (bikeRoutes.length - 1),
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  flexShrink={0}
                  height="100%"
                  width="100%"
                >
                  <Box borderRadius="16px 16px 0 0" flexGrow={1} overflow="hidden">
                    {backgroundImage && (
                      <GatsbyImage
                        alt=""
                        image={backgroundImage}
                        objectFit="cover"
                        objectPosition="left center"
                        style={{ height: '100%', width: '100%' }}
                      />
                    )}
                  </Box>
                  <Box
                    borderRadius="0 0 16px 16px"
                    display="flex"
                    justifyContent="center"
                    paddingY={3}
                    sx={({ palette }) => ({
                      backgroundColor: palette.primary.main,
                      color: palette.primary.contrastText,
                    })}
                  >
                    <Typography fontSize="1.15em">
                      {see_all_button_label?.text ||
                        t('geovelo.rides_and_trips.bike_routes_list.action')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </ButtonBase>
          </Box>
        )}
      </Box>
    </Box>
  );
}

function Description({ text }: { text: string | null }): JSX.Element {
  if (!text) return <></>;

  return (
    <Typography
      color="textSecondary"
      marginTop={1}
      sx={{
        display: '-webkit-box',
        lineHeight: '1.1875em',
        height: '34px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '0.875em',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
      }}
    >
      {text}
    </Typography>
  );
}

export default BikeRoutesList;
