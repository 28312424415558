import {
  BikeStation,
  BikeStationService,
  ComputedRoute,
  Route,
  Search,
  useCancellablePromise,
  useBikeStations as useCommonsBikeStations,
} from '@geovelo-frontends/commons';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';

import { Map } from '!maplibre-gl';

export const bikeStationsMinZoom = 14.5;

function useBikeStations(
  map: Map | null,
  enabled?: boolean,
  search?: Search,
  route?: ComputedRoute | Route | null,
  { smallDevice, onSearchUpdated }: { smallDevice?: boolean; onSearchUpdated?: () => void } = {},
): {
  selectedBikeStation: BikeStation | null;
  update: () => void;
  updateMarkers: (bikeStations: BikeStation[]) => void;
  unselect: () => void;
} {
  const theme = useTheme();
  const {
    initialized,
    selectedBikeStation,
    init,
    update: updateMarkers,
    unselect,
    clear: clearMarkers,
  } = useCommonsBikeStations(map, theme, search, smallDevice, { onSearchUpdated });
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    return () => {
      cancelPromises();
    };
  }, []);

  useEffect(() => {
    if (map) init();
  }, [map]);

  useEffect(() => {
    if (initialized) {
      if (route) {
        getSelectedRouteBikeStations();
      } else if (enabled) {
        update();

        map?.on('movestart', clear);
        map?.on('moveend', update);
      }
    }

    return () => {
      cancelPromises();
      clearMarkers();

      map?.off('movestart', clear);
      map?.off('moveend', update);
    };
  }, [initialized, enabled, route]);

  async function update() {
    if (!map) return;

    if (map.getZoom() < bikeStationsMinZoom) {
      clearMarkers();
      return;
    }

    try {
      const [[west, south], [east, north]] = map.getBounds().toArray();
      const bikeStations = await cancellablePromise(
        BikeStationService.getBikeStations({ bounds: { north, east, south, west } }),
      );

      updateMarkers(bikeStations);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') console.error(err);
    }
  }

  async function getSelectedRouteBikeStations() {
    if (!route) return;

    const { bikeStationIds } = route;
    if (bikeStationIds.length === 0) return;

    try {
      const bikeStations = await cancellablePromise(
        BikeStationService.getBikeStations({ ids: bikeStationIds }),
      );

      updateMarkers(bikeStations, { forceAsMarkers: true });
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
      }
    }
  }

  async function clear() {
    clearMarkers(true);
  }

  return { selectedBikeStation, update, updateMarkers, unselect };
}

export default useBikeStations;
