import { RideIcon } from '@geovelo-frontends/commons';
import { Add } from '@mui/icons-material';
import { Box, DialogContent, DialogProps, IconButton, Tooltip, Typography } from '@mui/material';
import { Link } from 'gatsby';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/button';
import Dialog from '../../components/dialog';
import { CloseIcon, UploadIcon } from '../../components/icons';
import { AppContext } from '../../context';

function CreateDialog({
  ...props
}: Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
}): JSX.Element {
  const {
    user: { current: currentUser },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  return (
    <Dialog fullWidth maxWidth="sm" {...props}>
      <Box display="flex" gap={2} justifyContent="space-between" paddingTop={2} paddingX={3}>
        <Typography fontWeight={700} variant="h6">
          {t('geovelo.rides.actions.create')}
        </Typography>
        <IconButton onClick={() => props.onClose()} size="small">
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent
        sx={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        <Typography variant="body2">{t('geovelo.rides.create_dialog.description')}</Typography>
        <Box display="flex" gap={2}>
          <Tooltip title={t('geovelo.rides.create_dialog.coming_soon')}>
            <div>
              <Button
                disabled
                color="primary"
                endIcon={<UploadIcon />}
                startIcon={<Add />}
                variant="contained"
              >
                {t('geovelo.actions.import_gpx')}
              </Button>
            </div>
          </Tooltip>
          <Button
            color="primary"
            component={Link}
            endIcon={<RideIcon />}
            startIcon={<Add />}
            state={currentUser ? {} : { prevPath: `/${currentLanguage}/ride-creation` }}
            to={currentUser ? `/${currentLanguage}/ride-creation` : `/${currentLanguage}/sign-in`}
            variant="contained"
          >
            {t('geovelo.rides.actions.define_path')}
          </Button>
        </Box>
        <Box
          alignItems="center"
          borderRadius={2}
          display="flex"
          gap={2}
          padding={2}
          sx={{ backgroundColor: '#f3f7fd' }}
          width="100%"
        >
          <Box flexGrow={1}>
            <Typography variant="body2">
              {t('geovelo.rides.loop_generator_card.description')}
            </Typography>
          </Box>
          <Box flexShrink={0}>
            <Button
              color="primary"
              component={Link}
              to={`/${currentLanguage}/loop`}
              variant="outlined"
            >
              {t('geovelo.rides.loop_generator_card.title')}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default CreateDialog;
