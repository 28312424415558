import { Box } from '@mui/material';
import React from 'react';

import { PrismicPressAreaRedirectionItemsFragment } from '../../../graphql-types';

import { maxWidth } from './consts';
import RedirectionItemCard from './redirection-item-card';

export type TRedirectionItemsData = PrismicPressAreaRedirectionItemsFragment;

function RedirectionItems({ data: { items } }: { data: TRedirectionItemsData }): JSX.Element {
  if (!items) return <></>;

  return (
    <Box
      alignItems="center"
      alignSelf="stretch"
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gap={5}
        maxWidth="100%"
        paddingBottom={10}
        paddingTop={10}
        paddingX={{ xs: 4, md: 8, lg: 16 }}
        width={maxWidth}
        zIndex={1}
      >
        <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
          {items.map((item, index) => (
            <RedirectionItemCard
              data={item}
              key={index}
              sx={{
                width: {
                  xs: '100%',
                  sm: 'calc((100% - 20px) / 2)',
                  md: 'calc((100% - 38px) / 3)',
                  lg: 'calc((100% - 56px) / 4)',
                },
              }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default RedirectionItems;
