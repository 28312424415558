import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function MergeIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M21.92 11.62C21.8724 11.4972 21.801 11.3851 21.71 11.29L19.21 8.79C19.0217 8.6017 18.7663 8.49591 18.5 8.49591C18.2337 8.49591 17.9783 8.6017 17.79 8.79C17.6017 8.9783 17.4959 9.2337 17.4959 9.5C17.4959 9.7663 17.6017 10.0217 17.79 10.21L18.59 11H13V8C13 7.73478 12.8946 7.48043 12.7071 7.29289C12.5196 7.10536 12.2652 7 12 7C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8V11H5.41L6.21 10.21C6.3983 10.0217 6.50409 9.7663 6.50409 9.5C6.50409 9.2337 6.3983 8.9783 6.21 8.79C6.0217 8.6017 5.7663 8.49591 5.5 8.49591C5.2337 8.49591 4.9783 8.6017 4.79 8.79L2.29 11.29C2.19896 11.3851 2.12759 11.4972 2.08 11.62C1.97998 11.8635 1.97998 12.1365 2.08 12.38C2.12759 12.5028 2.19896 12.6149 2.29 12.71L4.79 15.21C4.88296 15.3037 4.99356 15.3781 5.11542 15.4289C5.23728 15.4797 5.36799 15.5058 5.5 15.5058C5.63201 15.5058 5.76272 15.4797 5.88458 15.4289C6.00644 15.3781 6.11704 15.3037 6.21 15.21C6.30373 15.117 6.37812 15.0064 6.42889 14.8846C6.47966 14.7627 6.5058 14.632 6.5058 14.5C6.5058 14.368 6.47966 14.2373 6.42889 14.1154C6.37812 13.9936 6.30373 13.883 6.21 13.79L5.41 13H11V16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16V13H18.59L17.79 13.79C17.6963 13.883 17.6219 13.9936 17.5711 14.1154C17.5203 14.2373 17.4942 14.368 17.4942 14.5C17.4942 14.632 17.5203 14.7627 17.5711 14.8846C17.6219 15.0064 17.6963 15.117 17.79 15.21C17.883 15.3037 17.9936 15.3781 18.1154 15.4289C18.2373 15.4797 18.368 15.5058 18.5 15.5058C18.632 15.5058 18.7627 15.4797 18.8846 15.4289C19.0064 15.3781 19.117 15.3037 19.21 15.21L21.71 12.71C21.801 12.6149 21.8724 12.5028 21.92 12.38C22.02 12.1365 22.02 11.8635 21.92 11.62Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
