import { TLanguage } from '@geovelo-frontends/commons';
import React from 'react';
import { Helmet } from 'react-helmet-async';

import useSiteMetadata from '../hooks/site-metadata';
import { languages } from '../i18n';
import { TAppLanguage } from '../languages';

const imageUrls: { [key in TAppLanguage]: string } = {
  fr: 'baseline-fr.png',
  en: 'baseline-en.png',
  de: 'baseline-de.png',
  es: 'baseline-es.png',
};

function Seo({
  isRoot,
  title: _title,
  description,
  imageUrl,
  pageContext: { langKey },
  location,
  ld,
  metaTitle,
}: {
  description?: string | null;
  imageUrl?: string | null;
  isRoot?: boolean;
  ld?: object;
  location?: Location;
  pageContext: { langKey: TLanguage };
  title?: string;
  metaTitle?: string;
}): JSX.Element {
  const metadata = useSiteMetadata();

  const path = location?.pathname || '/';
  const isParamPath = path.indexOf('[') > -1;
  const title = _title || metadata?.title || 'Geovelo';
  const rootSlug = path === '/' ? '/' : isRoot ? path : path.slice(3);
  const siteUrl = metadata?.siteUrl || process.env.GATSBY_GEOVELO_SITE_URL || '';
  const url = `${siteUrl}${path}`;

  return (
    <Helmet>
      <html lang={langKey} />
      <link href={metadata?.icon || '/favicon.svg'} id="icon" rel="icon" />

      <title>{metaTitle || title}</title>
      {description && <meta content={description} name="description" />}

      <meta content={metaTitle || title} property="og:title" />
      {description && <meta content={description} property="og:description" />}
      {!isParamPath && <meta content={url} property="og:url" />}
      <meta content={imageUrl || `${siteUrl}/${imageUrls[langKey]}`} property="og:image" />

      <meta content={metaTitle || title} property="twitter:title" />
      {description && <meta content={description} property="twitter:description" />}
      {!isParamPath && <meta content={url} property="twitter:url" />}
      <meta content={imageUrl || `${siteUrl}/${imageUrls[langKey]}`} property="twitter:image" />

      <meta content="kchrau2r7qiz13zlgnhoq91gd1advn" name="facebook-domain-verification" />

      {!isParamPath && <link href={url} rel="canonical" />}
      {!isParamPath && <link href={`${siteUrl}${rootSlug}`} hrefLang="x-default" rel="alternate" />}
      {!isParamPath &&
        Object.values(languages).map(({ key }) => (
          <link href={`${siteUrl}/${key}${rootSlug}`} hrefLang={key} key={key} rel="alternate" />
        ))}

      {ld && <script type="application/ld+json">{JSON.stringify(ld)}</script>}
    </Helmet>
  );
}

export default Seo;
