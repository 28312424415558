import { HttpService } from '@geovelo-frontends/commons';
import { Avatar, Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/button';
import { FireWheelIcon } from '../../components/icons';
import { AppContext } from '../../context';
import environment from '../../environment';

function EmployeeCard(): JSX.Element {
  const {
    user: { current: currentUser, employee },
  } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Box
      bgcolor="#F3F6FF"
      borderRadius={4}
      display="flex"
      flexDirection={{ xs: 'column-reverse', md: 'row' }}
      gap={2}
      padding={2}
    >
      <Box
        alignItems={{ xs: 'flex-start', md: 'center' }}
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        flexGrow={1}
        gap={2}
      >
        {employee?.partner?.icon ? (
          <Avatar src={employee.partner.icon} sx={{ height: 48, width: 48 }} />
        ) : (
          <FireWheelIcon
            sx={{
              display: { xs: 'none', md: 'block' },
              flexShrink: 0,
              height: 48,
              width: 48,
            }}
          />
        )}
        <Box>
          <Typography fontWeight={600}>{t('geovelo.communities.employee_card.title')}</Typography>
          <Typography>{t('geovelo.communities.employee_card.description')}</Typography>
        </Box>
        <Box flexGrow={1} />
        <Button
          color="primary"
          component="a"
          href={`${
            environment.enterpriseUrl
          }?auth-token=${HttpService.authorizationToken?.substring(
            HttpService.authorizationToken.indexOf(' ') + 1,
          )}&user-id=${currentUser?.id}`}
          sx={{
            flexShrink: 0,
            textDecoration: 'underline',
            '&:hover': { textDecoration: 'underline' },
          }}
        >
          {t('geovelo.communities.employee_card.actions.discover')}
        </Button>
      </Box>
    </Box>
  );
}

export default EmployeeCard;
