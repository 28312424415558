import { ComputedRoute, ElevationsChart, Route } from '@geovelo-frontends/commons';
import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ArrowInward, ArrowOutward } from '../icons';

function ElevationsData({
  onHighlight,
  selectedComputedRoute,
  setChart,
}: {
  onHighlight?: (data: { sectionIndex: number; geometryIndex: number } | null) => void;
  selectedComputedRoute?: ComputedRoute | Route;
  setChart?: (chart: any) => void;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography fontSize="1.125rem" fontWeight={700}>
        {t('geovelo.rides.detail.elevation_profile')}
      </Typography>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" gap={2}>
          <Box alignItems="center" display="flex" gap={1}>
            <Typography variant="body2">
              {selectedComputedRoute ? (
                `${selectedComputedRoute.verticalGain} m`
              ) : (
                <Skeleton variant="text" width={50} />
              )}
            </Typography>
            <ArrowOutward fontSize="small" sx={{ color: '#CD3244' }} />
          </Box>
          <Box alignItems="center" display="flex" gap={1}>
            <Typography variant="body2">
              {selectedComputedRoute ? (
                `${selectedComputedRoute.verticalLoss} m`
              ) : (
                <Skeleton variant="text" width={50} />
              )}
            </Typography>
            <ArrowInward fontSize="small" sx={{ color: '#2AC682' }} />
          </Box>
        </Box>
        <Box height={136}>
          <ElevationsChart
            onHighlight={onHighlight}
            route={selectedComputedRoute}
            setChart={setChart}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ElevationsData;
