import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type TEntry = {
  color: string;
  key: string;
  labelKey: string;
};

function RoadTypeLegend({ entries }: { entries: Array<TEntry> }): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {entries.map(({ key, color, labelKey }) => (
        <Box alignItems="center" display="flex" gap={1} key={key}>
          <Box borderRadius={4} height={8} sx={{ backgroundColor: color }} width={8} />
          <Typography variant="body2">{t(labelKey)}</Typography>
        </Box>
      ))}
    </Box>
  );
}

export default RoadTypeLegend;
