import { CheckCircle } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PrismicJoinUsEmployeeOpinionFragment } from '../../../graphql-types';

import { maxWidth } from './consts';

export type TEmployeeOpinionData = PrismicJoinUsEmployeeOpinionFragment;

function EmployeeOpinion({ data: { primary } }: { data: TEmployeeOpinionData }): JSX.Element {
  const { t } = useTranslation();

  if (!primary) return <></>;

  const { prev_slice_background_color, next_slice_background_color, percentage, comment } = primary;

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      sx={{
        background: `linear-gradient(${prev_slice_background_color || '#fff'} 0%, ${
          prev_slice_background_color || '#fff'
        } 50%, ${next_slice_background_color || '#fff'} 50%, ${
          next_slice_background_color || '#fff'
        } 100%)`,
      }}
      width="100%"
    >
      <Box
        maxWidth={{ xs: 'calc(100% - 64px)', md: 'calc(100% - 128px)', lg: 'calc(100% - 256px)' }}
        width={maxWidth}
      >
        <Box
          alignItems={{ xs: 'flex-start', md: 'center' }}
          bgcolor="#D9E7FF"
          borderRadius={5}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          gap={5}
          padding={5}
          width="100%"
        >
          <Box alignItems="center" display="flex" flexShrink={0} gap={3}>
            <Box flexShrink={0}>
              <StaticImage
                alt="Heart"
                placeholder="none"
                src="../../assets/images/heart.svg"
                width={40}
              />
            </Box>
            <Box display="flex" flexDirection="column" flexShrink={{ md: 0 }}>
              <Typography color="primary" fontSize="1.5rem" fontWeight={700} lineHeight={1}>
                {percentage} %{' '}
                <Typography component="span" fontSize="1.125rem" fontWeight={700}>
                  {t('geovelo.join_us.employee_opinion.unit')}
                </Typography>
              </Typography>
              <Typography color="primary" fontWeight={700} variant="body2">
                {t('geovelo.join_us.employee_opinion.label')}
              </Typography>
            </Box>
          </Box>
          <Box alignItems="center" display="flex" gap={5}>
            <Box>
              <Typography fontSize="1.125rem" fontWeight={700}>
                “{comment.text}”
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" flexShrink={0} gap={1}>
              <StaticImage
                alt="Choose my company"
                height={36}
                layout="fixed"
                placeholder="none"
                src="../../assets/images/choose-my-company.png"
              />
              <Box alignItems="center" display="flex" gap={1}>
                <CheckCircle sx={{ color: '#2AC682', height: 20, width: 20 }} />
                <Typography variant="caption">
                  {t('geovelo.join_us.employee_opinion.certified_reviews')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default EmployeeOpinion;
