import { Typography, TypographyProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

function Subtitle({
  data,
  sx,
  ..._props
}: {
  data?: { html?: string | null; text?: string | null } | null;
} & TypographyProps): JSX.Element {
  const { palette } = useTheme();

  if (!data?.text && !data?.html) return <></>;

  const { text, html } = data;

  const props: TypographyProps = {
    fontSize: '1.3em',
    fontWeight: 600,
    sx: {
      ...sx,
      '> p': { margin: 0 },
      strong: { color: palette.secondary.main, fontWeight: 500 },
    },
    variant: 'subtitle1',
    ..._props,
  };

  if (html) {
    return <Typography dangerouslySetInnerHTML={html ? { __html: html } : undefined} {...props} />;
  }

  return <Typography {...props}>{text}</Typography>;
}

export default Subtitle;
