import { Box, Chip } from '@mui/material';
import { Link } from 'gatsby';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PrismicRidesAndTripsCatalogFragment } from '../../../graphql-types';
import { AppContext } from '../../context';

import { maxWidth } from './consts';
import CTA from './cta';
import Title from './title';

export type TCatalogData = PrismicRidesAndTripsCatalogFragment;

function Catalog({ data: { primary } }: { data: TCatalogData }): JSX.Element {
  const {
    ride: { themes },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  if (!primary) return <></>;

  const { title, cta_title } = primary;

  return (
    <Box alignItems="center" display="flex" flexDirection="column" width="100%" zIndex={1}>
      <Box
        alignItems="flex-start"
        border="1px solid #CFCFCF"
        borderRadius={4}
        display="flex"
        flexDirection="column"
        gap={3}
        maxWidth={{ xs: 'calc(100% - 100px)', md: 'calc(100% - 100px)', lg: 'calc(100% - 128px)' }}
        paddingX={4}
        paddingY={6}
        width={maxWidth}
      >
        <Title data={title} fontSize="1.5rem" fontWeight={700} />
        <Box display="flex" flexWrap="wrap" gap={1}>
          {themes?.map(({ id, key }) => (
            <Chip
              component={Link}
              key={id}
              label={t(`geovelo.rides_hub.thematic_list.themes.${key}`)}
              onClick={() => undefined}
              to={`/${currentLanguage}/rides-hub/themes/${key}`}
              variant="outlined"
            />
          ))}
        </Box>
        <CTA
          link={{ link_type: 'Document', url: `/${currentLanguage}/rides-hub` }}
          title={cta_title}
          variant="Text"
        />
      </Box>
    </Box>
  );
}

export default Catalog;
