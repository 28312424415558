import { Box, useMediaQuery } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  PrismicHomeBottomBannerFragment,
  PrismicHomeDataBodyBottombannerPrimary,
} from '../../../graphql-types';
import theme from '../../theme';
import { AppStoreBadge, GooglePlayBadge } from '../store-badges';

import { maxWidth } from './consts';
import Description from './description';
import StoreRating from './store-rating';
import Title from './title';

export type TBottomBannerData = PrismicHomeBottomBannerFragment;

function BottomBanner({
  data,
  enableStores,
}: {
  data: TBottomBannerData;
  enableStores?: boolean;
}): JSX.Element {
  const { t } = useTranslation();
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const { primary } = data;
  if (!primary) return <></>;

  const {
    background_color,
    image: _image,
    image_align,
    baseline,
    subtitle,
    google_play_rating,
    app_store_rating,
    number_of_millions_of_downloads,
  } = primary as PrismicHomeDataBodyBottombannerPrimary;

  const image = _image && getImage(_image);

  return (
    <Box
      alignItems="center"
      alignSelf="stretch"
      display="flex"
      flexDirection="column"
      position="relative"
    >
      {background_color && !smallDevice && (
        <Box
          bottom={0}
          height="75%"
          position="absolute"
          sx={{ backgroundColor: background_color }}
          width="100%"
        />
      )}
      <Box
        alignItems="center"
        alignSelf="stretch"
        display="flex"
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
        gap={10}
        justifyContent="center"
        maxWidth="100%"
        paddingBottom={image_align === 'Bottom' ? 0 : 6}
        paddingTop={6}
        paddingX={{ xs: 4, md: 8, lg: 16 }}
        width={maxWidth}
      >
        {image && (
          <Box
            alignSelf={image_align === 'Bottom' ? { xs: 'center', md: 'flex-end' } : 'center'}
            flexShrink={0}
            height={image_align === 'Bottom' ? 405 : 380}
            maxWidth="100%"
            width={{ xs: '100%', md: image_align === 'Bottom' ? 'auto' : 'calc(50% - 40px)' }}
          >
            <GatsbyImage
              alt=""
              image={image}
              objectFit="contain"
              objectPosition={image_align === 'Bottom' ? 'center bottom' : 'center center'}
              style={{ height: '100%', width: '100%' }}
            />
          </Box>
        )}
        <Box alignItems="center" display="flex" gap={16} zIndex={2}>
          <Box display="flex" flexDirection="column" gap={4}>
            <Title
              component="div"
              data={baseline}
              textAlign={{ xs: 'center', md: 'left' }}
              variant="h6"
            />
            <Description data={subtitle} textAlign={{ xs: 'center', md: 'left' }} />
            {enableStores && (
              <Box
                alignItems={{ xs: 'center', md: 'flex-start' }}
                display="flex"
                flexDirection="column"
                flexWrap="wrap"
                gap={4}
              >
                <Box alignItems="center" display={{ xs: 'none', lg: 'flex' }} gap={4}>
                  {app_store_rating && (
                    <StoreRating rating label="App Store" value={app_store_rating} />
                  )}
                  {google_play_rating && (
                    <StoreRating rating label="Google Play" value={google_play_rating} />
                  )}
                  {number_of_millions_of_downloads && (
                    <>
                      <Box borderLeft="1px solid #c0c7cc" height={20} />
                      <StoreRating
                        label={t('commons.stats_types.downloads')}
                        value={
                          t('commons.stats.million', {
                            count: number_of_millions_of_downloads,
                          }) || ''
                        }
                      />
                    </>
                  )}
                </Box>
                <Box alignItems="center" display="flex" flexWrap="wrap" gap={2}>
                  <AppStoreBadge height={50} trackingName="Secondary CTA home page" />
                  <GooglePlayBadge height={50} trackingName="Secondary CTA home page" />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BottomBanner;
