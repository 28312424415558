import { Poi, PoiDialog, Search, TPoiCategoryCode } from '@geovelo-frontends/commons';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, {
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { AppContext } from '../../context';
import usePois from '../../hooks/map/pois';

import { Map } from '!maplibre-gl';

export type TPoisRef = { update: () => void; unselect: () => void };

interface IProps {
  map?: Map | null;
  onAddAsRideStep?: (poi: Poi) => void;
  onRemoveFromRideSteps?: (poi: Poi) => void;
  onSearchUpdated?: () => void;
  onSelectedPoiChange: (selectedPoi: Poi | null) => void;
  search?: Search;
  selectedPoiCategories?: { [key in TPoiCategoryCode]?: boolean };
}

function Pois(
  {
    map: _map,
    search,
    selectedPoiCategories: _selectedPoiCategories,
    onSearchUpdated,
    onSelectedPoiChange,
    onAddAsRideStep,
    onRemoveFromRideSteps,
  }: IProps,
  ref: Ref<TPoisRef>,
): JSX.Element {
  const [dialogOpen, openDialog] = useState(false);
  const {
    map: { current: contextMap },
    poi: { categories: poiCategories, selectedCategories: selectedContextPoiCategories },
  } = useContext(AppContext);
  const map = _map || contextMap;
  const selectedPoiCategories = _selectedPoiCategories || selectedContextPoiCategories;
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { selectedPoi, update, unselect } = usePois(map, poiCategories, selectedPoiCategories, {
    smallDevice,
    search,
    onSearchUpdated,
    onAddAsRideStep,
    onRemoveFromRideSteps,
  });

  useEffect(() => {
    onSelectedPoiChange(selectedPoi);
    if (selectedPoi && smallDevice) openDialog(true);
  }, [selectedPoi]);

  useImperativeHandle(ref, () => ({
    update,
    unselect,
  }));

  return (
    <PoiDialog
      categories={poiCategories || []}
      onClose={() => {
        openDialog(false);
        setTimeout(() => {
          unselect();
        }, theme.transitions.duration.leavingScreen);
      }}
      open={dialogOpen}
      poi={selectedPoi}
    />
  );
}

export default forwardRef(Pois);
