import { Box, IconButton, Typography } from '@mui/material';
import { Link } from 'gatsby';
import React, { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/button';
import { BackIcon, MapIcon } from '../../components/icons';
import { AppContext } from '../../context';
import useQueryParams from '../../hooks/query-params';
import { TPageData, TPageProps } from '../../page-props';

function LeftPanelLayout<T = TPageData>({
  backRoute,
  title,
  children,
  ...props
}: Omit<TPageProps<T>, 'children'> & {
  backRoute?: string;
  children: ReactNode;
  title: ReactNode;
}): JSX.Element {
  const {
    app: { smallDeviceContentShowed },
    actions: { showSmallDeviceContent },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { get: getQueryParams } = useQueryParams(props.location.search);

  const { c: center, z: zoom } = getQueryParams();
  const params: string[] = [];
  if (center) params.push(`c=${center}`);
  if (zoom) params.push(`z=${zoom}`);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      sx={{ backgroundColor: '#fff', overflowY: 'auto' }}
    >
      <Box
        alignItems="flex-start"
        display="flex"
        flexShrink={0}
        gap={2}
        justifyContent="space-between"
      >
        <Box display="flex" flexShrink={0} gap={2} paddingLeft={3} paddingTop={3}>
          {backRoute && (
            <IconButton component={Link} size="small" to={backRoute}>
              <BackIcon />
            </IconButton>
          )}
          <Typography component="h1" fontSize="1.5rem" fontWeight={700}>
            {title}
          </Typography>
        </Box>
        {smallDeviceContentShowed && (
          <Box display={{ xs: 'block', md: 'none' }} flexShrink={0} paddingX={2} paddingY={1}>
            <Button
              color="primary"
              onClick={() => showSmallDeviceContent(false)}
              startIcon={<MapIcon />}
              variant="contained"
            >
              {t('commons.actions.show_map')}
            </Button>
          </Box>
        )}
      </Box>
      {children}
    </Box>
  );
}

export default LeftPanelLayout;
