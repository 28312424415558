import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function FeedbackIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M12 14C11.8022 14 11.6089 14.0586 11.4444 14.1685C11.28 14.2784 11.1518 14.4346 11.0761 14.6173C11.0004 14.8 10.9806 15.0011 11.0192 15.1951C11.0578 15.3891 11.153 15.5673 11.2929 15.7071C11.4327 15.847 11.6109 15.9422 11.8049 15.9808C11.9989 16.0194 12.1999 15.9996 12.3827 15.9239C12.5654 15.8482 12.7216 15.72 12.8315 15.5556C12.9413 15.3911 13 15.1978 13 15C13 14.7348 12.8946 14.4804 12.7071 14.2929C12.5196 14.1054 12.2652 14 12 14ZM12 2C10.6868 2 9.38641 2.25866 8.17315 2.7612C6.9599 3.26375 5.85751 4.00035 4.92892 4.92893C3.05356 6.8043 1.99999 9.34784 1.99999 12C1.99125 14.3091 2.79078 16.5485 4.25999 18.33L2.25999 20.33C2.12123 20.4706 2.02723 20.6492 1.98986 20.8432C1.95249 21.0372 1.97341 21.2379 2.04999 21.42C2.13305 21.5999 2.26769 21.7511 2.43683 21.8544C2.60598 21.9577 2.80199 22.0083 2.99999 22H12C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12 20H5.40999L6.33999 19.07C6.43448 18.9774 6.50965 18.8669 6.56114 18.7451C6.61264 18.6232 6.63944 18.4923 6.63999 18.36C6.63623 18.0962 6.5284 17.8446 6.33999 17.66C5.03057 16.352 4.21516 14.6305 4.03268 12.7888C3.8502 10.947 4.31193 9.09901 5.33922 7.55952C6.3665 6.02004 7.89578 4.88436 9.6665 4.34597C11.4372 3.80759 13.3398 3.8998 15.0502 4.60691C16.7606 5.31402 18.1728 6.59227 19.0464 8.22389C19.92 9.85551 20.2009 11.7395 19.8411 13.555C19.4814 15.3705 18.5033 17.005 17.0735 18.1802C15.6438 19.3554 13.8508 19.9985 12 20ZM12 8C11.7348 8 11.4804 8.10536 11.2929 8.29289C11.1053 8.48043 11 8.73478 11 9V12C11 12.2652 11.1053 12.5196 11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12V9C13 8.73478 12.8946 8.48043 12.7071 8.29289C12.5196 8.10536 12.2652 8 12 8Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
