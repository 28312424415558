import { Box, useMediaQuery } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PrismicMissionAppPraiseFragment,
  PrismicMissionDataBodyApppraisePrimary,
} from '../../../graphql-types';
import theme from '../../theme';

import { maxWidth } from './consts';
import StoreRating from './store-rating';
import Title from './title';

export type TAppPraiseData = PrismicMissionAppPraiseFragment;

function AppPraise({ data: { primary, items } }: { data: TAppPraiseData }): JSX.Element {
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  if (!primary) return <></>;

  const {
    app_store_rating,
    background_color,
    baseline,
    google_play_rating,
    icon: _icon,
    number_of_millions_of_downloads,
  } = primary as PrismicMissionDataBodyApppraisePrimary;
  const icon = _icon && getImage(_icon);

  return (
    <Box
      maxWidth={{ xs: 'calc(100% - 64px)', md: 'calc(100% - 128px)', lg: 'calc(100% - 256px)' }}
      paddingBottom={20}
      paddingTop={5}
      width={maxWidth}
    >
      <Box
        alignItems={{ xs: 'stretch', md: 'center' }}
        borderRadius={6}
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={{ xs: 3, md: 8 }}
        paddingLeft={{ xs: 2, sm: 3, lg: 18 }}
        paddingRight={{ xs: 2, sm: 3 }}
        paddingY={8}
        position="relative"
        sx={{ backgroundColor: background_color || '#528BE8' }}
      >
        {icon && (
          <Box
            display={{ xs: 'none', lg: 'block' }}
            flexShrink={0}
            left={0}
            position="absolute"
            top={0}
          >
            <GatsbyImage alt="" image={icon} />
          </Box>
        )}
        <Box alignItems="center" display="flex" flexDirection="row" gap={4}>
          <Box alignItems="center" display="flex" flexDirection="column" gap={2}>
            {baseline?.text && (
              <Box maxWidth={{ xs: '100%', md: 400 }}>
                <Title
                  color="#ffffff"
                  component="div"
                  data={baseline}
                  textAlign={{ xs: 'center', md: 'start' }}
                />
              </Box>
            )}
            <Box
              display="flex"
              flexWrap={{ xs: 'wrap', md: 'nowrap' }}
              gap={4}
              justifyContent={{ xs: 'center', md: 'flex-start' }}
            >
              {app_store_rating && (
                <StoreRating rating variant label="App Store" value={app_store_rating} />
              )}
              {google_play_rating && (
                <StoreRating rating variant label="Google Play" value={google_play_rating} />
              )}
              {number_of_millions_of_downloads && (
                <StoreRating
                  variant
                  label={t('commons.stats_types.downloads')}
                  value={
                    t('commons.stats.full_million', {
                      count: number_of_millions_of_downloads,
                    }) || ''
                  }
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexGrow={1}
          flexWrap="wrap"
          gap={1}
          justifyContent="space-evenly"
        >
          {items.map(({ logo: _logo }, index) => {
            const logo = _logo && getImage(_logo);
            if (!logo) return <Fragment key={index} />;
            return (
              <GatsbyImage
                alt=""
                image={logo}
                key={index}
                style={{
                  flexShrink: 0,
                  height: smallDevice ? 'auto' : '134px',
                  width: smallDevice ? '100px' : 'auto',
                }}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default AppPraise;
