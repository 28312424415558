import { IEnvironment, defaultEnvironment } from '@geovelo-frontends/commons';

let _environment: IEnvironment & {
  amplitude: { apiKey: string };
  config: { coveloCommunitiesCodes: string[] };
  highlightedEventId?: number;
  mdvEventId?: number;
} = {
  ...defaultEnvironment,
  source: 'website',
  apiKey: '0f8c781a-b4b4-4d19-b931-1e82f22e769f',
  config: { coveloCommunitiesCodes: ['lyon'] },
  crisp: { websiteId: '1e1dfe74-a0e3-4f05-b0fd-c260f626189f' },
  amplitude: { apiKey: '584fa9dd01a566e69bc4bb6c746a5f81' },
};

if (!process.env.GATSBY_APP_ENV || process.env.GATSBY_APP_ENV === 'development') {
  _environment = {
    ..._environment,
    // highlightedEventId: 6,
    // mdvEventId: 7,
  };
}

if (process.env.GATSBY_APP_ENV === 'staging') {
  _environment = {
    ..._environment,
    apiKey: '0f8c781a-b4b4-4d19-b931-1e82f22e769f',
    // highlightedEventId: 39,
    // mdvEventId: 40,
  };
}

if (process.env.GATSBY_APP_ENV === 'production') {
  _environment = {
    ..._environment,
    apiKey: '0f8c781a-b4b4-4d19-b931-1e82f22e769f',
    // highlightedEventId: 39,
    // mdvEventId: 40,
  };
}

const environment = _environment;

export default environment;
