import { Search, UserPlace, userPlaceTypes } from '@geovelo-frontends/commons';
import { DialogActions, DialogProps, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from './button';
import Dialog from './dialog';

type TProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
  place: UserPlace | null;
  search?: Search;
  onSearchUpdated: () => void;
};

function UserPlaceDialog({ place, search, onSearchUpdated, ...props }: TProps): JSX.Element {
  const { t } = useTranslation();
  const { titleKey } = (place && userPlaceTypes[place.type]) || { titleKey: null };

  return (
    <Dialog fullWidth maxWidth="md" {...props}>
      <DialogTitle>{titleKey ? t(titleKey) : place?.title}</DialogTitle>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            if (place) {
              if (search && !search.wayPoints[0]) search.wayPoints.splice(0, 1, place.clone());
              else search?.wayPoints.splice(0, 0, place.clone());
              onSearchUpdated();
            }
            props.onClose();
          }}
          variant="contained"
        >
          {t('commons.actions.leave_from_here')}
        </Button>
        <Button
          color="primary"
          onClick={() => {
            if (place) {
              if (search && !search.wayPoints[1]) search.wayPoints.splice(1, 1, place.clone());
              else search?.wayPoints.push(place.clone());
              onSearchUpdated();
            }
            props.onClose();
          }}
          variant="contained"
        >
          {t('commons.actions.go_there')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserPlaceDialog;
