import { TLanguage, badgeUrls, useTracker } from '@geovelo-frontends/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import environment from '../../environment';

export function AppStoreBadge({
  height,
  trackingName,
}: {
  height: number;
  trackingName: string;
}): JSX.Element {
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const { trackEvent } = useTracker();

  return (
    <Wrapper
      height={height}
      href={`${environment.ios.storeUrls[currentLanguage]}&pt=413079&mt=8&ct=website`}
      onClick={() => trackEvent('App store button', 'Clicked', trackingName)}
      rel="noreferrer"
      target="_blank"
    >
      <img
        alt={t('commons.app_badge_labels.app_store') || ''}
        src={badgeUrls[currentLanguage as TLanguage].ios}
      />
    </Wrapper>
  );
}

const Wrapper = styled.a<{ height: number }>`
  align-items: center;
  display: flex;
  height: ${({ height }) => `${height}px`};
  overflow: hidden;

  img {
    height: ${({ height }) => `${height}px`};
  }
`;
