import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function CheckIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 10 7" {...props}>
      <path
        d="M9.47297 0.306574C9.411 0.244088 9.33727 0.194492 9.25603 0.160646C9.17479 0.1268 9.08765 0.109375 8.99964 0.109375C8.91163 0.109375 8.8245 0.1268 8.74326 0.160646C8.66202 0.194492 8.58828 0.244088 8.52631 0.306574L3.55964 5.27991L1.47297 3.18657C1.40863 3.12441 1.33267 3.07554 1.24943 3.04274C1.16619 3.00993 1.07731 2.99385 0.987856 2.99539C0.898402 2.99694 0.810129 3.01609 0.728077 3.05176C0.646025 3.08742 0.5718 3.13889 0.509641 3.20324C0.447482 3.26759 0.398606 3.34355 0.365803 3.42679C0.333001 3.51002 0.316914 3.59891 0.318461 3.68836C0.320009 3.77781 0.339161 3.86609 0.374823 3.94814C0.410485 4.03019 0.46196 4.10441 0.526308 4.16657L3.08631 6.72657C3.14828 6.78906 3.22202 6.83866 3.30326 6.8725C3.3845 6.90635 3.47163 6.92377 3.55964 6.92377C3.64765 6.92377 3.73479 6.90635 3.81603 6.8725C3.89727 6.83866 3.971 6.78906 4.03297 6.72657L9.47297 1.28657C9.54064 1.22415 9.59465 1.14838 9.63159 1.06404C9.66853 0.979712 9.6876 0.888642 9.6876 0.796574C9.6876 0.704506 9.66853 0.613436 9.63159 0.529103C9.59465 0.44477 9.54064 0.369002 9.47297 0.306574Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
