import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function FacebookIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 25 24" {...props}>
      <circle cx="12.5" cy="12" fill="none" r="11.25" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M15.3354 12.5413L15.7214 10.0087H13.3439V8.36262C13.3439 7.67014 13.6758 6.99349 14.7372 6.99349H15.8333V4.8369C15.195 4.73178 14.55 4.67491 13.9035 4.66675C11.9467 4.66675 10.6691 5.88156 10.6691 8.07771V10.0087H8.5V12.5413H10.6691V18.6667H13.3439V12.5413H15.3354Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
