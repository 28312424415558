import { Autocomplete, Place, UserPlace, useTracker, useUnits } from '@geovelo-frontends/commons';
import { Box, Slider, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PrismicRidesAndTripsDataBodyLoopgeneratorPrimary,
  PrismicRidesAndTripsLoopGeneratorFragment,
} from '../../../graphql-types';
import {
  loopGeneratorDistanceMax,
  loopGeneratorDistanceMin,
  loopGeneratorDistanceStep,
  loopGeneratorDurationMax,
  loopGeneratorDurationMin,
  loopGeneratorDurationStep,
} from '../../templates/loop-generator/form';
import theme from '../../theme';
import Button from '../button';

import { maxWidth } from './consts';
import Description from './description';
import Title from './title';

export type TLoopGeneratorData = PrismicRidesAndTripsLoopGeneratorFragment;

function LoopGenerator({ data: { primary } }: { data: TLoopGeneratorData }): JSX.Element {
  const [place, setPlace] = useState<Place | UserPlace | null>(null);
  const [type, setType] = useState<'distance' | 'duration'>('distance');
  const [value, setValue] = useState(10000);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const { palette } = useTheme();
  const { toDistance, toTime } = useUnits();
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { trackEvent } = useTracker();

  if (!primary) return <></>;

  const {
    background_color,
    top_right_background_image,
    icon: _icon,
    title,
    description,
    departure_placeholder,
    cta_title,
  } = primary as PrismicRidesAndTripsDataBodyLoopgeneratorPrimary;

  const icon = _icon && getImage(_icon);
  const topRightBackgroundImage =
    top_right_background_image && getImage(top_right_background_image);

  return (
    <Box
      maxWidth={{ xs: 'calc(100% - 64px)', md: 'calc(100% - 128px)', lg: 'calc(100% - 256px)' }}
      paddingY={5}
      width={maxWidth}
    >
      <Box
        alignItems="center"
        borderRadius={5}
        display="flex"
        gap={3}
        paddingLeft={{ xs: 2, sm: 4, lg: 0 }}
        paddingRight={{ xs: 2, sm: 4 }}
        position="relative"
        sx={{ backgroundColor: background_color || '#528be8' }}
      >
        {topRightBackgroundImage && (
          <Box bottom={0} left={0} position="absolute" top={0} width="100%" zIndex={0}>
            <GatsbyImage
              alt=""
              image={topRightBackgroundImage}
              objectFit="contain"
              objectPosition="top right"
              style={{ height: '100%', width: '100%' }}
            />
          </Box>
        )}
        {icon && (
          <Box display={{ xs: 'none', lg: 'block' }} flexShrink={0} width={150}>
            <GatsbyImage alt="" image={icon} />
          </Box>
        )}
        <Box display="flex" flexDirection="column" flexGrow={1} gap={3} paddingY={{ xs: 2, sm: 5 }}>
          <Box
            alignItems="baseline"
            color="#fff"
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            flexShrink={0}
          >
            <Title data={title} fontSize="1.25em" />
            {!smallDevice && <>&nbsp;</>}
            <Description data={description} />
          </Box>
          <Box
            alignItems={{ xs: 'stretch', md: 'center' }}
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            flexGrow={1}
            gap={2}
          >
            <Box
              alignItems={{ xs: 'stretch', md: 'center' }}
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              flexGrow={1}
              gap={2}
            >
              <Autocomplete
                disableFloatingLabel
                defaultValue={null}
                inputProps={{
                  sx: {
                    '> div': {
                      backgroundColor: '#fff',
                      borderRadius: 2,
                      '> fieldset': { border: '0 !important' },
                    },
                  },
                }}
                label={
                  departure_placeholder?.text || 'geovelo.loop_generator.navigation.departure' || ''
                }
                margin="none"
                onSelect={setPlace}
                size="small"
              />
              <Box
                alignItems={{ xs: 'stretch', sm: 'center' }}
                alignSelf={{ xs: 'stretch', sm: 'flex-start', md: 'center' }}
                borderRadius={2}
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                gap={{ xs: 1, sm: 3 }}
                height={{ xs: 'auto', sm: 40 }}
                paddingX={2}
                paddingY={{ xs: 2, sm: 0 }}
                sx={{ backgroundColor: '#fff' }}
              >
                <Box
                  alignItems="center"
                  display="flex"
                  gap={1}
                  justifyContent={{ xs: 'space-evenly', sm: 'flex-start' }}
                >
                  <Button
                    onClick={() => {
                      setType('duration');
                      setValue(1800);
                    }}
                    size="small"
                    sx={type === 'distance' ? { '&&': { color: '#666666', fontWeight: 400 } } : {}}
                  >
                    {t('commons.duration')}
                  </Button>
                  <Box alignSelf="stretch" borderLeft="1px solid #eef3ff" />
                  <Button
                    onClick={() => {
                      setType('distance');
                      setValue(10000);
                    }}
                    size="small"
                    sx={type === 'duration' ? { '&&': { color: '#666666', fontWeight: 400 } } : {}}
                  >
                    {t('commons.distance')}
                  </Button>
                </Box>
                <Box alignItems="center" display="flex" flexGrow={1} gap={3}>
                  <Box alignItems="center" display="flex" flexGrow={1} minWidth={80}>
                    <Slider
                      color="secondary"
                      componentsProps={{
                        rail: { style: { color: palette.primary.main, height: 2 } },
                        track: { style: { color: palette.primary.main, border: 0, height: 2 } },
                      }}
                      max={
                        type === 'duration' ? loopGeneratorDurationMax : loopGeneratorDistanceMax
                      }
                      min={
                        type === 'duration' ? loopGeneratorDurationMin : loopGeneratorDistanceMin
                      }
                      onChange={(_, value) => typeof value === 'number' && setValue(value)}
                      step={
                        type === 'duration' ? loopGeneratorDurationStep : loopGeneratorDistanceStep
                      }
                      value={value}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexShrink={0}
                    justifyContent="flex-end"
                    width={type === 'duration' ? 60 : 20}
                  >
                    <Typography color="textSecondary" variant="caption">
                      {type === 'duration' ? toTime(value) : toDistance(value)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box flexShrink={0}>
              <Button
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                color="black"
                onClick={() => {
                  trackEvent('Calculates a ride', 'Clicked', 'Click on discover hub generate ride');
                  const params: string[] = [`type=${type}`, `value=${value}`];
                  if (place) {
                    params.push(
                      `from=${place.point.coordinates.join(',')}`,
                      `c=${place.point.coordinates.join(',')}`,
                      'z=15',
                    );
                  }

                  navigate(`/${currentLanguage}/loop?${params.join('&')}`);
                }}
                size="medium"
                sx={{ height: 40 }}
                variant="contained"
              >
                {cta_title?.text || t('geovelo.rides_and_trips.loop_generator.action')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default LoopGenerator;
