import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TerritoriesQuery } from '../../../graphql-types';
import Seo from '../../components/seo';
import { TPageProps } from '../../page-props';
import Content from '../content';

function TerritoriesPage(props: TPageProps<TerritoriesQuery>) {
  const { t } = useTranslation();

  const title = props.data.prismicTerritories?.data.title?.text;
  const description = props.data.prismicTerritories?.data.description?.text;

  return (
    <>
      <Seo
        description={description}
        title={`${title || t('geovelo.pages_titles.territories')}`}
        {...props}
      />
      <Content {...props} query={props.data.prismicTerritories} />
    </>
  );
}

export const query = graphql`
  fragment PrismicTerritoriesBanner on PrismicTerritoriesDataBodyBanner {
    id
    slice_type
    primary {
      background_color_start
      background_color_end
      dark_background
      background_image {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
      enable_image_margin
      image {
        gatsbyImageData(placeholder: NONE)
      }
      baseline {
        html
      }
      subtitle {
        html
      }
    }
    items {
      cta_link {
        link_type
        url
      }
      cta_title {
        text
      }
      cta_anchor {
        text
      }
      cta_color
    }
  }
  fragment PrismicTerritoriesLogos on PrismicTerritoriesDataBodyLogos {
    id
    slice_type
    primary {
      linked_to_prev_slice
      background_color
      background_color_end
      variant
      align
      title {
        text
        html
      }
    }
    items {
      item_logo {
        gatsbyImageData(placeholder: NONE)
      }
      item_title {
        text
      }
    }
  }
  fragment PrismicTerritoriesVerticalCards on PrismicTerritoriesDataBodyVerticalcards {
    id
    slice_type
    primary {
      background_color
      background_image {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
      cards
      title {
        text
        html
      }
      description {
        text
        html
      }
      images_shape
      cta_link {
        link_type
        url
      }
      cta_title {
        text
      }
    }
    items {
      icon {
        gatsbyImageData(placeholder: NONE)
      }
      title {
        text
      }
      description {
        text
        html
      }
    }
  }
  fragment PrismicTerritoriesHorizontalCard on PrismicTerritoriesDataBodyHorizontalcard {
    id
    slice_type
    primary {
      linked_to_next_slice
      header_type
      image_on_the_right
      image_type
      image {
        gatsbyImageData(placeholder: NONE)
      }
      title {
        text
        html
      }
      description {
        text
        html
      }
    }
    items {
      cta_type
      cta_variant
      cta_title {
        text
      }
      cta_link {
        link_type
        url
      }
    }
  }
  fragment PrismicTerritoriesContactForm on PrismicTerritoriesDataBodyContactform {
    id
    slice_type
    primary {
      section_id {
        text
      }
      background_color
      title {
        text
      }
      image {
        gatsbyImageData(placeholder: NONE, width: 230)
      }
      pipedrive_form_id {
        text
      }
    }
  }
  fragment TerritoriesData on PrismicTerritoriesData {
    title {
      text
    }
    description {
      text
    }
    body {
      ... on PrismicTerritoriesDataBodyBanner {
        ...PrismicTerritoriesBanner
      }
      ... on PrismicTerritoriesDataBodyVerticalcards {
        ...PrismicTerritoriesVerticalCards
      }
      ... on PrismicTerritoriesDataBodyHorizontalcard {
        ...PrismicTerritoriesHorizontalCard
      }
      ... on PrismicTerritoriesDataBodyLogos {
        ...PrismicTerritoriesLogos
      }
      ... on PrismicTerritoriesDataBodyContactform {
        ...PrismicTerritoriesContactForm
      }
    }
  }
`;

export default withPrismicPreview(TerritoriesPage);
