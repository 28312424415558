import {
  BikeStation,
  BikeStationDialog,
  ComputedRoute,
  Route,
  Search,
  bikeStationIcons,
} from '@geovelo-frontends/commons';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, {
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { AppContext } from '../../context';
import useBikeStations from '../../hooks/map/bike-stations';

export type TBikeStationsRef = { unselect: () => void };

interface IProps {
  enabled?: boolean;
  onSearchUpdated?: () => void;
  onSelectedBikeStationChange?: (selectedBikeStation: BikeStation | null) => void;
  search?: Search;
  route: ComputedRoute | Route | null | undefined;
}

function BikeStations(
  { enabled, search, route, onSelectedBikeStationChange, onSearchUpdated }: IProps,
  ref: Ref<TBikeStationsRef>,
): JSX.Element {
  const [dialogOpen, openDialog] = useState(false);
  const {
    map: { current: map },
  } = useContext(AppContext);
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { selectedBikeStation, unselect } = useBikeStations(map, enabled, search, route, {
    smallDevice,
    onSearchUpdated,
  });

  useEffect(() => {
    if (!enabled) unselect();
  }, [enabled]);

  useEffect(() => {
    onSelectedBikeStationChange?.(selectedBikeStation);
    if (selectedBikeStation && smallDevice) openDialog(true);
  }, [selectedBikeStation]);

  useImperativeHandle(ref, () => ({
    unselect,
  }));

  return (
    <BikeStationDialog
      bikeStation={selectedBikeStation}
      onClose={() => {
        openDialog(false);
        setTimeout(() => {
          unselect();
        }, theme.transitions.duration.leavingScreen);
      }}
      onSearchUpdated={onSearchUpdated}
      onUpdated={() => {
        if (!selectedBikeStation) return;

        const { id, status, availableBikesPercentage } = selectedBikeStation;
        const ele = document.getElementById(`bike-station-marker-${id}`);
        if (ele) {
          ele.style.backgroundImage = `url(${
            status === 'closed'
              ? bikeStationIcons.closed
              : bikeStationIcons[availableBikesPercentage]
          })`;
        }
      }}
      open={dialogOpen}
      search={search}
    />
  );
}

export default forwardRef(BikeStations);
