import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function XIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="-4 -4 32 32" {...props}>
      <circle cx="12" cy="12" fill="none" r="15" stroke="currentColor" strokeWidth="2" />
      <path
        d="M 2.3671875 3 L 9.4628906 13.140625 L 2.7402344 21 L 5.3808594 21 L 10.644531 14.830078 L 14.960938 21 L 21.871094 21 L 14.449219 10.375 L 20.740234 3 L 18.140625 3 L 13.271484 8.6875 L 9.2988281 3 L 2.3671875 3 z M 6.2070312 5 L 8.2558594 5 L 18.033203 19 L 16.001953 19 L 6.2070312 5 z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
