import { Box } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import { PrismicJoinUsCsrPolicyFragment } from '../../../graphql-types';
import valuesBackgroundImage from '../../assets/images/arrow-loop-left.svg';

import { maxWidth } from './consts';
import CTA from './cta';
import Description from './description';
import Title from './title';

export type TCSRPolicyData = PrismicJoinUsCsrPolicyFragment;

function CSRPolicy({ data: { primary, items } }: { data: TCSRPolicyData }): JSX.Element {
  if (!primary) return <></>;

  const { title, description, values_text, values_cta_link, values_cta_title } = primary;

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap={8}
      maxWidth={{ xs: 'calc(100% - 32px)', md: 'calc(100% - 64px)', lg: 'calc(100% - 128px)' }}
      paddingBottom={{ xs: 5, md: 10 }}
      paddingTop={8}
      paddingX={{ xs: 2, md: 4, lg: 8 }}
      width={maxWidth}
      zIndex={1}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Title align="center" component="h2" data={title} />
        <Description align="center" data={description} />
      </Box>
      <Box alignSelf="stretch" display="flex" flexDirection="column" gap={8}>
        {items.map(({ policy_image, policy_title, policy_description }, index) => {
          const image = policy_image && getImage(policy_image);

          return (
            <Box
              alignItems="center"
              display="flex"
              flexDirection={{ xs: 'column', md: index % 2 === 0 ? 'row-reverse' : 'row' }}
              gap={8}
              key={index}
            >
              <Box
                alignSelf="flex-start"
                borderRadius={5}
                flexShrink={0}
                overflow="hidden"
                width={{ xs: '100%', md: '50%', lg: 'calc(50% - 64px)' }}
              >
                {image && <GatsbyImage alt="" image={image} style={{ width: '100%' }} />}
              </Box>
              <Box display="flex" flexDirection="column" flexGrow={1} gap={3}>
                <Title color="#005A44" data={policy_title} fontSize="1.5rem" />
                <Description
                  data={policy_description}
                  sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box
        alignItems={{ xs: 'flex-start', md: 'center' }}
        bgcolor="#EEF9F4"
        borderRadius={5}
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={3}
        paddingLeft={8}
        paddingRight={{ xs: 8, md: 16 }}
        paddingY={5}
        sx={{
          backgroundImage: { md: `url(${valuesBackgroundImage})` },
          backgroundPosition: 'center right',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '104px auto',
        }}
      >
        <Box flexGrow={1}>
          <Description data={values_text} fontWeight={700} />
        </Box>
        <Box flexShrink={0}>
          <CTA link={values_cta_link} title={values_cta_title} />
        </Box>
      </Box>
    </Box>
  );
}

export default CSRPolicy;
