import { ComputedRouteService, IPoint, Place, Search } from '@geovelo-frontends/commons';
import { Divider, Menu, MenuItem, MenuProps } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

type TProps = Omit<MenuProps, 'onClose'> & {
  onClose: () => void;
  onSearchUpdated: () => void;
  point?: IPoint;
  pointClickedId?: number;
  search: Search;
};

function RoutingContextMenu({
  search,
  point,
  pointClickedId,
  onSearchUpdated,
  onClose,
  ...props
}: TProps): JSX.Element {
  function handleDepartureUpdate() {
    if (point) {
      const { lat, lng } = point;

      search.wayPoints.splice(
        0,
        1,
        new Place(undefined, { type: 'Point', coordinates: [lng, lat] }),
      );

      onSearchUpdated();
    }

    onClose();
  }

  async function handleStepUpdate() {
    if (point) {
      const { lat, lng } = point;
      const wayPointIndex = search.wayPoints.length - 1;

      search.wayPoints.splice(
        wayPointIndex,
        0,
        new Place(undefined, { type: 'Point', coordinates: [lng, lat] }),
      );

      try {
        const newWayPoints = await ComputedRouteService.reorder({ search });
        search.wayPoints = newWayPoints;
      } catch (err) {
        if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
          console.error(err);
        }
      }

      onSearchUpdated();
    }

    onClose();
  }

  function handleArrivalUpdate() {
    if (point) {
      const { lat, lng } = point;
      const wayPointIndex = search.wayPoints.length - 1;

      search.wayPoints.splice(
        wayPointIndex,
        1,
        new Place(undefined, { type: 'Point', coordinates: [lng, lat] }),
      );

      onSearchUpdated();
    }

    onClose();
  }

  function handleOpenPanoramax() {
    if (point) {
      const { lat, lng } = point;
      window.open(
        `https://api.panoramax.xyz/#focus=map&map=17.95/${lat}/${lng}&speed=250`,
        '_blank',
        'noreferrer',
      );
    }

    onClose();
  }

  function handleReverse() {
    search.wayPoints.reverse();
    onSearchUpdated();
    onClose();
  }

  function handleDeletePoint() {
    if (pointClickedId || pointClickedId === 0) {
      search.wayPoints.splice(pointClickedId, 1);
      onSearchUpdated();
    }
    onClose();
  }

  return (
    <StyledMenu {...props} onClose={() => onClose()}>
      {pointClickedId || pointClickedId === 0 ? (
        <div>
          {(pointClickedId === 0 || pointClickedId === search.wayPoints.length - 1) && (
            <>
              <MenuItem dense onClick={handleReverse}>
                Inverser le départ et l'arrivée
              </MenuItem>
              <Divider />
            </>
          )}
          <MenuItem dense onClick={handleDeletePoint}>
            Supprimer le point
          </MenuItem>
        </div>
      ) : (
        <div>
          <MenuItem dense onClick={handleDepartureUpdate}>
            <Trans i18nKey="geovelo.routing.actions.set_as_departure" />
          </MenuItem>
          {search.computable && (
            <div>
              <Divider />
              <MenuItem dense onClick={handleStepUpdate}>
                <Trans i18nKey="geovelo.routing.actions.set_as_way_point" />
              </MenuItem>
            </div>
          )}
          <Divider />
          <MenuItem dense onClick={handleArrivalUpdate}>
            <Trans i18nKey="geovelo.routing.actions.set_as_arrival" />
          </MenuItem>
          <Divider />
          <MenuItem dense onClick={handleOpenPanoramax}>
            <Trans i18nKey="geovelo.routing.actions.open_panoramax" />
          </MenuItem>
        </div>
      )}
      <StyledMarker />
    </StyledMenu>
  );
}

const StyledMenu = styled(Menu)`
  && {
    .MuiPaper-root {
      overflow: visible;
    }
  }
`;

const StyledMarker = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border: 2px solid white;
  border-radius: 50%;
  height: 12px;
  left: -8px;
  position: absolute;
  top: -8px;
  width: 12px;
`;

export default RoutingContextMenu;
