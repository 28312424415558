import {
  languages as _languages,
  deCommonsTranslations,
  defaultLanguage,
  enCommonsTranslations,
  esCommonsTranslations,
  frCommonsTranslations,
} from '@geovelo-frontends/commons';
import i18n, { ResourceKey } from 'i18next';
import { merge } from 'lodash';
import { initReactI18next } from 'react-i18next';

import { TAppLanguage } from './languages';
import de from './translations/de.json';
import en from './translations/en.json';
import es from './translations/es.json';
import fr from './translations/fr.json';

export const languages: {
  [key in TAppLanguage]: {
    code: string;
    key: TAppLanguage;
    translation: ResourceKey;
    icon: JSX.Element;
    label: string;
  };
} = {
  en: {
    ..._languages['en'],
    key: 'en',
    code: 'en_US',
    translation: merge(enCommonsTranslations, en),
  },
  fr: {
    ..._languages['fr'],
    key: 'fr',
    code: 'fr_FR',
    translation: merge(frCommonsTranslations, fr),
  },
  de: {
    ..._languages['de'],
    key: 'de',
    code: 'de_DE',
    translation: merge(deCommonsTranslations, de),
  },
  es: {
    ..._languages['es'],
    key: 'es',
    code: 'es_ES',
    translation: merge(esCommonsTranslations, es),
  },
};

i18n.use(initReactI18next).init({
  resources: languages,
  lng: defaultLanguage,
  supportedLngs: Object.keys(languages),
  fallbackLng: 'en',
  debug: process.env.NODE_ENV === 'development',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
