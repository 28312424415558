import { Box } from '@mui/material';
import { PageProps, navigate } from 'gatsby';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/button';
import { BackIcon, PhoneIcon } from '../../components/icons';
import { AppContext } from '../../context';

import Map from './map';

function AppContentLayout({
  location,
  path,
  children,
}: Omit<PageProps, 'children'> & { children: ReactNode }): JSX.Element {
  const [mapNeedResize, setMapNeedResize] = useState(false);
  const {
    app: {
      isDetailsView,
      smallDeviceContentShowed,
      smallDeviceShowDetailsAction,
      smallDeviceRedirectAction,
    },
    map: { current: currentMap },
    actions: { showSmallDeviceContent },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  useEffect(() => {
    function handleResize() {
      setMapNeedResize(true);
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!smallDeviceContentShowed && mapNeedResize) {
      currentMap?.resize();
      setMapNeedResize(false);
    }
  }, [smallDeviceContentShowed, mapNeedResize]);

  return (
    <Box
      alignItems="stretch"
      component="main"
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      sx={{
        height: '100%',
        overflowY: 'hidden',
        '@supports (-webkit-touch-callout: none)': {
          height: '-webkit-fill-available',
        },
      }}
    >
      <Box
        display={{ xs: smallDeviceContentShowed ? 'flex' : 'none', md: 'flex' }}
        flexDirection="column"
        flexGrow={{ xs: 1, md: 0 }}
        flexShrink={0}
        height="100%"
        maxWidth="100%"
        sx={({ zIndex }) => ({ zIndex: zIndex.drawer - 1 })}
        width={{ xs: '100%', md: 480 }}
      >
        {children}
      </Box>
      <>
        <Map
          display={{ xs: smallDeviceContentShowed ? 'none' : 'block', md: 'block' }}
          location={location}
          path={path}
        />
        <Box
          alignItems="center"
          display={{ xs: smallDeviceContentShowed ? 'none' : 'flex', md: 'none' }}
          height={56}
          left={0}
          paddingX={2}
          position="absolute"
          right={0}
          top={72}
        >
          {isDetailsView && (
            <Button
              onClick={() =>
                window.previousLocation ? navigate(-1) : navigate(`${currentLanguage}/route`)
              }
              size="small"
              startIcon={<BackIcon />}
              variant="contained"
            >
              {t('commons.actions.go_back')}
            </Button>
          )}
          <Box flexGrow={1} />
          <Button
            color="primary"
            onClick={() => showSmallDeviceContent(true)}
            size="medium"
            startIcon={<smallDeviceShowDetailsAction.Icon />}
            variant="contained"
          >
            {t(smallDeviceShowDetailsAction.labelKey)}
          </Button>
        </Box>
        {smallDeviceRedirectAction && (
          <Box
            bottom={56}
            display="flex"
            justifyContent="center"
            left={0}
            position="absolute"
            right={0}
          >
            <Button
              color="primary"
              onClick={smallDeviceRedirectAction.callback}
              size="medium"
              startIcon={<PhoneIcon />}
              sx={{ zIndex: 1200 }}
              variant="contained"
            >
              {t(smallDeviceRedirectAction.labelKey)}
            </Button>
          </Box>
        )}
      </>
    </Box>
  );
}

export default AppContentLayout;
