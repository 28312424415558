import { Ride, UserService } from '@geovelo-frontends/commons';
import { Box, ButtonBase, Divider, IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, navigate } from 'gatsby';
import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FavoriteCheckedIcon, FavoriteIcon, RemoveIcon } from '../../components/icons';
import RideCard from '../../components/ride-card';
import { AppContext } from '../../context';

function RidesList({
  divided,
  row,
  disableFavorites,
  path,
  rides,
  onRemove,
}: {
  disableFavorites?: boolean;
  divided?: boolean;
  onRemove?: (ride: Ride) => void;
  path: string;
  rides: Ride[] | undefined;
  row?: boolean;
}): JSX.Element {
  const [updatingFavorite, setUpdatingFavorite] = useState<{ [key: number]: boolean }>({});
  const {
    user: { current: currentUser, rides: userRides },
    actions: { setUserRides },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const { palette } = useTheme();

  async function handleAddToFavorites(id: number) {
    if (!userRides) return;

    setUpdatingFavorite({ [id]: true });

    try {
      const newUserRide = await UserService.addRide(id);

      const index = userRides.findIndex(({ rideId }) => rideId === id);
      if (newUserRide && index === -1) setUserRides([...userRides, newUserRide]);
    } catch (err) {
      console.error(err);
    }

    setUpdatingFavorite({ [id]: false });
  }

  async function handleRemoveFromFavorites(id: number) {
    if (!userRides) return;

    setUpdatingFavorite({ [id]: true });

    try {
      await UserService.removeRide(id);
      const index = userRides.findIndex(({ rideId }) => rideId === id);
      if (index > -1) {
        const newRides = [...userRides];
        newRides.splice(index, 1);

        setUserRides(newRides);
      }
    } catch (err) {
      console.error(err);
    }

    setUpdatingFavorite({ [id]: false });
  }

  function handleFavoriteClick({ id }: Ride) {
    if (!id) return;

    const favorite = userRides?.find(({ rideId }) => rideId === id);

    if (currentUser === null)
      navigate(`/${currentLanguage}/sign-in`, {
        state: { prevPath: path },
      });
    else if (favorite) handleRemoveFromFavorites(id);
    else handleAddToFavorites(id);
  }

  return (
    <Box display="flex" flexDirection="column">
      {rides ? (
        <>
          {rides
            .filter(({ id }) => Boolean(id))
            .map((ride, index) => {
              const { id } = ride;
              if (!id) return;

              const favorite = userRides?.find(({ rideId }) => rideId === id);

              return (
                <Fragment key={id}>
                  {divided && index > 0 && <Divider />}
                  <Box display="flex" flexDirection="column" flexGrow={1} position="relative">
                    <ButtonBase component={Link} to={`/${currentLanguage}/rides/${id}`}>
                      <RideCard ride={ride} row={row} />
                    </ButtonBase>
                    <Box
                      display={{ xs: 'flex', md: row ? 'none' : 'flex' }}
                      flexDirection="column"
                      gap={1}
                      position="absolute"
                      right={35}
                      top={35}
                    >
                      {!disableFavorites && (
                        <IconButton
                          disabled={
                            currentUser === undefined ||
                            (currentUser && userRides === undefined) ||
                            updatingFavorite[id]
                          }
                          onClick={() => handleFavoriteClick(ride)}
                          size="small"
                          sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.2)',
                            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.3)' },
                          }}
                        >
                          {favorite ? (
                            <FavoriteCheckedIcon sx={{ color: '#fff' }} />
                          ) : (
                            <FavoriteCheckedIcon fill="rgba(0, 0, 0, 0.5)" sx={{ color: '#fff' }} />
                          )}
                        </IconButton>
                      )}
                      {onRemove && (
                        <Tooltip title={t('commons.actions.remove')}>
                          <IconButton
                            onClick={() => onRemove(ride)}
                            size="small"
                            sx={{
                              backgroundColor: 'rgba(0, 0, 0, 0.2)',
                              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.3)' },
                            }}
                          >
                            <RemoveIcon sx={{ color: '#fff' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                    <Box
                      display={{ md: row ? 'flex' : 'none' }}
                      flexDirection="column"
                      gap={1}
                      position="absolute"
                      right={35}
                      top="calc(50% - 17px)"
                    >
                      {!disableFavorites && (
                        <IconButton
                          color="primary"
                          disabled={currentUser === undefined || updatingFavorite[id]}
                          onClick={() => handleFavoriteClick(ride)}
                          size="small"
                        >
                          {favorite ? (
                            <FavoriteCheckedIcon fill={palette.primary.main} />
                          ) : (
                            <FavoriteIcon />
                          )}
                        </IconButton>
                      )}
                      {onRemove && (
                        <Tooltip title={t('commons.actions.remove')}>
                          <IconButton color="primary" onClick={() => onRemove(ride)} size="small">
                            <RemoveIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                </Fragment>
              );
            })}
        </>
      ) : (
        [0, 1, 2].map((key, index) => (
          <Fragment key={key}>
            {divided && index > 0 && <Divider />}
            <RideCard row={row} />
          </Fragment>
        ))
      )}
    </Box>
  );
}

export default RidesList;
