import {
  ComputedRoute,
  InfoCard,
  Search,
  useLayers,
  useSource,
  useUnits,
} from '@geovelo-frontends/commons';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, ButtonBase, IconButton, Skeleton, Typography } from '@mui/material';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Button from '../../components/button';
import { EmptyStateIcon } from '../../components/icons';
import ElevationsData from '../../components/routing/elevations-data';
import RouteData from '../../components/routing/route-data';
import { AppContext } from '../../context';
import FacilitiesCard from '../ride-details/facilities-card';

import PlanTripCard from './plan-trip-card';

const highlightSourceId = 'route-highlight';

function RoutingResults({
  path,
  search,
  computedRoutes,
  selectedComputedRouteIndex,
  selectComputedRouteIndex,
}: {
  computedRoutes: ComputedRoute[] | undefined;
  path: string;
  search: Search;
  selectComputedRouteIndex: (index: number) => void;
  selectedComputedRouteIndex: number;
}): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const [sliderArrowVisible, setSliderArrowVisible] = useState({ left: false, right: false });
  const [tripCreationEnabled, enableTripCreation] = useState(false);
  const {
    map: { current: map },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const sliderRef = useRef<HTMLDivElement>(null);
  const { toTime, toDistance } = useUnits();
  const {
    addGeoJSONSource: addHighlightSource,
    updateGeoJSONSource: updateHighlightSource,
    clearGeoJSONSource: clearHighlightSource,
  } = useSource(map, highlightSourceId);
  const { addCircleLayer } = useLayers(map);

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (map) {
      addHighlightSource();

      addCircleLayer('route-highlight', highlightSourceId, {
        'circle-radius': 8,
        'circle-stroke-width': 2,
        'circle-stroke-opacity': 1,
        'circle-color': '#fff',
        'circle-opacity': 0.5,
        'circle-stroke-color': '#326ac2',
      });
    }

    return () => {
      clearHighlightSource();
    };
  }, [map]);

  useEffect(() => {
    enableTripCreation(
      (computedRoutes && computedRoutes[0] && computedRoutes[0].distances.total >= 100000) || false,
    );
  }, [computedRoutes]);

  useEffect(() => {
    function handleScroll() {
      if (!sliderRef.current) return;

      const { scrollLeft, clientWidth, scrollWidth } = sliderRef.current;

      setSliderArrowVisible({
        left: scrollLeft > 0,
        right: scrollLeft + clientWidth < scrollWidth,
      });
    }

    if (typeof window !== 'undefined') {
      sliderRef.current?.addEventListener('scroll', handleScroll);
    }

    handleScroll();

    return () => {
      typeof window !== 'undefined' &&
        sliderRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [initialized, computedRoutes]);

  function handleHighlight(data: { sectionIndex: number; geometryIndex: number } | null) {
    if (selectedComputedRoute && data) {
      const { sectionIndex, geometryIndex } = data;
      const section = selectedComputedRoute.sections[sectionIndex];
      const coordinates = section.geometry.coordinates[geometryIndex];
      if (!coordinates) return;

      updateHighlightSource({
        type: 'FeatureCollection',
        features: [{ type: 'Feature', geometry: { type: 'Point', coordinates }, properties: {} }],
      });
    } else {
      clearHighlightSource();
    }
  }

  const selectedComputedRoute = computedRoutes?.[selectedComputedRouteIndex];
  const hasNoSharedBikeRoute =
    !tripCreationEnabled &&
    search.bikeType === 'shared' &&
    computedRoutes &&
    !computedRoutes.find(({ bikeStationIds }) => bikeStationIds.length > 0);

  return (
    <Box display="flex" flexDirection="column" gap={1} padding={3}>
      {tripCreationEnabled && (
        <PlanTripCard
          computedRoute={selectedComputedRoute || null}
          onClose={() => enableTripCreation(false)}
          path={path}
          search={search}
        />
      )}
      {hasNoSharedBikeRoute && (
        <InfoCard
          error
          description={t('geovelo.routing.no_shared_bike_route')}
          Icon={EmptyStateIcon}
          sx={{ borderRadius: 2, marginBottom: 2 }}
          variant="outlined"
        />
      )}
      <Box display="flex" flexDirection="column" gap={3}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography fontSize="1.125rem" fontWeight={700}>
            {t('geovelo.routing.list.type')}
          </Typography>
          <Box marginX={-3} position="relative">
            <Box
              display="flex"
              gap={2}
              paddingX={3}
              ref={sliderRef}
              sx={{ overflowY: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}
            >
              {computedRoutes
                ? computedRoutes.map(({ id, labelKey, duration, distances }, index) => {
                    const active = index === selectedComputedRouteIndex;

                    return (
                      <Box flexShrink={0} key={id} minWidth={120}>
                        <Box
                          alignItems="flex-start"
                          bgcolor={({ palette }) => (active ? palette.primary.main : undefined)}
                          border={({ palette }) =>
                            active ? `1px solid ${palette.primary.main}` : `1px solid #CFCFCF`
                          }
                          borderRadius={2}
                          component={ButtonBase}
                          flexDirection="column"
                          flexShrink={0}
                          onClick={() => {
                            if (!active) selectComputedRouteIndex(index);
                          }}
                          padding={2}
                          width="100%"
                        >
                          <Typography
                            noWrap
                            align="left"
                            color={active ? '#fff' : 'inherit'}
                            fontWeight={700}
                          >
                            {t(`commons.routing.titles.${labelKey}`)}
                          </Typography>
                          <Typography
                            align="left"
                            color={active ? '#fff' : '#666'}
                            variant="caption"
                          >
                            {toTime(duration)}
                            <br />
                            {toDistance(distances.total)}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })
                : [1, 2, 3].map((key) => (
                    <Box flexShrink={0} key={key} width={180}>
                      <Box
                        alignItems="flex-start"
                        border="1px solid #CFCFCF"
                        borderRadius={2}
                        flexDirection="column"
                        flexShrink={0}
                        padding={2}
                      >
                        <Typography fontWeight={700}>
                          <Skeleton variant="text" width="100%" />
                        </Typography>
                        <Typography color="#666" variant="body2">
                          <Skeleton variant="text" width="100%" />
                          <Skeleton variant="text" width="90%" />
                        </Typography>
                      </Box>
                    </Box>
                  ))}
            </Box>
            {sliderArrowVisible.left && (
              <Box left="16px" position="absolute" top="calc(50% - 15px)">
                <IconButton
                  onClick={() => sliderRef.current?.scrollTo({ left: 0, behavior: 'smooth' })}
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: '#fff',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.6)' },
                  }}
                >
                  <ChevronLeft fontSize="small" />
                </IconButton>
              </Box>
            )}
            {sliderArrowVisible.right && (
              <Box position="absolute" right="16px" top="calc(50% - 15px)">
                <IconButton
                  onClick={() =>
                    sliderRef.current?.scrollTo({
                      left: sliderRef.current.scrollWidth,
                      behavior: 'smooth',
                    })
                  }
                  size="small"
                  sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: '#fff',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.6)' },
                  }}
                >
                  <ChevronRight fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
        <RouteData selectedComputedRoute={selectedComputedRoute} />
        <ElevationsData
          onHighlight={handleHighlight}
          selectedComputedRoute={selectedComputedRoute}
        />
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography fontSize="1.125rem" fontWeight={700}>
            {t('geovelo.rides.detail.road_types')}
          </Typography>
          <FacilitiesCard route={selectedComputedRoute} />
        </Box>
        <Box sx={{ marginX: '-24px', borderTop: '1px solid rgba(0, 0, 0, 0.12)' }} />
        <Button
          color="primary"
          component={Link}
          disabled={!selectedComputedRoute}
          to={`/${currentLanguage}/route/${selectedComputedRoute?.id}`}
          variant="contained"
        >
          <Trans i18nKey="geovelo.actions.see_itinerary_details" />
        </Button>
      </Box>
    </Box>
  );
}

export default RoutingResults;
