import {
  IPoint,
  Ratings,
  Report,
  ReportPopup,
  ReportType,
  Review,
  User,
  createNewImageMarker,
  getAnchorAndOffset,
  useLayers,
  usePopup,
  useSource,
} from '@geovelo-frontends/commons';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';

import { TReportSourcesMap, TReportTypesMap } from '../../context';

import { Map, MapMouseEvent, Marker, Popup } from '!maplibre-gl';

const sourceId = 'reports';
const layerId = 'reports';
const asMarkerMinZoom = 14;

function useReports(
  map: Map | null,
  _currentUser: User | null | undefined,
  _reportTypes: ReportType[] | undefined,
  _reportTypesMap: TReportTypesMap | undefined,
  _reportSourcesMap: TReportSourcesMap | undefined,
  {
    smallDevice,
    openCommentsDialog,
    onRating,
  }: {
    onRating?: (report: Report, oldReview: Review | null, rating: Ratings) => void;
    openCommentsDialog?: (open: boolean) => void;
    smallDevice?: boolean;
  } = {},
): {
  selectedReport: Report | null;
  init: () => void;
  update: (reports: Report[], props?: { forceAsMarkers?: boolean }) => void;
  unselect: () => void;
  reopenPopup: (report: Report) => void;
  clearLayers: () => void;
  clear: () => void;
} {
  const [selectedReport, selectReport] = useState<Report | null>(null);
  const currentUser = useRef(_currentUser);
  const reportTypes = useRef(_reportTypes);
  const reportTypesMap = useRef(_reportTypesMap);
  const reportSourcesMap = useRef(_reportSourcesMap);
  const reportsRef = useRef<Report[]>([]);
  const markers = useRef<{ [key: number]: { id: number; marker: Marker } }>({});
  const selectedReportIdRef = useRef<number | null>(null);
  const tooltip = useRef<Popup>();
  const { t } = useTranslation();
  const theme = useTheme();
  const { addGeoJSONSource, updateGeoJSONSource, clearGeoJSONSource } = useSource(map, sourceId);
  const { addCircleLayer } = useLayers(map);
  const { centerPopup } = usePopup(map);

  useEffect(() => {
    currentUser.current = _currentUser;
  }, [_currentUser]);

  useEffect(() => {
    reportTypes.current = _reportTypes;
  }, [_reportTypes]);

  useEffect(() => {
    reportTypesMap.current = _reportTypesMap;
  }, [_reportTypesMap]);

  useEffect(() => {
    reportSourcesMap.current = _reportSourcesMap;
  }, [_reportSourcesMap]);

  function handleMouseEnter({
    features,
  }: MapMouseEvent & {
    features?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[] | undefined;
  }) {
    if (!map || !features || !features[0]) return;

    const feature = features.find(
      ({ geometry, properties }) => geometry.type === 'Point' && properties,
    );
    if (!feature || !feature.properties) return;

    const { id, title, created, creator, sourceId, startDate, endDate } = feature.properties;
    if (feature.geometry.type === 'Point' && id !== selectedReportIdRef.current) {
      const [lng, lat] = feature.geometry.coordinates;
      map.getCanvas().style.cursor = 'pointer';
      openTooltip(id, { lat, lng }, { title, created, creator, sourceId, startDate, endDate }, 6);
    }
  }

  function handleClick(
    event: MapMouseEvent & {
      features?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[] | undefined;
    },
  ) {
    if (event.originalEvent.defaultPrevented) return;

    event.originalEvent.preventDefault();

    const { features } = event;
    const feature = features?.[0];
    if (!feature) return;

    const { id, geometry } = feature;
    if (typeof id === 'number' && geometry.type === 'Point') {
      selectedReportIdRef.current = id;
      selectReport(reportsRef.current.find((report) => id === report.id) || null);

      const [lng, lat] = geometry.coordinates;
      map?.flyTo({ center: { lat, lng }, zoom: Math.max(17, map.getZoom()) });
    }
  }

  function handleMouseLeave() {
    if (!map) return;

    map.getCanvas().style.cursor = '';
    clearTooltip();
  }

  function init() {
    if (!map) return;

    addGeoJSONSource();

    addCircleLayer(layerId, sourceId, {
      'circle-radius': 4,
      'circle-stroke-width': 2,
      'circle-stroke-opacity': 1,
      'circle-color': ['get', 'color'],
      'circle-stroke-color': '#fff',
    });

    map.on('mouseenter', layerId, handleMouseEnter);
    map.on('click', layerId, handleClick);
    map.on('mouseleave', layerId, handleMouseLeave);
  }

  function openTooltip(
    id: number,
    location: IPoint,
    {
      title,
      created,
      creator,
      sourceId,
      startDate: from,
      endDate: to,
    }: {
      title: string;
      created: string | null;
      creator: string | null;
      sourceId: number | null;
      startDate: string | null;
      endDate: string | null;
    },
    _offset: number,
  ) {
    tooltip.current?.remove();
    if (!map || !title || selectedReportIdRef.current === id) return;

    const { anchor, offset } = getAnchorAndOffset(map, location, _offset);
    let html = `<b>${title}</b>`;

    const source = (sourceId !== null && reportSourcesMap.current?.[sourceId]) || null;
    if (source) {
      html += `<br/><span>${t('commons.report.source', { source: source.title })}</span>`;
    }

    if (from && to) html += `<br/>${t('commons.periods.from_to', { from, to })}`;
    else if (from) html += `<br/>${t('commons.periods.from', { from })}`;
    else if (to) html += `<br/>${t('commons.periods.until', { to })}`;
    else if (!source && creator)
      html += `<br/>${t('commons.created_by_on', { creator, created, context: 'unformatted' })}`;
    else html += `<br/>${t('commons.created_on', { created })}`;

    tooltip.current = new Popup({
      anchor,
      offset: (Array.isArray(offset) && [offset[0], -15]) || 0,
      className: 'map-tooltip',
      closeButton: false,
    })
      .setHTML(html)
      .setLngLat(location)
      .addTo(map);
  }

  function unselect() {
    selectedReportIdRef.current = null;
    selectReport(null);
    closePopup();
  }

  function closePopup() {
    Object.values(markers.current).forEach(({ id, marker }) => {
      if (id !== selectedReportIdRef.current && marker.getPopup().isOpen()) marker.togglePopup();
    });
  }

  function openPopup(report: Report) {
    closePopup();

    if (smallDevice) return;

    const {
      geoPoint: {
        coordinates: [lng, lat],
      },
    } = report;
    const selectedMarker =
      selectedReportIdRef.current && markers.current[selectedReportIdRef.current];
    if (!selectedMarker || !reportTypesMap.current) {
      unselect();
      return;
    }
    if (selectedMarker.marker.getPopup().isOpen()) return;

    selectedMarker.marker.togglePopup();

    const ele = document.getElementById(`report-${report.id}-popup`);
    if (ele) {
      const root = createRoot(ele);
      root.render(
        <ReportPopup
          currentUser={currentUser.current}
          onClose={() => {
            if (selectedMarker.marker.getPopup().isOpen()) selectedMarker.marker.togglePopup();
          }}
          onRating={onRating}
          openCommentsDialog={openCommentsDialog}
          report={report}
          sourcesMap={reportSourcesMap.current}
          theme={theme}
          types={reportTypes.current}
        />,
      );
    }

    centerPopup({ lat, lng }, selectedMarker.marker.getPopup(), { height: 400 });
  }

  function reopenPopup(report: Report) {
    selectedReportIdRef.current = null;

    closePopup();

    selectedReportIdRef.current = report.id;

    selectReport(report);
    openPopup(report);
  }

  function addMarker(report: Report) {
    if (!map || markers.current[report.id]) return;
    const {
      id,
      created,
      creator,
      geoPoint: {
        coordinates: [lng, lat],
      },
      typeCode,
      sourceId,
      startDate,
      endDate,
    } = report;
    if (typeCode === 'support' || typeCode === 'exclusionZone') return;

    const reportType = reportTypesMap.current?.[typeCode];
    if (!reportType) return;

    const { color, icon, titleKey } = reportType;
    const marker = createNewImageMarker(
      {
        color,
        icon,
        height: 30,
        round: true,
      },
      {
        onClick: () => {
          clearTooltip();
          if (selectedReportIdRef.current !== id) {
            selectedReportIdRef.current = id;
            selectReport(reportsRef.current.find((report) => id === report.id) || null);
            closePopup();
            openPopup(report.clone());
          }
        },
        onMouseEnter: () =>
          openTooltip(
            id,
            { lat, lng },
            {
              title: `${t(titleKey)} #${id}`,
              created: created.format('L'),
              creator,
              sourceId,
              startDate: startDate ? startDate.format('L') : null,
              endDate: endDate ? endDate.format('L') : null,
            },
            10,
          ),
        onMouseLeave: () => clearTooltip(),
      },
    )
      .setLngLat({ lat, lng })
      .setPopup(
        new Popup({
          maxWidth: '350px',
          closeButton: false,
          closeOnClick: true,
          className: 'map-custom-popup',
          offset: [0, -25],
        })
          .setHTML(`<div id="report-${id}-popup"></div>`)
          .on('close', () => {
            if (selectedReportIdRef.current === id && markers.current[id]) {
              unselect();
            }
          }),
      )
      .addTo(map);

    markers.current[id] = { id, marker };
  }

  function update(reports: Report[], { forceAsMarkers }: { forceAsMarkers?: boolean } = {}) {
    reportsRef.current = reports;

    if (!map || !reportTypesMap) return;

    const zoom = map.getZoom();
    if (zoom >= asMarkerMinZoom || forceAsMarkers) {
      clearCircleMarkers();

      // remove markers that are no longer visible
      Object.values(markers.current)
        .filter(({ id }) => !reports.find((report) => report.id === id))
        .forEach(({ id, marker }) => {
          marker.remove();
          delete markers.current[id];
        });

      // add markers that were not visible
      reports.filter(({ id }) => !markers.current[id]).forEach((report) => addMarker(report));

      // close previous selected report popup
      closePopup();

      // open selected report popup
      const selectedReport =
        selectedReportIdRef.current && reports.find(({ id }) => selectedReportIdRef.current === id);
      if (selectedReport) openPopup(selectedReport.clone());
    } else {
      clearMarkers();

      // add circle markers
      updateGeoJSONSource({
        type: 'FeatureCollection',
        features: reports.reduce<GeoJSON.Feature[]>(
          (
            res,
            { id, created, creator, geoPoint: geometry, typeCode, sourceId, startDate, endDate },
          ) => {
            if (typeCode === 'support' || typeCode === 'exclusionZone') return res;

            const reportType = reportTypesMap.current?.[typeCode];
            if (reportType) {
              const { color, titleKey } = reportType;
              const properties: GeoJSON.GeoJsonProperties = {
                id,
                color,
                title: `${t(titleKey)} #${id}`,
                created: created.format('L'),
                creator,
                sourceId,
              };
              if (startDate) properties.startDate = startDate.format('L');
              if (endDate) properties.endDate = endDate.format('L');

              res.push({
                id,
                type: 'Feature',
                geometry,
                properties,
              });
            }

            return res;
          },
          [],
        ),
      });
    }
  }

  function clearTooltip() {
    tooltip.current?.remove();
  }

  function clearCircleMarkers() {
    clearGeoJSONSource();
  }

  function clearMarkers() {
    Object.values(markers.current).forEach(({ marker }) => marker.remove());
    markers.current = {};
  }

  function clearLayers() {
    reportsRef.current = [];

    clearTooltip();
    clearCircleMarkers();
    clearMarkers();
  }

  function clear() {
    clearLayers();

    map?.off('mouseenter', layerId, handleMouseEnter);
    map?.off('click', layerId, handleClick);
    map?.off('mouseleave', layerId, handleMouseLeave);
  }

  return {
    selectedReport,
    init,
    update,
    unselect,
    reopenPopup,
    clearLayers,
    clear,
  };
}

export default useReports;
