import { graphql, useStaticQuery } from 'gatsby';

import { SiteMetadataQuery } from '../../graphql-types';

function useSiteMetadata() {
  const data = useStaticQuery<SiteMetadataQuery>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteUrl
          title
          icon
        }
      }
    }
  `);

  return data.site?.siteMetadata;
}

export default useSiteMetadata;
