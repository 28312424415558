import { ComputedRoute, Route, useUnits } from '@geovelo-frontends/commons';
import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

function RouteData({
  selectedComputedRoute,
}: {
  selectedComputedRoute?: ComputedRoute | Route;
}): JSX.Element {
  const { t } = useTranslation();
  const { toTime, toDistance } = useUnits();

  return (
    <Box display="flex" gap={2} justifyContent="space-between">
      <Box display="flex" flexDirection="column">
        <Typography fontWeight={600} variant="body2">
          {t('commons.duration')}
        </Typography>
        <Typography color="primary" fontSize="1.125rem" fontWeight={600}>
          {selectedComputedRoute ? (
            toTime(selectedComputedRoute.duration)
          ) : (
            <Skeleton variant="text" width={50} />
          )}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography fontWeight={600} variant="body2">
          {t('commons.distance')}
        </Typography>
        <Typography color="primary" fontSize="1.125rem" fontWeight={600}>
          {selectedComputedRoute ? (
            toDistance(selectedComputedRoute.distances.total)
          ) : (
            <Skeleton variant="text" width={50} />
          )}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography fontWeight={600} variant="body2">
          {t('geovelo.routing.list.vertical_gain')}
        </Typography>
        <Typography color="primary" fontSize="1.125rem" fontWeight={600}>
          {selectedComputedRoute ? (
            `${selectedComputedRoute.verticalGain} m`
          ) : (
            <Skeleton variant="text" width={50} />
          )}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography fontWeight={600} variant="body2">
          {t('geovelo.routing.list.shared_roads')}
        </Typography>
        <Typography color="primary" fontSize="1.125rem" fontWeight={600}>
          {selectedComputedRoute ? (
            `${
              selectedComputedRoute.recommendedRoadsPercentage +
              selectedComputedRoute.normalRoadsPercentage
            } %`
          ) : (
            <Skeleton variant="text" width={50} />
          )}
        </Typography>
      </Box>
    </Box>
  );
}

export default RouteData;
