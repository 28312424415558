import { ButtonBase, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { BackIcon } from '../../../components/icons';

type TSize = 'small' | 'large';

export interface IHeaderProps {
  actions?: React.ReactNode;
  backRoute?: string;
  backgroundImage?: string;
  backgroundColor?: string;
  backgroundSize?: string;
  onBackButtonClick?: () => void;
  onClick?: () => void;
  size?: TSize;
  enableShadow?: boolean;
}

const defaultHeaderProps: IHeaderProps = {
  size: 'small',
};

interface IProps {
  headerProps?: IHeaderProps;
  subtitle?: React.ReactNode | undefined;
  title?: React.ReactNode | undefined;
}

function HeaderContent({
  size,
  title,
  subtitle,
  actions,
}: {
  actions?: React.ReactNode;
  size?: TSize;
  subtitle?: React.ReactNode | undefined;
  title?: React.ReactNode;
}): JSX.Element {
  const [isTitleCut, setIsTitleCut] = useState<boolean>();

  useEffect(() => {
    function handleResize() {
      const title = document.getElementById('title');
      if (title) {
        setIsTitleCut(title.offsetWidth - title.scrollWidth < 0);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (title) {
    return (
      <>
        <TitleWrapper>
          <Tooltip disableHoverListener={!isTitleCut} title={title}>
            <Typography noWrap component="h1" fontSize="1.5rem" fontWeight={700} id="title">
              {title}
            </Typography>
          </Tooltip>
          {subtitle && (
            <Typography noWrap variant="body2">
              {subtitle}
            </Typography>
          )}
        </TitleWrapper>
        <StyledSpacer />
        {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
      </>
    );
  }

  if (title === '') {
    return <TitleWrapper />;
  }

  return (
    <TitleWrapper>
      <Typography component="div" variant={size === 'large' ? 'h5' : 'h6'}>
        <Skeleton variant="text" width={300} />
      </Typography>
    </TitleWrapper>
  );
}

function Header({ headerProps: _headerProps, ...props }: IProps): JSX.Element {
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  const {
    size,
    backgroundColor,
    backgroundImage,
    backgroundSize,
    enableShadow,
    backRoute,
    actions,
    onClick,
    onBackButtonClick,
  }: IHeaderProps = {
    ...defaultHeaderProps,
    ..._headerProps,
  };

  return (
    <Wrapper
      background-size={backgroundSize}
      className={size === 'large' ? 'large' : ''}
      color={backgroundColor}
      image={backgroundImage}
      shadow={enableShadow}
    >
      {onClick && props.title !== (null || undefined) ? (
        <ButtonBase className="container" onClick={onClick}>
          <HeaderContent actions={actions} size={size} {...props} />
        </ButtonBase>
      ) : (
        <div className="container">
          <HeaderContent actions={actions} size={size} {...props} />
        </div>
      )}
      <Tooltip placement="right" title={t('commons.actions.go_back')}>
        <StyledBackButton
          color="inherit"
          onClick={() =>
            onBackButtonClick
              ? onBackButtonClick()
              : window.previousLocation
              ? navigate(-1)
              : navigate(backRoute || `/${currentLanguage}/route`)
          }
          size="small"
        >
          <BackIcon />
        </StyledBackButton>
      </Tooltip>
    </Wrapper>
  );
}

const Wrapper = styled.header<{
  'background-size'?: string;
  color?: string;
  image?: string;
  shadow?: boolean;
}>`
  background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${({ 'background-size': backgroundSize }) => backgroundSize ?? 'cover'};
  box-shadow: ${({ shadow }) => (shadow ? '0 4px 6px rgb(0 0 0 / 20%)}' : '')};
  color: ${({ image }) => (image ? '#fff' : '#000')};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 72px;
  position: relative;

  > .container {
    align-items: center;
    align-self: stretch;
    background: ${({ image }) =>
      image ? 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))' : 'none'};
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    padding: 0 16px 0 66px;
  }

  &.large {
    align-items: flex-start;
    height: 128px;

    > .container {
      padding: 72px 16px 0 16px;
    }
  }
`;

const TitleWrapper = styled.div`
  text-overflow: ellipsis;
  max-width: 100%;
  overflow-x: hidden;
`;

const StyledBackButton = styled(IconButton)`
  && {
    left: 24px;
    position: absolute;
    top: 19px;
  }
`;

const StyledSpacer = styled.div`
  flex-grow: 1;
`;

const ActionsWrapper = styled.div`
  flex-shrink: 0;
  margin-left: 16px;
`;

export default Header;
