import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function IdeaIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M17.0899 2.81995C16.1702 2.06353 15.0933 1.52198 13.9376 1.23479C12.7819 0.947605 11.5767 0.922035 10.4099 1.15995C8.85632 1.47377 7.43093 2.24202 6.31462 3.36723C5.19832 4.49243 4.44141 5.92388 4.13994 7.47995C3.91979 8.64667 3.95979 9.8476 4.2571 10.9971C4.55441 12.1466 5.1017 13.2163 5.85994 14.1299C6.56363 14.924 6.96698 15.9395 6.99994 16.9999V19.9999C6.99994 20.7956 7.31601 21.5587 7.87862 22.1213C8.44123 22.6839 9.20429 22.9999 9.99994 22.9999H13.9999C14.7956 22.9999 15.5587 22.6839 16.1213 22.1213C16.6839 21.5587 16.9999 20.7956 16.9999 19.9999V17.1899C17.0335 16.0191 17.4637 14.8944 18.2199 13.9999C19.5451 12.3607 20.1697 10.2648 19.9581 8.16757C19.7465 6.07038 18.7158 4.14143 17.0899 2.79995V2.81995ZM14.9999 19.9999C14.9999 20.2652 14.8946 20.5195 14.707 20.7071C14.5195 20.8946 14.2652 20.9999 13.9999 20.9999H9.99994C9.73473 20.9999 9.48037 20.8946 9.29284 20.7071C9.1053 20.5195 8.99994 20.2652 8.99994 19.9999V18.9999H14.9999V19.9999ZM16.6699 12.7599C15.6644 13.9525 15.0778 15.442 14.9999 16.9999H12.9999V13.9999C12.9999 13.7347 12.8946 13.4804 12.707 13.2928C12.5195 13.1053 12.2652 12.9999 11.9999 12.9999C11.7347 12.9999 11.4804 13.1053 11.2928 13.2928C11.1053 13.4804 10.9999 13.7347 10.9999 13.9999V16.9999H8.99994C8.97356 15.468 8.40689 13.9947 7.39994 12.8399C6.73558 12.0439 6.28883 11.0894 6.10325 10.0693C5.91767 9.04922 5.99959 7.99846 6.34103 7.01948C6.68247 6.04049 7.27176 5.16669 8.05146 4.48327C8.83116 3.79985 9.77465 3.33015 10.7899 3.11995C11.6625 2.94029 12.5641 2.95719 13.4293 3.16942C14.2945 3.38164 15.1016 3.78387 15.792 4.3469C16.4824 4.90993 17.0387 5.61962 17.4206 6.42446C17.8025 7.22931 18.0004 8.10909 17.9999 8.99995C18.0073 10.3698 17.537 11.6994 16.6699 12.7599Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
