import { Box, SvgIcon, SvgIconProps, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

import emptyStateIcon from '../assets/images/plotty-search.svg';

function EmptyState({
  Icon,
  title,
  text,
  action,
  hideIcon,
}: {
  action?: ReactNode;
  hideIcon?: boolean;
  Icon?: ((props: SvgIconProps) => JSX.Element) | typeof SvgIcon;
  text?: ReactNode;
  title?: ReactNode;
}): JSX.Element {
  return (
    <Box alignItems="center" display="flex" flexDirection="column" gap={3} marginX={6}>
      {!hideIcon &&
        (Icon ? (
          <Icon fontSize="large" sx={{ color: '#666666' }} />
        ) : (
          <img alt="" src={emptyStateIcon} width={80} />
        ))}
      <Box alignItems="center" display="flex" flexDirection="column" gap={1}>
        {title && (
          <Typography align="center" fontWeight={700} variant="body1">
            {title}
          </Typography>
        )}
        {text && (
          <Typography align="center" color="textSecondary" variant="caption">
            {text}
          </Typography>
        )}
      </Box>
      {action}
    </Box>
  );
}

export default EmptyState;
