import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function ArrowRightIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 12 13" {...props}>
      <path
        d="M11.92 6.19788C11.8724 6.07513 11.801 5.96299 11.71 5.86788L6.71 0.867883C6.61676 0.774645 6.50607 0.700684 6.38425 0.650224C6.26243 0.599763 6.13186 0.573792 6 0.573792C5.7337 0.573792 5.4783 0.679579 5.29 0.867883C5.19676 0.961122 5.1228 1.07181 5.07234 1.19363C5.02188 1.31546 4.99591 1.44602 4.99591 1.57788C4.99591 1.84418 5.1017 2.09958 5.29 2.28788L8.59 5.57788H1C0.734784 5.57788 0.48043 5.68324 0.292893 5.87078C0.105357 6.05831 0 6.31267 0 6.57788C0 6.8431 0.105357 7.09745 0.292893 7.28499C0.48043 7.47253 0.734784 7.57788 1 7.57788H8.59L5.29 10.8679C5.19627 10.9608 5.12188 11.0714 5.07111 11.1933C5.02034 11.3152 4.9942 11.4459 4.9942 11.5779C4.9942 11.7099 5.02034 11.8406 5.07111 11.9625C5.12188 12.0843 5.19627 12.1949 5.29 12.2879C5.38296 12.3816 5.49356 12.456 5.61542 12.5068C5.73728 12.5575 5.86799 12.5837 6 12.5837C6.13201 12.5837 6.26272 12.5575 6.38458 12.5068C6.50644 12.456 6.61704 12.3816 6.71 12.2879L11.71 7.28788C11.801 7.19278 11.8724 7.08063 11.92 6.95788C12.02 6.71442 12.02 6.44134 11.92 6.19788Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
