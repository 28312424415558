import { useEffect, useState } from 'react';

import { defaultLanguage, languagesMap } from '../languages';

function usePrismicNodes<T>(
  nodes: Array<{ lang: string; data: T }> | undefined,
  { currentLanguage }: { currentLanguage: string },
) {
  const [data, setData] = useState<T | undefined>(getData());

  useEffect(() => {
    setData(getData());
  }, [currentLanguage, nodes]);

  function getData() {
    let node = nodes?.find(({ lang }) => languagesMap[lang] === currentLanguage);

    if (node) return node.data;
    else {
      node = nodes?.find(({ lang }) => languagesMap[lang] === defaultLanguage);
      if (node) return node.data;
    }
  }

  return { data };
}

export default usePrismicNodes;
