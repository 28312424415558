import { Button, Search, UserPlace, userPlaceTypes } from '@geovelo-frontends/commons';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CloseIcon } from './icons';

interface IProps {
  search?: Search;
  onClose: () => void;
  onSearchUpdated: () => void;
  place: UserPlace;
  theme: Theme;
}

function UserPlacePopup({ theme, place, search, onSearchUpdated, onClose }: IProps): JSX.Element {
  const { t } = useTranslation();
  const { type, title } = place;
  const { titleKey } = place && userPlaceTypes[type];

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <CloseButtonWrapper>
          <Tooltip placement="left" title={t('commons.actions.close')}>
            <IconButton onClick={() => onClose()} size="small">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </CloseButtonWrapper>
        <StyledHeader>
          <div style={{ overflowX: 'hidden' }}>
            <StyledTitle title={title} variant="h6">
              {title || (titleKey && t(titleKey)) || ''}
            </StyledTitle>
          </div>
        </StyledHeader>
        <StyledActions>
          <Button
            color="primary"
            onClick={() => {
              if (search && !search.wayPoints[0]) search.wayPoints.splice(0, 1, place.clone());
              else search?.wayPoints.splice(0, 0, place.clone());
              onSearchUpdated();
              onClose();
            }}
            size="small"
            variant="contained"
          >
            {t('commons.actions.leave_from_here')}
          </Button>
          <Button
            color="primary"
            onClick={() => {
              if (search && !search.wayPoints[1]) search.wayPoints.splice(1, 1, place.clone());
              else search?.wayPoints.push(place.clone());
              onSearchUpdated();
              onClose();
            }}
            size="small"
            variant="contained"
          >
            {t('commons.actions.go_there')}
          </Button>
        </StyledActions>
      </Wrapper>
    </ThemeProvider>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: max(calc(50vh - 24px), 400px);
  overflow-y: hidden;
  position: relative;
  width: min(100vw - 120px, 350px);
`;

const CloseButtonWrapper = styled.div`
  padding: 8px;
  position: absolute;
  right: 0;
  top: 0;

  button {
    background-color: rgba(255, 255, 255, 0.9);

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
`;

const StyledHeader = styled.div`
  display: flex;
  flex-shrink: 0;
  padding: 16px 16px 8px 16px;
`;

const StyledTitle = styled(Typography)<{ component?: 'a'; href?: string; target?: '_blank' }>`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  height: 48px;
  padding: 0 16px;

  button {
    margin-left: 8px;
  }
`;

export default UserPlacePopup;
