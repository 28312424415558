import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function EditIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M15.5472 4.76397C15.7894 4.52176 16.077 4.32963 16.3935 4.19855C16.7099 4.06747 17.0491 4 17.3916 4C17.7342 4 18.0734 4.06747 18.3898 4.19855C18.7063 4.32963 18.9938 4.52176 19.236 4.76397C19.4782 5.00618 19.6704 5.29373 19.8015 5.61019C19.9325 5.92665 20 6.26583 20 6.60837C20 6.9509 19.9325 7.29008 19.8015 7.60655C19.6704 7.92301 19.4782 8.21055 19.236 8.45276L9.07208 18.6167L4 20L5.3833 14.9279L15.5472 4.76397Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
