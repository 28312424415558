import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function NotFoundIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 155 154" {...props}>
      <g clipPath="url(#clip0_6533_47567)">
        <ellipse cx="78" cy="125.5" fill="#E0EAF9" rx="76.5" ry="6.5" />
        <path
          d="M32.104 77.796C40.2643 81.6362 44.0465 73.9138 51.0541 71.3154"
          fill="none"
          stroke="#B0360F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.91441"
        />
        <path
          d="M29.6108 56.1212C29.6108 37.8249 28.6501 23 21.4814 23C8.50212 23 1.51779 38.3093 1.51779 56.6056C1.51779 56.6056 0.355512 90.8799 16.8214 122.6C17.8283 124.543 22.0979 126.552 22.8976 124.516C35.0197 93.6729 29.605 56.1212 29.605 56.1212H29.6108Z"
          fill="#B0360F"
        />
        <path
          d="M34.6479 56.1212C34.6479 37.8249 28.8369 23 21.6741 23C14.5114 23 8.70063 37.8306 8.70063 56.1212C8.70063 56.1212 8.10235 93.1864 20.2244 124.036C21.0241 126.071 22.3244 126.071 23.1298 124.036C35.2519 93.1922 34.6536 56.1212 34.6536 56.1212H34.6479Z"
          fill="#EB6B23"
        />
        <path
          d="M24.8952 81.1809C28.8764 81.1809 32.104 71.7142 32.104 60.0362C32.104 48.3583 28.8764 38.8916 24.8952 38.8916C20.9138 38.8916 17.6863 48.3583 17.6863 60.0362C17.6863 71.7142 20.9138 81.1809 24.8952 81.1809Z"
          fill="white"
        />
        <path
          d="M25.2601 56.6067C25.9113 56.6067 26.4394 55.1093 26.4394 53.2622C26.4394 51.4152 25.9113 49.918 25.2601 49.918C24.6086 49.918 24.0806 51.4152 24.0806 53.2622C24.0806 55.1093 24.6086 56.6067 25.2601 56.6067Z"
          fill="#212121"
        />
        <path
          d="M30.07 56.6067C30.4767 56.6067 30.8062 55.1093 30.8062 53.2622C30.8062 51.4152 30.4767 49.918 30.07 49.918C29.6632 49.918 29.3335 51.4152 29.3335 53.2622C29.3335 55.1093 29.6632 56.6067 30.07 56.6067Z"
          fill="#212121"
        />
        <path
          d="M135.814 122.429C144.336 116.345 149.709 104.726 149.843 95.9827C149.843 78.3688 135.596 64.0898 118.022 64.0898C100.448 64.0898 86.2007 78.3688 86.2007 95.9827C87.2329 107.836 92.0528 116.07 100.23 122.429H135.814Z"
          fill="none"
          stroke="#B0360F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="6"
        />
        <rect fill="#B0360F" height="4" width="3" x="112.803" y="116" />
        <path
          d="M56.8721 88.2949L56.8737 121.081"
          fill="none"
          stroke="#313131"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8.21618"
        />
        <path
          d="M45.1348 123.763H68.4185"
          fill="none"
          stroke="#313131"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4.43633"
        />
        <path
          d="M45.1348 67.2529H68.4185"
          fill="none"
          stroke="#313131"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4.43633"
        />
        <path
          d="M56.7766 88.3904V66.8828"
          fill="none"
          stroke="#313131"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.91441"
        />
        <path
          d="M56.8735 121.081C81.1562 100.996 103.303 89.4998 113.803 115.5"
          fill="none"
          stroke="#313131"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.91441"
        />
      </g>
      <defs>
        <clipPath id="clip0_6533_47567">
          <rect fill="white" height="154" transform="translate(0.5)" width="154" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
