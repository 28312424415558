import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  RidesAndTripsBikeRouteDataFragment,
  RidesAndTripsQuery,
  RidesAndTripsRideDataFragment,
} from '../../../graphql-types';
import Seo from '../../components/seo';
import { TPageProps } from '../../page-props';
import Content from '../content';

function RidesAndTripsPage(props: TPageProps<RidesAndTripsQuery>) {
  const { t } = useTranslation();

  const title = props.data.prismicRidesAndTrips?.data.title?.text;
  const description = props.data.prismicRidesAndTrips?.data.description?.text;

  return (
    <>
      <Seo
        description={description}
        title={`${title || t('geovelo.pages_titles.rides_and_trips')}`}
        {...props}
      />
      <Content
        {...props}
        enableStoresInBanner
        bikeRoutesData={props.data.allBikeRouteProd.nodes.reduce<RidesAndTripsRideDataFragment[]>(
          (res, { data }) => {
            if (data) res.push(data);

            return res;
          },
          [],
        )}
        query={props.data.prismicRidesAndTrips}
        ridesData={props.data.allRideProd.nodes.reduce<RidesAndTripsBikeRouteDataFragment[]>(
          (res, { data }) => {
            if (data) res.push(data);

            return res;
          },
          [],
        )}
      />
    </>
  );
}

export const query = graphql`
  fragment PrismicRidesAndTripsBanner on PrismicRidesAndTripsDataBodyBanner {
    id
    slice_type
    primary {
      background_color_start
      background_color_end
      dark_background
      fullscreen_background
      background_image {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
      baseline {
        html
      }
      subtitle {
        html
      }
    }
    items {
      cta_link {
        link_type
        slug
        url
      }
      cta_title {
        text
      }
      cta_anchor {
        text
      }
      cta_color
    }
  }
  fragment PrismicRidesAndTripsRidesList on PrismicRidesAndTripsDataBodyRideslist {
    id
    slice_type
    primary {
      section_id {
        text
      }
      cta_baseline {
        text
      }
      cta_link {
        url
      }
      title {
        text
      }
      description {
        html
      }
      linked_to_next_slice
    }
    items {
      ride_id
    }
  }
  fragment PrismicRidesAndTripsLoopGenerator on PrismicRidesAndTripsDataBodyLoopgenerator {
    id
    slice_type
    primary {
      background_color
      top_right_background_image {
        gatsbyImageData(placeholder: NONE, width: 300)
      }
      icon {
        gatsbyImageData(placeholder: NONE, width: 150)
      }
      title {
        text
      }
      description {
        html
      }
      departure_placeholder {
        text
      }
      cta_title {
        text
      }
    }
  }
  fragment PrismicRidesAndTripsHighlightedRide on PrismicRidesAndTripsDataBodyHighlightedride {
    id
    slice_type
    primary {
      image {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
      tag_label {
        text
      }
      ride_id
      cta_title {
        text
      }
      partnership_text {
        text
      }
      partner_image {
        gatsbyImageData(placeholder: NONE, width: 128)
      }
    }
  }
  fragment PrismicRidesAndTripsBikeRoutesList on PrismicRidesAndTripsDataBodyBikerouteslist {
    id
    slice_type
    primary {
      section_id {
        text
      }
      linked_to_next_slice
      variant
      background_image {
        gatsbyImageData(placeholder: NONE)
      }
      title {
        text
      }
      subtitle {
        text
      }
      see_all_button_label {
        text
      }
    }
    items {
      bike_route_id
      logo {
        gatsbyImageData(placeholder: NONE, width: 24)
      }
    }
  }
  fragment PrismicRidesAndTripsTripPlanner on PrismicRidesAndTripsDataBodyTripplanner {
    id
    slice_type
    primary {
      background_color
      title {
        text
      }
      description {
        text
      }
      departure_placeholder {
        text
      }
      arrival_placeholder {
        text
      }
      cta_title {
        text
      }
      image {
        gatsbyImageData(placeholder: NONE)
      }
    }
  }
  fragment PrismicRidesAndTripsHighlightedBikeRoute on PrismicRidesAndTripsDataBodyHighlightedbikeroute {
    id
    slice_type
    primary {
      image {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
      bike_route_id
      steps_title {
        text
      }
      steps_cta_title {
        text
      }
      pois_title {
        text
      }
      pois_cta_title {
        text
      }
      cta_title {
        text
      }
    }
  }
  fragment PrismicRidesAndTripsBottomBanner on PrismicRidesAndTripsDataBodyBottombanner {
    id
    slice_type
    primary {
      background_color
      image {
        gatsbyImageData(placeholder: NONE)
      }
      image_align
      baseline {
        text
        html
      }
      app_store_rating
      google_play_rating
      number_of_millions_of_downloads
    }
  }
  fragment PrismicRidesAndTripsCatalog on PrismicRidesAndTripsDataBodyCatalog {
    id
    slice_type
    primary {
      title {
        text
      }
      cta_title {
        text
      }
    }
  }
  fragment RidesAndTripsData on PrismicRidesAndTripsData {
    title {
      text
    }
    description {
      text
    }
    body {
      ... on PrismicRidesAndTripsDataBodyBanner {
        ...PrismicRidesAndTripsBanner
      }
      ... on PrismicRidesAndTripsDataBodyRideslist {
        ...PrismicRidesAndTripsRidesList
      }
      ... on PrismicRidesAndTripsDataBodyLoopgenerator {
        ...PrismicRidesAndTripsLoopGenerator
      }
      ... on PrismicRidesAndTripsDataBodyHighlightedride {
        ...PrismicRidesAndTripsHighlightedRide
      }
      ... on PrismicRidesAndTripsDataBodyBikerouteslist {
        ...PrismicRidesAndTripsBikeRoutesList
      }
      ... on PrismicRidesAndTripsDataBodyTripplanner {
        ...PrismicRidesAndTripsTripPlanner
      }
      ... on PrismicRidesAndTripsDataBodyHighlightedbikeroute {
        ...PrismicRidesAndTripsHighlightedBikeRoute
      }
      ... on PrismicRidesAndTripsDataBodyBottombanner {
        ...PrismicRidesAndTripsBottomBanner
      }
      ... on PrismicRidesAndTripsDataBodyCatalog {
        ...PrismicRidesAndTripsCatalog
      }
    }
  }
  fragment RidesAndTripsRideData on rideProdData {
    id
    title
    description
    is_loop
    geo_point_a {
      coordinates
      type
    }
    geo_point_a_title
    geo_point_b {
      coordinates
      type
    }
    geo_point_b_title
    geometry_condensed {
      coordinates
      type
    }
    distance
    route_duration
    duration
    vertical_gain
    difficulty
    themes
    photos {
      id
      thumbnail
    }
  }
  fragment RidesAndTripsBikeRouteData on bikeRouteProdData {
    id
    title
    photos {
      id
      title
      thumbnail
      url
    }
    distance
    description
    rides {
      ride_id
      order_atob
    }
  }
`;

export default withPrismicPreview(RidesAndTripsPage);
