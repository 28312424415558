import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function NextIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M15.54 11.29L9.88001 5.63998C9.78705 5.54625 9.67645 5.47185 9.55459 5.42108C9.43273 5.37032 9.30202 5.34418 9.17001 5.34418C9.038 5.34418 8.90729 5.37032 8.78544 5.42108C8.66358 5.47185 8.55298 5.54625 8.46001 5.63998C8.27376 5.82734 8.16922 6.08079 8.16922 6.34498C8.16922 6.60916 8.27376 6.86261 8.46001 7.04998L13.41 12.05L8.46001 17C8.27376 17.1873 8.16922 17.4408 8.16922 17.705C8.16922 17.9692 8.27376 18.2226 8.46001 18.41C8.55263 18.5045 8.66307 18.5796 8.78495 18.6311C8.90682 18.6826 9.03771 18.7094 9.17001 18.71C9.30232 18.7094 9.4332 18.6826 9.55508 18.6311C9.67695 18.5796 9.7874 18.5045 9.88001 18.41L15.54 12.76C15.6415 12.6663 15.7225 12.5527 15.7779 12.4262C15.8333 12.2997 15.8619 12.1631 15.8619 12.025C15.8619 11.8869 15.8333 11.7503 15.7779 11.6238C15.7225 11.4973 15.6415 11.3836 15.54 11.29Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
