import { SearchIcon } from '@geovelo-frontends/commons';
import {
  Box,
  InputAdornment,
  Pagination,
  PaginationItem,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { graphql, navigate } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlogPostPreviewFragment, BlogQuery } from '../../../graphql-types';
import Button from '../../components/button';
import {
  NotFoundIcon,
  PaginationFirstIcon,
  PaginationLastIcon,
  PaginationNextIcon,
  PaginationPrevIcon,
} from '../../components/icons';
import Seo from '../../components/seo';
import Banner from '../../components/slices/banner';
import { maxWidth } from '../../components/slices/consts';
import useQueryParams from '../../hooks/query-params';
import { TPageProps } from '../../page-props';

import BlogPostCard from './blog-post-card';

function BlogPage(props: TPageProps<BlogQuery>) {
  const [filteredBlogPosts, filterBlogPosts] = useState<BlogPostPreviewFragment[]>();
  const [paginatedBlogPosts, paginateBlogPosts] = useState<BlogPostPreviewFragment[]>();
  const [search, setSearch] = useState('');
  const { get: getQueryParams, update: updateQueryParams } = useQueryParams(props.location.search);
  const [selectedTheme, selectTheme] = useState<string | null>(() => {
    const { theme } = getQueryParams();
    if (theme)
      return (
        props.data.allPrismicBlogPostTheme.nodes.find(
          ({ data: { title } }) =>
            theme ===
            title?.text
              ?.toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/\s+/g, '-')
              .replace(/\'/g, '-'),
        )?.uid || null
      );

    return null;
  });
  const [pageCount, setPageCount] = useState(1);
  const [pageIndex, setPageIndex] = useState(1);
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (process.env.GATSBY_BLOG_DISABLED === 'true') navigate(`/`);
    else if (!props.data.prismicBlog) navigate(`/fr/blog`);
  }, []);

  useEffect(() => {
    const posts: BlogPostPreviewFragment[] = props.data.allPrismicBlogPost.nodes
      .filter(
        ({ data: { themes, title } }) =>
          title?.text &&
          (!selectedTheme || themes?.find((data) => data?.theme?.uid === selectedTheme)) &&
          (!search ||
            title.text
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .indexOf(
                search
                  .trim()
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, ''),
              ) > -1),
      )
      .sort((a, b) =>
        moment(a.data.publication_date || a.first_publication_date).isBefore(
          moment(b.data.publication_date || b.first_publication_date),
        )
          ? 1
          : -1,
      );

    setPageIndex(1);
    if (posts.length <= 7) setPageCount(1);
    else setPageCount(Math.ceil((posts.length - 7) / 9) + 1);
    filterBlogPosts(posts);
  }, [search, selectedTheme]);

  useEffect(() => {
    if (pageIndex === 1) paginateBlogPosts(filteredBlogPosts?.slice(0, 7));
    else {
      paginateBlogPosts(
        filteredBlogPosts?.slice(7 + 9 * (pageIndex - 2), 7 + 9 * (pageIndex - 2) + 9),
      );
    }
  }, [filteredBlogPosts, pageIndex]);

  if (!props.data.prismicBlog || process.env.GATSBY_BLOG_DISABLED === 'true') return <></>;

  const {
    allPrismicBlogPostTheme: { nodes: themes },
    prismicBlog: {
      data: { title, description, image: _image },
    },
  } = props.data;

  return (
    <>
      <Seo
        description={description?.text}
        title={`${title?.text || t('geovelo.pages_titles.blog')}`}
        {...props}
      />
      <Banner
        centerText={true}
        data={{
          id: 'blog_banner',
          slice_type: 'banner',
          primary: {
            background_image: _image,
            baseline: { text: 'Blog' },
            dark_background: true,
            fullscreen_background: true,
            subtitle: {},
          },
        }}
        small={true}
      />
      <Box alignItems="center" display="flex" flexDirection="column">
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          gap={6}
          maxWidth="100%"
          paddingX={{ xs: 4, md: 8, lg: 16 }}
          paddingY={{ xs: 6, lg: 8 }}
          width={maxWidth}
        >
          <Box maxWidth="calc(100% - 48px)" width={476}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ fontSize: '14px' }} />
                  </InputAdornment>
                ),
                sx: { backgroundColor: '#f2f2f2', borderRadius: 2 },
              }}
              margin="none"
              onChange={({ target: { value } }) => setSearch(value)}
              placeholder={`${t('commons.actions.search') || ''}...`}
              size="small"
              sx={{ fieldset: { border: '0 !important' } }}
              value={search}
              variant="outlined"
            />
          </Box>
          <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
            {themes.map(
              ({ uid, data: { title } }) =>
                title?.text && (
                  <Button
                    disableElevation
                    className={selectedTheme === uid ? 'active' : ''}
                    color="primary"
                    key={uid}
                    onClick={() => {
                      if (selectedTheme === uid) {
                        selectTheme(null);
                        updateQueryParams({ theme: null });
                      } else {
                        selectTheme(uid);
                        updateQueryParams({
                          theme: title.text
                            ?.toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .replace(/\s+/g, '-')
                            .replace(/\'/g, '-'),
                        });
                      }
                    }}
                    variant={selectedTheme === uid ? 'contained' : 'outlined'}
                  >
                    {title.text}
                  </Button>
                ),
            )}
          </Box>
          <Box display="flex" flexWrap="wrap" gap={5}>
            {paginatedBlogPosts ? (
              paginatedBlogPosts.length > 0 ? (
                paginatedBlogPosts.map((blogPost, index) => (
                  <BlogPostCard
                    blogPost={blogPost}
                    key={blogPost.uid}
                    large={!isSmallScreen && pageIndex === 1 && index === 0}
                    themes={themes}
                  />
                ))
              ) : (
                <Box
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  flexGrow={1}
                  gap={2}
                  marginY={5}
                >
                  <NotFoundIcon sx={{ height: 100, width: 100 }} />
                  <Typography>{t('geovelo.blog.empty_state')}</Typography>
                </Box>
              )
            ) : (
              new Array(7)
                .fill(null)
                .map((_, index) => (
                  <BlogPostCard key={index} large={!isSmallScreen && index === 0} themes={themes} />
                ))
            )}
          </Box>
          {(!paginatedBlogPosts || paginatedBlogPosts.length > 0) && (
            <Box display="flex" justifyContent="center">
              <Pagination
                showFirstButton
                showLastButton
                color="primary"
                count={pageCount}
                disabled={!paginatedBlogPosts}
                onChange={(_, index) => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  updateQueryParams({ page: index });
                  setPageIndex(index);
                }}
                page={pageIndex}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{
                      first: PaginationFirstIcon,
                      previous: PaginationPrevIcon,
                      next: PaginationNextIcon,
                      last: PaginationLastIcon,
                    }}
                    {...item}
                  />
                )}
                sx={{
                  '.MuiPaginationItem-firstLast': { border: '1px solid #f1f1f1' },
                  '.MuiPaginationItem-previousNext': { border: '1px solid #f1f1f1' },
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export const query = graphql`
  fragment PrismicBlogPostTheme on PrismicBlogPostTheme {
    _previewable
    uid
    data {
      title {
        text
      }
    }
  }
  fragment PrismicBlogPostThemes on PrismicBlogPostThemeConnection {
    nodes {
      ...PrismicBlogPostTheme
    }
  }
  fragment BlogPostPreview on PrismicBlogPost {
    _previewable
    uid
    url
    first_publication_date
    data {
      title {
        text
      }
      publication_date
      themes {
        theme {
          uid
        }
      }
      image {
        gatsbyImageData(width: 580, placeholder: DOMINANT_COLOR)
        alt
        copyright
      }
      subtitle_short {
        text
      }
    }
  }
  fragment PrismicBlogPosts on PrismicBlogPostConnection {
    nodes {
      ...BlogPostPreview
    }
  }
  fragment BlogData on PrismicBlogData {
    title {
      text
    }
    description {
      text
    }
    image {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
`;

export default withPrismicPreview(BlogPage);
