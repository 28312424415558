import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ApiQuery } from '../../../graphql-types';
import Seo from '../../components/seo';
import { TPageProps } from '../../page-props';
import Content from '../content';

function ApiPage(props: TPageProps<ApiQuery>) {
  const { t } = useTranslation();

  const title = props.data.prismicApi?.data.title?.text;
  const description = props.data.prismicApi?.data.description?.text;

  return (
    <>
      <Seo
        description={description}
        title={`${title || t('geovelo.pages_titles.api')}`}
        {...props}
      />
      <Content {...props} query={props.data.prismicApi} />
    </>
  );
}

export const query = graphql`
  fragment PrismicApiBanner on PrismicApiDataBodyBanner {
    id
    slice_type
    primary {
      background_color_start
      background_color_end
      dark_background
      image {
        gatsbyImageData(placeholder: NONE)
      }
      baseline {
        html
      }
      subtitle {
        html
      }
    }
    items {
      cta_link {
        link_type
        url
      }
      cta_anchor {
        text
      }
      cta_title {
        text
      }
      cta_color
      cta_variant
    }
  }
  fragment PrismicApiLogos on PrismicApiDataBodyLogos {
    id
    slice_type
    primary {
      background_color
      background_color_end
      linked_to_prev_slice
      variant
      align
      title {
        text
        html
      }
    }
    items {
      item_logo {
        gatsbyImageData(placeholder: NONE)
      }
      item_title {
        text
      }
    }
  }
  fragment PrismicApiHorizontalCard on PrismicApiDataBodyHorizontalcard {
    id
    slice_type
    primary {
      linked_to_next_slice
      header_type
      image_on_the_right
      image_type
      image {
        gatsbyImageData(placeholder: NONE)
      }
      title {
        text
        html
      }
      description {
        text
        html
      }
    }
    items {
      cta_type
      cta_variant
      cta_title {
        text
      }
      cta_link {
        link_type
        url
      }
    }
  }
  fragment PrismicApiKeynumberscard on PrismicApiDataBodyKeynumberscard {
    id
    slice_type
    primary {
      background_color
      items_color
      items_border_color
      icon {
        gatsbyImageData(placeholder: NONE, width: 120)
      }
      baseline {
        text
      }
    }
    items {
      key_number {
        text
      }
      number_label {
        text
      }
    }
  }
  fragment PrismicApiContactForm on PrismicApiDataBodyContactform {
    id
    slice_type
    primary {
      section_id {
        text
      }
      background_color
      title {
        text
      }
      image {
        gatsbyImageData(placeholder: NONE, width: 230)
      }
      pipedrive_form_id {
        text
      }
    }
  }
  fragment ApiData on PrismicApiData {
    title {
      text
    }
    description {
      text
    }
    body {
      ... on PrismicApiDataBodyBanner {
        ...PrismicApiBanner
      }
      ... on PrismicApiDataBodyHorizontalcard {
        ...PrismicApiHorizontalCard
      }
      ... on PrismicApiDataBodyLogos {
        ...PrismicApiLogos
      }
      ... on PrismicApiDataBodyKeynumberscard {
        ...PrismicApiKeynumberscard
      }
      ... on PrismicApiDataBodyContactform {
        ...PrismicApiContactForm
      }
    }
  }
`;

export default withPrismicPreview(ApiPage);
