import { Autocomplete, BikeRoute, FilterIcon, Place, Ride } from '@geovelo-frontends/commons';
import { Box, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { navigate } from 'gatsby';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SortIcon } from '../../components/icons';
import { AppContext } from '../../context';

const autocompleteId = 'place-search';

interface IProps<TSortKey extends string> {
  customSearchOptions?: (Ride | BikeRoute)[];
  disableCard?: boolean;
  searchLabel: string;
  selectedSort?: TSortKey;
  sorts?: Array<{ key: TSortKey; label: string }>;
  onFiltersDialogOpen?: () => void;
  onInputChange?: (input: string) => void;
  onSortChange?: (key: TSortKey) => void;
}

function Header<TSortKey extends string>({
  disableCard,
  searchLabel,
  customSearchOptions,
  sorts,
  selectedSort,
  onInputChange,
  onSortChange,
  onFiltersDialogOpen,
}: IProps<TSortKey>): JSX.Element {
  const [defaultSearch, setDefaultSearch] = useState<Place | null>(null);
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    map: { current: map },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  function handleSelect(place: Place | null) {
    setDefaultSearch(place);
    if (place && map) {
      const [lng, lat] = place.point.coordinates;
      map.flyTo({ center: [lng, lat], zoom: 15 });
    }

    setTimeout(() => {
      setDefaultSearch(null);
      document.getElementById(autocompleteId)?.blur();
    }, 100);
  }

  function handleCustomOptionSelect(option: Ride | BikeRoute) {
    if (option instanceof Ride) navigate(`/${currentLanguage}/rides/${option.id}`);
    else if (option instanceof BikeRoute) navigate(`/${currentLanguage}/ride-sets/${option.id}`);
  }

  function handleSortChange(key: TSortKey) {
    onSortChange?.(key);
    setSortMenuAnchorEl(null);
  }

  return (
    <>
      <Wrapper className={disableCard ? '' : 'card'}>
        <Autocomplete
          disableFloatingLabel
          center={map?.getCenter()}
          customOptions={customSearchOptions}
          defaultValue={defaultSearch}
          id={autocompleteId}
          inputProps={{
            sx: { backgroundColor: '#fff', margin: 0 },
            InputProps: { sx: { '&&': { borderRadius: 2 } } },
          }}
          label={searchLabel}
          nbResults={3}
          onCustomOptionSelect={handleCustomOptionSelect}
          onInputChange={onInputChange}
          onSelect={handleSelect}
          size="small"
        />
        {sorts && sorts.length > 1 && (
          <>
            <Tooltip placement="left" title={t('commons.actions.sort')}>
              <IconButton
                color="primary"
                onClick={({ currentTarget }) => setSortMenuAnchorEl(currentTarget)}
                size="small"
                sx={({ palette }) => ({
                  '&&': { border: `1px solid ${palette.primary.main}`, borderRadius: 2 },
                })}
              >
                <SortIcon />
              </IconButton>
            </Tooltip>
            <Menu
              keepMounted
              anchorEl={sortMenuAnchorEl}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              id="sort-menu"
              onClose={() => setSortMenuAnchorEl(null)}
              open={Boolean(sortMenuAnchorEl)}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              {sorts.map(({ key, label }) => (
                <MenuItem dense key={key} onClick={() => handleSortChange(key)}>
                  <Typography
                    color={key === selectedSort ? 'primary' : 'initial'}
                    style={{ fontSize: 'initial' }}
                  >
                    {label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
        {onFiltersDialogOpen && (
          <Tooltip placement="left" title={t('commons.actions.filter')}>
            <IconButton
              color="primary"
              onClick={() => onFiltersDialogOpen()}
              size="small"
              sx={() => ({
                '&&': {
                  border: `1px solid rgba(50, 106, 194, 0.5)`,
                  borderRadius: 2,
                  '&:hover': { border: `1px solid #326ac2` },
                },
              })}
            >
              <FilterIcon />
            </IconButton>
          </Tooltip>
        )}
      </Wrapper>
      {!disableCard && <Divider />}
    </>
  );
}

const Wrapper = styled(Box)`
  && {
    align-items: center;
    border-radius: 0;
    display: flex;
    flex-shrink: 0;
    z-index: 2;

    &.card {
      height: 64px;
      padding: 0 16px;
    }

    &:not(.card) {
      background-color: transparent;
    }

    > *:not(:first-child) {
      margin-left: 16px;
    }
  }
`;

export default Header;
