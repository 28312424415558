import {
  Parking,
  ParkingService,
  useCancellablePromise,
  useParkingLots as useCommonsParkingLots,
} from '@geovelo-frontends/commons';
import { useTheme } from '@mui/material/styles';
import { useContext, useEffect } from 'react';

import { AppContext } from '../../context';

import { Map } from '!maplibre-gl';

function useParkingLots(
  map: Map | null,
  { smallDevice }: { smallDevice?: boolean } = {},
): { selectedParking: Parking | null; update: () => void; unselect: () => void } {
  const {
    map: { parkingSpacesShowed },
  } = useContext(AppContext);
  const theme = useTheme();
  const {
    initialized,
    selectedParking,
    init: initMarkers,
    update: updateMarkers,
    unselect,
    clear: clearMarkers,
  } = useCommonsParkingLots(map, theme, smallDevice);
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    return () => {
      cancelPromises();
    };
  }, []);

  useEffect(() => {
    if (map) initMarkers();
  }, [map]);

  useEffect(() => {
    if (initialized) {
      update();

      map?.on('moveend', update);
    }

    return () => {
      clearMarkers();
      map?.off('moveend', update);
    };
  }, [initialized, parkingSpacesShowed]);

  async function update() {
    cancelPromises();
    if (!map) return;

    if (!parkingSpacesShowed) {
      clearMarkers();
      return;
    }

    try {
      const [[west, south], [east, north]] = map.getBounds().toArray();
      const parkingLots = await cancellablePromise(
        ParkingService.getParkingLots({
          zoom: map.getZoom(),
          bounds: { north, east, south, west },
        }),
      );

      updateMarkers(parkingLots);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') console.error(err);
    }
  }

  return { selectedParking, unselect, update };
}

export default useParkingLots;
