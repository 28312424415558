import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function DiscoverIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M22.78 11.88L20.78 9.38C20.6868 9.26215 20.5684 9.16679 20.4333 9.101C20.2983 9.03521 20.1502 9.00069 20 9H14V3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5195 2.10536 13.2652 2 13 2C12.7348 2 12.4804 2.10536 12.2929 2.29289C12.1053 2.48043 12 2.73478 12 3V4H5.99998C5.84977 4.00069 5.70165 4.03521 5.56662 4.101C5.43158 4.16679 5.3131 4.26215 5.21998 4.38L3.21998 6.88C3.08047 7.05655 3.00458 7.27499 3.00458 7.5C3.00458 7.72501 3.08047 7.94345 3.21998 8.12L5.21998 10.62C5.3131 10.7379 5.43158 10.8332 5.56662 10.899C5.70165 10.9648 5.84977 10.9993 5.99998 11H12V20H9.99998C9.73476 20 9.48041 20.1054 9.29287 20.2929C9.10533 20.4804 8.99998 20.7348 8.99998 21C8.99998 21.2652 9.10533 21.5196 9.29287 21.7071C9.48041 21.8946 9.73476 22 9.99998 22H16C16.2652 22 16.5195 21.8946 16.7071 21.7071C16.8946 21.5196 17 21.2652 17 21C17 20.7348 16.8946 20.4804 16.7071 20.2929C16.5195 20.1054 16.2652 20 16 20H14V16H20C20.1502 15.9993 20.2983 15.9648 20.4333 15.899C20.5684 15.8332 20.6868 15.7379 20.78 15.62L22.78 13.12C22.9195 12.9435 22.9954 12.725 22.9954 12.5C22.9954 12.275 22.9195 12.0565 22.78 11.88ZM12 9H6.47998L5.27998 7.5L6.47998 6H12V9ZM19.52 14H14V11H19.52L20.72 12.5L19.52 14Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
