import { IPhoto, useTracker } from '@geovelo-frontends/commons';
import { Box, ButtonBase, Card, CardMedia, Typography } from '@mui/material';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  PrismicRidesAndTripsDataBodyHighlightedbikeroutePrimary,
  PrismicRidesAndTripsHighlightedBikeRouteFragment,
  RidesAndTripsBikeRouteDataFragment,
  RidesAndTripsRideDataFragment,
} from '../../../graphql-types';
import { parseBikeRoute } from '../../utils/bike-route';
import { parseRide } from '../../utils/ride';
import Button from '../button';
import { ArrowRightIcon, BinocularsIcon } from '../icons';
import RideCard from '../ride-card';

import BikeRouteStats from './bike-route-stats';
import { maxWidth } from './consts';
import CTA from './cta';
import Description from './description';
import Title from './title';

export type THighlightedBikeRouteData = PrismicRidesAndTripsHighlightedBikeRouteFragment;

function HighlightedBikeRoute({
  bikeRoutesData,
  ridesData,
  data: { primary },
}: {
  bikeRoutesData?: RidesAndTripsBikeRouteDataFragment[];
  data: THighlightedBikeRouteData;
  ridesData?: RidesAndTripsRideDataFragment[];
}): JSX.Element {
  const { trackEvent } = useTracker();
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  if (!primary) return <></>;

  const {
    image: _image,
    bike_route_id,
    steps_title,
    steps_cta_title,
    pois_title,
    pois_cta_title,
    cta_title,
  } = primary as PrismicRidesAndTripsDataBodyHighlightedbikeroutePrimary;

  const bikeRouteData = bike_route_id && bikeRoutesData?.find(({ id }) => id === bike_route_id);
  const bikeRoute = bikeRouteData && parseBikeRoute(bikeRouteData, 'https://backend.geovelo.fr');
  if (!bikeRoute) return <></>;

  const { id, title, description, rides: _rides, photos } = bikeRoute;
  const rides = _rides
    .sort((a, b) => a.order - b.order)
    .map(({ rideId }) => ridesData?.find(({ id }) => id === rideId))
    .map((data) => data && parseRide(data, 'https://backend.geovelo.fr'))
    .filter(Boolean)
    .slice(0, 3);

  const image = _image && getImage(_image);

  return (
    <Box
      alignItems="center"
      alignSelf="stretch"
      display="flex"
      flexDirection="column"
      paddingBottom={8}
      paddingTop={{ xs: '250px', sm: '500px' }}
      position="relative"
    >
      {image && (
        <Box
          height={{ xs: '375px', sm: '750px' }}
          left={0}
          position="absolute"
          top={0}
          width="100%"
          zIndex={0}
        >
          <GatsbyImage
            alt=""
            image={image}
            objectFit="cover"
            style={{ height: '100%', width: '100%' }}
          />
        </Box>
      )}
      <Box
        alignItems="stretch"
        borderRadius={5}
        display="flex"
        flexDirection="column"
        maxWidth={{ xs: 'calc(100% - 64px)', md: 'calc(100% - 128px)', lg: 'calc(100% - 256px)' }}
        sx={{ backgroundColor: '#fff' }}
        width={maxWidth}
        zIndex={1}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          gap={10}
          paddingTop={10}
          paddingX={{ xs: 4, md: 8, lg: 16 }}
        >
          <Box alignItems="center" display="flex" flexDirection="column" gap={3}>
            <Box alignItems="center" alignSelf="center" display="flex" gap={2}>
              <BinocularsIcon color="secondary" />
              <Typography color="secondary" variant="subtitle1">
                {t('geovelo.focus_on')}
              </Typography>
            </Box>
            <Title align="center" component="h2" data={{ text: title }} />
            <BikeRouteStats bikeRoute={bikeRoute} size="medium" />
            {description && <Description align="center" data={{ text: description }} />}
          </Box>
          {rides.length > 0 && (
            <Box
              alignItems="stretch"
              alignSelf="stretch"
              display="flex"
              flexDirection="column"
              gap={3}
            >
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                gap={2}
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="column">
                  <Title
                    data={{
                      text:
                        steps_title?.text ||
                        t('geovelo.rides_and_trips.highlighted_bike_route.steps.title'),
                    }}
                    fontSize="1.25em"
                  />
                </Box>
                <Button
                  color="primary"
                  component={Link}
                  endIcon={<ArrowRightIcon sx={{ '&&&': { fontSize: '12px' } }} />}
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                  to={`/${currentLanguage}/ride-sets/${id}?tab=steps`}
                >
                  {steps_cta_title?.text ||
                    t('geovelo.rides_and_trips.highlighted_bike_route.steps.action')}
                </Button>
              </Box>
              <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
                {rides &&
                  rides.map(
                    (ride) =>
                      ride && (
                        <Box
                          key={ride.id}
                          sx={{
                            width: {
                              xs: '100%',
                              sm: 'calc((100% - 20px) / 2)',
                              lg: 'calc((100% - 38px) / 3)',
                            },
                          }}
                        >
                          <ButtonBase
                            component={Link}
                            sx={{ height: '100%', width: '100%' }}
                            to={`/${currentLanguage}/rides/${id}`}
                          >
                            <RideCard
                              disableDuration
                              disableVerticalGain
                              enableDescription
                              fullHeight
                              outlined
                              ride={ride}
                            />
                          </ButtonBase>
                        </Box>
                      ),
                  )}
              </Box>
            </Box>
          )}
          {photos.length > 0 && (
            <Box
              alignItems="stretch"
              alignSelf="stretch"
              display="flex"
              flexDirection="column"
              gap={3}
            >
              <Box
                alignItems="center"
                display="flex"
                flexDirection="row"
                gap={2}
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="column">
                  <Title
                    data={{
                      text:
                        pois_title?.text ||
                        t('geovelo.rides_and_trips.highlighted_bike_route.pois.title'),
                    }}
                    fontSize="1.25em"
                  />
                </Box>
                <Button
                  color="primary"
                  component={Link}
                  endIcon={<ArrowRightIcon sx={{ '&&&': { fontSize: '12px' } }} />}
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                  to={`/${currentLanguage}/ride-sets/${id}`}
                >
                  {pois_cta_title?.text ||
                    t('geovelo.rides_and_trips.highlighted_bike_route.pois.action')}
                </Button>
              </Box>
              <Box display="flex" gap={3}>
                <Box flexShrink={0} width="calc(50% - 12px)">
                  <Photo data={photos[0]} />
                </Box>
                <Box display="flex" flexGrow={1} flexWrap="wrap" gap={3}>
                  {photos.slice(1, 5).map((photo) => (
                    <Box flexShrink={0} key={photo.id} width="calc(50% - 12px)">
                      <Photo data={photo} />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          )}
          <Box>
            <CTA
              link={{ link_type: 'Document', url: `/${currentLanguage}/ride-sets/${id}` }}
              onClick={() =>
                trackEvent('Calculates a trip', 'Clicked', 'Click on discover hub promoted travel')
              }
              title={{
                text: cta_title?.text || t('geovelo.rides_and_trips.highlighted_bike_route.action'),
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function Photo({ data: { title, url } }: { data: IPhoto }): JSX.Element {
  return (
    <Box paddingTop="100%" position="relative">
      <Card
        sx={{
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
      >
        <CardMedia alt={title || ''} component="img" height="100%" image={url} />
      </Card>
    </Box>
  );
}

export default HighlightedBikeRoute;
