import { RideTheme, TBackendRideThemeTitle, rideThemeKeys } from '@geovelo-frontends/commons';

import { RideThemeData } from '../../graphql-types';

export function parseRideTheme({ id, title }: RideThemeData): RideTheme | null {
  if (!title) return null;

  const key = rideThemeKeys[title as TBackendRideThemeTitle];
  if (!key) return null;

  return new RideTheme(id || -1, key);
}
