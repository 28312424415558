import { FilledLinkToDocumentField } from '@prismicio/types';

import { languagesMap } from './languages';

function linkResolver({ uid, lang, type }: FilledLinkToDocumentField) {
  if (type === 'home') return `/${languagesMap[lang]}`;
  if (type === 'rides_and_trips') return `/${languagesMap[lang]}/rides-and-trips`;
  if (type === 'rides_hub') return `/${languagesMap[lang]}/rides-hub`;
  if (type === 'routing') return `/${languagesMap[lang]}/route`;
  if (type === 'rides') return `/${languagesMap[lang]}/rides`;
  if (type === 'trips') return `/${languagesMap[lang]}/trips`;
  if (type === 'communities') return `/${languagesMap[lang]}/communities`;
  if (type === 'trip_planner') return `/${languagesMap[lang]}/trip-planner`;
  if (type === 'api') return `/${languagesMap[lang]}/api`;
  if (type === 'territories') return `/${languagesMap[lang]}/territories`;
  if (type === 'mission') return `/${languagesMap[lang]}/our-mission`;
  if (type === 'privacy_policy') return `/${languagesMap[lang]}/privacy-policy`;
  if (type === 'eula') return `/${languagesMap[lang]}/eula`;
  if (type === 'legal_notice') return `/${languagesMap[lang]}/legal-notice`;
  if (type === 'cookies_policy') return `/${languagesMap[lang]}/cookies-policy`;
  if (type === 'press_area') return `/${languagesMap[lang]}/press-area`;
  if (type === 'blog') return `/${languagesMap[lang]}/blog`;
  if (type === 'blog_post') return `/${languagesMap[lang]}/blog/${uid}`;
  if (type === 'download') return `/${languagesMap[lang]}/download`;
  if (type === 'join_us') return `/${languagesMap[lang]}/join-us`;

  return '/';
}

export default linkResolver;
