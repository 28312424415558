import { BikeRoute, DistanceIcon, FlagIcon, Ride, useUnits } from '@geovelo-frontends/commons';
import { Box, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import defaultBikeRouteImage from '../assets/images/bike-route.jpg';

function BikeRouteCard({
  fullHeight,
  outlined,
  bikeRoute,
  rides: allRides,
}: {
  bikeRoute?: BikeRoute;
  fullHeight?: boolean;
  outlined?: boolean;
  rides?: Ride[];
}): JSX.Element {
  const [rides, setRides] = useState<Ride[]>();
  const { t } = useTranslation();
  const { toDistance } = useUnits();

  useEffect(() => {
    if (bikeRoute && allRides)
      setRides(allRides.filter(({ id }) => bikeRoute.rides.find(({ rideId }) => rideId === id)));
  }, [allRides]);

  const to = rides?.[rides.length - 1].to;

  return (
    <Box
      border={outlined ? `1px solid #CFCFCF` : undefined}
      borderRadius={4}
      display="flex"
      flexDirection="column"
      gap={outlined ? 0 : 3}
      height={fullHeight ? '100%' : undefined}
      overflow="hidden"
      padding={outlined ? 0 : 3}
      width="100%"
    >
      {bikeRoute ? (
        <Box
          borderRadius={outlined ? 0 : 4}
          height={240}
          sx={{
            backgroundImage: `url(${bikeRoute.photos[0]?.thumbnailUrl || defaultBikeRouteImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        />
      ) : (
        <Skeleton height={240} sx={{ borderRadius: outlined ? 0 : 4 }} variant="rectangular" />
      )}
      <Box display="flex" flexDirection="column" gap={1} padding={outlined ? 3 : 0}>
        <Box display="flex" flexDirection="column">
          <Typography fontSize="1.125rem" fontWeight={700}>
            {bikeRoute ? bikeRoute.title : <Skeleton variant="text" width={200} />}
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {rides ? (
              <>
                {rides[0].from?.address}
                {to && <>&nbsp;&#x3e;&nbsp;{to.address}</>}
              </>
            ) : (
              <Skeleton variant="text" width={150} />
            )}
          </Typography>
        </Box>
        <Box columnGap={2} display="flex" flexWrap="wrap">
          <Box alignItems="center" display="flex" gap={0.5}>
            <DistanceIcon color="primary" sx={{ height: 16, width: 16 }} />
            <Typography variant="body2">
              {bikeRoute ? (
                toDistance(bikeRoute.distance || 0)
              ) : (
                <Skeleton variant="text" width={50} />
              )}
            </Typography>
          </Box>
          <Box alignItems="center" display="flex" gap={0.5}>
            <FlagIcon color="primary" sx={{ height: 16, width: 16 }} />
            <Typography variant="body2">
              {bikeRoute ? (
                `${t('geovelo.bike_routes.list_item.steps', {
                  count: bikeRoute.stepsCount,
                })}`
              ) : (
                <Skeleton variant="text" width={50} />
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BikeRouteCard;
