import { Box } from '@mui/material';
import React from 'react';

import {
  PrismicPressAreaDataBodyPressreleasePrimary,
  PrismicPressAreaPressReleaseFragment,
} from '../../../graphql-types';

import { maxWidth } from './consts';
import PressArticle from './press-article';
import Title from './title';

export type TPressReleaseData = PrismicPressAreaPressReleaseFragment;

function PressRelease({ data: { primary, items } }: { data: TPressReleaseData }): JSX.Element {
  if (!primary) return <></>;

  const { title } = primary as PrismicPressAreaDataBodyPressreleasePrimary;

  return (
    <Box
      alignItems="center"
      alignSelf="stretch"
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gap={5}
        maxWidth="100%"
        paddingBottom={10}
        paddingTop={10}
        paddingX={{ xs: 4, md: 8, lg: 16 }}
        width={maxWidth}
        zIndex={1}
      >
        {title?.text && (
          <Box
            alignSelf="stretch"
            display="flex"
            flexDirection="column"
            gap={2}
            justifyContent="center"
          >
            <Title data={title} fontSize="2em" sx={{ textAlign: 'center' }} />
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={5}>
          {items.map((item, index) => (
            <PressArticle data={item} key={index} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default PressRelease;
