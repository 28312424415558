import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function FireWheelIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props}>
      <path
        d="M45.1201 8.51562C39.1598 15.9662 33.6962 10.0057 23.2657 10.0057C23.7304 10.8532 32.5787 15.6548 32.9269 16.4629C38.9047 30.3377 28.2645 38.3784 20.2855 39.4155C29.7585 39.4155 33.9177 38.2366 38.1664 35.3377C39.8752 34.1718 42.1399 31.8608 42.6366 29.3773C40.1532 30.3707 39.6565 28.8806 39.1598 28.3839C40.6498 26.8937 44.6234 25.9003 45.6168 21.43C40.6498 22.9201 41.1465 21.43 41.1465 21.43C41.1465 19.705 48.1002 15.9662 45.1201 8.51562Z"
        fill="#FCCE2C"
      />
      <path
        d="M21.6294 25.0531L12.3342 34.3486L12.954 34.9684L22.2492 25.6729L31.8558 35.2798L32.4756 34.66L22.869 25.0531L31.8559 16.0659L31.2361 15.4461L22.2492 24.4333L12.6424 14.8262L12.0226 15.446L21.6294 25.0531Z"
        fill="#B0B0B0"
        stroke="#B0B0B0"
        strokeWidth="1.65566"
      />
      <path
        clipRule="evenodd"
        d="M21.2964 25.6749V38.8157H22.952V25.6749L35.1843 25.6749V24.0193L22.952 24.0193V11.7598L21.2964 11.7598V24.0193L8.25049 24.0193L8.25049 25.6749L21.2964 25.6749Z"
        fill="#B0B0B0"
        fillRule="evenodd"
      />
      <path
        d="M21.2964 38.8157H20.4685V39.6435H21.2964V38.8157ZM21.2964 25.6749H22.1242V24.8471H21.2964V25.6749ZM22.952 38.8157V39.6435H23.7799V38.8157H22.952ZM22.952 25.6749V24.8471L22.1242 24.8471V25.6749H22.952ZM35.1843 25.6749V26.5027H36.0122V25.6749H35.1843ZM35.1843 24.0193H36.0122V23.1914H35.1843V24.0193ZM22.952 24.0193H22.1242V24.8471H22.952V24.0193ZM22.952 11.7598H23.7799V10.9319L22.952 10.9319V11.7598ZM21.2964 11.7598V10.9319L20.4685 10.9319V11.7598H21.2964ZM21.2964 24.0193V24.8471H22.1242V24.0193H21.2964ZM8.25049 24.0193L8.25049 23.1914L7.42266 23.1914L7.42266 24.0193H8.25049ZM8.25049 25.6749H7.42266L7.42266 26.5027H8.25049L8.25049 25.6749ZM22.1242 38.8157V25.6749H20.4685V38.8157H22.1242ZM22.952 37.9878H21.2964V39.6435H22.952V37.9878ZM22.1242 25.6749V38.8157H23.7799V25.6749H22.1242ZM35.1843 24.8471L22.952 24.8471V26.5027L35.1843 26.5027V24.8471ZM34.3565 24.0193V25.6749H36.0122V24.0193H34.3565ZM22.952 24.8471L35.1843 24.8471V23.1914L22.952 23.1914V24.8471ZM22.1242 11.7598V24.0193H23.7799V11.7598H22.1242ZM21.2964 12.5876L22.952 12.5876V10.9319L21.2964 10.9319V12.5876ZM22.1242 24.0193V11.7598H20.4685V24.0193H22.1242ZM8.25049 24.8471L21.2964 24.8471V23.1914L8.25049 23.1914L8.25049 24.8471ZM9.07832 25.6749L9.07832 24.0193H7.42266L7.42266 25.6749H9.07832ZM21.2964 24.8471L8.25049 24.8471L8.25049 26.5027L21.2964 26.5027V24.8471Z"
        fill="#B0B0B0"
      />
      <path
        d="M34.7171 24.9078C34.7171 31.782 29.1446 37.3546 22.2706 37.3546C15.3967 37.3546 9.82416 31.782 9.82416 24.9078C9.82416 18.0335 15.3967 12.4609 22.2706 12.4609C29.1446 12.4609 34.7171 18.0335 34.7171 24.9078Z"
        fill="none"
        stroke="#BB8A1A"
        strokeWidth="2.92175"
      />
      <path
        d="M36.0758 24.907C36.0758 32.5316 29.895 38.7125 22.2707 38.7125C14.6464 38.7125 8.46565 32.5316 8.46565 24.907C8.46565 17.2824 14.6464 11.1015 22.2707 11.1015C29.895 11.1015 36.0758 17.2824 36.0758 24.907Z"
        fill="none"
        stroke="#212121"
        strokeWidth="2.19132"
      />
      <ellipse
        cx="1.75303"
        cy="1.75308"
        fill="#313131"
        rx="1.75303"
        ry="1.75308"
        transform="matrix(1 0 0 -1 20.5167 26.6602)"
      />
    </SvgIcon>
  );
}
