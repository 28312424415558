import { Box } from '@mui/material';
import { Script } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';

import {
  PrismicApiContactFormFragment,
  PrismicApiDataBodyContactformPrimary,
  PrismicTerritoriesContactFormFragment,
  PrismicTerritoriesDataBodyContactformPrimary,
} from '../../../graphql-types';

import { maxWidth } from './consts';
import Title from './title';

export type TContactFormData =
  | PrismicTerritoriesContactFormFragment
  | PrismicApiContactFormFragment;

function ContactForm({ data: { primary } }: { data: TContactFormData }): JSX.Element {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);

    return () => setReady(false);
  }, []);

  if (!primary) return <></>;

  const {
    section_id,
    background_color,
    title,
    image: _image,
    pipedrive_form_id,
  } = primary as PrismicTerritoriesDataBodyContactformPrimary &
    PrismicApiDataBodyContactformPrimary;
  const uuid = pipedrive_form_id?.text;

  if (!uuid) return <></>;

  const image = _image && getImage(_image);

  return (
    <>
      {ready && <Script src="https://webforms.pipedrive.com/f/loader" />}
      <Box
        alignItems="center"
        alignSelf="stretch"
        display="flex"
        flexDirection="column"
        position="relative"
        sx={{ backgroundColor: background_color }}
      >
        <Box
          alignItems={{ xs: 'stretch', md: 'flex-start' }}
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          gap={5}
          id={section_id?.text || undefined}
          maxWidth="100%"
          paddingBottom={10}
          paddingTop={16}
          paddingX={{ xs: 4, md: 8, lg: 16 }}
          sx={{ scrollMargin: 72 }}
          width={maxWidth}
          zIndex={1}
        >
          <Box display="flex" flexDirection="column" flexShrink={0} gap={3}>
            <Title data={title} />
            <Box display={{ xs: 'none', md: 'block' }}>
              {image && <GatsbyImage alt="" image={image} />}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" flexGrow={1} height={608}>
            <Box
              className="pipedriveWebForms"
              flexGrow={1}
              sx={{ '&&': { iframe: { maxWidth: 'none', overflowY: 'auto' } } }}
            >
              <iframe
                height="100%"
                src={`https://webforms.pipedrive.com/f/${uuid}?embeded=1&uuid=${uuid}`}
                style={{ border: 0 }}
                width="100%"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ContactForm;
