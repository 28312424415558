import { Box, Typography } from '@mui/material';
import React from 'react';

import { PrismicJoinUsWhoAreWeFragment } from '../../../graphql-types';

import { maxWidth } from './consts';
import Description from './description';
import Title from './title';

export type TWhoAreWeData = PrismicJoinUsWhoAreWeFragment;

function WhoAreWe({ data: { primary, items } }: { data: TWhoAreWeData }): JSX.Element {
  if (!primary) return <></>;

  const { title, text, video, key_numbers_title } = primary;

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap={10}
      maxWidth={{ xs: 'calc(100% - 64px)', md: 'calc(100% - 128px)', lg: 'calc(100% - 256px)' }}
      paddingTop={{ xs: 5, md: 10 }}
      width={maxWidth}
    >
      <Box display="flex" flexDirection="column" gap={3} maxWidth="100%" width={782}>
        <Title component="h2" data={title} />
        <Description data={text} sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }} />
        {video?.url && (
          <Box marginTop={7} paddingTop={`${(563 / 1000) * 100}%`} position="relative">
            <Box bottom={0} left={0} position="absolute" right={0} top={0}>
              <iframe allowFullScreen frameBorder={0} height="100%" src={video.url} width="100%" />
            </Box>
          </Box>
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={6}>
        <Title align="center" component="h3" data={key_numbers_title} />
        <Box display="flex" flexWrap="wrap" gap={3}>
          {items.map(({ key_number_value, key_number_unit, key_number_label }, index) => {
            return (
              <Box
                alignItems="center"
                bgcolor="#EEF9F4"
                borderRadius={5}
                display="flex"
                flexDirection="column"
                gap={1}
                key={index}
                paddingX={3}
                paddingY={2}
                width={{
                  xs: '100%',
                  sm: 'calc((100% - 24px) / 2)',
                  md: 'calc((100% - 48px) / 3)',
                  lg: `calc((100% - 24px * ${items.length - 1}) / ${items.length})`,
                }}
              >
                <Typography color="#03825C" fontSize="2.5rem" fontWeight={700}>
                  {key_number_value.text}
                  {key_number_unit.text && (
                    <>
                      {' '}
                      <Typography component="span" fontSize="1.5rem" fontWeight={700}>
                        {key_number_unit.text}
                      </Typography>
                    </>
                  )}
                </Typography>
                <Description align="center" data={key_number_label} fontWeight={600} />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default WhoAreWe;
