import { Box, Typography } from '@mui/material';
import React from 'react';

import { StarIcon } from '../icons';

function StoreRating({
  rating,
  value,
  label,
  variant,
}: {
  rating?: boolean;
  label: string;
  value: number | string;
  variant?: boolean;
}): JSX.Element {
  return (
    <Box
      alignItems={variant ? 'flex-start' : 'center'}
      color={variant ? '#fff' : undefined}
      display="flex"
      flexDirection={variant ? 'column' : 'row'}
      flexShrink={0}
      gap={rating ? (variant ? 0 : 1) : 0}
    >
      {!variant && rating && <StarIcon sx={{ fontSize: '16px' }} />}
      <Box alignItems="center" display="flex" gap={1}>
        <Typography fontSize={variant ? '1.5em' : '0.875em'} fontWeight={variant ? 700 : 600}>
          {value}
          {rating ? ` / 5` : ''}
        </Typography>
        {variant && rating && <StarIcon sx={{ fontSize: '16px' }} />}
      </Box>
      <Typography fontSize={variant ? '1.5em' : '0.875em'}>
        {!rating && !variant && <>&nbsp;</>}
        {label}
      </Typography>
    </Box>
  );
}

export default StoreRating;
