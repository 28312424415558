import { Box, Skeleton } from '@mui/material';
import React from 'react';

import { TEntry } from './road-type-legend';

function RoadTypeBar({
  entries,
  values,
}: {
  entries: Array<TEntry>;
  values?: number[];
}): JSX.Element {
  return values ? (
    <Box borderRadius={3} display="flex" height={6} overflow="hidden">
      {entries.map(({ key, color }, index) => {
        const last = index === entries.length - 1;
        return (
          <Box
            flexGrow={last ? 1 : 0}
            flexShrink={0}
            key={key}
            sx={{ backgroundColor: color }}
            title={`${values[index]}%`}
            width={last ? undefined : `${values[index]}%`}
          />
        );
      })}
    </Box>
  ) : (
    <Skeleton height={6} sx={{ borderRadius: 3 }} variant="rectangular" />
  );
}

export default RoadTypeBar;
