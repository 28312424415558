import {
  ComputedRoute,
  DeepLinkService,
  InfoCard,
  Search,
  TBikeType,
  TCyclingProfile,
  TWayPoint,
  useTracker,
} from '@geovelo-frontends/commons';
import { Box, Typography } from '@mui/material';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RoutingQuery } from '../../../graphql-types';
import Button from '../../components/button';
import { FavoriteIcon, InfoIcon, ListIcon, RoutingIcon, UploadIcon } from '../../components/icons';
import MapMarkers, { TMapMarkersRef } from '../../components/map-markers';
import Seo from '../../components/seo';
import { AppContext, defaultSmallDeviceShowDetailsAction } from '../../context';
import { bikeStationsMinZoom } from '../../hooks/map/bike-stations';
import useQueryParams from '../../hooks/query-params';
import LeftPanelLayout from '../../layouts/app-content/left-panel';
import { TPageProps } from '../../page-props';
import { getCurrentGeoagglo } from '../../utils/geoagglo';

import RoutingFormWrapper, { TRoutingFormWrapperRef } from './form';
import ImportGpxDialog from './import-gpx-dialog';
import RoutingResults from './results';

function RoutingPage(props: TPageProps<RoutingQuery>) {
  const [computedRoutes, setComputedRoutes] = useState<ComputedRoute[] | null | undefined>(null);
  const [selectedComputedRouteIndex, selectComputedRouteIndex] = useState(0);
  const [gpxDialogOpen, openGpxDialog] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const { trackEvent } = useTracker();
  const {
    map: { current: map },
    geoagglo: { countries, cities: geoagglos },
    user: { current: currentUser, routes: userRoutes },
    actions: {
      setSmallDeviceShowDetailsAction,
      setSmallDeviceRedirectAction,
      setCurrentGeoagglo,
      getUserRoutes,
    },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const search = useRef<Search>();
  const routingFormRef = useRef<TRoutingFormWrapperRef>(null);
  const mapMarkersRef = useRef<TMapMarkersRef>(null);
  const {
    get: getQueryParams,
    getFrom,
    getSteps,
    getTo,
    update: updateQueryParams,
  } = useQueryParams(props.location.search);
  const [wayPoints, setWayPoints] = useState<TWayPoint[]>([getFrom(), ...getSteps(), getTo()]);
  const { 'e-bike': _eBike, 'bike-type': _bikeType } = getQueryParams();

  useEffect(() => {
    initSearch();
    updateQueryParams({ search: search.current });
    setInitialized(true);

    return () => {
      setSmallDeviceShowDetailsAction(defaultSmallDeviceShowDetailsAction);
      setCurrentGeoagglo(null);
    };
  }, []);

  useEffect(() => {
    function update() {
      updateGeoagglos();
    }

    if (initialized && map) {
      update();
      map.on('moveend', update);
    }

    return () => {
      map?.off('moveend', update);
    };
  }, [initialized, map]);

  useEffect(() => {
    if (currentUser) {
      if (!userRoutes) getUserRoutes();
    }
  }, [currentUser]);

  useEffect(() => {
    if (map && geoagglos) updateGeoagglos();
  }, [map, geoagglos, wayPoints]);

  useEffect(() => {
    if (computedRoutes)
      setSmallDeviceShowDetailsAction({ labelKey: 'geovelo.routing.list.title', Icon: ListIcon });
    else
      setSmallDeviceShowDetailsAction({
        labelKey: 'commons.actions.compute_route',
        Icon: RoutingIcon,
      });
  }, [computedRoutes]);

  useEffect(() => {
    const computedRoute = computedRoutes?.[selectedComputedRouteIndex];
    if (computedRoute) {
      const deepLinkCallback = DeepLinkService.getCallback({
        androidLink: `france/computed-route/${computedRoute.id}`,
        iosLink: `computed-route/${computedRoute.id}`,
      });

      if (deepLinkCallback) {
        setSmallDeviceRedirectAction({
          labelKey: 'geovelo.actions.navigate_on_app',
          callback: () => {
            trackEvent('General stores button', 'Clicked', 'Navigation redirection computed route');
            deepLinkCallback;
          },
        });
      }
    }

    return () => setSmallDeviceRedirectAction();
  }, [computedRoutes, selectedComputedRouteIndex]);

  function initSearch() {
    const _search = window.previousLocation?.state?.search;

    if (_search && _search instanceof Search) search.current = _search;
    else {
      let profile: TCyclingProfile = 'daily';
      let bikeType: TBikeType = 'own';
      let eBikeEnabled = false;
      if (currentUser) {
        const {
          parameters: { profile: _profile, bikeType: _bikeType, eBike: _eBikeEnabled },
        } = currentUser;

        profile = _profile === 'hybridBike' || _profile === 'cargo' ? 'daily' : _profile;
        bikeType =
          _profile === 'hybridBike' || _profile === 'cargo'
            ? _profile
            : _bikeType === 'shared'
              ? 'shared'
              : 'own';
        eBikeEnabled = _eBikeEnabled;
      }

      if (_bikeType === 'cargo' || _bikeType === 'hybridBike') {
        profile = _bikeType;
        bikeType = _bikeType;
      } else if (_bikeType === 'shared') {
        bikeType = 'shared';
      }
      if (_eBike === 'true' || _eBike === 'false') eBikeEnabled = _eBike === 'true';

      search.current = new Search({
        profile,
        bikeType,
        eBikeEnabled,
        transportModes: bikeType === 'shared' ? ['bike', 'pedestrian'] : ['bike'],
        wayPoints,
      });
    }
  }

  function updateGeoagglos() {
    if (
      !map ||
      !geoagglos ||
      !search.current ||
      search.current.wayPoints.find(Boolean) ||
      map.getZoom() < 5
    )
      return;

    const { city, country } = getCurrentGeoagglo(
      map.getBounds(),
      map.getZoom(),
      geoagglos,
      countries || [],
    );

    setCurrentGeoagglo(city || country || null);
  }

  if (!search.current && typeof window !== 'undefined') return <></>;

  return (
    <>
      <Seo
        description={
          props.data.prismicRouting?.data.description?.text ||
          t('geovelo.pages_descriptions.routing') ||
          ''
        }
        title={`${
          props.data.prismicRouting?.data.title?.text || t('geovelo.pages_titles.routing')
        }`}
        {...props}
      />
      <LeftPanelLayout title={t('geovelo.navigation.routing')} {...props}>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <RoutingFormWrapper
            {...props}
            computedRoutes={computedRoutes}
            mapInteractionsDisabled={mapMarkersRef.current?.hasPopupOpen}
            ref={routingFormRef}
            search={search.current || new Search()}
            selectComputedRouteIndex={selectComputedRouteIndex}
            selectedComputedRouteIndex={selectedComputedRouteIndex}
            setComputedRoutes={setComputedRoutes}
            setWayPoints={setWayPoints}
            updateQueryParams={updateQueryParams}
            wayPoints={wayPoints}
          />
          {computedRoutes === null ? (
            search.current &&
            search.current.bikeType === 'shared' &&
            !search.current.computable &&
            map &&
            map.getZoom() < bikeStationsMinZoom && (
              <InfoCard
                description={t('geovelo.routing.too_far_for_bike_stations')}
                Icon={InfoIcon}
                iconProps={{ color: 'secondary' }}
                sx={{ borderRadius: 2, margin: 2 }}
                variant="outlined"
              />
            )
          ) : (
            <RoutingResults
              computedRoutes={computedRoutes}
              path={props.path}
              search={search.current || new Search()}
              selectComputedRouteIndex={selectComputedRouteIndex}
              selectedComputedRouteIndex={selectedComputedRouteIndex}
            />
          )}
          {currentUser !== null && computedRoutes === null && (
            <Box display="flex" flexDirection="column" gap={2} padding={3}>
              <Typography fontWeight={600} variant="body2">
                {t('geovelo.routing.existing_route')}
              </Typography>
              <Box display="flex" gap={2}>
                <Button
                  color="primary"
                  onClick={() => openGpxDialog(true)}
                  size="medium"
                  startIcon={<UploadIcon />}
                  variant="outlined"
                >
                  {t('geovelo.actions.import_gpx')}
                </Button>
                {(!userRoutes || userRoutes.length > 0) && (
                  <Button
                    color="primary"
                    component={Link}
                    disabled={!userRoutes}
                    size="medium"
                    startIcon={<FavoriteIcon />}
                    to={`/${currentLanguage}/user?tab=favorites`}
                    variant="outlined"
                  >
                    {t('geovelo.navigation.my_favorites')}
                  </Button>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </LeftPanelLayout>
      <MapMarkers
        enableBikeStations
        enableUserPlaces
        computedRoute={computedRoutes?.[selectedComputedRouteIndex]}
        path={props.path}
        ref={mapMarkersRef}
        routingFormRef={routingFormRef.current}
        search={search.current}
      />
      <ImportGpxDialog onClose={() => openGpxDialog(false)} open={gpxDialogOpen} />
    </>
  );
}

export default RoutingPage;
