import {
  PoiCategory,
  TBackendPoiCategoryCode,
  poiCategoryCodesMap,
  poiCategoryColors,
  poiCategoryIcons,
  poiCategoryLabelKeys,
  poiCategoryOrders,
} from '@geovelo-frontends/commons';

import { PoiCategoryData } from '../../graphql-types';

export function parsePoiCategories(_categories: PoiCategoryData[]): PoiCategory[] {
  const parentCodes: TBackendPoiCategoryCode[] = ['BICYCLE', 'USEFUL', 'TOURISM'];
  const categories = [
    { id: -1, code: 'BICYCLE', parent: null },
    { id: -2, code: 'BICYCLE_PARKING', parent: -1 },
    { id: -3, code: 'TOURISM', parent: null },
    ..._categories,
  ]
    .filter(({ code }) => code && poiCategoryCodesMap[code as TBackendPoiCategoryCode])
    .map((data) => {
      if (data.code && ['BICYCLE_PUMP', 'BICYCLE_REPAIR', 'CHARGING_STATION'].includes(data.code))
        return { ...data, parent: -1 };
      if (
        data.code &&
        ['BICYCLE_RENTAL', 'ACCOMODATION', 'TO_DO_TO_SEE', 'RESTAURANT'].includes(data.code)
      )
        return { ...data, parent: -3 };

      return data;
    });

  let order = 1000;

  return parentCodes.reduce<PoiCategory[]>((res, _code) => {
    const code = poiCategoryCodesMap[_code];
    const category = categories.find(({ code }) => code === _code);
    if (code && category) {
      const parentColor = poiCategoryColors[code];
      const parentIcon = poiCategoryIcons[code];
      const _children = categories.filter(({ parent }) => parent === category.id);
      const children = _children.map(({ id: childId, code: _childCode }) => {
        const childCode = poiCategoryCodesMap[_childCode as TBackendPoiCategoryCode];

        return new PoiCategory(
          childId || -1,
          childCode,
          poiCategoryLabelKeys[childCode],
          poiCategoryColors[childCode] || parentColor,
          poiCategoryIcons[childCode] || parentIcon,
          code,
          categories
            .filter(({ parent }) => parent === childId)
            .map(({ id: childChildId, code: _childChildCode }) => {
              const childChildCode =
                poiCategoryCodesMap[_childChildCode as TBackendPoiCategoryCode];

              return new PoiCategory(
                childChildId || -1,
                childChildCode,
                poiCategoryLabelKeys[childChildCode],
                poiCategoryColors[childChildCode] || poiCategoryColors[childCode] || parentColor,
                poiCategoryIcons[childChildCode] || poiCategoryIcons[childCode] || parentIcon,
                code,
                [],
                poiCategoryOrders[childChildCode] || order++,
              );
            }),
          poiCategoryOrders[childCode] || order++,
        );
      });

      res.push(
        new PoiCategory(
          category.id || -1,
          code,
          poiCategoryLabelKeys[code],
          parentColor,
          parentIcon,
          null,
          children,
          poiCategoryOrders[code] || order++,
        ),
        ...children,
      );
    }

    return res;
  }, []);
}
