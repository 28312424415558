import { DistanceIcon, DurationIcon, Ride, useUnits } from '@geovelo-frontends/commons';
import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';

import defaultRideImage from '../assets/images/ride.jpg';

import { ArrowOutward } from './icons';

function RideCard({
  fullHeight,
  outlined,
  row,
  ride,
  disableDuration,
  disableVerticalGain,
  enableDescription,
}: {
  disableDuration?: boolean;
  disableVerticalGain?: boolean;
  fullHeight?: boolean;
  enableDescription?: boolean;
  outlined?: boolean;
  ride?: Ride;
  row?: boolean;
}): JSX.Element {
  const { toTime, toDistance } = useUnits();

  return (
    <Box
      border={outlined ? `1px solid #CFCFCF` : undefined}
      borderRadius={4}
      display="flex"
      flexDirection={{ xs: 'column', md: row ? 'row' : 'column' }}
      gap={outlined ? 0 : 3}
      height={fullHeight ? '100%' : undefined}
      overflow="hidden"
      padding={outlined ? 0 : 3}
      width="100%"
    >
      {ride ? (
        <Box
          borderRadius={outlined ? 0 : 4}
          height={{ xs: 240, md: row ? 126 : 240 }}
          sx={{
            backgroundImage: `url(${ride.photos[0]?.thumbnailUrl || defaultRideImage})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
          width={{ md: row ? 200 : '100%' }}
        />
      ) : (
        <Skeleton height={240} sx={{ borderRadius: outlined ? 0 : 4 }} variant="rectangular" />
      )}
      <Box display="flex" flexDirection="column" flexGrow={1} gap={1} padding={outlined ? 3 : 0}>
        <Box display="flex" flexDirection="column">
          <Typography fontSize="1.125rem" fontWeight={700}>
            {ride ? ride.title : <Skeleton variant="text" width={200} />}
          </Typography>
          {(!ride || ride.from) && (
            <Typography color="textSecondary" variant="body2">
              {ride ? (
                ride.from?.addressDetail && (
                  <>
                    {ride.from.address}
                    {ride.to?.addressDetail &&
                      ride.to.address.localeCompare(ride.from.address) !== 0 && (
                        <>&nbsp;&#x3e;&nbsp;{ride.to.address}</>
                      )}
                  </>
                )
              ) : (
                <Skeleton variant="text" width={150} />
              )}
            </Typography>
          )}
        </Box>
        <Box columnGap={2} display="flex" flexWrap="wrap">
          {!disableDuration && (
            <Box alignItems="center" display="flex" gap={0.5}>
              <DurationIcon color="primary" sx={{ height: 16, width: 16 }} />
              <Typography variant="body2">
                {ride ? (
                  toTime(ride.overloadDuration || ride.duration || 0)
                ) : (
                  <Skeleton variant="text" width={50} />
                )}
              </Typography>
            </Box>
          )}
          <Box alignItems="center" display="flex" gap={0.5}>
            <DistanceIcon color="primary" sx={{ height: 16, width: 16 }} />
            <Typography variant="body2">
              {ride ? toDistance(ride.distance || 0) : <Skeleton variant="text" width={50} />}
            </Typography>
          </Box>
          {!disableVerticalGain && (
            <Box alignItems="center" display="flex" gap={0.5}>
              <ArrowOutward color="primary" sx={{ height: 16, width: 16 }} />
              <Typography variant="body2">
                {ride ? `${ride.verticalGain} m` : <Skeleton variant="text" width={50} />}
              </Typography>
            </Box>
          )}
        </Box>
        {enableDescription && (!ride || ride.description) && (
          <Typography
            color="textSecondary"
            marginTop={1}
            sx={{
              display: '-webkit-box',
              lineHeight: '1.1875em',
              height: '34px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '0.875em',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
            variant="body2"
          >
            {ride ? (
              ride.description
            ) : (
              <>
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="90%" />
              </>
            )}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default RideCard;
