import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function TripsIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 26 25" {...props}>
      <path
        d="M17.5 5.5C18.6 5.5 19.5 4.6 19.5 3.5C19.5 2.4 18.6 1.5 17.5 1.5C16.4 1.5 15.5 2.4 15.5 3.5C15.5 4.6 16.4 5.5 17.5 5.5ZM7 12C4.2 12 2 14.2 2 17C2 19.8 4.2 22 7 22C9.8 22 12 19.8 12 17C12 14.2 9.8 12 7 12ZM7 20.5C5.1 20.5 3.5 18.9 3.5 17C3.5 15.1 5.1 13.5 7 13.5C8.9 13.5 10.5 15.1 10.5 17C10.5 18.9 8.9 20.5 7 20.5ZM12.8 10.5L15.2 8.1L16 8.9C17.06 9.96 18.38 10.68 19.96 10.92C20.56 11.01 21.1 10.53 21.1 9.92C21.1 9.43 20.73 9.01 20.25 8.93C19.14 8.75 18.23 8.22 17.5 7.5L15.6 5.6C15.1 5.2 14.6 5 14 5C13.4 5 12.9 5.2 12.6 5.6L9.8 8.4C9.4 8.8 9.2 9.3 9.2 9.8C9.2 10.4 9.4 10.9 9.8 11.2L13 14V18C13 18.55 13.45 19 14 19C14.55 19 15 18.55 15 18V13.6C15 13.08 14.8 12.59 14.45 12.22L12.8 10.5ZM21 12C18.2 12 16 14.2 16 17C16 19.8 18.2 22 21 22C23.8 22 26 19.8 26 17C26 14.2 23.8 12 21 12ZM21 20.5C19.1 20.5 17.5 18.9 17.5 17C17.5 15.1 19.1 13.5 21 13.5C22.9 13.5 24.5 15.1 24.5 17C24.5 18.9 22.9 20.5 21 20.5Z"
        fill="currentColor"
      />
      <path
        d="M17.5 5.5C18.6 5.5 19.5 4.6 19.5 3.5C19.5 2.4 18.6 1.5 17.5 1.5C16.4 1.5 15.5 2.4 15.5 3.5C15.5 4.6 16.4 5.5 17.5 5.5ZM7 12C4.2 12 2 14.2 2 17C2 19.8 4.2 22 7 22C9.8 22 12 19.8 12 17C12 14.2 9.8 12 7 12ZM7 20.5C5.1 20.5 3.5 18.9 3.5 17C3.5 15.1 5.1 13.5 7 13.5C8.9 13.5 10.5 15.1 10.5 17C10.5 18.9 8.9 20.5 7 20.5ZM12.8 10.5L15.2 8.1L16 8.9C17.06 9.96 18.38 10.68 19.96 10.92C20.56 11.01 21.1 10.53 21.1 9.92C21.1 9.43 20.73 9.01 20.25 8.93C19.14 8.75 18.23 8.22 17.5 7.5L15.6 5.6C15.1 5.2 14.6 5 14 5C13.4 5 12.9 5.2 12.6 5.6L9.8 8.4C9.4 8.8 9.2 9.3 9.2 9.8C9.2 10.4 9.4 10.9 9.8 11.2L13 14V18C13 18.55 13.45 19 14 19C14.55 19 15 18.55 15 18V13.6C15 13.08 14.8 12.59 14.45 12.22L12.8 10.5ZM21 12C18.2 12 16 14.2 16 17C16 19.8 18.2 22 21 22C23.8 22 26 19.8 26 17C26 14.2 23.8 12 21 12ZM21 20.5C19.1 20.5 17.5 18.9 17.5 17C17.5 15.1 19.1 13.5 21 13.5C22.9 13.5 24.5 15.1 24.5 17C24.5 18.9 22.9 20.5 21 20.5Z"
        fill="currentColor"
      />
      <path
        d="M12.9126 4.06453L8.69571 8.40734C8.64702 8.57827 8.30741 8.62936 7.33852 7.46628C6.12742 6.01244 8.86222 4.01947 9.58244 3.39303C10.1586 2.89188 11.1378 3.00452 11.5554 3.12348L12.9126 4.06453Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth="0.242424"
      />
    </SvgIcon>
  );
}
