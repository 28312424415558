import { Typography } from '@mui/material';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { BreadcrumbQuery } from '../../../graphql-types';
import usePrismicNodes from '../../hooks/prismic-nodes';

function Breadcrumb({ items }: { items?: Array<{ label: string; to?: string }> }): JSX.Element {
  const staticQuery = useStaticQuery<BreadcrumbQuery>(graphql`
    query Breadcrumb {
      allPrismicRidesHub {
        nodes {
          _previewable
          lang
          data {
            ...RidesHubData
          }
        }
      }
    }
  `);
  const query = useMergePrismicPreviewData(staticQuery);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const { data } = usePrismicNodes(query?.allPrismicRidesHub.nodes, { currentLanguage });

  return (
    <Typography
      color={
        data?.body.find(({ slice_type }) => slice_type === 'banner')?.primary.dark_background
          ? '#fff'
          : 'textSecondary'
      }
      variant="body2"
    >
      <Link
        style={{ color: 'inherit', textDecoration: 'none' }}
        to={`/${currentLanguage}/rides-and-trips`}
      >
        {t('geovelo.pages_titles.rides_and_trips')}
      </Link>{' '}
      /{' '}
      <Link
        style={{ color: 'inherit', textDecoration: 'none' }}
        to={`/${currentLanguage}/rides-hub`}
      >
        {data?.title.text}
      </Link>
      {items?.map(({ label, to }, index) => (
        <Fragment key={index}>
          {' '}
          /{' '}
          {to ? (
            <Link style={{ color: 'inherit', textDecoration: 'none' }} to={to}>
              {label.replaceAll('/', '&')}
            </Link>
          ) : (
            <Typography component="span" fontWeight={700} variant="body2">
              {label.replaceAll('/', '&')}
            </Typography>
          )}
        </Fragment>
      ))}
    </Typography>
  );
}

export default Breadcrumb;
