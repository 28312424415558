import { Typography, TypographyProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import checkIcon from '../../assets/images/check.svg';

function Description({
  data,
  itemPadding,
  linkColor,
  sx,
  ..._props
}: {
  data?: { html?: string | null; text?: string | null } | null;
  linkColor?: string;
  itemPadding?: string;
} & TypographyProps): JSX.Element {
  const { palette } = useTheme();

  if (!data?.text && !data?.html) return <></>;

  const { text, html } = data;

  const props: TypographyProps = {
    fontSize: '1.125em',
    lineHeight: 1.6,
    sx: {
      ...sx,
      '> p': {
        margin: 0,
        '&.block-img': {
          backgroundColor: '#fff',
          borderRadius: 2,
          marginBottom: 4,
          padding: 1,
          width: 150,
        },
      },
      '> ul': {
        listStyle: 'none',
        'li::before': {
          color: palette.secondary.main,
          content: "'\\2022'",
          display: 'inline-block',
          marginLeft: '-1em',
          fontWeight: 700,
          width: '1em',
        },
      },
      '> ol': {
        padding: 0,
        listStyle: 'none',
        '> li': {
          padding: itemPadding || '4px 0 4px 48px',
          background: `url(${checkIcon}) no-repeat left center`,
        },
      },
      strong: { fontWeight: 600 },
      a: { color: linkColor || palette.secondary.main },
    },
    ..._props,
  };

  if (html) {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <Typography
        component="div"
        dangerouslySetInnerHTML={html ? { __html: html } : undefined}
        {...props}
      />
    );
  }

  return <Typography {...props}>{text}</Typography>;
}

export default Description;
