import { Geogroup, HttpService } from '@geovelo-frontends/commons';
import { DialogActions, DialogContent, DialogProps, DialogTitle, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { Trans } from 'react-i18next';

import Button from '../../components/button';
import Dialog from '../../components/dialog';
import { AppContext } from '../../context';
import environment from '../../environment';

type TProps = Omit<DialogProps, 'onClose'> & {
  onClose: (geogroup?: Geogroup) => void;
  geogroup?: Geogroup;
};

function CompanyMigrationDialog({ geogroup, onClose, ...props }: TProps): JSX.Element {
  const {
    user: { current: currentUser },
  } = useContext(AppContext);

  return (
    <Dialog fullWidth maxWidth="sm" onClose={() => onClose()} {...props}>
      <DialogTitle>
        <Trans i18nKey="geovelo.communities.redirect_dialog.title" />
      </DialogTitle>
      <DialogContent>
        <Typography>
          <Trans i18nKey="geovelo.communities.redirect_dialog.content" />
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: '24px' }}>
        <Button onClick={() => onClose()} variant="outlined">
          <Trans i18nKey="commons.actions.cancel" />
        </Button>
        <Button
          color="primary"
          component="a"
          href={`${
            environment.enterpriseUrl
          }subscription/connect?auth-token=${HttpService.authorizationToken?.substring(
            HttpService.authorizationToken.indexOf(' ') + 1,
          )}&user-id=${currentUser?.id}&geogroup-id=${geogroup?.id}`}
          rel="noreferrer"
          target="_blank"
          variant="contained"
        >
          <Trans
            i18nKey="geovelo.communities.redirect_dialog.redirect"
            values={{ name: geogroup?.title }}
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CompanyMigrationDialog;
