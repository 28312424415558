import { Box, ButtonBase, Chip, Skeleton, Typography } from '@mui/material';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { BlogPostPreviewFragment, PrismicBlogPostThemeFragment } from '../../../graphql-types';

function BlogPostCard({
  card,
  large,
  themes,
  blogPost,
}: {
  blogPost?: BlogPostPreviewFragment;
  card?: boolean;
  large?: boolean;
  themes?: PrismicBlogPostThemeFragment[];
}): JSX.Element {
  const image = blogPost?.data.image && getImage(blogPost.data.image);

  if (large) {
    return (
      <StyledButton
        disableRipple
        component={blogPost?.url ? Link : 'div'}
        sx={{ width: '100%' }}
        to={blogPost?.url || '/'}
      >
        <Box display="flex" flexDirection="row" gap={5} width="100%">
          <Box alignSelf="center" flexShrink={0} maxWidth="100%" width="calc((100% - 64px) / 2)">
            <Box paddingTop="66%" position="relative">
              <Box
                borderRadius={6}
                height="100%"
                left={0}
                overflow="hidden"
                position="absolute"
                top={0}
                width="100%"
              >
                {image ? (
                  <GatsbyImage
                    alt=""
                    image={image}
                    objectFit="cover"
                    style={{ height: '100%', width: '100%' }}
                  />
                ) : (
                  <Skeleton height="100%" variant="rectangular" width="100%" />
                )}
              </Box>
            </Box>
          </Box>
          <BlogPostCardContent blogPost={blogPost} large={large} themes={themes} />
        </Box>
      </StyledButton>
    );
  }

  return (
    <StyledButton
      disableRipple
      component={blogPost?.url ? Link : 'div'}
      sx={{
        alignItems: 'flex-start',
        width: { xs: '100%', sm: 'calc((100% - 64px) / 2)', md: 'calc((100% - 128px) / 3)' },
      }}
      to={blogPost?.url || undefined}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        padding={3}
        sx={card ? { backgroundColor: '#fff', borderRadius: 6 } : {}}
        width="100%"
      >
        <Box alignSelf="center" width="100%">
          <Box paddingTop="66%" position="relative">
            <Box
              borderRadius={6}
              height="100%"
              left={0}
              overflow="hidden"
              position="absolute"
              top={0}
              width="100%"
            >
              {image ? (
                <GatsbyImage
                  alt=""
                  image={image}
                  objectFit="cover"
                  style={{ height: '100%', width: '100%' }}
                />
              ) : (
                <Skeleton height="100%" variant="rectangular" width="100%" />
              )}
            </Box>
          </Box>
        </Box>
        <BlogPostCardContent blogPost={blogPost} large={large} themes={themes} />
      </Box>
    </StyledButton>
  );
}

function BlogPostCardContent({
  large,
  themes: allThemes,
  blogPost,
}: {
  blogPost?: BlogPostPreviewFragment;
  large?: boolean;
  themes?: PrismicBlogPostThemeFragment[];
}): JSX.Element {
  const date =
    blogPost && moment(blogPost.data.publication_date || blogPost.first_publication_date);
  const themes = blogPost
    ? (blogPost.data.themes || []).reduce<PrismicBlogPostThemeFragment[]>((res, data) => {
        const theme = (allThemes || []).find(({ uid }) => uid === data?.theme?.uid);
        if (theme) {
          res.push(theme);
        }

        return res;
      }, [])
    : undefined;

  return (
    <Box display="flex" flexDirection="column" gap={2} paddingY={large ? 5 : 0}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography color="textSecondary" variant="caption">
          {date?.format('L') || <Skeleton variant="text" width={100} />}
        </Typography>
        {themes ? (
          themes.length > 0 && (
            <Box display="flex" gap={1}>
              {themes.map((theme) => (
                <Chip
                  color="primary"
                  key={theme.uid}
                  label={theme.data.title?.text}
                  size="small"
                  variant="outlined"
                />
              ))}
            </Box>
          )
        ) : (
          <Skeleton height={24} variant="rounded" width={100} />
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography
          component="h3"
          fontSize={large ? '1.5em' : '1.2em'}
          fontWeight={700}
          lineHeight={1.3}
          variant="h6"
        >
          {blogPost ? (
            blogPost.data.title?.text
          ) : (
            <>
              <Skeleton sx={{ maxWidth: '100%' }} variant="text" width={400} />
              <Skeleton sx={{ maxWidth: '100%' }} variant="text" width={300} />
            </>
          )}
        </Typography>
        {(!blogPost || blogPost.data.subtitle_short?.text) && (
          <Typography fontSize={large ? '1.125em' : '1em'} lineHeight={1.6}>
            {blogPost ? (
              blogPost.data.subtitle_short?.text
            ) : (
              <>
                <Skeleton sx={{ maxWidth: '100%' }} variant="text" width={400} />
                <Skeleton sx={{ maxWidth: '100%' }} variant="text" width={300} />
              </>
            )}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

const StyledButton = styled(ButtonBase)<{ component?: typeof Link | 'div'; to?: string }>`
  img {
    transition: transform 1s ease !important;
  }
  &:hover {
    color: rgba(0, 0, 0, 0.7);
    img {
      transform: scale(1.25);
    }
  }
`;

export default BlogPostCard;
