import { Card, CardActions, CardContent } from '@mui/material';
import React, { ReactNode } from 'react';

function FormCard({
  children,
  actions,
}: {
  actions?: ReactNode;
  children: ReactNode;
}): JSX.Element {
  return (
    <Card sx={{ borderRadius: 0, borderWidth: '0 0 1px 0', flexShrink: 0 }} variant="outlined">
      <CardContent sx={{ padding: 3 }}>{children}</CardContent>
      {actions && <CardActions sx={{ justifyContent: 'center' }}>{actions}</CardActions>}
    </Card>
  );
}

export default FormCard;
