import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CTA from './cta';
import Description from './description';
import Subtitle from './subtitle';

function PressArticle({
  data: { item_title, item_description, item_link },
}: {
  data: {
    item_link?: {
      url?: string | null;
    } | null;
    item_title?: { text?: string | null } | null;
    item_description?: { text?: string | null } | null;
  };
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Subtitle data={item_title} />
      <Description data={item_description} sx={{ color: '#5C5C5C' }} />
      <Box display="flex" flexDirection="row-reverse">
        <CTA label={t('commons.actions.more_informations') || ''} link={item_link} variant="Text" />
      </Box>
    </Box>
  );
}

export default PressArticle;
