import {
  BikeRoute,
  DistanceIcon,
  DurationIcon,
  FlagIcon,
  useUnits,
} from '@geovelo-frontends/commons';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

function BikeRouteStats({
  size,
  bikeRoute: { distance, stepsCount },
}: {
  bikeRoute: BikeRoute;
  size?: 'medium';
}): JSX.Element {
  const { t } = useTranslation();
  const { toDistance } = useUnits();

  return (
    <Box
      columnGap={size === 'medium' ? 3 : 1}
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      rowGap={1}
    >
      <Box alignItems="center" display="flex" flexDirection="row" gap="4px">
        <DurationIcon color="primary" sx={{ fontSize: size === 'medium' ? 16 : 12 }} />
        <Typography variant={size === 'medium' ? 'body2' : 'caption'}>
          {t('commons.day', {
            count: Math.round((3 * distance) / 5.5 / 86400),
          })}
        </Typography>
      </Box>
      <Box alignItems="center" display="flex" flexDirection="row" gap="4px">
        <DistanceIcon color="primary" sx={{ fontSize: size === 'medium' ? 16 : 12 }} />
        <Typography variant={size === 'medium' ? 'body2' : 'caption'}>
          {toDistance(distance)}
        </Typography>
      </Box>
      <Box alignItems="center" display="flex" flexDirection="row" gap="4px">
        <FlagIcon color="primary" sx={{ fontSize: size === 'medium' ? 16 : 12 }} />
        <Typography variant={size === 'medium' ? 'body2' : 'caption'}>
          {t('geovelo.bike_routes.list_item.steps', {
            count: stepsCount,
          })}
        </Typography>
      </Box>
    </Box>
  );
}

export default BikeRouteStats;
