import { WrapRootElementBrowserArgs } from 'gatsby';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import React from 'react';

import ContextProvider from './context';
import linkResolver from './link-resolver';
import BlogPostPage from './pages/blog/[uid]';
import ApiPage from './templates/api';
import BlogPage from './templates/blog';
import CommunitiesPage from './templates/communities';
import CookiesPolicyPage from './templates/cookies-policy';
import EulaPage from './templates/eula';
import RoutingPage from './templates/home';
import IndexPage from './templates/index';
import LegalNoticePage from './templates/legal-notice';
import OurMissionPage from './templates/our-mission';
import PressAreaPage from './templates/press-area';
import PrivacyPolicyPage from './templates/privacy-policy';
import RidesPage from './templates/rides';
import RidesAndTripsPage from './templates/rides-and-trips';
import RidesHubPage from './templates/rides-hub';
import TerritoriesPage from './templates/territories';
import TripPlannerPage from './templates/trip-planner';
import TripsPage from './templates/trips';

import 'maplibre-gl/dist/maplibre-gl.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './style.css';

function RootElementWrapper({ element }: WrapRootElementBrowserArgs) {
  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_GEOVELO_PRISMIC_REPO_NAME || '',
          linkResolver,
          componentResolver: {
            home: IndexPage,
            rides_and_trips: RidesAndTripsPage,
            rides_hub: RidesHubPage,
            routing: RoutingPage,
            rides: RidesPage,
            trips: TripsPage,
            communities: CommunitiesPage,
            trip_planner: TripPlannerPage,
            api: ApiPage,
            territories: TerritoriesPage,
            missions: OurMissionPage,
            privacy_policy: PrivacyPolicyPage,
            eula: EulaPage,
            legal_notice: LegalNoticePage,
            cookies_policy: CookiesPolicyPage,
            press_area: PressAreaPage,
            blog: BlogPage,
            blog_post: BlogPostPage,
          },
        },
      ]}
    >
      <ContextProvider>{element}</ContextProvider>
    </PrismicPreviewProvider>
  );
}

export default RootElementWrapper;
