import { init as initAmplitude } from '@amplitude/analytics-browser';
import { createInstance } from '@jonkoops/matomo-tracker-react';

import environment from './environment';

export const matomoInstance = createInstance({
  urlBase: 'https://geovelo.matomo.cloud/',
  siteId: 7,
});

initAmplitude(environment.amplitude.apiKey, undefined, {
  serverZone: 'EU',
  defaultTracking: false,
});
