import {
  ComputedRoute,
  Ratings,
  Report,
  ReportDialog,
  ReportService,
  Review,
  Route,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import simplify from '@turf/simplify';
import { navigate } from 'gatsby';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, {
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../context';
import useReports from '../../hooks/map/reports';
import CommentsDialog from '../comments-dialog';
import NewReportDialog from '../new-report-dialog';

export const reportsMinZoom = 14;

export type TReportsRef = { update: () => void; unselect: () => void };

function Reports(
  {
    path,
    disableBoundsUpdate,
    route,
    onSelectedReportChange,
  }: {
    disableBoundsUpdate?: boolean;
    disableRouteReports?: boolean;
    onSelectedReportChange?: (selectedReport: Report | null) => void;
    path: string;
    route?: ComputedRoute | Route | null;
  },
  ref: Ref<TReportsRef>,
): JSX.Element {
  const [dialogOpen, openDialog] = useState(false);
  const [commentsDialogOpen, openCommentsDialog] = useState(false);
  const {
    map: { current: map, reportsShowed },
    user: { current: currentUser },
    report: {
      types: reportTypes,
      typesMap: reportTypesMap,
      sourcesMap: reportSourcesMap,
      newReportDialogOpen,
    },
    actions: { toggleReportsOnMap, openNewReportDialog },
  } = useContext(AppContext);
  const reports = useRef<Report[]>([]);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedReport,
    init: initMap,
    update: updateMap,
    unselect,
    reopenPopup,
    clear: clearMap,
    clearLayers: clearMapLayers,
  } = useReports(map, currentUser, reportTypes, reportTypesMap, reportSourcesMap, {
    smallDevice,
    openCommentsDialog,
    onRating: handleRating,
  });
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    return () => {
      cancelPromises();
    };
  }, []);

  useEffect(() => {
    if (map) initMap();

    return () => {
      clearMap();
    };
  }, [map]);

  useEffect(() => {
    if (map) {
      if (route) getRouteReports();
      else update();

      map.on('moveend', update);
    }

    return () => {
      map?.off('moveend', update);
    };
  }, [map, currentUser, route, reportsShowed, reportTypes, reportSourcesMap]);

  useEffect(() => {
    onSelectedReportChange?.(selectedReport);
    if (selectedReport && smallDevice) openDialog(true);
  }, [selectedReport]);

  useImperativeHandle(ref, () => ({
    update,
    unselect,
  }));

  async function getRouteReports() {
    if (!map || !route) return;

    cancelPromises();
    reports.current = [];

    try {
      const simplifiedGeometry = simplify(route.geometry, { tolerance: 0.1, highQuality: false });

      let { reports: _reports } = await cancellablePromise(
        ReportService.getReports({
          geometry: simplifiedGeometry,
          geometryBuffer: 50,
          typeCodes: ['roadBlocked', 'roadwork'],
          rowsPerPage: 500,
        }),
      );

      const now = moment();
      _reports = _reports.filter(({ startDate, endDate, sourceId, reviews }) => {
        const source = (sourceId !== null && reportSourcesMap?.[sourceId]) || null;
        const nbReviews = reviews?.length || 0;
        const nbUsefulReviews =
          reviews?.filter(({ rating }) => rating === Ratings.Useful).length || 0;

        if (startDate && moment(startDate).startOf('day').add(-1, 'days').isAfter(now))
          return false;
        if (endDate && moment(endDate).endOf('day').isBefore(now)) return false;
        if (!source && (nbReviews === 0 || nbUsefulReviews / nbReviews < 2 / 3)) return false;

        return true;
      });

      reports.current = _reports;
      updateMap(_reports, { forceAsMarkers: true });
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') console.error(err);
    }
  }

  async function update() {
    if (route) return;

    cancelPromises();
    reports.current = [];

    if (!map || !reportTypes || !reportSourcesMap || disableBoundsUpdate) return;

    if (!reportsShowed || map.getZoom() < reportsMinZoom) {
      clearMapLayers();
      return;
    }

    try {
      const [[west, south], [east, north]] = map.getBounds().toArray();
      const { reports: _reports } = await cancellablePromise(
        ReportService.getReports({
          page: 1,
          rowsPerPage: 500,
          bounds: { north, east, south, west },
          typeCodes: ['dangerous', 'pothole', 'roadBlocked', 'roadwork'],
          query:
            '{id, geo_point, report_type_code, created, creator{username}, description, photo, start_date, end_date, report_source, reviews{id, created, creator{id, username}, comment, rating} }',
        }),
      );

      reports.current = _reports;
      updateMap(_reports);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
        clearMapLayers();
      }
    }
  }

  async function handleRating(report: Report, oldReview: Review | null, rating: Ratings) {
    if (!report) return;

    if (!currentUser) {
      navigate(`/${currentLanguage}/sign-in`, { state: { prevPath: path } });
      return;
    }

    try {
      if (oldReview) {
        if (oldReview.rating === rating) {
          const index = report.reviews.findIndex(({ id }) => oldReview.id === id);
          await ReportService.deleteReview(oldReview.id);

          report.reviews.splice(index, 1);
        } else {
          const index = report.reviews.findIndex(({ id }) => oldReview.id === id);
          const review = await ReportService.updateReview(oldReview.id, { rating });

          report.reviews.splice(index, 1, review);
        }
      } else {
        const review = await ReportService.addReview({ reportId: report.id, rating });

        report.reviews.push(review);
      }
      reports.current.splice(
        reports.current.findIndex(({ id }) => id === report.id),
        1,
        report,
      );

      enqueueSnackbar(
        t(`geovelo.report_dialog.${oldReview?.rating === rating ? 'removed' : 'reviewed'}`),
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('geovelo.report_dialog.not_reviewed'), { variant: 'error' });
    }

    reopenPopup(report.clone());
  }

  function handleNewReportDialogClose(report?: Report) {
    if (report) {
      toggleReportsOnMap(true);
      update();
    }

    openNewReportDialog(false);
  }

  return (
    <>
      <ReportDialog
        currentUser={currentUser}
        onClose={() => {
          openDialog(false);
          setTimeout(() => {
            unselect();
          }, theme.transitions.duration.leavingScreen);
        }}
        onRating={handleRating}
        open={dialogOpen}
        report={selectedReport}
        reportSourcesMap={reportSourcesMap}
        reportTypes={reportTypes || []}
      />
      {selectedReport && (
        <CommentsDialog
          onChange={(report) => {
            reports.current.splice(
              reports.current.findIndex(({ id }) => id === report.id),
              1,
              report,
            );

            reopenPopup(report.clone());
          }}
          onClose={() => openCommentsDialog(false)}
          open={commentsDialogOpen}
          report={selectedReport}
        />
      )}
      <NewReportDialog onClose={handleNewReportDialogClose} open={newReportDialogOpen} />
    </>
  );
}

export default forwardRef(Reports);
