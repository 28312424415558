import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function InfoIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V15C11 15.2652 11.1054 15.5196 11.2929 15.7071C11.4804 15.8946 11.7348 16 12 16C12.2652 16 12.5196 15.8946 12.7071 15.7071C12.8946 15.5196 13 15.2652 13 15V12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11ZM12 8C11.8022 8 11.6089 8.05865 11.4444 8.16853C11.28 8.27841 11.1518 8.43459 11.0761 8.61732C11.0004 8.80004 10.9806 9.00111 11.0192 9.19509C11.0578 9.38907 11.153 9.56725 11.2929 9.70711C11.4327 9.84696 11.6109 9.9422 11.8049 9.98079C11.9989 10.0194 12.2 9.99957 12.3827 9.92388C12.5654 9.84819 12.7216 9.72002 12.8315 9.55557C12.9414 9.39112 13 9.19778 13 9C13 8.73478 12.8946 8.48043 12.7071 8.29289C12.5196 8.10536 12.2652 8 12 8ZM12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92894 4.92893C3.05357 6.8043 2 9.34784 2 12C1.99126 14.3091 2.7908 16.5485 4.26 18.33L2.26 20.33C2.12125 20.4706 2.02725 20.6492 1.98988 20.8432C1.9525 21.0372 1.97342 21.2379 2.05 21.42C2.13306 21.5999 2.26771 21.7511 2.43685 21.8544C2.60599 21.9577 2.802 22.0083 3 22H12C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12 20H5.41L6.34 19.07C6.43449 18.9774 6.50966 18.8669 6.56116 18.7451C6.61265 18.6232 6.63945 18.4923 6.64 18.36C6.63625 18.0962 6.52842 17.8446 6.34 17.66C5.03059 16.352 4.21518 14.6305 4.03269 12.7888C3.85021 10.947 4.31195 9.09901 5.33923 7.55952C6.36652 6.02004 7.89579 4.88436 9.66652 4.34597C11.4372 3.80759 13.3399 3.8998 15.0502 4.60691C16.7606 5.31402 18.1729 6.59227 19.0464 8.22389C19.92 9.85551 20.2009 11.7395 19.8411 13.555C19.4814 15.3705 18.5033 17.005 17.0735 18.1802C15.6438 19.3554 13.8508 19.9985 12 20Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
