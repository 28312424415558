import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function LinkedinIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 25 24" {...props}>
      <circle cx="12.5" cy="12" fill="none" r="11.25" stroke="currentColor" strokeWidth="1.5" />
      <path d="M7.35859 16.7614H9.73359V9.12388H7.35859V16.7614Z" fill="currentColor" />
      <path
        d="M7.16797 6.70825C7.16797 7.46763 7.78359 8.08325 8.54609 8.08325C9.30547 8.08325 9.92109 7.4645 9.92109 6.70825C9.92109 5.94888 9.30547 5.33325 8.54609 5.33325C7.78359 5.33325 7.16797 5.94888 7.16797 6.70825Z"
        fill="currentColor"
      />
      <path
        d="M16.2492 16.7614H18.6211V12.5708C18.6211 10.5145 18.1773 8.93325 15.7742 8.93325C14.6211 8.93325 13.8461 9.56763 13.5305 10.1676H13.4992V9.12388H11.2242V16.7614H13.593V12.9864C13.593 11.9895 13.7805 11.0239 15.0148 11.0239C16.2336 11.0239 16.2492 12.1645 16.2492 13.0489V16.7614Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
