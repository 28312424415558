import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BlogPostRootQuery } from '../../../../graphql-types';
import Seo from '../../../components/seo';
import useRedirect from '../../../hooks/redirect';
import { TPageProps } from '../../../page-props';

function BlogPostPage(props: TPageProps<BlogPostRootQuery>) {
  const { t } = useTranslation();

  useRedirect({ slug: `/blog/${props.params.uid || props.pageContext.uid}` });

  const title = props.data.prismicBlogPost?.data.title?.text;
  const metaTitle = props.data.prismicBlogPost?.data.meta_title?.text;
  const subtitle = props.data.prismicBlogPost?.data.subtitle_short?.text;

  return (
    <Seo
      isRoot
      description={subtitle}
      metaTitle={`${metaTitle || title || t('geovelo.pages_titles.blog')}`}
      title={`${title || t('geovelo.pages_titles.blog')}`}
      {...props}
    />
  );
}

export default BlogPostPage;

export const query = graphql`
  query BlogPostRoot($uid: String, $lang: String) {
    prismicBlogPost(uid: { eq: $uid }, lang: { eq: $lang }) {
      _previewable
      data {
        title {
          text
        }
        meta_title {
          text
        }
        subtitle_short {
          text
        }
      }
    }
  }
`;
