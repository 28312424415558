import { Box, Card, CardContent, CardHeader, CardProps } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';
import moment from 'moment';
import React from 'react';

import Description from './description';

function RedirectionItemCard({
  data: { item_title, item_description, item_icon, item_link, item_date },
  ...props
}: {
  data: {
    item_link?: {
      url?: string | null;
    } | null;
    item_title?: { text?: string | null } | null;
    item_description?: { text?: string | null } | null;
    item_icon?: { gatsbyImageData?: any } | null | undefined;
    item_date?: string;
  };
} & CardProps): JSX.Element {
  return (
    <Card
      component="a"
      elevation={0}
      href={item_link?.url || undefined}
      target="_blank"
      {...props}
      sx={{
        border: '1px solid #CFCFCF',
        borderRadius: 4,
        textDecoration: 'none',
        ...props.sx,
      }}
    >
      <Box display="flex" flexDirection="column" gap={2} margin={3}>
        <CardHeader subheader={moment(item_date).format('LL')} title={item_title?.text} />
        {item_icon && (
          <GatsbyImage
            alt=""
            image={item_icon.gatsbyImageData}
            objectFit="contain"
            style={{ height: '48px' }}
          />
        )}
        <CardContent>
          <Description data={item_description} />
        </CardContent>
      </Box>
    </Card>
  );
}

export default RedirectionItemCard;
