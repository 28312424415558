import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function UploadIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M20 15V18.3333C20 18.7754 19.8127 19.1993 19.4793 19.5118C19.1459 19.8244 18.6937 20 18.2222 20H5.77778C5.30628 20 4.8541 19.8244 4.5207 19.5118C4.1873 19.1993 4 18.7754 4 18.3333V15"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M16 8L12 4L8 8"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M12 4V15"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
