import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MissionQuery } from '../../../graphql-types';
import Seo from '../../components/seo';
import { TPageProps } from '../../page-props';
import Content from '../content';

function OurMissionPage(props: TPageProps<MissionQuery>) {
  const { t } = useTranslation();

  const title = props.data.prismicMission?.data.title?.text;
  const description = props.data.prismicMission?.data.description?.text;

  return (
    <>
      <Seo
        description={description}
        title={`${title || t('geovelo.navigation.our_mission')}`}
        {...props}
      />
      <Content
        {...props}
        query={props.data.prismicMission}
        themes={props.data.allPrismicBlogPostTheme.nodes}
      />
    </>
  );
}

export const query = graphql`
  fragment PrismicMissionBanner on PrismicMissionDataBodyBanner {
    id
    slice_type
    primary {
      dark_background
      fullscreen_background
      background_image {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
      baseline {
        html
      }
    }
    items {
      cta_link {
        link_type
        url
      }
      cta_title {
        text
      }
      cta_color
      cta_variant
    }
  }
  fragment PrismicMissionVerticalCards on PrismicMissionDataBodyVerticalcards {
    id
    slice_type
    primary {
      background_color
      background_image {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
      cards
      border_color
      title {
        text
        html
      }
      description {
        text
        html
      }
      images_shape
      images_size
      cta_link {
        link_type
        url
      }
      cta_title {
        text
      }
    }
    items {
      icon {
        gatsbyImageData(placeholder: NONE)
      }
      title {
        text
      }
      description {
        text
        html
      }
      link {
        link_type
        url
      }
    }
  }
  fragment PrismicMissionHorizontalCard on PrismicMissionDataBodyHorizontalcard {
    id
    slice_type
    primary {
      background_color
      background_image {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
      background_image_position
      linked_to_next_slice
      header_type
      image_on_the_right
      image_type
      image {
        gatsbyImageData(placeholder: NONE)
      }
      title {
        text
        html
      }
      title_color
      description {
        text
        html
      }
    }
    items {
      cta_type
      cta_variant
      cta_title {
        text
      }
      cta_link {
        link_type
        url
      }
    }
  }
  fragment PrismicMissionAppPraise on PrismicMissionDataBodyApppraise {
    id
    slice_type
    primary {
      background_color
      icon {
        gatsbyImageData(placeholder: NONE, width: 136)
      }
      baseline {
        text
        html
      }
      app_store_rating
      google_play_rating
      number_of_millions_of_downloads
    }
    items {
      logo {
        gatsbyImageData(placeholder: NONE)
      }
    }
  }
  fragment PrismicMissionVideo on PrismicMissionDataBodyVideo {
    id
    slice_type
    primary {
      header_image {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
      title {
        text
        html
      }
      description {
        text
        html
      }
      video_url {
        link_type
        url
      }
      cta_baseline {
        text
        html
      }
      cta_button {
        text
        html
      }
      cta_link {
        link_type
        url
      }
      cta_background_color
      cta_right_image {
        gatsbyImageData(placeholder: NONE, width: 140)
      }
    }
  }
  fragment PrismicMissionBlogPostList on PrismicMissionDataBodyBlogpostlist {
    id
    slice_type
    primary {
      baseline {
        text
      }
      cta_title {
        text
      }
    }
    items {
      blog_post {
        uid
        document {
          ... on PrismicBlogPost {
            ...BlogPostPreview
          }
        }
      }
    }
  }
  fragment MissionData on PrismicMissionData {
    title {
      text
    }
    description {
      text
    }
    body {
      ... on PrismicMissionDataBodyBanner {
        ...PrismicMissionBanner
      }
      ... on PrismicMissionDataBodyVerticalcards {
        ...PrismicMissionVerticalCards
      }
      ... on PrismicMissionDataBodyHorizontalcard {
        ...PrismicMissionHorizontalCard
      }
      ... on PrismicMissionDataBodyApppraise {
        ...PrismicMissionAppPraise
      }
      ... on PrismicMissionDataBodyVideo {
        ...PrismicMissionVideo
      }
      ... on PrismicMissionDataBodyBlogpostlist {
        ...PrismicMissionBlogPostList
      }
    }
  }
`;

export default withPrismicPreview(OurMissionPage);
