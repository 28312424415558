import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function HideMapIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <defs />
      <path
        d="M 15 19.05 L 9.3 20.95 C 9.099 21.016 8.881 21.016 8.68 20.95 L 2.6799999999999997 18.95 C 2.481 18.883 2.309 18.755 2.187 18.585 C 2.065 18.414 1.999 18.21 2 18 L 2 4 C 2.001 3.841 2.039 3.685 2.112 3.544 C 2.179 3.415 2.273 3.303 2.387 3.215 L 4.8420000000000005 5.67 L 4 5.39 L 4 17.28 L 8 18.61 L 8 8.828 L 10 10.828 L 10 18.61 L 14 17.28 L 14 14.828 L 16 16.828 L 16 17.28 L 16.677 17.505 L 19.906 20.734 L 15 19.05 Z"
        fill="currentColor"
      />
      <line
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={2}
        x1="2"
        x2="22"
        y1="2"
        y2="22"
      />
      <path
        d="M 14.938 10.092 L 15.003 4.024 M 10.41 5.607 L 14.994 4.02 L 21.057 6.064 L 20.98 16.171"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </SvgIcon>
  );
}
