import { IPhoto } from '@geovelo-frontends/commons';

import { BikeRouteDataPhotos } from '../../graphql-types';
import environment from '../environment';

const { backendUrl: _backendUrl } = environment;

export function parsePhoto(
  { id, title, description, copyright, url, thumbnail, thumbnailSquare }: BikeRouteDataPhotos,
  backendUrl = _backendUrl,
): IPhoto {
  return {
    id: id || -1,
    title,
    description,
    copyright,
    url: backendUrl ? `${backendUrl}${url || ''}` : url || '',
    thumbnailUrl: backendUrl ? `${backendUrl}${thumbnail || ''}` : thumbnail || '',
    squaredThumbnailUrl: backendUrl
      ? `${backendUrl}${thumbnailSquare || ''}`
      : thumbnailSquare || '',
  };
}
