import { Box } from '@mui/material';
import { navigate } from 'gatsby';
import React, { Fragment, ReactNode, useEffect } from 'react';

import {
  HomeBikeRouteDataFragment,
  PrismicBlogPostThemeFragment,
  RidesAndTripsRideDataFragment,
} from '../../graphql-types';
import AppPraise, { TAppPraiseData } from '../components/slices/app-praise';
import Banner, { TBannerData } from '../components/slices/banner';
import BikeRoutesList, { TBikeRoutesListData } from '../components/slices/bike-routes-list';
import BlogPostList, { TBlogPostListData } from '../components/slices/blog-post-list';
import BottomBanner, { TBottomBannerData } from '../components/slices/bottom-banner';
import Catalog, { TCatalogData } from '../components/slices/catalog';
import ContactForm, { TContactFormData } from '../components/slices/contact-form';
import CSRPolicy, { TCSRPolicyData } from '../components/slices/csr-policy';
import Divider, { TDividerData } from '../components/slices/divider';
import EmployeeOpinion, { TEmployeeOpinionData } from '../components/slices/employee-opinion';
import HighlightedBikeRoute, {
  THighlightedBikeRouteData,
} from '../components/slices/highlighted-bike-route';
import HighlightedRide, { THighlightedRideData } from '../components/slices/highlighted-ride';
import HorizontalCard, { THorizontalCardData } from '../components/slices/horizontal-card';
import KeyNumbersCard, { TKeyNumbersCardData } from '../components/slices/key-numbers-card';
import LogosList, { TLogosListData } from '../components/slices/logos-list';
import LoopGenerator, { TLoopGeneratorData } from '../components/slices/loop-generator';
import PressRelease, { TPressReleaseData } from '../components/slices/press-release';
import RecruitmentProcess, {
  TRecruitmentProcessData,
} from '../components/slices/recruitment-process';
import RedirectionItems, { TRedirectionItemsData } from '../components/slices/redirection-items';
import Reviews, { TReviewsData } from '../components/slices/reviews';
import RidesList, { TRidesListData } from '../components/slices/rides-list';
import TripPlanner, { TTripPlannerData } from '../components/slices/trip-planner';
import VerticalCards, { TVerticalCardsData } from '../components/slices/vertical-cards';
import Video, { TVideoData } from '../components/slices/video';
import WhoAreWe, { TWhoAreWeData } from '../components/slices/who-are-we';
import { defaultLanguage } from '../languages';
import { TPageData, TPageProps } from '../page-props';

type TQuery = { data: { body: Array<{ id: string; slice_type: string }> } } | undefined | null;

function Content<T = TPageData>({
  query,
  disableRedirect,
  bikeRoutesData,
  ridesData,
  smallBanner,
  breadcrumb,
  enableStoresInBanner,
  themes,
  ...props
}: {
  bikeRoutesData?: HomeBikeRouteDataFragment[];
  breadcrumb?: ReactNode;
  disableRedirect?: boolean;
  query: TQuery;
  enableStoresInBanner?: boolean;
  ridesData?: RidesAndTripsRideDataFragment[];
  smallBanner?: boolean;
  themes?: PrismicBlogPostThemeFragment[];
} & TPageProps<T>): JSX.Element {
  useEffect(() => {
    if (!query && !disableRedirect)
      navigate(`/${defaultLanguage}${props.pageContext.slug.substring(3)}`);
  }, []);

  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      {query?.data.body.map((slice) => {
        switch (slice.slice_type) {
          case 'banner':
            return (
              <Banner
                breadcrumb={breadcrumb}
                data={slice as TBannerData}
                enableStores={enableStoresInBanner}
                key={slice.id}
                small={smallBanner}
              />
            );
          case 'bottombanner':
            return (
              <BottomBanner
                data={slice as TBottomBannerData}
                enableStores={enableStoresInBanner}
                key={slice.id}
              />
            );
          case 'verticalcards':
            return <VerticalCards data={slice as TVerticalCardsData} key={slice.id} />;
          case 'horizontalcard':
            return <HorizontalCard data={slice as THorizontalCardData} key={slice.id} />;
          case 'logos':
            return <LogosList data={slice as TLogosListData} key={slice.id} />;
          case 'divider':
            return <Divider data={slice as TDividerData} key={slice.id} />;
          case 'pressrelease':
            return <PressRelease data={slice as TPressReleaseData} key={slice.id} />;
          case 'redirectionitems':
            return <RedirectionItems data={slice as TRedirectionItemsData} key={slice.id} />;
          case 'apppraise':
            return <AppPraise data={slice as TAppPraiseData} key={slice.id} />;
          case 'bikerouteslist':
            return (
              <BikeRoutesList
                bikeRoutesData={bikeRoutesData}
                data={slice as TBikeRoutesListData}
                key={slice.id}
              />
            );
          case 'rideslist':
            return (
              <RidesList data={slice as TRidesListData} key={slice.id} ridesData={ridesData} />
            );
          case 'loopgenerator':
            return <LoopGenerator data={slice as TLoopGeneratorData} key={slice.id} />;
          case 'keynumberscard':
            return <KeyNumbersCard data={slice as TKeyNumbersCardData} key={slice.id} />;
          case 'video':
            return <Video data={slice as TVideoData} key={slice.id} />;
          case 'highlightedride':
            return (
              <HighlightedRide
                data={slice as THighlightedRideData}
                key={slice.id}
                ridesData={ridesData}
              />
            );
          case 'highlightedbikeroute':
            return (
              <HighlightedBikeRoute
                bikeRoutesData={bikeRoutesData}
                data={slice as THighlightedBikeRouteData}
                key={slice.id}
                ridesData={ridesData}
              />
            );
          case 'tripplanner':
            return <TripPlanner data={slice as TTripPlannerData} key={slice.id} />;
          case 'contactform':
            return <ContactForm data={slice as TContactFormData} key={slice.id} />;
          case 'whoarewe':
            return <WhoAreWe data={slice as TWhoAreWeData} key={slice.id} />;
          case 'employeeopinion':
            return <EmployeeOpinion data={slice as TEmployeeOpinionData} key={slice.id} />;
          case 'csrpolicy':
            return <CSRPolicy data={slice as TCSRPolicyData} key={slice.id} />;
          case 'reviews':
            return <Reviews data={slice as TReviewsData} key={slice.id} />;
          case 'recruitmentprocess':
            return <RecruitmentProcess data={slice as TRecruitmentProcessData} key={slice.id} />;
          case 'catalog':
            return <Catalog data={slice as TCatalogData} key={slice.id} />;
          case 'blogpostlist':
            return (
              <BlogPostList data={slice as TBlogPostListData} key={slice.id} themes={themes} />
            );
          default:
            return <Fragment key={slice.id} />;
        }
      })}
    </Box>
  );
}

export default Content;
