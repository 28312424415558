import {
  Autocomplete,
  FileInput,
  GeocoderService,
  Place,
  Report,
  ReportService,
  TFile,
  TReportTypeCode,
  useGeoveloMap,
} from '@geovelo-frontends/commons';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AppContext } from '../context';

import Button from './button';
import Dialog from './dialog';

import { Marker } from '!maplibre-gl';

interface IValues {
  type: TReportTypeCode;
  description: string;
}

type TProps = Omit<DialogProps, 'onClose'> & { onClose: (report?: Report) => void };

const mapId = 'new-report-map';
const defaultType: TReportTypeCode = 'bikeFacility';

function NewReportDialog({ onClose, ...props }: TProps): JSX.Element {
  const [file, setFile] = useState<TFile | undefined>();
  const [selectedPlace, selectPlace] = useState<Place | null>(null);
  const [placeError, setPlaceError] = useState(false);
  const {
    map: { current: mainMap },
    report: { types: reportTypes, typesMap: reportTypesMap },
  } = useContext(AppContext);
  const markerRef = useRef<Marker>();
  const { t } = useTranslation();
  const { palette, transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const {
    isSubmitting,
    values,
    errors,
    isValid,
    setFieldValue,
    resetForm,
    handleChange,
    handleSubmit,
  } = useFormik<IValues>({
    initialValues: { type: defaultType, description: '' },
    validationSchema: Yup.object().shape({
      type: Yup.string().required(),
      description: Yup.string().min(10).required(),
    }),
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit,
  });
  const {
    map,
    init: initMap,
    destroy: destroyMap,
  } = useGeoveloMap({
    smallDevice: true,
    baseLayer: 'geovelo',
  });

  useEffect(() => {
    if (props.open && mainMap) {
      resetForm({ values: { type: defaultType, description: '' } });
      setFile(undefined);

      const center = mainMap.getCenter();

      setTimeout(() => {
        initMap({
          container: mapId,
          customZoomControls: true,
          baseLayersControl: true,
          center,
          zoom: 15,
        });
      }, transitions.duration.enteringScreen);
    }

    return () => destroyMap();
  }, [props.open]);

  useEffect(() => {
    if (mainMap && map) {
      const center = mainMap.getCenter();

      markerRef.current = new Marker({ color: palette.secondary.main, draggable: true })
        .setLngLat(center)
        .on('dragend', ({ target }: { target: Marker }) => {
          map.flyTo({ center: target.getLngLat(), zoom: Math.max(15, map.getZoom()) });
          selectPlace(
            new Place(undefined, {
              type: 'Point',
              coordinates: target.getLngLat().toArray(),
            }),
          );
        })
        .addTo(map);

      selectPlace(new Place(undefined, { type: 'Point', coordinates: center.toArray() }));
    }
  }, [map]);

  useEffect(() => {
    if (isSubmitting && markerRef.current) {
      markerRef.current.setDraggable(!isSubmitting);
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (selectedPlace && !selectedPlace.addressDetail) {
      try {
        GeocoderService.reverseGeocode(undefined, selectedPlace.point, (place) => {
          selectPlace(place);
        });
      } catch {}
    }
  }, [selectedPlace]);

  async function onSubmit(
    { type: typeCode, description }: IValues,
    { setSubmitting }: FormikHelpers<IValues>,
  ) {
    const type = reportTypes?.find(({ code }) => code === typeCode);
    if (!type || !markerRef.current) return;

    if (!selectedPlace) {
      setPlaceError(true);
      return;
    }

    setSubmitting(true);
    setPlaceError(false);
    markerRef.current.setDraggable(false);

    try {
      const { lat, lng } = markerRef.current.getLngLat();
      const point: GeoJSON.Point = {
        type: 'Point',
        coordinates: [lng, lat],
      };

      const place = await GeocoderService.reverseGeocode(undefined, point);
      const report = await ReportService.addReport({
        type: type.id,
        place,
        description,
        photo: file instanceof File ? file : undefined,
      });

      onClose(report);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('geovelo.new_report_dialog.server_error'));
    }

    setSubmitting(false);
    markerRef.current.setDraggable(true);
  }

  return (
    <Dialog fullWidth maxWidth="sm" scroll="body" {...props}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>{t('geovelo.new_report_dialog.title')}</DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Autocomplete
            defaultValue={selectedPlace}
            disableClearable={true}
            disabled={!values.type}
            error={placeError}
            onSelect={(place) => {
              selectPlace(place);
              if (!place) return;
              const position = { lng: place.point.coordinates[0], lat: place.point.coordinates[1] };
              map?.flyTo({ center: position, zoom: Math.max(15, map.getZoom()) });
              markerRef.current?.setLngLat(position);
            }}
            size="small"
          />
          <StyledMap id={mapId} />
          <Typography color="textSecondary" variant="caption">
            {t('geovelo.new_report_dialog.type')}
          </Typography>
          <Box>
            <Grid container spacing={2}>
              {reportTypesMap &&
                reportTypes?.map(({ id, code, titleKey }) => {
                  if (code === 'support' || code === 'exclusionZone') return <Fragment key={id} />;

                  const type = reportTypesMap[code];
                  if (!type) return <Fragment key={id} />;

                  const { Icon, color } = type;

                  return (
                    <Grid
                      item
                      alignItems="center"
                      display="flex"
                      flexDirection="column"
                      key={id}
                      sm={3}
                      xs={6}
                    >
                      <StyledIconButton
                        className={values.type === code ? 'active' : ''}
                        custom-color={color}
                        disabled={isSubmitting}
                        onClick={() => setFieldValue('type', code)}
                        size="large"
                      >
                        <Icon />
                      </StyledIconButton>
                      <Typography align="center" variant="caption">
                        {t(titleKey)}
                      </Typography>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
          <TextField
            fullWidth
            multiline
            required
            disabled={isSubmitting}
            error={Boolean(errors.description)}
            id="description"
            InputLabelProps={{ shrink: true }}
            inputProps={{ minLength: 10 }}
            label={t('geovelo.new_report_dialog.description_label')}
            margin="none"
            name="description"
            onChange={handleChange}
            rows={1}
            size="small"
            value={values.description}
            variant="outlined"
          />
          <FileInput
            disabled={isSubmitting}
            file={file}
            onChange={setFile}
            size="small"
            type="image"
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isSubmitting} onClick={() => onClose()} type="reset">
            {t('commons.actions.cancel')}
          </Button>
          <Button
            color="primary"
            disabled={isSubmitting || !isValid}
            type="submit"
            variant="contained"
          >
            {t('commons.actions.contribute')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const StyledMap = styled.div`
  height: 200px;
  margin: 0 -24px;
  position: relative;
`;

const StyledIconButton = styled(IconButton)<{ 'custom-color': string }>`
  && {
    border: 1px solid #ddd;
    margin-bottom: 8px;

    &.active {
      background-color: ${({ 'custom-color': color }) => color};
      border: 1px solid ${({ 'custom-color': color }) => color};
      color: #fff;
    }
  }
`;

export default NewReportDialog;
