import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PressAreaQuery } from '../../../graphql-types';
import Seo from '../../components/seo';
import { TPageProps } from '../../page-props';
import Content from '../content';

function PressAreaPage(props: TPageProps<PressAreaQuery>) {
  const { t } = useTranslation();

  const title = props.data.prismicPressArea?.data.title?.text;
  const description = props.data.prismicPressArea?.data.description?.text;

  return (
    <>
      <Seo
        description={description}
        title={`${title || t('geovelo.pages_titles.press_area')}`}
        {...props}
      />
      <Content {...props} query={props.data.prismicPressArea} />
    </>
  );
}

export const query = graphql`
  fragment PrismicPressAreaBanner on PrismicPressAreaDataBodyBanner {
    id
    slice_type
    primary {
      background_color_start
      background_color_end
      baseline {
        html
      }
      image {
        gatsbyImageData(placeholder: NONE)
      }
    }
    items {
      cta_link {
        link_type
        url
      }
      cta_title {
        text
      }
      cta_color
      cta_variant
    }
  }
  fragment PrismicPressAreaRedirectionItems on PrismicPressAreaDataBodyRedirectionitems {
    id
    slice_type
    items {
      item_icon {
        gatsbyImageData(placeholder: NONE)
      }
      item_title {
        text
      }
      item_description {
        text
      }
      item_link {
        url
      }
      item_date
    }
  }
  fragment PrismicPressAreaPressRelease on PrismicPressAreaDataBodyPressrelease {
    id
    slice_type
    primary {
      title {
        text
      }
    }
    items {
      item_title {
        text
        html
      }
      item_description {
        text
      }
      item_link {
        url
      }
    }
  }
  fragment PressAreaData on PrismicPressAreaData {
    title {
      text
    }
    description {
      text
    }
    body {
      ... on PrismicPressAreaDataBodyBanner {
        ...PrismicPressAreaBanner
      }
      ... on PrismicPressAreaDataBodyRedirectionitems {
        ...PrismicPressAreaRedirectionItems
      }
      ... on PrismicPressAreaDataBodyPressrelease {
        ...PrismicPressAreaPressRelease
      }
    }
  }
`;

export default withPrismicPreview(PressAreaPage);
