import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function StarOutlinedIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 25 25" {...props}>
      <path
        d="M11.1549 3.30307C11.7052 2.18832 13.2948 2.18832 13.8451 3.30307L15.8593 7.38376C16.0776 7.82603 16.4994 8.13272 16.9875 8.20405L21.4943 8.8628C22.7242 9.04257 23.2144 10.5543 22.324 11.4216L19.0652 14.5956C18.7114 14.9402 18.5499 15.4369 18.6334 15.9237L19.4021 20.4061C19.6123 21.6315 18.326 22.566 17.2256 21.9872L13.1982 19.8693C12.7611 19.6394 12.2389 19.6394 11.8018 19.8693L7.77445 21.9872C6.674 22.566 5.38768 21.6315 5.59786 20.4061L6.36663 15.9237C6.45012 15.4369 6.28863 14.9402 5.93482 14.5956L2.67602 11.4216C1.78563 10.5543 2.2758 9.04257 3.50567 8.8628L8.01255 8.20405C8.50058 8.13272 8.92236 7.82603 9.14067 7.38376L11.1549 3.30307Z"
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </SvgIcon>
  );
}
