import {
  BikeRoute,
  IBikeRouteRide,
  IPhoto,
  IRideMedia,
  TBackendBikeRoutePriority,
  TBackendPublicationStatus,
  TBackendRideArea,
  TBackendRideMediaType,
  areas,
  mediaTypes,
  priorities,
  publicationStatuses,
} from '@geovelo-frontends/commons';

import { BikeRouteData } from '../../graphql-types';
import environment from '../environment';

import { parsePhoto } from './photo';

const { backendUrl: _backendUrl } = environment;

export function parseBikeRoute(
  {
    id,
    creator,
    title,
    description,
    distance,
    area_wide,
    icon,
    icon_alt,
    geo_point_icon,
    geometry_condensed,
    rides,
    photos,
    medias,
    theme_primary_color,
    theme_secondary_color,
    publication_status,
  }: BikeRouteData,
  backendUrl = _backendUrl,
): BikeRoute {
  return new BikeRoute(
    id || -1,
    creator || null,
    title || '',
    description || null,
    distance || 0,
    areas[area_wide as TBackendRideArea] || 'local',
    icon ? `${backendUrl}${icon}` : null,
    icon_alt || null,
    (geo_point_icon as GeoJSON.Point) || null,
    (geometry_condensed as GeoJSON.MultiLineString) || null,
    null,
    (rides || []).length,
    (rides || [])
      .reduce<IBikeRouteRide[]>((res, ride) => {
        if (ride) {
          const {
            ride_id,
            priority,
            order_atob,
            ride_atob_next,
            ride_atob_previous,
            order_btoa,
            ride_btoa_next,
            ride_btoa_previous,
          } = ride;

          res.push({
            rideId: ride_id || -1,
            priority: priorities[priority as TBackendBikeRoutePriority] || 'main',
            order: order_atob || 1,
            nextRideId: ride_atob_next || null,
            prevRideId: ride_atob_previous || null,
            returnOrder: order_btoa || 1,
            returnNextRideId: ride_btoa_next || null,
            returnPrevRideId: ride_btoa_previous || null,
          });
        }

        return res;
      }, [])
      .sort((a, b) => a.order - b.order),
    (photos || [])
      .reduce<IPhoto[]>((res, photo) => {
        if (photo) res.push(parsePhoto(photo, backendUrl));

        return res;
      }, [])
      .sort((a, b) => (a.id && b.id ? a.id - b.id : 0)),
    (medias || []).reduce<IRideMedia[]>((res, media) => {
      if (media) {
        const { id, title, description, copyright, url, type } = media;

        res.push({
          id: id || -1,
          title,
          description,
          copyright,
          url: url || undefined,
          type: mediaTypes[type as TBackendRideMediaType] || undefined,
        });
      }

      return res;
    }, []),
    theme_primary_color || null,
    theme_secondary_color || null,
    publicationStatuses[publication_status as TBackendPublicationStatus] || 'published',
  );
}
