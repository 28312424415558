import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function SendIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M20.17 9.23L6.17001 3.45C5.64029 3.23078 5.05884 3.16794 4.49451 3.26891C3.93018 3.36988 3.4066 3.63043 2.98575 4.01972C2.5649 4.40901 2.2644 4.91073 2.11983 5.46549C1.97526 6.02025 1.99268 6.60483 2.17001 7.15L3.71001 12L2.13001 16.85C1.94788 17.3974 1.92742 17.9856 2.07108 18.5443C2.21475 19.103 2.51644 19.6084 2.94001 20C3.48465 20.5051 4.19729 20.7901 4.94001 20.8C5.33474 20.7997 5.72554 20.7216 6.09001 20.57L20.14 14.79C20.6872 14.5624 21.1547 14.1779 21.4836 13.6849C21.8125 13.192 21.988 12.6126 21.988 12.02C21.988 11.4274 21.8125 10.848 21.4836 10.3551C21.1547 9.86209 20.6872 9.47756 20.14 9.25L20.17 9.23ZM5.36001 18.7C5.18361 18.7728 4.99007 18.7935 4.80225 18.7599C4.61442 18.7262 4.44015 18.6395 4.30001 18.51C4.168 18.3838 4.07304 18.224 4.02545 18.0477C3.97785 17.8714 3.97943 17.6854 4.03001 17.51L5.49001 13H19.22L5.36001 18.7ZM5.49001 11L4.00001 6.53C3.94943 6.35455 3.94785 6.1686 3.99545 5.99232C4.04304 5.81603 4.138 5.65615 4.27001 5.53C4.36412 5.43128 4.47742 5.35285 4.60296 5.29954C4.7285 5.24622 4.86362 5.21916 5.00001 5.22C5.13406 5.22026 5.26669 5.24746 5.39001 5.3L19.22 11H5.49001Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
