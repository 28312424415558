import {
  IPoint,
  Search,
  UserPlace,
  createNewImageMarker,
  getAnchorAndOffset,
  usePopup,
  userPlaceTypes,
} from '@geovelo-frontends/commons';
import { Theme } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';

import UserPlacePopup from '../../components/user-place-popup';
import { AppContext } from '../../context';

import { Map, Marker, Popup } from '!maplibre-gl';

const minZoom = 9;

function useUserPlaces(
  map: Map | null,
  {
    theme,
    search,
    smallDevice,
    onSearchUpdated,
  }: { onSearchUpdated: () => void; search?: Search; smallDevice: boolean; theme: Theme },
) {
  const [selectedPlace, selectPlace] = useState<UserPlace | null>(null);
  const {
    user: { places },
  } = useContext(AppContext);
  const selectedIdRef = useRef<number | null>(null);
  const markers = useRef<{ [key: number]: { id: number; marker: Marker } }>({});
  const tooltip = useRef<Popup>();
  const { t } = useTranslation();
  const { centerPopup } = usePopup(map);

  useEffect(() => {
    update();

    map?.on('zoomend', update);

    return () => {
      map?.off('zoomend', update);
      clear();
    };
  }, [map, places]);

  function clearTooltip() {
    tooltip.current?.remove();
  }

  function openTooltip({
    id,
    type,
    title,
    point: {
      coordinates: [lng, lat],
    },
  }: UserPlace) {
    clearTooltip();
    if (!map || selectedIdRef.current === id) return;

    const location: IPoint = { lat, lng };
    const { titleKey } = userPlaceTypes[type];
    const { anchor, offset } = getAnchorAndOffset(map, location, 12);

    tooltip.current = new Popup({
      anchor,
      offset: (Array.isArray(offset) && [offset[0], -20]) || 0,
      className: 'map-tooltip',
      closeButton: false,
    })
      .setHTML(title || (titleKey && t(titleKey)) || '')
      .setLngLat(location)
      .addTo(map);
  }

  function closePrevSelectedPopup() {
    Object.values(markers.current).forEach(({ id, marker }) => {
      if (id !== selectedIdRef.current && marker.getPopup().isOpen()) marker.togglePopup();
    });
  }

  function openPopup(place: UserPlace) {
    closePrevSelectedPopup();

    if (smallDevice) return;

    const {
      id,
      point: {
        coordinates: [lng, lat],
      },
    } = place;
    const selectedMarker = selectedIdRef.current && markers.current[selectedIdRef.current];
    if (!selectedMarker) {
      unselect();
      return;
    }
    if (selectedMarker.marker.getPopup().isOpen()) return;

    selectedMarker.marker.togglePopup();

    const ele = document.getElementById(`user-place-${id}-popup`);
    if (ele) {
      const container = createRoot(ele);
      container.render(
        <UserPlacePopup
          onClose={() => {
            if (selectedMarker.marker.getPopup().isOpen()) selectedMarker.marker.togglePopup();
            if (container) container.unmount();
          }}
          onSearchUpdated={onSearchUpdated}
          place={place}
          search={search}
          theme={theme}
        />,
      );
    }

    centerPopup({ lat, lng }, selectedMarker.marker.getPopup(), { height: 400 });
  }

  function update() {
    if (!map || !places || map.getZoom() < minZoom) {
      clear();
      return;
    }

    places.forEach((place) => {
      const {
        id,
        type,
        point: {
          coordinates: [lng, lat],
        },
      } = place;
      if (typeof id !== 'number') return;

      if (!markers.current[id]) {
        const { icon, color } = userPlaceTypes[type];
        const marker = createNewImageMarker(
          {
            color,
            icon,
          },
          {
            onClick: () => {
              clearTooltip();
              if (selectedIdRef.current !== id) {
                selectedIdRef.current = id;
                closePrevSelectedPopup();
                openPopup(place);
                selectPlace(place);
              }
            },
            onMouseEnter: () => openTooltip(place),
            onMouseLeave: () => clearTooltip(),
          },
        )
          .setLngLat({ lat, lng })
          .setPopup(
            new Popup({
              maxWidth: '350px',
              closeButton: false,
              closeOnClick: true,
              className: 'map-custom-popup',
              offset: [0, -35],
            })
              .setHTML(`<div id="user-place-${id}-popup"></div>`)
              .on('close', () => {
                if (selectedIdRef.current === id && markers.current[id]) {
                  unselect();
                }
              }),
          )
          .addTo(map);

        markers.current[id] = { id, marker };
      }
    });
  }

  function unselect() {
    selectedIdRef.current = null;
    selectPlace(null);
    closePrevSelectedPopup();
  }

  function clear() {
    Object.values(markers.current).forEach(({ marker }) => marker.remove());
    markers.current = {};
  }

  return { selectedPlace, unselect };
}

export default useUserPlaces;
