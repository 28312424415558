import isPropValid from '@emotion/is-prop-valid';
import { i18nCommons } from '@geovelo-frontends/commons';
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider, ThemeOptions, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { WrapPageElementBrowserArgs } from 'gatsby';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';
import React, { ReactElement, useContext, useEffect, useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import GuestPage from './components/guest-page';
import { AppContext } from './context';
import i18n from './i18n';
import Layout from './layouts/default';
import { TPageContext, TPageData, TPageProps, TPageState } from './page-props';

export function LayoutWrapper({ element, ...props }: TPageProps & { element: ReactElement }) {
  const {
    pageContext: { langKey, pageLayout },
  } = props;

  useEffect(() => {
    i18nCommons.changeLanguage(langKey);
    moment.locale(langKey);
  }, [props.pageContext.slug]);

  if (pageLayout === 'sign-in') return <GuestPage {...props} element={element} />;

  return <Layout {...props} element={element} />;
}

function PageElementWrapper({
  element,
  props,
}: WrapPageElementBrowserArgs<TPageData, TPageContext, TPageState>) {
  const {
    app: { mode },
  } = useContext(AppContext);
  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: ["'Nunito Sans'", "'Roboto'", 'sans-serif'].join(', '),
        },
        palette: {
          mode,
          primary: {
            light: '#c2d5f3',
            main: '#326ac2',
            dark: '#0a429a',
          },
          secondary: {
            light: '#8eeac2',
            main: '#2ac682',
            dark: '#03825c',
            contrastText: '#fff',
          },
          error: {
            main: '#d34949',
          },
          black: {
            main: '#313131',
            contrastText: '#fff',
          },
          white: {
            main: '#fff',
            contrastText: '#326AC2',
          },
          purple: { main: '#905EFA', contrastText: '#FFF' },
          red: { main: '#DD428D', contrastText: '#fff' },
        },
        components: {
          MuiLinearProgress: {
            styleOverrides: {
              root: {
                borderRadius: '8px',
                backgroundColor: '#cfcfcf',
              },
              bar: {
                borderRadius: '8px',
              },
            },
          },
          MuiButton: {
            defaultProps: {
              color: 'inherit',
            },
          },
        },
      } as ThemeOptions),
    [mode],
  );

  return (
    <I18nextProvider i18n={i18n.cloneInstance({ lng: props.pageContext.langKey })}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <StyleSheetManager
            shouldForwardProp={(propName, elementToBeRendered) =>
              typeof elementToBeRendered === 'string' ? isPropValid(propName) : true
            }
          >
            <CssBaseline />
            <SnackbarProvider
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              maxSnack={1}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <LayoutWrapper {...props} element={element} />
              </LocalizationProvider>
            </SnackbarProvider>
          </StyleSheetManager>
        </ThemeProvider>
      </MuiThemeProvider>
    </I18nextProvider>
  );
}

export default PageElementWrapper;
