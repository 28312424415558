import {
  BikeRoute,
  IPoint,
  Place,
  Ride,
  createImageMarker,
  getAnchorAndOffset,
  useLayers,
  useSource,
} from '@geovelo-frontends/commons';
import { darken, useTheme } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';

import tripStepIcon from '../../assets/images/trip-step-white.svg';

import { LngLatBounds, Map, MapMouseEvent, Marker, Popup } from '!maplibre-gl';

const colors = [
  '#326AC2',
  '#E06621',
  '#DA3A87',
  '#1589BB',
  '#BB8A1A',
  '#268B1D',
  '#D34949',
  '#B235C6',
  '#03825C',
  '#743AEF',
];

const sourceId = 'bike-routes';
const layerId = 'bike-routes';
const stepsLayerId = 'bike-route-steps';

function useBikeRoutes(
  map: Map | null,
  { detailed: _detailed, onClick }: { detailed?: boolean; onClick?: (id: number) => void } = {},
): {
  initialized: boolean;
  init: () => void;
  add: (bikeRoute: BikeRoute, rides: Ride[]) => void;
  update: (bikeRoutes: BikeRoute[]) => void;
  clear: () => void;
} {
  const [initialized, setInitialized] = useState(false);
  const [detailed, setDetailed] = useState(false);
  const markers = useRef<{ [key: number]: Marker }>({});
  const stepMarkers = useRef<Marker[]>([]);
  const tooltip = useRef<Popup>();
  const { addGeoJSONSource, updateGeoJSONSource, clearGeoJSONSource } = useSource(map, sourceId);
  const { addLineLayer, addCircleLayer } = useLayers(map);
  const theme = useTheme();

  useEffect(() => {
    if (_detailed !== undefined) setDetailed(_detailed);
  }, [_detailed]);

  function handleMove({
    lngLat,
    point,
    features,
    defaultPrevented,
    originalEvent,
  }: MapMouseEvent & {
    features?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[] | undefined;
  }) {
    if (
      defaultPrevented ||
      originalEvent.defaultPrevented ||
      !map ||
      !features ||
      !features[0] ||
      !features[0].properties ||
      map.queryRenderedFeatures(point).find(({ layer: { id: layerId } }) => layerId === 'my-trips')
    ) {
      return;
    }

    map.getCanvas().style.cursor = 'pointer';

    addTooltip(lngLat, features[0].properties as BikeRoute);
  }

  function handleClick({
    point,
    features,
    defaultPrevented,
    originalEvent,
  }: MapMouseEvent & {
    features?: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>[] | undefined;
  }) {
    if (
      defaultPrevented ||
      originalEvent.defaultPrevented ||
      !map ||
      !features ||
      !features[0] ||
      !features[0].properties ||
      map.queryRenderedFeatures(point).find(({ layer: { id: layerId } }) => layerId === 'my-trips')
    ) {
      return;
    }

    const { id } = features[0].properties;
    if (id) onClick?.(id);
  }

  function handleLeave() {
    if (!map) return;

    map.getCanvas().style.cursor = '';
    clearTooltip();
  }

  function init() {
    if (!map) return;

    addGeoJSONSource();

    addLineLayer(
      layerId,
      sourceId,
      { 'line-join': 'round', 'line-cap': 'round' },
      {
        'line-color': ['get', 'color'],
        'line-width': ['get', 'width'],
      },
    );

    if (!detailed)
      addCircleLayer(
        stepsLayerId,
        sourceId,
        {
          'circle-radius': ['get', 'radius'],
          'circle-stroke-width': 2,
          'circle-stroke-opacity': 1,
          'circle-color': ['get', 'color'],
          'circle-stroke-color': '#fff',
        },
        { filter: ['==', 'step', true] },
      );

    map.on('mousemove', layerId, handleMove);
    map.on('click', layerId, handleClick);
    map.on('mouseleave', layerId, handleLeave);

    if (!detailed) {
      map.on('mousemove', stepsLayerId, handleMove);
      map.on('click', stepsLayerId, handleClick);
      map.on('mouseleave', stepsLayerId, handleLeave);
    }

    setInitialized(true);
  }

  function addTooltip(location: IPoint, { title, iconUrl }: BikeRoute, _offset = 0) {
    clearTooltip();
    if (!map || !title) return;

    const { anchor, offset } = getAnchorAndOffset(map, location, _offset);

    tooltip.current = new Popup({
      anchor,
      offset,
      className: 'map-tooltip',
      closeButton: false,
    })
      .setHTML(
        `<div style="align-items: center; display: flex; flex-direction: row; gap: 8px;">${
          iconUrl ? `<img src="${iconUrl}" width="24px" />` : ''
        }<span>${title}</span></div>`,
      )
      .setLngLat(location)
      .addTo(map);
  }

  function clearTooltip() {
    tooltip.current?.remove();
  }

  function update(bikeRoutes: BikeRoute[]) {
    if (!map) return;

    updateGeoJSONSource({
      type: 'FeatureCollection',
      features: bikeRoutes
        .sort((a, b) => b.id - a.id)
        .reduce<GeoJSON.Feature[]>((res, bikeRoute) => {
          const { id, title, center, condensedGeometry, iconUrl, primaryColor } = bikeRoute;
          const color = primaryColor || '#326ac2';

          if (condensedGeometry) {
            res.push(
              {
                type: 'Feature',
                geometry: condensedGeometry,
                properties: { color: darken(color, 0.25), width: 9, id, title, iconUrl },
              },
              {
                type: 'Feature',
                geometry: condensedGeometry,
                properties: { color, width: 5, id, title, iconUrl },
              },
            );
          }

          if (center && !markers.current[id] && iconUrl) {
            const [lng, lat] = center.coordinates;
            markers.current[id] = createImageMarker(
              {
                radius: 12,
                borderWidth: 2,
                backgroundImage: iconUrl || undefined,
              },
              {
                onClick: () => onClick?.(id),
                onMouseEnter: () => addTooltip({ lat, lng }, bikeRoute, 14),
                onMouseLeave: () => clearTooltip(),
              },
            )
              .setLngLat({ lat, lng })
              .addTo(map);
          }

          return res;
        }, []),
    });
  }

  function add(bikeRoute: BikeRoute, _rides: Ride[]) {
    if (!map) return;

    const { bounds, primaryColor } = bikeRoute;
    const color = primaryColor || '#326ac2';

    const features: GeoJSON.Feature[] = [];

    const rides = bikeRoute.rides
      .map(({ rideId }) => _rides.find(({ id }) => id === rideId))
      .filter(Boolean);

    for (let i = 0; i < rides.length; ++i) {
      const ride = rides[i];
      if (ride) {
        const { id, title, from, to, condensedGeometry } = ride;

        if (from) {
          features.push({
            type: 'Feature',
            geometry: from.point,
            properties: {
              id,
              title,
              step: true,
              radius: i === 0 ? 6 : 4,
              color: i === 0 ? '#2ac682' : color,
            },
          });
        }

        if (to && i === rides.length - 1) {
          features.push({
            type: 'Feature',
            geometry: to.point,
            properties: {
              id,
              title,
              step: true,
              radius: 6,
              color: '#dd428d',
            },
          });
        }

        if (detailed) {
          rides.forEach((ride, index) => {
            if (ride && ride.condensedGeometry)
              features.push(
                {
                  type: 'Feature',
                  geometry: ride.condensedGeometry,
                  properties: { color: '#fff', width: 9, id: ride.id, title: ride.title },
                },
                {
                  type: 'Feature',
                  geometry: ride.condensedGeometry,
                  properties: {
                    color: index === 0 ? color : colors[index % colors.length],
                    width: 5,
                    id: ride.id,
                    title: ride.title,
                  },
                },
              );
          });
        } else if (condensedGeometry) {
          features.push(
            {
              type: 'Feature',
              geometry: condensedGeometry,
              properties: { color: '#fff', width: 9, id, title },
            },
            {
              type: 'Feature',
              geometry: condensedGeometry,
              properties: { color, width: 5, id, title },
            },
          );
        }
      }
    }

    updateGeoJSONSource({
      type: 'FeatureCollection',
      features,
    });

    if (detailed) addTripMarkers(rides);

    if (bounds) {
      const { north, east, south, west } = bounds;
      map.fitBounds(new LngLatBounds({ lat: south, lng: west }, { lat: north, lng: east }), {
        padding: 50,
      });
    }
  }

  function addTripMarker({
    point: {
      coordinates: [lng, lat],
    },
  }: Place) {
    if (!map) return;

    const marker = createImageMarker({
      radius: 10,
      borderWidth: 2,
      backgroundColor: theme.palette.primary.main,
      backgroundSize: 16,
      backgroundImage: tripStepIcon,
      draggable: false,
    })
      .setLngLat({ lat, lng })
      .addTo(map);

    stepMarkers.current.push(marker);
  }

  function addTripMarkers(rides: (Ride | undefined)[]) {
    if (!map) return;

    rides.forEach((ride, index) => {
      if (ride && ride.from) {
        addTripMarker(ride.from);

        const nextRide = rides[index + 1];
        if (!nextRide && ride.to) addTripMarker(ride.to);
      }
    });
  }

  function clearMarkers() {
    stepMarkers.current.forEach((marker) => marker.remove());
    stepMarkers.current = [];
  }

  function clear() {
    clearGeoJSONSource();

    Object.values(markers.current).forEach((marker) => marker.remove());
    markers.current = {};
    clearMarkers();

    clearTooltip();

    map?.off('mousemove', layerId, handleMove);
    map?.off('click', layerId, handleClick);
    map?.off('mouseleave', layerId, handleLeave);

    if (!detailed) {
      map?.off('mousemove', stepsLayerId, handleMove);
      map?.off('click', stepsLayerId, handleClick);
      map?.off('mouseleave', stepsLayerId, handleLeave);
    }
  }

  return { initialized, init, update, add, clear };
}

export default useBikeRoutes;
