import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  HomeBikeRouteDataFragment,
  HomeQuery,
  PrismicHomeDataBodyBannerPrimary,
} from '../../graphql-types';
import Seo from '../components/seo';
import environment from '../environment';
import { TPageProps } from '../page-props';

import Content from './content';

function IndexPage(props: TPageProps<HomeQuery>) {
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  const banner = props.data.prismicHome?.data.body.find(({ slice_type }) => slice_type === 'banner')
    ?.primary as PrismicHomeDataBodyBannerPrimary;

  return (
    <>
      <Seo
        description={
          props.data.prismicHome?.data.description?.text ||
          t('geovelo.pages_descriptions.home') ||
          ''
        }
        ld={{
          '@context': 'http://schema.org',
          '@type': 'SoftwareApplication',
          name: 'Geovelo',
          downloadUrl: `${environment.android.storeUrls[currentLanguage]}&referrer=utm_source%3Dstructured_data`,
          operatingSystem: 'ANDROID',
          applicationCategory: 'TravelApplication',
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: banner?.google_play_rating,
            ratingCount: '20000',
          },
          offers: {
            '@type': 'Offer',
            price: '0',
          },
        }}
        title={
          props.data.prismicHome?.data.title?.text || t('geovelo.pages_titles.home') || 'Geovelo'
        }
        {...props}
      />
      <Content
        {...props}
        enableStoresInBanner
        bikeRoutesData={props.data.allBikeRouteProd.nodes.reduce<HomeBikeRouteDataFragment[]>(
          (res, { data }) => {
            if (data) res.push(data);

            return res;
          },
          [],
        )}
        query={props.data.prismicHome}
      />
    </>
  );
}

export const query = graphql`
  fragment PrismicHomeBanner on PrismicHomeDataBodyBanner {
    id
    slice_type
    primary {
      background_color_start
      background_color_end
      dark_background
      fullscreen_background
      image {
        gatsbyImageData(placeholder: NONE)
      }
      enable_image_margin
      baseline {
        html
      }
      subtitle {
        html
      }
      app_store_rating
      google_play_rating
      number_of_millions_of_downloads
    }
    items {
      cta_link {
        link_type
        slug
        url
      }
      cta_title {
        text
      }
      cta_variant
      cta_color
      cta_tracking_category
      cta_tracking_name {
        text
      }
    }
  }
  fragment PrismicHomeBottomBanner on PrismicHomeDataBodyBottombanner {
    id
    slice_type
    primary {
      background_color
      image {
        gatsbyImageData(placeholder: NONE)
      }
      image_align
      baseline {
        text
        html
      }
      app_store_rating
      google_play_rating
      number_of_millions_of_downloads
    }
  }
  fragment PrismicHomeVerticalCards on PrismicHomeDataBodyVerticalcards {
    id
    slice_type
    primary {
      linked_to_prev_slice
      background_color
      background_color_end
      dark_background
      images_size
      title {
        text
        html
      }
      description {
        text
        html
      }
      cta_link {
        link_type
        url
      }
      cta_title {
        text
      }
    }
    items {
      icon {
        gatsbyImageData(placeholder: NONE)
      }
      title {
        text
      }
      description {
        text
        html
      }
      anchor {
        text
      }
    }
  }
  fragment PrismicHomeHorizontalCard on PrismicHomeDataBodyHorizontalcard {
    id
    slice_type
    primary {
      section_id {
        text
      }
      linked_to_next_slice
      image_on_the_right
      image_type
      image {
        gatsbyImageData(placeholder: NONE)
      }
      title {
        text
        html
      }
      description {
        text
        html
      }
    }
    items {
      cta_type
      cta_variant
      cta_title {
        text
      }
      cta_link {
        link_type
        url
      }
      cta_tracking_category
      cta_tracking_name {
        text
      }
    }
  }
  fragment PrismicHomeLogos on PrismicHomeDataBodyLogos {
    id
    slice_type
    primary {
      background_color
      title {
        text
        html
      }
    }
    items {
      item_logo {
        gatsbyImageData(placeholder: NONE)
      }
      item_title {
        text
      }
    }
  }
  fragment PrismicHomeDivider on PrismicHomeDataBodyDivider {
    id
    slice_type
    primary {
      align
      image {
        gatsbyImageData(placeholder: NONE, width: 64)
      }
    }
  }
  fragment PrismicHomeBikeRoutesList on PrismicHomeDataBodyBikerouteslist {
    id
    slice_type
    items {
      bike_route_id
    }
    primary {
      variant
      background_color
      see_all_button_label {
        text
      }
      subtitle {
        text
      }
      title {
        text
      }
    }
  }
  fragment HomeData on PrismicHomeData {
    title {
      text
    }
    description {
      text
    }
    body {
      ... on PrismicHomeDataBodyBanner {
        ...PrismicHomeBanner
      }
      ... on PrismicHomeDataBodyBottombanner {
        ...PrismicHomeBottomBanner
      }
      ... on PrismicHomeDataBodyVerticalcards {
        ...PrismicHomeVerticalCards
      }
      ... on PrismicHomeDataBodyHorizontalcard {
        ...PrismicHomeHorizontalCard
      }
      ... on PrismicHomeDataBodyBikerouteslist {
        ...PrismicHomeBikeRoutesList
      }
      ... on PrismicHomeDataBodyLogos {
        ...PrismicHomeLogos
      }
      ... on PrismicHomeDataBodyDivider {
        ...PrismicHomeDivider
      }
    }
  }
  fragment HomeBikeRouteData on bikeRouteProdData {
    id
    title
    photos {
      id
      thumbnail
    }
    distance
    description
    rides {
      ride_id
    }
  }
`;

export default withPrismicPreview(IndexPage);
