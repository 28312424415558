import { DialogContent, DialogProps, Dialog as MuiDialog } from '@mui/material';
import React from 'react';

type TProps = DialogProps;

function Dialog({ ...props }: TProps): JSX.Element {
  return <MuiDialog {...props} sx={{ '.MuiPaper-root': { borderRadius: 4 } }} />;
}

export function DialogWithContent({ children, ...props }: TProps): JSX.Element {
  return (
    <MuiDialog {...props} sx={{ '.MuiPaper-root': { borderRadius: 4 } }}>
      <DialogContent>{children}</DialogContent>
    </MuiDialog>
  );
}

export default Dialog;
