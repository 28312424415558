import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function RemoveIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 25" {...props}>
      <g id="trash">
        <path
          d="M15.5 6.5V7H16H20C20.1326 7 20.2598 7.05268 20.3536 7.14645C20.4473 7.24021 20.5 7.36739 20.5 7.5C20.5 7.63261 20.4473 7.75979 20.3536 7.85355C20.2598 7.94732 20.1326 8 20 8H19H18.5V8.5V19.5C18.5 20.163 18.2366 20.7989 17.7678 21.2678C17.2989 21.7366 16.663 22 16 22H8C7.33696 22 6.70107 21.7366 6.23223 21.2678C5.76339 20.7989 5.5 20.163 5.5 19.5V8.5V8H5H4C3.86739 8 3.74021 7.94732 3.64645 7.85355C3.55268 7.75979 3.5 7.63261 3.5 7.5C3.5 7.36739 3.55268 7.24021 3.64645 7.14645C3.74021 7.05268 3.86739 7 4 7H8H8.5V6.5V5.5C8.5 4.83696 8.76339 4.20107 9.23223 3.73223C9.70107 3.26339 10.337 3 11 3H13C13.663 3 14.2989 3.26339 14.7678 3.73223C15.2366 4.20107 15.5 4.83696 15.5 5.5V6.5ZM14 7H14.5V6.5V5.5C14.5 5.10217 14.342 4.72064 14.0607 4.43934C13.7794 4.15804 13.3978 4 13 4H11C10.6022 4 10.2206 4.15804 9.93934 4.43934C9.65804 4.72064 9.5 5.10217 9.5 5.5V6.5V7H10H14ZM7 8H6.5V8.5V19.5C6.5 19.8978 6.65804 20.2794 6.93934 20.5607C7.22064 20.842 7.60218 21 8 21H16C16.3978 21 16.7794 20.842 17.0607 20.5607C17.342 20.2794 17.5 19.8978 17.5 19.5V8.5V8H17H7Z"
          id="Vector"
          stroke="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
