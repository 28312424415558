import { navigatorLanguage } from '@geovelo-frontends/commons';
import { navigate } from 'gatsby';
import { useEffect } from 'react';

import { langKeys } from '../languages';

function useRedirect({ slug, search }: { search?: string; slug: string }) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (navigatorLanguage && langKeys.includes(navigatorLanguage))
        navigate(`/${navigatorLanguage}${slug}${search || ''}`, { replace: true });
      else navigate(`/en${slug}${search || ''}`, { replace: true });
    }
  }, []);

  return;
}

export default useRedirect;
