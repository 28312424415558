import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function ArrowOutward(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 16 17" {...props}>
      <path
        d="M11.9467 4.91333C11.879 4.75043 11.7496 4.62098 11.5867 4.55333C11.5065 4.51917 11.4205 4.50105 11.3334 4.5H4.66669C4.48988 4.5 4.32031 4.57024 4.19528 4.69526C4.07026 4.82029 4.00002 4.98986 4.00002 5.16667C4.00002 5.34348 4.07026 5.51305 4.19528 5.63807C4.32031 5.7631 4.48988 5.83333 4.66669 5.83333H9.72669L4.19335 11.36C4.13087 11.422 4.08127 11.4957 4.04743 11.5769C4.01358 11.6582 3.99615 11.7453 3.99615 11.8333C3.99615 11.9213 4.01358 12.0085 4.04743 12.0897C4.08127 12.171 4.13087 12.2447 4.19335 12.3067C4.25533 12.3692 4.32906 12.4187 4.4103 12.4526C4.49154 12.4864 4.57868 12.5039 4.66669 12.5039C4.75469 12.5039 4.84183 12.4864 4.92307 12.4526C5.00431 12.4187 5.07805 12.3692 5.14002 12.3067L10.6667 6.77333V11.8333C10.6667 12.0101 10.7369 12.1797 10.8619 12.3047C10.987 12.4298 11.1565 12.5 11.3334 12.5C11.5102 12.5 11.6797 12.4298 11.8048 12.3047C11.9298 12.1797 12 12.0101 12 11.8333V5.16667C11.999 5.07955 11.9808 4.99348 11.9467 4.91333Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
