import {
  ComputedRoute,
  InfoCard,
  Search,
  Trip,
  TripService,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Box, FormControl, Input, InputAdornment, Typography } from '@mui/material';
import { Link, navigate } from 'gatsby';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '../../components/button';
import { TripsActiveIcon } from '../../components/icons';
import { AppContext } from '../../context';
import { TPageState } from '../../page-props';

function PlanTripCard({
  path,
  search,
  computedRoute,
  onClose,
}: {
  computedRoute: ComputedRoute | null;
  onClose: () => void;
  path: string;
  search: Search;
}): JSX.Element {
  const [trip, setTrip] = useState<Trip>();
  const [loading, setLoading] = useState(false);
  const [nbDays, setNbDays] = useState(2);
  const {
    user: { current: currentUser, trips: userTrips },
    actions: { setUserTrips },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (computedRoute) {
      setNbDays(Math.round(computedRoute.distances.total / 60000));
    }

    return () => {
      cancelPromises();
    };
  }, [computedRoute]);

  useEffect(() => {
    if (trip) {
      navigate(`/${currentLanguage}/trips/${trip.id}`);
    }
  }, [trip]);

  async function handleTripCreate() {
    if (!currentUser) return;

    cancelPromises();

    search.reset({ bikeType: 'own', profile: computedRoute?.cyclingProfile });

    const { wayPoints } = search;
    const from = wayPoints[0];
    const to = wayPoints[wayPoints.length - 1];

    setLoading(true);

    try {
      const { trip: _trip } = await cancellablePromise(
        TripService.create(search, {
          title:
            from?.addressDetail?.primaryText && to?.addressDetail?.primaryText
              ? `${from.addressDetail.primaryText} -> ${to.addressDetail.primaryText}`
              : t('geovelo.trip_planner.trip_title'),
          nbDays,
        }),
      );

      if (userTrips) setUserTrips([_trip, ...userTrips]);

      setTrip(_trip);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('geovelo.trip_planner.server_error'));
        setLoading(false);
      }
    }
  }

  return (
    <InfoCard
      actions={
        <>
          <StyledFormControl margin="none">
            <Input
              aria-describedby="standard-weight-helper-text"
              endAdornment={
                <InputAdornment position="end">
                  <Typography color="inherit" variant="caption">
                    {t('commons.periods.days', { count: nbDays }).toLowerCase()}
                  </Typography>
                </InputAdornment>
              }
              inputProps={{ min: 1 }}
              onChange={({ target: { value } }) => {
                const _nbDays = parseInt(value);
                setNbDays(Number.isNaN(_nbDays) || _nbDays < 1 ? 1 : _nbDays);
              }}
              type="number"
              value={nbDays}
            />
          </StyledFormControl>
          <Typography color="textSecondary" variant="caption">
            {t('geovelo.routing.plan_trip_card.distance', {
              distance: computedRoute
                ? Math.round(computedRoute.distances.total / 1000 / nbDays)
                : '-',
            })}
          </Typography>
          <Box flexGrow={1} />
          {currentUser ? (
            <Button
              color="primary"
              disabled={loading}
              onClick={handleTripCreate}
              variant="contained"
            >
              {t('geovelo.routing.actions.plan_trip')}
            </Button>
          ) : (
            <Button<TPageState>
              color="primary"
              component={Link}
              state={{ prevPath: `${path}${window.location.search}` }}
              to={`/${currentLanguage}/sign-in`}
              variant="contained"
            >
              {t('geovelo.routing.actions.plan_trip')}
            </Button>
          )}
        </>
      }
      description={t('geovelo.routing.plan_trip_card.description')}
      Icon={TripsActiveIcon}
      iconProps={{ color: 'secondary' }}
      onClose={onClose}
      sx={{ borderRadius: 2, marginBottom: 2 }}
      variant="outlined"
    />
  );
}

const StyledFormControl = styled(FormControl)`
  width: 72px;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
  }
`;

export default PlanTripCard;
