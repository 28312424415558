import {
  Poi,
  PoiCategory,
  PoiService,
  Search,
  TPoiCategoryCode,
  useCancellablePromise,
  usePois as useCommonsPois,
} from '@geovelo-frontends/commons';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

import { Map } from '!maplibre-gl';

function usePois(
  map: Map | null,
  categories?: PoiCategory[],
  selectedCategories: { [key in TPoiCategoryCode]?: boolean } = {},
  {
    smallDevice,
    search,
    onSearchUpdated,
    onAddAsRideStep,
    onRemoveFromRideSteps,
  }: {
    onAddAsRideStep?: (poi: Poi) => void;
    onRemoveFromRideSteps?: (poi: Poi) => void;
    onSearchUpdated?: () => void;
    search?: Search;
    smallDevice?: boolean;
  } = {},
): { selectedPoi: Poi | null; update: () => void; unselect: () => void } {
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    initialized: markersInitialized,
    selectedPoi,
    init: initMarkers,
    update: updateMarkers,
    unselect,
    clear: clearMarkers,
  } = useCommonsPois(
    map,
    theme,
    Boolean(smallDevice),
    categories,
    selectedCategories,
    {},
    {
      useNewImageMarkers: true,
      search,
      enqueueSnackbar,
      closeSnackbar,
      onSearchUpdated,
      onAddAsRideStep,
      onRemoveFromRideSteps,
    },
  );
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    return () => {
      cancelPromises();
    };
  }, []);

  useEffect(() => {
    if (map) initMarkers();
  }, [map]);

  useEffect(() => {
    if (markersInitialized && categories) update();

    return () => {
      if (markersInitialized) clearMarkers();
    };
  }, [markersInitialized, categories, selectedCategories]);

  async function update() {
    cancelPromises();
    if (!map || !categories) return;

    try {
      const [[west, south], [east, north]] = map.getBounds().toArray();
      const categoryCodes = categories.flatMap(({ code, children }) => [
        code,
        ...children.map(({ code: childCode }) => childCode),
      ]);

      const pois = await cancellablePromise(
        PoiService.getPois(categories, {
          bounds: { north, east, south, west },
          zoom: map.getZoom(),
          selectedCategories: categoryCodes.filter((code) => selectedCategories[code]),
        }),
      );

      updateMarkers(pois);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') console.error(err);
    }
  }

  return { selectedPoi, unselect, update };
}

export default usePois;
