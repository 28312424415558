import { BikeRoute, Ride } from '@geovelo-frontends/commons';
import { Box, ButtonBase } from '@mui/material';
import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BikeRouteCard from '../../components/bike-route-card';

function BikeRoutesList({
  bikeRoutes,
  rides,
}: {
  bikeRoutes: BikeRoute[] | undefined;
  rides: Ride[] | undefined;
}): JSX.Element {
  const {
    i18n: { language: currentLanguage },
  } = useTranslation();

  if (bikeRoutes && bikeRoutes.length === 0) return <></>;

  return (
    <Box display="flex" flexDirection="column">
      {bikeRoutes
        ? bikeRoutes.map((bikeRoute) => (
            <ButtonBase
              component={Link}
              key={bikeRoute.id}
              to={`/${currentLanguage}/ride-sets/${bikeRoute.id}`}
            >
              <BikeRouteCard bikeRoute={bikeRoute} rides={rides} />
            </ButtonBase>
          ))
        : [0, 1, 2].map((key) => <BikeRouteCard key={key} />)}
    </Box>
  );
}

export default BikeRoutesList;
