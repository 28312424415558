import { Box } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { PrismicJoinUsRecruitmentProcessFragment } from '../../../graphql-types';

import { maxWidth } from './consts';
import CTA from './cta';
import Description from './description';
import Title from './title';

export type TRecruitmentProcessData = PrismicJoinUsRecruitmentProcessFragment;

function RecruitmentProcess({
  data: { primary, items },
}: {
  data: TRecruitmentProcessData;
}): JSX.Element {
  const { t } = useTranslation();

  if (!primary) return <></>;

  const { background_color, title, cta_title, cta_link } = primary;

  return (
    <Box
      alignItems="center"
      bgcolor={background_color || undefined}
      display="flex"
      flexDirection="column"
      gap={5}
      paddingY={10}
      width="100%"
      zIndex={1}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gap={5}
        maxWidth={{ xs: 'calc(100% - 100px)', md: 'calc(100% - 100px)', lg: 'calc(100% - 128px)' }}
        width={maxWidth}
      >
        <Title align="center" data={title} fontSize="1.5rem" fontWeight={700} />
        <Box display="flex" flexWrap="wrap" gap={5}>
          {items.map(({ step_icon, step_description }, index) => {
            const icon = step_icon && getImage(step_icon);

            return (
              <Box
                alignItems="center"
                bgcolor="#fff"
                borderRadius={6}
                display="flex"
                flexDirection="column"
                gap={2}
                key={index}
                paddingX={3}
                paddingY={4}
                width={{ xs: '100%', sm: 'calc((100% - 40px) / 2)', md: 'calc((100% - 80px) / 3)' }}
              >
                {icon && <GatsbyImage alt="" image={icon} style={{ height: 30 }} />}
                <Description
                  color="primary"
                  data={{
                    text: t('geovelo.join_us.recruitment_process.step', { index: index + 1 }),
                  }}
                  fontWeight={700}
                />
                <Description align="center" data={step_description} fontSize="1rem" />
              </Box>
            );
          })}
        </Box>
        <Box>
          <CTA link={cta_link} title={cta_title} />
        </Box>
      </Box>
    </Box>
  );
}

export default RecruitmentProcess;
