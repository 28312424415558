import {
  CyclabilityZone,
  CyclabilityZoneService,
  RideTheme,
  TRideDifficulty,
  difficultiesMap,
  rideThemesMap,
} from '@geovelo-frontends/commons';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CardProps,
  Skeleton,
  Typography,
} from '@mui/material';
import { Link, graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React, { Fragment, ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RidesHubQuery } from '../../../graphql-types';
import Seo from '../../components/seo';
import { maxWidth } from '../../components/slices/consts';
import Description from '../../components/slices/description';
import Title from '../../components/slices/title';
import { AppContext } from '../../context';
import { TPageProps } from '../../page-props';
import Content from '../content';

import Breadcrumb from './breadcrumb';

const rideDifficulties: TRideDifficulty[] = ['easy', 'medium', 'hard'];

function RidesHubPage(props: TPageProps<RidesHubQuery>) {
  const [departments, setDepartments] = useState<CyclabilityZone[]>();
  const [initialized, setInitialized] = useState(false);
  const {
    ride: { themes: rideThemes },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  const title = props.data.prismicRidesHub?.data.title?.text;
  const description = props.data.prismicRidesHub?.data.description?.text;

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (currentLanguage === 'fr') getDepartments();
  }, [currentLanguage, initialized]);

  async function getDepartments() {
    try {
      const { zones: cyclabilityZones } = await CyclabilityZoneService.getZones({
        countryCode: 'fr',
        administrativeLevel: 'DEPARTMENT',
        rowsPerPage: 100,
        query: '{id, administrative_level, name, reference}',
      });

      setDepartments(
        cyclabilityZones.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
      );
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <Seo
        description={description}
        title={`${title || t('geovelo.pages_titles.rides_hub')}`}
        {...props}
      />
      <Content
        disableRedirect
        {...props}
        smallBanner
        breadcrumb={<Breadcrumb />}
        query={props.data.prismicRidesHub}
      />
      <Box
        display="flex"
        flexDirection="column"
        gap={{ xs: 6, lg: 8 }}
        paddingBottom={{ xs: 6, lg: 8 }}
        paddingTop={{ xs: 6, lg: 8 }}
      >
        <List
          subtitle={{
            text:
              props.data.prismicRidesHub?.data.thematic_list_description?.text ||
              t('geovelo.rides_hub.thematic_list.title'),
          }}
          title={{
            text:
              props.data.prismicRidesHub?.data.thematic_list_title?.text ||
              t('geovelo.rides_hub.thematic_list.description'),
          }}
        >
          <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
            {rideThemes
              ? rideThemes.map((theme) => (
                  <RideThemeCard
                    key={theme.id}
                    sx={{
                      width: {
                        xs: '100%',
                        sm: 'calc((100% - 16px) / 2)',
                        md: 'calc((100% - 32px) / 3)',
                        xl: 'calc((100% - 48px) / 4)',
                      },
                    }}
                    theme={theme}
                  />
                ))
              : [1, 2, 3, 4, 5, 6, 7, 8].map((key) => (
                  <RideThemeCard
                    key={key}
                    sx={{
                      width: {
                        xs: '100%',
                        sm: 'calc((100% - 16px) / 2)',
                        md: 'calc((100% - 32px) / 3)',
                        xl: 'calc((100% - 48px) / 4)',
                      },
                    }}
                  />
                ))}
          </Box>
        </List>
        <List
          subtitle={{
            text:
              props.data.prismicRidesHub?.data.difficulty_level_list_description?.text ||
              t('geovelo.rides_hub.difficulty_level_list.title'),
          }}
          title={{
            text:
              props.data.prismicRidesHub?.data.difficulty_level_list_title?.text ||
              t('geovelo.rides_hub.difficulty_level_list.description'),
          }}
        >
          <Box display="flex" flexWrap="wrap" gap={2}>
            {rideDifficulties.map((key) => (
              <PublicKindCard
                difficulty={key}
                key={key}
                sx={{
                  width: {
                    xs: '100%',
                    sm: 'calc((100% - 16px) / 2)',
                    md: 'calc((100% - 32px) / 3)',
                  },
                }}
              />
            ))}
          </Box>
        </List>
        {currentLanguage === 'fr' && (
          <List
            subtitle={{
              text:
                props.data.prismicRidesHub?.data.departments_list_description?.text ||
                t('geovelo.rides_hub.departments_list.title'),
            }}
            title={{
              text:
                props.data.prismicRidesHub?.data.departments_list_title?.text ||
                t('geovelo.rides_hub.departments_list.description'),
            }}
          >
            <Box display="flex" flexWrap="wrap" gap={2}>
              {departments
                ? departments.map(({ id, name, ref }) => (
                    <Box
                      key={id}
                      width={{
                        xs: '100%',
                        sm: 'calc((100% - 16px) / 2)',
                        md: 'calc((100% - 32px) / 3)',
                      }}
                    >
                      <Typography
                        color="inherit"
                        component={Link}
                        to={`/${currentLanguage}/rides-hub/departments/${ref}`}
                      >
                        {name} ({ref})
                      </Typography>
                    </Box>
                  ))
                : new Array(100).fill(null).map((_, index) => (
                    <Box
                      key={index}
                      width={{
                        xs: '100%',
                        sm: 'calc((100% - 16px) / 2)',
                        md: 'calc((100% - 32px) / 3)',
                      }}
                    >
                      <Typography>
                        <Skeleton variant="text" width="100%" />
                      </Typography>
                    </Box>
                  ))}
            </Box>
          </List>
        )}
        <List
          subtitle={{
            text: t('geovelo.rides_hub.partner_list.description'),
          }}
          title={{
            text: t('geovelo.rides_hub.partner_list.title'),
          }}
        >
          <Box display="flex" flexWrap="wrap" gap={2}>
            {props.data.allGeogroupWithRides.nodes.map(({ data }, index) => {
              if (!data) return <Fragment key={index} />;

              const { id, title } = data;

              return (
                <Box
                  key={id}
                  width={{
                    xs: '100%',
                    sm: 'calc((100% - 16px) / 2)',
                    md: 'calc((100% - 32px) / 3)',
                  }}
                >
                  <Typography
                    color="inherit"
                    component={Link}
                    to={`/${currentLanguage}/communities/${id}`}
                  >
                    {title}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </List>
      </Box>
    </>
  );
}

function List({
  title,
  subtitle,
  children,
}: {
  children?: ReactNode;
  subtitle:
    | {
        html?: string | null | undefined;
        text?: string | null | undefined;
      }
    | null
    | undefined;
  title:
    | {
        html?: string | null | undefined;
        text?: string | null | undefined;
      }
    | null
    | undefined;
}): JSX.Element {
  return (
    <Box alignItems="center" alignSelf="stretch" display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="column"
        gap={5}
        maxWidth="100%"
        paddingX={{ xs: 4, md: 8, lg: 16 }}
        width={maxWidth}
      >
        <Box display="flex" flexDirection="column" gap={2}>
          <Title component="h2" data={title} fontSize="1.5em" />
          <Description data={subtitle} />
        </Box>
        {children}
      </Box>
    </Box>
  );
}

function RideThemeCard({ theme, ...props }: { theme?: RideTheme } & CardProps): JSX.Element {
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  return (
    <Card
      elevation={0}
      {...props}
      sx={{ border: '1px solid #CFCFCF', borderRadius: 4, ...props.sx }}
    >
      <CardActionArea
        component={Link}
        sx={{ height: '100%' }}
        to={`/${currentLanguage}/rides-hub/themes/${theme?.key}`}
      >
        {theme ? (
          <CardMedia alt="" component="img" height="170" image={rideThemesMap[theme.key].image} />
        ) : (
          <Skeleton height={170} variant="rectangular" />
        )}
        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 2 }}>
          <Box display="flex" flexDirection="column">
            <Typography fontSize="1rem" fontWeight={600}>
              {theme ? (
                t(`geovelo.rides_hub.thematic_list.themes.${theme.key}`)
              ) : (
                <Skeleton variant="text" width={150} />
              )}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function PublicKindCard({
  difficulty,
  ...props
}: { difficulty: TRideDifficulty } & CardProps): JSX.Element {
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  const { color, icon } = difficultiesMap[difficulty];

  return (
    <Card elevation={0} {...props} sx={{ backgroundColor: color, borderRadius: 4, ...props.sx }}>
      <CardActionArea
        component={Link}
        sx={{ height: '100%' }}
        to={`/${currentLanguage}/rides-hub/difficulties/${difficulty}`}
      >
        <CardContent
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            justifyContent: 'center',
            minHeight: 170,
            padding: 2,
          }}
        >
          <img height={70} src={icon} />
          <Typography fontSize="1rem" fontWeight={600}>
            {t(`geovelo.rides_hub.difficulty_level_list.difficulties.${difficulty}`)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export const query = graphql`
  fragment PrismicRidesHubBanner on PrismicRidesHubDataBodyBanner {
    id
    slice_type
    primary {
      baseline {
        text
      }
      background_color_start
      dark_background
      fullscreen_background
    }
  }
  fragment RidesHubData on PrismicRidesHubData {
    title {
      text
    }
    description {
      text
    }
    thematic_list_title {
      text
    }
    thematic_list_description {
      text
    }
    difficulty_level_list_title {
      text
    }
    difficulty_level_list_description {
      text
    }
    departments_list_title {
      text
    }
    departments_list_description {
      text
    }
    body {
      ... on PrismicRidesHubDataBodyBanner {
        ...PrismicRidesHubBanner
      }
    }
  }
  fragment RidesHubGeogroup on geogroupWithRides {
    data {
      id
      title
      partner {
        id
      }
    }
  }
`;

export default withPrismicPreview(RidesHubPage);
