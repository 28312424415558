import { Geoagglo, IBounds } from '@geovelo-frontends/commons';

import { GeoaggloData } from '../../graphql-types';
import environment from '../environment';

import { LngLatBounds } from '!maplibre-gl';

const { backendUrl } = environment;

function contains(a: IBounds, b: IBounds): boolean {
  const latContains = b.south >= a.south && b.north <= a.north;
  const lngContains = b.west >= a.west && b.east <= a.east;

  return latContains && lngContains;
}

function intersects(a: IBounds, b: IBounds): boolean {
  const latIntersects = b.north >= a.south && b.south <= a.north;
  const lngIntersects = b.east >= a.west && b.west <= a.east;

  return latIntersects && lngIntersects;
}

export function getCurrentGeoagglo(
  _bounds: LngLatBounds,
  zoom: number,
  cities: Geoagglo[],
  countries: Geoagglo[],
): { city: Geoagglo | null; country?: Geoagglo } {
  const [[west, south], [east, north]] = _bounds.toArray();
  const bounds: IBounds = { north, east, south, west };

  const citiesInBounds = cities.filter(({ bounds: cityBounds, center }) => {
    if (!center || !cityBounds) return false;
    const {
      coordinates: [lng, lat],
    } = center;

    return zoom >= 12 ? intersects(bounds, cityBounds) : _bounds.contains({ lat, lng });
  });

  const country = countries
    .filter(({ code }) => code !== 'france')
    .find(({ bounds: countryBounds }) => countryBounds && contains(countryBounds, bounds));

  return {
    city: citiesInBounds.length === 1 ? citiesInBounds[0] : null,
    country: country || countries.find(({ code }) => code === 'france'),
  };
}

export function parseGeoagglo({
  id,
  code,
  area,
  bounds: _bounds,
  geo_center,
  long_name,
  logo,
  logo_alt,
  photo,
  has_bss,
  importance,
}: GeoaggloData): Geoagglo {
  let bounds: IBounds | null = null;
  let center = geo_center;
  if (_bounds) {
    const [west, south, east, north] = _bounds;
    if (west && south && east && north) {
      bounds = { north, east, south, west };

      if (!center) {
        const { lat, lng } = new LngLatBounds(
          { lat: south, lng: west },
          { lat: north, lng: east },
        ).getCenter();
        center = { type: 'Point', coordinates: [lng, lat] };
      }
    }
  }

  return new Geoagglo(
    id || -1,
    code || '',
    area || null,
    bounds,
    center ? (center as GeoJSON.Point) : null,
    long_name || '',
    logo ? `${backendUrl}${logo}` : null,
    logo_alt ? `${backendUrl}${logo_alt}` : null,
    photo
      ? {
          url: `${backendUrl}${photo}`,
          squaredThumbnailUrl: `${backendUrl}${photo.replace('large', 'thumbnail')}`,
        }
      : null,
    Boolean(has_bss),
    importance || 0,
  );
}
