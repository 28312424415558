import { ThemeOptions, createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: ["'Nunito Sans'", "'Roboto'", 'sans-serif'].join(', '),
  },
  palette: {
    primary: {
      light: '#c2d5f3',
      main: '#326ac2',
      dark: '#0a429a',
    },
    secondary: {
      light: '#8eeac2',
      main: '#2ac682',
      dark: '#03825c',
      contrastText: '#fff',
    },
    error: {
      main: '#d34949',
    },
    black: {
      main: '#313131',
      contrastText: '#fff',
    },
    white: {
      main: '#fff',
      contrastText: '#326AC2',
    },
    purple: { main: '#905EFA', contrastText: '#FFF' },
    red: { main: '#DD428D', contrastText: '#fff' },
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          backgroundColor: '#cfcfcf',
        },
        bar: {
          borderRadius: '8px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'inherit',
      },
    },
  },
} as ThemeOptions);

export default theme;
