import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function BinocularsIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 20 18" {...props}>
      <path
        d="M4.60011 17.3999C6.91926 17.3999 8.80011 15.5191 8.80011 13.1999V12.2999C8.80011 11.6367 9.33684 11.0999 10.0001 11.0999C10.6634 11.0999 11.2001 11.6367 11.2001 12.2999V13.1999C11.2001 15.5191 13.081 17.3999 15.4001 17.3999C17.7193 17.3999 19.6001 15.5191 19.6001 13.1999C19.6001 12.3421 19.538 11.4867 19.4138 10.6382L18.4001 3.69019C18.2032 2.28394 17.1978 1.12495 15.8337 0.7312C14.4696 0.33628 13.0013 0.781591 12.0849 1.86556C10.8439 0.98197 9.17748 0.98197 7.9365 1.86556C7.02243 0.7675 5.54586 0.31282 4.1724 0.70891C2.79897 1.10383 1.78998 2.27335 1.60014 3.69016L0.586473 10.6382C0.462255 11.4866 0.400146 12.3421 0.400146 13.1999C0.400146 15.519 2.281 17.3999 4.60015 17.3999L4.60011 17.3999ZM4.60011 14.9999C3.87237 14.9999 3.21612 14.5617 2.93721 13.889C2.6583 13.2163 2.81299 12.4417 3.32745 11.9273C3.84189 11.4128 4.61652 11.2581 5.28918 11.537C5.96184 11.8159 6.40011 12.4722 6.40011 13.1999C6.40011 14.1937 5.59386 14.9999 4.60011 14.9999ZM15.4001 14.9999C14.6724 14.9999 14.0161 14.5617 13.7372 13.889C13.4583 13.2163 13.613 12.4417 14.1275 11.9273C14.6419 11.4128 15.4165 11.2581 16.0892 11.537C16.7618 11.8159 17.2001 12.4722 17.2001 13.1999C17.2001 14.1937 16.3939 14.9999 15.4001 14.9999ZM13.6001 4.19992C13.5509 3.53311 14.0525 2.95306 14.7193 2.90383C15.3861 2.85461 15.9661 3.35617 16.0153 4.02298L16.7806 9.23668C15.7388 8.8687 14.5939 8.93083 13.6 9.41129L13.6001 4.19992ZM10.0001 3.59992C10.3189 3.59992 10.6235 3.72648 10.8485 3.95149C11.0735 4.1765 11.2001 4.48117 11.2001 4.79992V8.90962C10.4243 8.62954 9.57588 8.62954 8.80011 8.90962V4.79992C8.80011 4.13665 9.33684 3.59992 10.0001 3.59992ZM5.20011 2.99992C5.85987 3.00812 6.39192 3.54016 6.40011 4.19992V9.41122C5.40636 8.93074 4.26144 8.86864 3.21951 9.23661L3.98475 4.03821C4.07616 3.43821 4.59294 2.9964 5.19999 2.99994L5.20011 2.99992Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
