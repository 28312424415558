import { Search, UserPlace } from '@geovelo-frontends/commons';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, {
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { AppContext } from '../../context';
import useUserPlaces from '../../hooks/map/user-places';
import UserPlaceDialog from '../user-place-dialog';

export type TUserPlacesRef = { unselect: () => void };

interface IProps {
  search?: Search;
  onSearchUpdated: () => void;
  onSelectedUserPlaceChange: (selectedUserPlace: UserPlace | null) => void;
}

function UserPlaces(
  { search, onSelectedUserPlaceChange, onSearchUpdated }: IProps,
  ref: Ref<TUserPlacesRef>,
): JSX.Element {
  const [dialogOpen, openDialog] = useState(false);
  const {
    map: { current: map },
  } = useContext(AppContext);
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { selectedPlace, unselect } = useUserPlaces(map, {
    theme,
    search,
    smallDevice,
    onSearchUpdated,
  });

  useEffect(() => {
    onSelectedUserPlaceChange(selectedPlace);
    if (selectedPlace && smallDevice) openDialog(true);
  }, [selectedPlace]);

  useImperativeHandle(ref, () => ({
    unselect,
  }));

  return (
    <UserPlaceDialog
      onClose={() => {
        openDialog(false);
        setTimeout(() => {
          unselect();
        }, theme.transitions.duration.leavingScreen);
      }}
      onSearchUpdated={onSearchUpdated}
      open={dialogOpen}
      place={selectedPlace}
      search={search}
    />
  );
}

export default forwardRef(UserPlaces);
