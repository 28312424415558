import { Autocomplete, TEventCategory, useTracker } from '@geovelo-frontends/commons';
import { Box, Typography, TypographyProps, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PrismicApiDataBodyHorizontalcardPrimary,
  PrismicApiHorizontalCardFragment,
  PrismicHomeDataBodyHorizontalcardPrimary,
  PrismicHomeHorizontalCardFragment,
  PrismicMissionDataBodyHorizontalcardPrimary,
  PrismicMissionHorizontalCardFragment,
  PrismicTerritoriesDataBodyHorizontalcardPrimary,
  PrismicTerritoriesHorizontalCardFragment,
} from '../../../graphql-types';
import Button from '../button';
import { BinocularsIcon } from '../icons';

import { maxWidth } from './consts';
import CTA from './cta';
import Description from './description';
import Title from './title';

export type THorizontalCardData =
  | PrismicHomeHorizontalCardFragment
  | PrismicTerritoriesHorizontalCardFragment
  | PrismicApiHorizontalCardFragment
  | PrismicMissionHorizontalCardFragment;

function HorizontalCard({ data }: { data: THorizontalCardData }): JSX.Element {
  const [width, setWidth] = useState<number>();
  const [diff, setDiff] = useState<number>();
  const containerRef = useRef<HTMLDivElement>();
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumDevice = useMediaQuery(theme.breakpoints.up('md'));
  const largeScreen = useMediaQuery(`(min-width: ${maxWidth + 128}px)`);

  const { primary } = data;
  if (!primary) return <></>;

  const {
    background_color,
    background_image_position,
    section_id,
    linked_to_next_slice,
    image_on_the_right,
    image_type,
    image: _image,
  } = primary as PrismicHomeDataBodyHorizontalcardPrimary &
    PrismicTerritoriesDataBodyHorizontalcardPrimary &
    PrismicApiDataBodyHorizontalcardPrimary &
    PrismicMissionDataBodyHorizontalcardPrimary;

  const image = _image && getImage(_image);

  const backgroundImage =
    primary &&
    'background_image' in primary &&
    primary.background_image &&
    getImage(primary.background_image);

  useEffect(() => {
    function handleResize() {
      const width = document.body.clientWidth;

      if (backgroundImage) {
        const { width: imageWidth, height: imageHeight } = backgroundImage || {
          width: 0,
          height: 0,
        };
        const height = (width * imageHeight) / imageWidth;

        setDiff(Math.max(height - (containerRef.current?.clientHeight || 0), 256));
      }

      setWidth(width);
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (image_type === 'Behind') {
    return (
      <Box
        alignItems="center"
        alignSelf="center"
        display="flex"
        flexDirection="column"
        id={section_id?.text || undefined}
        maxWidth="100%"
        paddingBottom={linked_to_next_slice ? 5 : { xs: 3, md: 10 }}
        paddingTop={{ xs: 4, md: 8 }}
        position="relative"
        sx={{ scrollMargin: 72 }}
        width={`calc(${maxWidth}px + 128px)`}
      >
        {image && (
          <Box
            alignItems="center"
            bottom={{ xs: 448, sm: 320, md: linked_to_next_slice ? 40 : 80 }}
            display="flex"
            left={{ xs: '0', md: image_on_the_right ? 'calc(50% - 64px)' : 0 }}
            position="absolute"
            right={{ xs: '0', md: image_on_the_right ? 0 : 'calc(50% - 64px)' }}
            top={{ xs: 32, md: 64 }}
            zIndex={1}
          >
            <Box
              borderRadius={{
                xs: 0,
                md: largeScreen ? 6 : image_on_the_right ? '24px 0 0 24px' : '0 24px 24px 0',
              }}
              flexGrow={1}
              height="100%"
              overflow="hidden"
            >
              <GatsbyImage
                alt=""
                image={image}
                objectFit="cover"
                objectPosition={image_on_the_right ? 'right top' : 'left bottom'}
                style={{ height: '100%', width: '100%' }}
              />
            </Box>
          </Box>
        )}
        <Box
          alignItems="center"
          display="flex"
          flexDirection={{ xs: 'column-reverse', sm: image_on_the_right ? 'row' : 'row-reverse' }}
          gap={{ xs: 4, md: 8, lg: 16 }}
          justifyContent="flex-start"
          maxWidth={{ xs: 'calc(100% - 64px)', md: 'calc(100% - 128px)', lg: 'calc(100% - 256px)' }}
          minHeight={!smallDevice ? 500 : 0}
          paddingTop={{ xs: 16, md: 0 }}
          width={maxWidth}
          zIndex={2}
        >
          <Box
            borderRadius={5}
            marginBottom={{ xs: 0, md: 4 }}
            marginTop={{ xs: 16, md: 4 }}
            marginX={{ xs: 2, sm: 4, md: 0 }}
            paddingLeft={{ xs: 4, md: image_on_the_right ? 0 : 8 }}
            paddingRight={{ xs: 4, md: image_on_the_right ? 8 : 0 }}
            paddingY={{ xs: 4, md: 8 }}
            sx={{ backgroundColor: '#fff' }}
            width={{ xs: 'calc(100% - 32px)', sm: 'calc(100% - 64px)', md: '50%' }}
            zIndex={2}
          >
            <Content
              data={data}
              descriptionProps={{
                textAlign: useMediaQuery(theme.breakpoints.down('md')) ? 'justify' : 'start',
              }}
              titleProps={{
                textAlign: useMediaQuery(theme.breakpoints.down('md')) ? 'center' : 'start',
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  if (image_type === 'Background') {
    return (
      <Box
        alignItems="center"
        alignSelf="stretch"
        display="flex"
        flexDirection="column"
        id={section_id?.text || undefined}
        paddingBottom={linked_to_next_slice ? 0 : 10}
        paddingTop={10}
        position="relative"
        sx={{ scrollMargin: 72 }}
      >
        {image && (
          <Box
            bottom={linked_to_next_slice ? 0 : 80}
            left={0}
            position="absolute"
            top={80}
            width="100%"
            zIndex={0}
          >
            <GatsbyImage
              alt=""
              image={image}
              objectFit="cover"
              style={{ height: '100%', width: '100%' }}
            />
          </Box>
        )}
        <Box
          alignItems="center"
          display="flex"
          justifyContent={image_on_the_right ? 'flex-start' : 'flex-end'}
          maxWidth="100%"
          position="relative"
          width={maxWidth}
        >
          <Box
            borderRadius={5}
            marginBottom={20}
            marginTop={10}
            marginX={{ xs: 4, lg: 0 }}
            paddingLeft={{ xs: 4, md: 8 }}
            paddingRight={{ xs: 4, md: 8 }}
            paddingY={{ xs: 4, md: 8 }}
            sx={{ backgroundColor: '#fff' }}
            width={{ xs: 'calc(100% - 64px)', md: 'calc(50% - 128px)' }}
            zIndex={2}
          >
            <Content
              data={data}
              descriptionProps={{ textAlign: 'center' }}
              titleProps={{ textAlign: 'center' }}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      alignItems="center"
      alignSelf="stretch"
      display="flex"
      flexDirection="column"
      id={section_id?.text || undefined}
      paddingBottom={{ xs: 3, md: 5 }}
      paddingTop={{ xs: 3, md: 5 }}
      position="relative"
      ref={containerRef}
      sx={{ backgroundColor: background_color, scrollMargin: 72 }}
    >
      {backgroundImage && diff !== undefined && (
        <Box
          height={
            background_image_position === 'Center' ? `calc(100% + ${diff}px)` : 'calc(100% + 2px)'
          }
          left={0}
          position="absolute"
          top={background_image_position === 'Center' ? -(diff / 2) : -1}
          width="100%"
          zIndex={0}
        >
          <GatsbyImage
            alt=""
            image={backgroundImage}
            objectFit="cover"
            objectPosition={
              background_image_position === 'Top'
                ? 'center top'
                : background_image_position === 'Bottom'
                  ? 'center bottom'
                  : 'unset'
            }
            style={{ height: '100%', width: '100%' }}
          />
        </Box>
      )}
      {mediumDevice && image_type === 'Edge' && !largeScreen && image && (
        <Box
          alignItems="center"
          bottom={{ xs: 24, md: linked_to_next_slice ? 40 : 80 }}
          display="flex"
          left={image_on_the_right ? undefined : 0}
          position="absolute"
          right={image_on_the_right ? 0 : undefined}
          top={{ xs: 32, md: 64 }}
          width="calc(50% - 64px)"
          zIndex={1}
        >
          <Box flexGrow={1}>
            <GatsbyImage
              alt=""
              image={image}
              objectFit="cover"
              objectPosition={image_on_the_right ? 'left center' : 'right center'}
              style={{ height: '100%', width: '100%' }}
            />
          </Box>
        </Box>
      )}
      <Box
        alignItems="center"
        display="flex"
        flexDirection={{ xs: 'column-reverse', md: image_on_the_right ? 'row' : 'row-reverse' }}
        gap={{ xs: 4, md: 8, lg: 16 }}
        justifyContent={image_on_the_right ? 'flex-start' : 'flex-end'}
        maxWidth={{ xs: 'calc(100% - 64px)', md: 'calc(100% - 128px)', lg: 'calc(100% - 256px)' }}
        minHeight={
          (mediumDevice &&
            image_type === 'Edge' &&
            !largeScreen &&
            image &&
            width &&
            `calc(${width / 2 - 64}px * ${image.height} / ${image.width})`) ||
          undefined
        }
        width={maxWidth}
        zIndex={2}
      >
        <Box
          marginLeft={
            mediumDevice && image && image_type === 'Edge' && !largeScreen && !image_on_the_right
              ? {
                  sm: 'calc(50% + 32px)',
                  lg: 'calc(50% + 64px)',
                }
              : {}
          }
          width={{ xs: '100%', md: 'calc(50% - 32px)', lg: 'calc(50% - 64px)' }}
        >
          <Content data={data} />
        </Box>
        {(smallDevice ||
          image_type === 'Default' ||
          (image_type === 'Edge' && (!mediumDevice || largeScreen))) &&
          image && (
            <Box
              borderRadius={{
                xs: background_image_position === 'Center' ? (largeScreen ? 6 : 4) : 0,
              }}
              marginLeft={{
                xs: image_type === 'Edge' && largeScreen && !image_on_the_right ? -4 : 0,
                sm: 0,
              }}
              marginRight={{
                xs: image_type === 'Edge' && largeScreen && image_on_the_right ? -4 : 0,
                sm: 0,
              }}
              overflow="hidden"
              width={{
                xs: image_type === 'Edge' && largeScreen ? 'calc(100% + 32px)' : '100%',
                md: 'calc(50% - 32px)',
                lg: 'calc(50% - 64px)',
              }}
            >
              <GatsbyImage
                alt=""
                image={image}
                objectFit={background_image_position === 'Center' ? 'cover' : 'contain'}
                style={{ height: '100%', maxHeight: smallDevice ? 300 : 500, width: '100%' }}
              />
            </Box>
          )}
      </Box>
    </Box>
  );
}

function Content({
  data: {
    primary: { title, description, ...primaryProps },
    items,
  },
  titleProps,
  descriptionProps,
}: {
  data: THorizontalCardData;
  descriptionProps?: TypographyProps;
  titleProps?: Omit<TypographyProps, 'component'>;
}): JSX.Element {
  const { trackEvent } = useTracker();
  const theme = useTheme();
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  const linkItems = items.filter((data) => 'item_type' in data && data.item_type !== 'Data');
  const dataItems = items.filter((data) => 'item_type' in data && data.item_type === 'Data');

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {'header_type' in primaryProps &&
        primaryProps.header_type &&
        primaryProps.header_type !== 'Default' && (
          <Box
            alignItems="center"
            alignSelf={
              primaryProps.header_type === 'Highlight' &&
              useMediaQuery(theme.breakpoints.down('md'))
                ? 'center'
                : 'start'
            }
            display="flex"
            gap={2}
            marginLeft={{ xs: 0, md: primaryProps.header_type === 'Detailed' ? 2 : 0 }}
          >
            <BinocularsIcon color="secondary" />
            <Typography color="secondary" variant="subtitle1">
              {primaryProps.header_type === 'Highlight' && t(`geovelo.focus_on`)}
              {primaryProps.header_type === 'Detailed' && t(`geovelo.application_example`)}
            </Typography>
          </Box>
        )}
      <Title
        color={('title_color' in primaryProps && primaryProps.title_color) || undefined}
        component="h2"
        data={title}
        {...titleProps}
      />
      <Description data={description} {...descriptionProps} />
      {linkItems.length > 0 && (
        <Box alignItems="center" display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
          {linkItems.map(({ cta_type, cta_variant, cta_link, cta_title, ...itemProps }, index) => {
            if (cta_type === 'Routing') {
              return (
                <Box
                  alignItems="center"
                  display="flex"
                  flexGrow={1}
                  key={index}
                  sx={({ palette }) => ({
                    border: `1px solid ${palette.primary.main}`,
                    borderRadius: 2,
                    overflow: 'hidden',
                  })}
                >
                  <Autocomplete
                    disableFloatingLabel
                    defaultValue={null}
                    inputProps={{ sx: { '> div > fieldset': { border: '0 !important' } } }}
                    label={t('geovelo.routing.actions.enter_destination') || ''}
                    margin="none"
                    onSelect={(place) => {
                      if (place) {
                        trackEvent(
                          'Specify itinerary and bike types',
                          'Clicked',
                          'Secondary CTA itinerary home page',
                        );
                        navigate(
                          `/${currentLanguage}/route?to=${place.point.coordinates.join(
                            ',',
                          )}&c=${place.point.coordinates.join(',')}&z=15`,
                        );
                      }
                    }}
                    size="small"
                  />
                  <Button
                    disableElevation
                    color="primary"
                    key={index}
                    size="large"
                    sx={{ '&&': { borderRadius: 0, flexShrink: 0 } }}
                    variant="contained"
                  >
                    {cta_title?.text}
                  </Button>
                </Box>
              );
            }

            return (
              <CTA
                key={index}
                link={cta_link}
                onClick={() => {
                  if (
                    'cta_anchor' in itemProps &&
                    typeof itemProps.cta_anchor === 'object' &&
                    itemProps.cta_anchor &&
                    'text' in itemProps.cta_anchor &&
                    typeof itemProps.cta_anchor.text === 'string' &&
                    itemProps.cta_anchor?.text
                  )
                    document
                      .getElementById(itemProps.cta_anchor.text)
                      ?.scrollIntoView({ behavior: 'smooth' });
                  if (
                    'cta_tracking_category' in itemProps &&
                    'cta_tracking_name' in itemProps &&
                    typeof itemProps.cta_tracking_name === 'object' &&
                    itemProps.cta_tracking_name &&
                    'text' in itemProps.cta_tracking_name &&
                    typeof itemProps.cta_tracking_name.text === 'string' &&
                    itemProps.cta_tracking_category
                  )
                    trackEvent(
                      itemProps.cta_tracking_category as TEventCategory,
                      'Clicked',
                      itemProps.cta_tracking_name.text,
                    );
                }}
                title={cta_title}
                variant={cta_variant}
              />
            );
          })}
        </Box>
      )}
      {dataItems.length > 0 && (
        <Box
          alignItems={{ xs: 'center', sm: 'start' }}
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          flexWrap="nowrap"
          gap={3}
        >
          {dataItems.map((data, index) => {
            const icon =
              'data_icon' in data && data.data_icon ? getImage(data.data_icon) : undefined;

            return (
              <Box
                alignItems={{ xs: 'center', sm: 'flex-start' }}
                display="flex"
                flex="1 1 0px"
                flexDirection="column"
                gap={3}
                key={index}
              >
                {icon && (
                  <Box height={48} width={48}>
                    <GatsbyImage
                      alt=""
                      image={icon}
                      objectFit="cover"
                      style={{ height: '100%', width: '100%' }}
                    />
                  </Box>
                )}
                {'data_text' in data && typeof data.data_text === 'object' && (
                  <Description
                    data={data.data_text}
                    fontSize="1em"
                    textAlign={{ xs: 'center', sm: 'start' }}
                  />
                )}
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
}

export default HorizontalCard;
