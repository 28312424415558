import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function ChevronRightIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 25" {...props}>
      <path
        d="M8 19.5L15 12.5L8 5.5"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
}
