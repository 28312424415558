import { ButtonProps, Button as MuiButton } from '@mui/material';
import { lighten, useTheme } from '@mui/material/styles';
import { GatsbyLinkProps, Link } from 'gatsby';
import React from 'react';

export type TLinkProps<T> = { component: 'a' | typeof Link } & ButtonProps & GatsbyLinkProps<T>;

function Button<T = Record<string, never>>(props: TLinkProps<T> | ButtonProps): JSX.Element {
  const theme = useTheme();

  return (
    <>
      {props?.variant === 'contained' && props?.disabled === true ? (
        <MuiButton
          {...props}
          disableElevation
          sx={{
            backgroundColor: lighten(theme.palette.primary.main, 0.7),
            borderRadius: 2,
            color: theme.palette.primary.contrastText,
            fontSize: props.size === 'large' ? '1.125rem' : '1rem',
            fontWeight: 600,
            textTransform: 'initial',
            boxShadow: 'none',
            ...props.sx,
          }}
        />
      ) : (
        <MuiButton
          {...props}
          disableElevation
          sx={{
            borderRadius: 2,
            fontSize: props.size === 'large' ? '1.125rem' : '1rem',
            fontWeight: 600,
            textTransform: 'initial',
            boxShadow: 'none',
            ...props.sx,
          }}
        />
      )}
    </>
  );
}

export default Button;
