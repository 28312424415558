import {
  TRideDifficulty,
  TRideThemeKey,
  difficultiesMap,
  rideThemesMap,
  useUnits,
} from '@geovelo-frontends/commons';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Slider,
  Typography,
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/button';
import Dialog from '../../components/dialog';

export interface IFilters {
  themeKeys: { [key in TRideThemeKey]?: boolean };
  maxDistance: number;
  difficultyKeys: { [key in TRideDifficulty]?: boolean };
}

const rideThemeKeys: TRideThemeKey[] = [
  'nature',
  'cultural',
  'sportive',
  'mountain',
  'urban',
  'coastal',
  'family',
  'gourmet',
];

export const rideDifficulties: TRideDifficulty[] = ['easy', 'medium', 'hard'];

export const minFilterDistance = 1_000;
export const maxFilterDistance = 100_000;
export const filterDistanceStep = 1_000;

type TProps = Omit<DialogProps, 'onClose'> & {
  currentFilters?: IFilters;
  onClose: (newFilters?: IFilters) => void;
};

function FilterDialog({ currentFilters, onClose, ...props }: TProps): JSX.Element {
  const [newFilters, setNewFilters] = useState(currentFilters);
  const { t } = useTranslation();
  const { toDistance } = useUnits();

  useEffect(() => {
    if (props.open && currentFilters) {
      setNewFilters({ ...currentFilters });
    }
  }, [props.open]);

  function handleThemeToggle(key: TRideThemeKey) {
    if (!newFilters) return;

    const oldThemeKeys = newFilters.themeKeys;
    const newThemeKeys = { ...oldThemeKeys, [key]: !oldThemeKeys[key] };
    if (Object.values(newThemeKeys).find((enabled) => enabled)) {
      setNewFilters({
        ...newFilters,
        themeKeys: newThemeKeys,
      });
    }
  }

  function handleDifficultyToggle(key: TRideDifficulty) {
    if (!newFilters) return;

    const oldDifficultyKeys = newFilters.difficultyKeys;
    const newDifficultyKeys = { ...oldDifficultyKeys, [key]: !oldDifficultyKeys[key] };
    if (Object.values(newDifficultyKeys).find((enabled) => enabled)) {
      setNewFilters({
        ...newFilters,
        difficultyKeys: newDifficultyKeys,
      });
    }
  }

  return (
    <Dialog fullWidth maxWidth="sm" onClose={() => onClose()} {...props}>
      <DialogTitle>{t('geovelo.rides.filters_dialog.title')}</DialogTitle>
      <DialogContent dividers sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box>
          <Typography gutterBottom variant="subtitle1">
            {t('geovelo.rides.filters_dialog.categories')}
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {rideThemeKeys.map((key) => {
              const theme = rideThemesMap[key];
              if (!theme) return <Fragment key={key} />;

              const { labelKey } = theme;
              const selected = newFilters?.themeKeys[key];

              return (
                <Button
                  disableElevation
                  color={selected ? 'primary' : 'inherit'}
                  key={key}
                  onClick={() => handleThemeToggle(key)}
                  size="small"
                  variant={selected ? 'contained' : 'outlined'}
                >
                  {t(labelKey)}
                </Button>
              );
            })}
          </Box>
        </Box>
        <Box>
          <Typography gutterBottom variant="subtitle1">
            {t('geovelo.rides.filters_dialog.max_distance')}
          </Typography>
          <Box margin="36px 16px 0 8px">
            <Slider
              aria-labelledby="discrete-slider-always"
              marks={[
                { value: 10000, label: toDistance(10000) },
                { value: 25000, label: toDistance(25000) },
                { value: 50000, label: toDistance(50000) },
                { value: 100000, label: toDistance(100000) },
              ]}
              max={maxFilterDistance}
              min={minFilterDistance}
              onChange={(event, maxDistance) => {
                if (newFilters && typeof maxDistance === 'number')
                  setNewFilters({ ...newFilters, maxDistance });
              }}
              step={filterDistanceStep}
              value={newFilters?.maxDistance || maxFilterDistance}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => Math.round(value / 1000)}
            />
          </Box>
        </Box>
        <Box>
          <Typography gutterBottom variant="subtitle1">
            {t('geovelo.rides.filters_dialog.difficulties')}
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={1}>
            {rideDifficulties.map((key) => {
              const { labelKey } = difficultiesMap[key];
              const selected = newFilters?.difficultyKeys[key];

              return (
                <Button
                  disableElevation
                  color={selected ? 'primary' : 'inherit'}
                  key={key}
                  onClick={() => handleDifficultyToggle(key)}
                  size="small"
                  variant={selected ? 'contained' : 'outlined'}
                >
                  {t(labelKey)}
                </Button>
              );
            })}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} size="small" variant="outlined">
          {t('commons.actions.cancel')}
        </Button>
        <Button
          color="primary"
          onClick={() => onClose(newFilters)}
          size="small"
          variant="contained"
        >
          {t('commons.actions.filter')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FilterDialog;
