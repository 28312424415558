import { Box, Typography } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import {
  PrismicApiDataBodyKeynumberscardPrimary,
  PrismicApiKeynumberscardFragment,
} from '../../../graphql-types';

import { maxWidth } from './consts';
import Title from './title';

export type TKeyNumbersCardData = PrismicApiKeynumberscardFragment;

function KeyNumbersCard({ data: { primary, items } }: { data: TKeyNumbersCardData }): JSX.Element {
  if (!primary) return <></>;

  const {
    background_color,
    baseline,
    icon: _icon,
    items_border_color,
    items_color,
  } = primary as PrismicApiDataBodyKeynumberscardPrimary;
  const icon = _icon && getImage(_icon);

  return (
    <Box
      maxWidth={{ xs: 'calc(100% - 64px)', md: 'calc(100% - 128px)', lg: 'calc(100% - 256px)' }}
      paddingBottom={8}
      width={maxWidth}
    >
      <Box
        alignItems={{ xs: 'stretch', md: 'center' }}
        borderRadius={5}
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={{ xs: 3, md: 8 }}
        paddingLeft={{ xs: 2, md: 8, lg: 18 }}
        paddingRight={{ xs: 2, lg: 3 }}
        paddingY={8}
        position="relative"
        sx={{ backgroundColor: background_color || '#03825C' }}
      >
        {icon && (
          <Box
            display={{ xs: 'none', lg: 'block' }}
            flexShrink={0}
            left={0}
            position="absolute"
            top={0}
          >
            <GatsbyImage alt="" image={icon} />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="row"
          gap={4}
          justifyContent="center"
          maxWidth={{ xs: 'inherit', md: 180 }}
          width="100%"
        >
          {baseline?.text && <Title color="#ffffff" data={baseline} />}
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexGrow={1}
          flexWrap="wrap"
          gap={4}
          justifyContent="space-evenly"
        >
          {items.map(({ key_number, number_label }, index) => {
            return (
              <Box
                key={index}
                sx={{
                  border: items_border_color ? `2px solid ${items_border_color}` : undefined,
                  borderRadius: 5,
                  padding: '32px',
                  backgroundColor: items_color + '80' || '#01735180',
                }}
              >
                <Title color="#ffffff" data={key_number} />
                <Typography color="#ffffff">{number_label?.text}</Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default KeyNumbersCard;
