import {
  DistanceIcon,
  DurationIcon,
  difficultiesMap,
  useTracker,
  useUnits,
} from '@geovelo-frontends/commons';
import { Box, Chip, Typography, useMediaQuery } from '@mui/material';
import { lighten } from '@mui/material/styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  PrismicRidesAndTripsDataBodyHighlightedridePrimary,
  PrismicRidesAndTripsHighlightedRideFragment,
  RidesAndTripsRideDataFragment,
} from '../../../graphql-types';
import theme from '../../theme';
import { parseRide } from '../../utils/ride';
import { BinocularsIcon } from '../icons';

import { maxWidth } from './consts';
import CTA from './cta';
import Description from './description';
import Title from './title';

export type THighlightedRideData = PrismicRidesAndTripsHighlightedRideFragment;

function HighlightedRide({
  ridesData,
  data: { primary },
}: {
  data: THighlightedRideData;
  ridesData?: RidesAndTripsRideDataFragment[];
}): JSX.Element {
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const { toDistance, toTime } = useUnits();
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const { trackEvent } = useTracker();

  if (!primary) return <></>;

  const {
    image: _image,
    tag_label,
    ride_id,
    cta_title,
    partnership_text,
    partner_image,
  } = primary as PrismicRidesAndTripsDataBodyHighlightedridePrimary;

  const rideData = ride_id && ridesData?.find(({ id }) => id === ride_id);
  const ride = rideData && parseRide(rideData, 'https://backend.geovelo.fr');
  if (!ride) return <></>;

  const {
    id,
    title,
    description,
    distance,
    duration,
    overloadDuration,
    difficulty: _difficulty,
  } = ride;
  const difficulty = _difficulty && difficultiesMap[_difficulty];

  const image = _image && getImage(_image);
  const partnerImage = partner_image && getImage(partner_image);

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="flex-end"
      maxWidth={{
        xs: '100%',
        sm: 'calc(100% - 64px)',
        md: 'calc(100% - 128px)',
        lg: 'calc(100% - 256px)',
      }}
      paddingBottom={8}
      paddingTop={{ xs: '250px', sm: 10 }}
      position="relative"
      width={maxWidth}
    >
      {image && (
        <Box
          alignItems="center"
          bottom={{ xs: 136, md: 80 }}
          display="flex"
          height={{ xs: '375px', sm: 'auto' }}
          left={0}
          position="absolute"
          right={{ xs: '0', md: 'calc(50% - 128px)' }}
          top={24}
          zIndex={1}
        >
          <Box borderRadius={{ xs: 0, sm: 6 }} flexGrow={1} height="100%" overflow="hidden">
            <GatsbyImage
              alt=""
              image={image}
              objectFit="cover"
              objectPosition="left center"
              style={{ height: '100%', width: '100%' }}
            />
          </Box>
        </Box>
      )}
      <Box
        borderRadius={5}
        marginBottom={{ xs: 0, md: 8 }}
        marginX={{ xs: 2, sm: 4, md: 0 }}
        paddingLeft={{ xs: 4, md: 8 }}
        paddingRight={{ xs: 4, md: 8 }}
        paddingY={{ xs: 4, md: 8 }}
        sx={{ backgroundColor: '#fff' }}
        width={{ xs: 'calc(100% - 32px)', sm: 'calc(100% - 64px)', md: '50%' }}
        zIndex={2}
      >
        <Box display="flex" flexDirection="column" gap={3}>
          <Box alignItems="center" display="flex" gap={2} paddingLeft={{ xs: 0, md: 2 }}>
            <BinocularsIcon color="secondary" />
            <Typography color="secondary" variant="subtitle1">
              {tag_label?.text || t('geovelo.rides_and_trips.highlighted_ride.label')}
            </Typography>
          </Box>
          <Title component="h2" data={{ text: title }} />
          <Box columnGap={2} display="flex" flexDirection="row" flexWrap="wrap" rowGap={1}>
            <Box alignItems="center" display="flex" flexDirection="row" gap="4px">
              <DurationIcon color="primary" fontSize="small" />
              <Typography variant="caption">{toTime(overloadDuration || duration || 0)}</Typography>
            </Box>
            <Box alignItems="center" display="flex" flexDirection="row" gap="4px">
              <DistanceIcon color="primary" fontSize="small" />
              <Typography variant="caption">{toDistance(distance || 0)}</Typography>
            </Box>
            {difficulty && (
              <Chip
                label={t(difficulty.labelKey)}
                size="small"
                sx={({ palette }) => ({
                  backgroundColor: lighten(palette.secondary.main, 0.8),
                  border: `1px solid ${palette.secondary.main}`,
                  color: palette.primary.main,
                })}
              />
            )}
          </Box>
          <Description data={{ text: description }} />
          <Box alignItems="center" display="flex" flexWrap="wrap" gap={2}>
            <CTA
              link={{ link_type: 'Document', url: `/${currentLanguage}/rides/${id}` }}
              onClick={() =>
                trackEvent('Calculates a ride', 'Clicked', 'Click on discover hub promoted ride')
              }
              title={cta_title}
            />
          </Box>
          {partnership_text?.text && (
            <Box
              alignSelf="flex-start"
              maxWidth={{ xs: 'calc(100% - 32px)', sm: 'calc(100% - 64px)' }}
              paddingBottom={3}
              paddingTop={4}
              position="relative"
            >
              <Box
                borderRadius={2}
                paddingLeft={2}
                paddingRight={10}
                paddingY={3}
                sx={{ backgroundColor: 'rgba(133, 162, 208, 0.1)' }}
              >
                <Typography color="primary" variant="caption">
                  {partnership_text.text}
                </Typography>
              </Box>
              {partnerImage && (
                <Box bottom={{ xs: 20, sm: 0 }} position="absolute" right={-64}>
                  <GatsbyImage
                    alt=""
                    image={partnerImage}
                    style={
                      smallDevice
                        ? { height: '75%', width: '75%' }
                        : { height: '100%', width: '100%' }
                    }
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default HighlightedRide;
