import { Box, IconButton } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useRef } from 'react';
import Slider from 'react-slick';

import {
  PrismicHomeDataBodyLogosPrimary,
  PrismicHomeLogosFragment,
  PrismicTerritoriesDataBodyLogosPrimary,
  PrismicTerritoriesLogosFragment,
} from '../../../graphql-types';
import { NextIcon, PrevIcon } from '../icons';

import { maxWidth } from './consts';
import Title from './title';

export type TLogosListData = PrismicHomeLogosFragment | PrismicTerritoriesLogosFragment;

function LogosList({ data: { primary, items } }: { data: TLogosListData }): JSX.Element {
  const sliderRef = useRef<Slider>(null);

  if (!primary) return <></>;

  const { linked_to_prev_slice, variant, align, background_color, background_color_end, title } =
    primary as PrismicHomeDataBodyLogosPrimary & PrismicTerritoriesDataBodyLogosPrimary;

  return (
    <Box
      alignItems="center"
      alignSelf="stretch"
      display="flex"
      flexDirection="column"
      paddingBottom={5}
      paddingTop={linked_to_prev_slice ? 0 : 5}
      sx={
        background_color_end
          ? {
              background: linked_to_prev_slice
                ? `linear-gradient(270deg, ${background_color} 0%, ${background_color_end} 100%)`
                : undefined,
            }
          : { backgroundColor: linked_to_prev_slice ? background_color : undefined }
      }
      zIndex={1}
    >
      <Box
        alignItems={{ xs: 'stretch', md: 'center' }}
        borderRadius={linked_to_prev_slice ? '' : '20px'}
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        gap={{ xs: 5, md: 8 }}
        maxWidth="100%"
        paddingBottom={5}
        paddingTop={5}
        paddingX={background_color && !linked_to_prev_slice ? { xs: 2, md: 4, lg: 8 } : {}}
        sx={linked_to_prev_slice ? {} : { backgroundColor: background_color }}
        width={maxWidth}
      >
        {title?.text && (
          <Box flexShrink={0} maxWidth={{ xs: '100%', md: 400 }}>
            <Title
              component="div"
              data={title}
              fontSize="1.25em"
              sx={'dark_background' in primary && primary.dark_background ? { color: '#fff' } : {}}
            />
          </Box>
        )}
        {variant === 'Slider' ? (
          <Box alignItems="center" display="flex" flexGrow={1} gap={2} sx={{ overflowX: 'hidden' }}>
            <IconButton
              onClick={() => sliderRef.current?.slickPrev()}
              size="small"
              sx={{ flexShrink: 0 }}
            >
              <PrevIcon fontFamily="small" />
            </IconButton>
            <Box flexGrow={1} sx={{ overflowX: 'hidden' }}>
              <Slider
                infinite
                arrows={false}
                dots={false}
                ref={sliderRef}
                responsive={[
                  {
                    breakpoint: 1536,
                    settings: {
                      slidesToShow: 4,
                    },
                  },
                  {
                    breakpoint: 1200,
                    settings: {
                      slidesToShow: 3,
                    },
                  },
                  {
                    breakpoint: 900,
                    settings: {
                      slidesToShow: 2,
                    },
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 1,
                    },
                  },
                ]}
                slidesToScroll={1}
                slidesToShow={5}
                speed={500}
              >
                {items.map(({ item_logo, item_title }, index) => {
                  const logo = item_logo && getImage(item_logo);
                  if (!logo) return;

                  return (
                    <Box key={index}>
                      <Box height={70} paddingX={2}>
                        <GatsbyImage
                          alt={item_title?.text || ''}
                          image={logo}
                          objectFit="contain"
                          style={{ height: '100%', width: '100%' }}
                        />
                      </Box>
                    </Box>
                  );
                })}
              </Slider>
            </Box>
            <IconButton
              onClick={() => sliderRef.current?.slickNext()}
              size="small"
              sx={{ flexShrink: 0 }}
            >
              <NextIcon fontFamily="small" />
            </IconButton>
          </Box>
        ) : (
          <Box
            alignItems="center"
            display="flex"
            flexGrow={1}
            flexWrap="wrap"
            gap={4}
            justifyContent={{
              xs: 'space-evenly',
              md: align === 'Left' ? 'flex-start' : 'space-evenly',
            }}
          >
            {items.map(({ item_logo, item_title }, index) => {
              const logo = item_logo && getImage(item_logo);
              if (!logo) return;

              return (
                <Box
                  key={index}
                  sx={{
                    border: variant === 'Outlined' ? `1px solid #c0c7cc` : 0,
                    borderRadius: variant === 'Outlined' ? 2 : 0,
                    maxWidth: 150,
                    minWidth: { xs: 120, md: 'auto' },
                    overflow: 'hidden',
                    width: `calc((100% - (32px * ${items.length - 1})) / ${items.length})`,
                  }}
                >
                  <GatsbyImage
                    alt={item_title?.text || ''}
                    image={logo}
                    objectFit="contain"
                    style={{ height: 70, width: '100%' }}
                  />
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default LogosList;
