import { Ride } from '@geovelo-frontends/commons';
import { Box, ButtonBase, useMediaQuery } from '@mui/material';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PrismicRidesAndTripsDataBodyRideslistPrimary,
  PrismicRidesAndTripsRidesListFragment,
  RidesAndTripsRideDataFragment,
} from '../../../graphql-types';
import { AppContext } from '../../context';
import theme from '../../theme';
import { parseRide } from '../../utils/ride';
import RideCard from '../ride-card';

import { maxWidth } from './consts';
import CTA from './cta';
import Description from './description';
import Title from './title';

export type TRidesListData = PrismicRidesAndTripsRidesListFragment;

function RidesList({
  ridesData,
  data: { primary, items },
}: {
  data: TRidesListData;
  ridesData?: RidesAndTripsRideDataFragment[];
}): JSX.Element {
  const smallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    user: { geolocation },
  } = useContext(AppContext);
  const [rides, setRides] = useState(() => {
    return items.reduce<Ride[]>((res, { ride_id }) => {
      const rideData = ridesData?.find(({ id }) => ride_id === id);
      if (rideData) {
        if (smallDevice) {
          if (res.length < 4) res.push(parseRide(rideData, 'https://backend.geovelo.fr'));
        } else res.push(parseRide(rideData, 'https://backend.geovelo.fr'));
      }

      return res;
    }, []);
  });
  const {
    i18n: { language: currentLanguage },
  } = useTranslation();

  useEffect(() => {
    if (!ridesData) return;

    if (geolocation) {
      const distances: Array<{ index: number; distanceFromLocation: number }> = [];

      ridesData.forEach(({ id: rideId, geometry_condensed }, index) => {
        if (rideId && geometry_condensed) {
          const {
            properties: { dist: distanceFromLocation },
          } = nearestPointOnLine(geometry_condensed as GeoJSON.MultiLineString, geolocation.point, {
            units: 'meters',
          });

          if (distanceFromLocation || distanceFromLocation === 0)
            distances.push({ index, distanceFromLocation });
        }
      });

      setRides(
        distances
          .sort((a, b) => a.distanceFromLocation - b.distanceFromLocation)
          .slice(0, 6)
          .map(({ index }) => parseRide(ridesData[index], 'https://backend.geovelo.fr')),
      );
    }
  }, [geolocation]);

  if (!primary) return <></>;

  const { section_id, cta_baseline, cta_link, title, description, linked_to_next_slice } =
    primary as PrismicRidesAndTripsDataBodyRideslistPrimary;

  return (
    <Box
      alignItems="center"
      alignSelf="stretch"
      display="flex"
      flexDirection="column"
      id={section_id?.text || undefined}
      position="relative"
      sx={{ scrollMargin: 72 }}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gap={5}
        maxWidth="100%"
        paddingBottom={linked_to_next_slice ? 5 : 10}
        paddingTop={10}
        paddingX={{ xs: 4, md: 8, lg: 16 }}
        width={maxWidth}
        zIndex={1}
      >
        {(title?.text || description?.text) && (
          <Box alignSelf="stretch" display="flex" flexDirection="column" gap={2}>
            <Title component="h2" data={title} fontSize="2em" />
            <Description data={description} />
          </Box>
        )}
        <Box display="flex" flexWrap="wrap" gap={2} justifyContent="center">
          {rides &&
            rides.map((ride) => (
              <Box
                key={ride.id}
                sx={{
                  width: {
                    xs: '100%',
                    sm: 'calc((100% - 20px) / 2)',
                    lg: 'calc((100% - 38px) / 3)',
                  },
                }}
              >
                <ButtonBase
                  component={Link}
                  sx={{ height: '100%', width: '100%' }}
                  to={`/${currentLanguage}/rides/${ride.id}`}
                >
                  <RideCard enableDescription fullHeight outlined ride={ride} />
                </ButtonBase>
              </Box>
            ))}
        </Box>
        <CTA link={cta_link} title={cta_baseline} />
      </Box>
    </Box>
  );
}

export default RidesList;
