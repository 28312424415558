import { BikeRoute, Ride } from '@geovelo-frontends/commons';
import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';
import booleanIntersects from '@turf/boolean-intersects';
import { Link, navigate } from 'gatsby';
import debounce from 'lodash/debounce';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TripsPageQuery } from '../../../graphql-types';
import Button from '../../components/button';
import { EditIcon } from '../../components/icons';
import MapMarkers from '../../components/map-markers';
import Seo from '../../components/seo';
import { AppContext } from '../../context';
import useBikeRoutes from '../../hooks/map/bike-routes';
import LeftPanelLayout from '../../layouts/app-content/left-panel';
import { TPageProps } from '../../page-props';
import { parseBikeRoute } from '../../utils/bike-route';
import { parseRide } from '../../utils/ride';
import BikeRoutesList from '../rides/bike-routes-list';
import Header from '../rides/header';

type TSortKey = '-id' | 'distance' | '-distance' | 'distance_from_ride';

function TripsPage(props: TPageProps<TripsPageQuery>) {
  const {
    data: { allBikeRoute, allRide },
    path,
  } = props;
  const [bikeRoutes] = useState(
    allBikeRoute.nodes.reduce<BikeRoute[]>((res, { data }) => {
      if (data) res.push(parseBikeRoute(data));

      return res;
    }, []),
  );
  const [rides] = useState(
    allRide.nodes.reduce<Ride[]>((res, { data }) => {
      if (data) res.push(parseRide(data));

      return res;
    }, []),
  );
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState<BikeRoute[]>();
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const [sorts] = useState<Array<{ key: TSortKey; label: string }>>([
    { key: '-id', label: t('geovelo.rides.sorts.novelties') },
    { key: 'distance_from_ride', label: t('geovelo.rides.sorts.nearer') },
    { key: 'distance', label: t('geovelo.rides.sorts.min_distance') },
    { key: '-distance', label: t('geovelo.rides.sorts.max_distance') },
  ]);
  const {
    map: { current: map, bounds },
    user: { current: currentUser, geolocation },
  } = useContext(AppContext);
  const [selectedSort, selectSort] = useState<TSortKey>(
    geolocation ? 'distance_from_ride' : 'distance',
  );
  const [filteredBikeRoutes, filterBikeRoutes] = useState<BikeRoute[]>();
  const fetch = useMemo(
    () =>
      debounce(async (search: string, callback: (bikeRoutes: BikeRoute[]) => void) => {
        callback(
          bikeRoutes?.filter(
            ({ title, condensedGeometry }) =>
              condensedGeometry && title.toLowerCase().includes(search.toLowerCase()),
          ) || [],
        );
      }, 700),
    [],
  );
  const { enqueueSnackbar } = useSnackbar();
  const {
    init: initBikeRoutesOnMap,
    update: updateBikeRoutesOnMap,
    clear: clearBikeRoutesMap,
  } = useBikeRoutes(map, {
    onClick: (id) => navigate(`/${currentLanguage}/ride-sets/${id}`),
    // onClick: (id) => navigate(`/${currentLanguage}/trip-planner?bike-route=${id}`),
  });

  useEffect(() => {
    if (!map) return;

    initBikeRoutesOnMap();

    return () => {
      clearBikeRoutesMap();
    };
  }, [map]);

  useEffect(() => {
    if (map && bikeRoutes) updateBikeRoutesOnMap(bikeRoutes);
  }, [map, bikeRoutes]);

  useEffect(() => {
    if (!bounds || !bikeRoutes || !selectedSort) return;

    if (!geolocation && selectedSort === 'distance_from_ride') {
      selectSort('-id');
      enqueueSnackbar(t('commons.location_error'), { variant: 'error' }) ||
        console.error('cannot get current position');
      return;
    }

    const { north, east, south, west } = bounds;
    const boundsPolygon: GeoJSON.Polygon = {
      type: 'Polygon',
      coordinates: [
        [
          [west, north],
          [east, north],
          [east, south],
          [west, south],
          [west, north],
        ],
      ],
    };

    filterBikeRoutes(
      bikeRoutes
        ?.filter(
          ({ condensedGeometry }) =>
            condensedGeometry && booleanIntersects(boundsPolygon, condensedGeometry),
        )
        .sort((a, b) => {
          switch (selectedSort) {
            case '-id':
              return b.id - a.id;
            case 'distance_from_ride':
              return (a.distanceFromLocation || 0) - (b.distanceFromLocation || 0);
            case 'distance':
              return a.distance - b.distance;
            case '-distance':
              return b.distance - a.distance;
          }
        }),
    );
  }, [bikeRoutes, bounds, selectedSort]);

  useEffect(() => {
    let active = true;

    setSearchResults(undefined);
    if (!search) return;

    fetch(search, (bikeRoutes: BikeRoute[]) => {
      if (active) setSearchResults(bikeRoutes);
    });

    return () => {
      active = false;
    };
  }, [search, fetch]);

  return (
    <>
      <Seo
        description={t('geovelo.pages_descriptions.trips') || null}
        title={`${t('geovelo.pages_titles.trips')}`}
        {...props}
      />
      <LeftPanelLayout title={t('geovelo.navigation.trips')} {...props}>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            justifyContent="stretch"
            paddingX={3}
            paddingY={2}
          >
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Header
                disableCard
                customSearchOptions={searchResults}
                onInputChange={setSearch}
                onSortChange={selectSort}
                searchLabel={t('geovelo.bike_routes.actions.search')}
                selectedSort={selectedSort}
                sorts={sorts}
              />
            </Box>
            <Box alignItems="center" display="flex" gap={2}>
              <Button
                color="primary"
                component={Link}
                disabled={currentUser === undefined}
                startIcon={<EditIcon />}
                state={currentUser === null ? { prevPath: `/${currentLanguage}/user-trips` } : {}}
                to={
                  currentUser === null
                    ? `/${currentLanguage}/sign-in`
                    : `/${currentLanguage}/user-trips`
                }
                variant="outlined"
              >
                {t('geovelo.trips.navigation.my_trips')}
              </Button>
              <Button
                color="primary"
                component={Link}
                disabled={currentUser === undefined}
                startIcon={<Add />}
                state={currentUser ? {} : { prevPath: `/${currentLanguage}/trip-planner` }}
                sx={{ flexGrow: 1 }}
                to={
                  currentUser ? `/${currentLanguage}/trip-planner` : `/${currentLanguage}/sign-in`
                }
                variant="outlined"
              >
                {t('geovelo.trips.actions.create')}
              </Button>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <BikeRoutesList bikeRoutes={filteredBikeRoutes} rides={rides} />
          </Box>
        </Box>
      </LeftPanelLayout>
      <MapMarkers path={path} />
    </>
  );
}

export default TripsPage;
