import { Parking, ParkingDialog } from '@geovelo-frontends/commons';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, {
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { AppContext } from '../../context';
import useParkingLots from '../../hooks/map/parking-lots';

export type TParkingLotsRef = { unselect: () => void };

interface IProps {
  onSelectedParkingLotChange: (selectedParking: Parking | null) => void;
}

function ParkingLots(
  { onSelectedParkingLotChange }: IProps,
  ref: Ref<TParkingLotsRef>,
): JSX.Element {
  const [dialogOpen, openDialog] = useState(false);
  const {
    map: { current: map },
  } = useContext(AppContext);
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const { selectedParking, unselect } = useParkingLots(map, { smallDevice });

  useEffect(() => {
    onSelectedParkingLotChange(selectedParking);
    if (selectedParking && smallDevice) openDialog(true);
  }, [selectedParking]);

  useImperativeHandle(ref, () => ({
    unselect,
  }));

  return (
    <ParkingDialog
      onClose={() => {
        openDialog(false);
        setTimeout(() => {
          unselect();
        }, theme.transitions.duration.leavingScreen);
      }}
      open={dialogOpen}
      parking={selectedParking}
    />
  );
}

export default forwardRef(ParkingLots);
