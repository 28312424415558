import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';

import {
  PrismicHomeDataBodyVerticalcardsPrimary,
  PrismicHomeVerticalCardsFragment,
  PrismicJoinUsDataBodyVerticalcardsPrimary,
  PrismicJoinUsVerticalCardsFragment,
  PrismicMissionDataBodyVerticalcardsPrimary,
  PrismicMissionVerticalCardsFragment,
  PrismicTerritoriesDataBodyVerticalcardsPrimary,
  PrismicTerritoriesVerticalCardsFragment,
} from '../../../graphql-types';

import { maxWidth } from './consts';
import CTA from './cta';
import Description from './description';
import Title from './title';

export type TVerticalCardsData =
  | PrismicHomeVerticalCardsFragment
  | PrismicTerritoriesVerticalCardsFragment
  | PrismicMissionVerticalCardsFragment
  | PrismicJoinUsVerticalCardsFragment;

function VerticalCards({ data: { primary, items } }: { data: TVerticalCardsData }): JSX.Element {
  const [diff, setDiff] = useState<number>();
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const containerRef = useRef<HTMLDivElement>();

  const backgroundImage =
    primary &&
    'background_image' in primary &&
    primary.background_image &&
    getImage(primary.background_image);

  useEffect(() => {
    function handleResize() {
      const width = document.body.clientWidth;
      if (backgroundImage) {
        const { width: imageWidth, height: imageHeight } = backgroundImage || {
          width: 0,
          height: 0,
        };
        const height = (width * imageHeight) / imageWidth;

        setDiff(Math.max(height - (containerRef.current?.clientHeight || 0), 0));
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!primary) return <></>;

  const {
    linked_to_prev_slice,
    dark_background,
    fullwidth,
    background_color,
    background_color_end,
    border_color,
    cards,
    outlined,
    max_items_per_line,
    title,
    description,
    images_shape,
    images_size,
    cta_link,
    cta_title,
  } = primary as PrismicHomeDataBodyVerticalcardsPrimary &
    PrismicTerritoriesDataBodyVerticalcardsPrimary &
    PrismicMissionDataBodyVerticalcardsPrimary &
    PrismicJoinUsDataBodyVerticalcardsPrimary;

  let itemsLines: number[] = [];
  const itemsPerLine: number[] = [0];
  const largeItemsPerLine: number[] = [0];
  if (max_items_per_line) {
    let line = 0;
    let count = 0;
    for (const item of items) {
      count += 'large' in item && item.large ? 2 : 1;

      if (count === max_items_per_line + 1) {
        ++line;
        count = 0;
        itemsLines.push(line);
        itemsPerLine.push(1);
        largeItemsPerLine.push(1);
      } else {
        itemsLines.push(line);
        ++itemsPerLine[line];
        if ('large' in item && item.large) ++largeItemsPerLine[line];
        if (count === max_items_per_line) {
          ++line;
          count = 0;
          itemsPerLine.push(0);
          largeItemsPerLine.push(0);
        }
      }
    }
  } else {
    itemsLines = items.map(() => 0);
    itemsPerLine[0] = items.length;
    largeItemsPerLine[0] = items.filter((itemData) => 'large' in itemData && itemData.large).length;
  }

  return (
    <Box
      alignItems="center"
      alignSelf="stretch"
      display="flex"
      flexDirection="column"
      position="relative"
      ref={containerRef}
      sx={
        background_color_end
          ? {
              background: linked_to_prev_slice
                ? `linear-gradient(270deg, ${background_color} 0%, ${background_color_end} 100%)`
                : undefined,
              color: dark_background ? '#fff' : 'inherit',
            }
          : {
              backgroundColor: linked_to_prev_slice ? background_color : undefined,
              color: dark_background ? '#fff' : 'inherit',
            }
      }
    >
      {backgroundImage && diff !== undefined && (
        <Box
          height={`calc(100% + ${diff}px)`}
          left={0}
          position="absolute"
          top={-(diff / 2)}
          width="100%"
          zIndex={0}
        >
          <GatsbyImage
            alt=""
            image={backgroundImage}
            objectFit="cover"
            style={{ height: '100%', width: '100%' }}
          />
        </Box>
      )}
      <Box
        alignItems="center"
        borderRadius={background_color && !linked_to_prev_slice && !fullwidth ? '20px' : ''}
        display="flex"
        flexDirection="column"
        gap={10}
        marginTop={background_color ? (linked_to_prev_slice ? 3 : 8) : undefined}
        maxWidth="100%"
        paddingBottom={{ xs: 3, md: 5 }}
        paddingTop={background_color ? { xs: 5, md: 10 } : linked_to_prev_slice ? 3 : 5}
        paddingX={{ xs: 2, md: 4, lg: 8 }}
        sx={{ backgroundColor: linked_to_prev_slice ? undefined : background_color }}
        width={fullwidth ? '100%' : maxWidth}
        zIndex={1}
      >
        {(title?.text || description?.text) && (
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            maxWidth={fullwidth ? maxWidth : undefined}
          >
            <Title align="center" component="h2" data={title} />
            <Description align="center" data={description} />
          </Box>
        )}
        <Box
          alignSelf={fullwidth ? 'center' : 'stretch'}
          columnGap={items[0] && 'large' in items[0] ? 5 : 9}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          maxWidth={fullwidth ? maxWidth : undefined}
          rowGap={5}
        >
          {items.map(({ icon, title, description, ...itemsData }, index) => {
            const image = icon && getImage(icon);
            const lineIndex = itemsLines[index];
            const lineItems = itemsPerLine[lineIndex];
            const largeItemsCount = largeItemsPerLine[lineIndex];

            return (
              <Box
                flexShrink={0}
                key={index}
                onClick={() =>
                  'anchor' in itemsData &&
                  itemsData.anchor?.text &&
                  document
                    .getElementById(itemsData.anchor.text)
                    ?.scrollIntoView({ behavior: 'smooth' })
                }
                position="relative"
                sx={
                  'anchor' in itemsData && itemsData.anchor?.text
                    ? {
                        cursor: 'pointer',
                      }
                    : {}
                }
                width={{
                  xs: '100%',
                  sm:
                    ('large' in itemsData && itemsData.large) || description?.text
                      ? '100%'
                      : `calc((100% - ${'large' in itemsData ? 50 : 72}px) / 2)`,
                  md:
                    'large' in itemsData
                      ? itemsData.large
                        ? 370
                        : `calc((100% - 370px * ${largeItemsCount} - ${lineItems - 1} * 40px) / ${
                            lineItems - largeItemsCount
                          })`
                      : `calc((100% - (72px * ${(cards ? 2 : lineItems) - 1})) / ${
                          cards ? 2 : lineItems
                        })`,
                  lg:
                    'large' in itemsData
                      ? itemsData.large
                        ? 470
                        : `calc((100% - 470px * ${largeItemsCount} - ${lineItems - 1} * 40px) / ${
                            lineItems - largeItemsCount
                          })`
                      : `calc((100% - (72px * ${lineItems - 1})) / ${lineItems})`,
                }}
              >
                <Box
                  alignItems="center"
                  border={
                    outlined ? '1px solid #DCE1E4' : border_color ? `2px solid ${border_color}` : ''
                  }
                  borderRadius={cards ? 4 : 0}
                  display="flex"
                  flexDirection={{
                    xs: images_shape === 'Rectangle' || images_size === 'Large' ? 'column' : 'row',
                    md: 'column',
                  }}
                  gap={3}
                  height={cards ? '100%' : undefined}
                  padding={cards ? (border_color ? 4 : 2) : 0}
                  position="relative"
                  sx={cards ? { backgroundColor: '#fff' } : {}}
                  zIndex={2}
                >
                  {image &&
                    (images_shape === 'Top_Right' ? (
                      <Box position="absolute" right={16} top={16}>
                        <GatsbyImage
                          alt=""
                          image={image}
                          objectFit="contain"
                          style={{ height: 30, width: 30 }}
                        />
                      </Box>
                    ) : (
                      <GatsbyImage
                        alt=""
                        image={image}
                        objectFit="contain"
                        style={{
                          alignSelf: 'center',
                          flexShrink: 0,
                          height:
                            images_shape === 'Rectangle'
                              ? 'auto'
                              : images_size === 'Small'
                                ? Math.min(64, image.height)
                                : images_size === 'Large'
                                  ? 205
                                  : 80,
                          width:
                            smallDevice && images_shape === 'Rectangle'
                              ? 'auto'
                              : images_shape === 'Rectangle'
                                ? '100%'
                                : images_size === 'Small'
                                  ? Math.min(64, image.width)
                                  : images_size === 'Large'
                                    ? 'auto'
                                    : 80,
                        }}
                      />
                    ))}
                  <Box
                    alignItems={{
                      xs:
                        !border_color && (images_shape === 'Rectangle' || images_size === 'Large')
                          ? 'center'
                          : 'flex-start',
                      md: border_color ? 'flex-start' : 'center',
                    }}
                    display="flex"
                    flexDirection="column"
                    flexGrow={1}
                    gap={{
                      xs: images_shape === 'Rectangle' || images_size === 'Large' ? 1 : 2,
                      md: 2,
                    }}
                    justifyContent="flex-start"
                  >
                    <Title
                      color={border_color || outlined ? 'primary' : ''}
                      data={title}
                      fontSize={outlined ? '1.5em' : '1.125em'}
                      fontWeight={outlined ? 700 : undefined}
                      sx={{
                        textAlign: {
                          xs:
                            images_shape === 'Rectangle' || images_size === 'Large'
                              ? 'center'
                              : 'left',
                          md: border_color ? 'left' : 'center',
                        },
                      }}
                      variant="subtitle1"
                    />
                    <Description
                      data={description}
                      fontSize={title?.text ? '1em' : '1.125em'}
                      sx={{
                        textAlign: {
                          xs:
                            images_shape === 'Rectangle' || images_size === 'Large'
                              ? 'center'
                              : 'left',
                          md: border_color ? 'left' : 'center',
                        },
                        ul: { margin: 0, paddingInlineStart: 2 },
                      }}
                    />
                    {'link' in itemsData && itemsData.link?.url && (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        marginTop="auto"
                        width="100%"
                      >
                        <CTA link={itemsData.link} variant="IconButton" />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
        {cta_title?.text && <CTA link={cta_link} title={cta_title} />}
      </Box>
    </Box>
  );
}

export default VerticalCards;
