import { TEventCategory, useTracker } from '@geovelo-frontends/commons';
import { Box, Rating } from '@mui/material';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import React, { ReactNode, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PrismicApiBannerFragment,
  PrismicApiDataBodyBannerPrimary,
  PrismicHomeBannerFragment,
  PrismicHomeDataBodyBannerPrimary,
  PrismicJoinUsBannerFragment,
  PrismicJoinUsDataBodyBannerPrimary,
  PrismicRidesAndTripsBannerFragment,
  PrismicRidesAndTripsDataBodyBannerPrimary,
  PrismicRidesHubBannerFragment,
  PrismicRidesHubDataBodyBannerPrimary,
  PrismicTerritoriesBannerFragment,
  PrismicTerritoriesDataBodyBannerPrimary,
} from '../../../graphql-types';
import { AppContext } from '../../context';
import { StarIcon, StarOutlinedIcon } from '../icons';

import { maxWidth } from './consts';
import CTA from './cta';
import Description from './description';
import StoreRating from './store-rating';
import Title from './title';

export type TBannerData =
  | PrismicHomeBannerFragment
  | PrismicTerritoriesBannerFragment
  | PrismicRidesAndTripsBannerFragment
  | PrismicApiBannerFragment
  | PrismicRidesHubBannerFragment
  | PrismicJoinUsBannerFragment;

function Banner({
  data: { primary, ...dataProps },
  small,
  breadcrumb,
  enableStores,
  centerText,
}: {
  breadcrumb?: ReactNode;
  data: TBannerData;
  enableStores?: boolean;
  small?: boolean;
  centerText?: boolean;
}): JSX.Element {
  const {
    actions: { setHasHeaderDarkBackground, setHeaderBackgroundColor },
  } = useContext(AppContext);
  const { trackEvent } = useTracker();

  useEffect(() => {
    if (primary) {
      if ('dark_background' in primary)
        setHasHeaderDarkBackground(Boolean(primary.dark_background));
      if (
        ('background_color_start' in primary && primary.background_color_start) ||
        ('fullscreen_background' in primary && primary.fullscreen_background)
      )
        setHeaderBackgroundColor('transparent');
    }

    return () => {
      setHasHeaderDarkBackground(false);
      setHeaderBackgroundColor();
    };
  }, []);

  if (!primary) return <></>;

  const {
    background_color_start,
    background_color_end,
    dark_background,
    background_image,
    fullscreen_background,
    enable_image_margin,
    image: _image,
    baseline,
    subtitle,
    google_play_rating,
    app_store_rating,
    number_of_millions_of_downloads,
    happy_at_work_index,
  } = primary as PrismicHomeDataBodyBannerPrimary &
    PrismicTerritoriesDataBodyBannerPrimary &
    PrismicRidesAndTripsDataBodyBannerPrimary &
    PrismicApiDataBodyBannerPrimary &
    PrismicRidesHubDataBodyBannerPrimary &
    PrismicJoinUsDataBodyBannerPrimary;

  const { t } = useTranslation();
  const backgroundImage = background_image && getImage(background_image);
  const image = _image && getImage(_image);

  return (
    <Box
      alignItems="center"
      alignSelf="stretch"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      minHeight={small ? 200 : 'calc(80vh - 72px - 96px)'}
      paddingBottom={6}
      paddingTop={small ? 2 : 6}
      position="relative"
      sx={
        background_color_end
          ? {
              background: `linear-gradient(270deg, ${background_color_start} 0%, ${background_color_end} 100%)`,
              color: dark_background ? '#fff' : 'inherit',
            }
          : { backgroundColor: background_color_start, color: dark_background ? '#fff' : 'inherit' }
      }
    >
      {background_color_start && (
        <Box
          bottom={0}
          left={0}
          position="absolute"
          right={0}
          sx={
            background_color_end
              ? {
                  background: `linear-gradient(270deg, ${background_color_start} 0%, ${background_color_end} 100%)`,
                }
              : { backgroundColor: background_color_start }
          }
          top={-72}
          zIndex={0}
        />
      )}
      {backgroundImage && (
        <Box
          bottom={0}
          left={0}
          position="absolute"
          right={0}
          top={fullscreen_background ? -72 : '50%'}
          zIndex={0}
        >
          <GatsbyImage
            alt=""
            image={backgroundImage}
            objectFit="cover"
            objectPosition={fullscreen_background ? 'center center' : 'center top'}
            style={{ height: '100%', width: '100%' }}
          />
        </Box>
      )}
      {image && (
        <Box
          alignItems="center"
          bottom={48}
          display={{ xs: 'none', md: 'flex' }}
          position="absolute"
          right={0}
          top={48}
          width="calc(40% - 48px)"
          zIndex={1}
        >
          <Box
            flexGrow={1}
            height={`calc((40vw - 48px) * ${image.height} / ${image.width})`}
            marginRight={enable_image_margin ? { md: 8, lg: 16 } : 0}
            maxHeight="100%"
          >
            <GatsbyImage
              alt=""
              image={image}
              objectFit="contain"
              objectPosition={enable_image_margin ? 'left center' : 'right center'}
              style={{ height: '100%', width: '100%' }}
            />
          </Box>
        </Box>
      )}
      {breadcrumb && (
        <Box
          flexGrow={1}
          maxWidth={{ xs: 'calc(100% - 64px)', md: 'calc(100% - 128px)', lg: 'calc(100% - 256px)' }}
          width={maxWidth}
          zIndex={2}
        >
          {breadcrumb}
        </Box>
      )}
      <Box
        alignItems="center"
        display="flex"
        gap={16}
        justifyContent={centerText ? 'center' : 'initial'}
        maxWidth={{ xs: 'calc(100% - 64px)', md: 'calc(100% - 128px)', lg: 'calc(100% - 256px)' }}
        width={maxWidth}
        zIndex={2}
      >
        <Box display="flex" flexDirection="column" gap={4} width={{ xs: '100%', md: '60%' }}>
          <Title
            component="h1"
            data={baseline}
            fontSize={{ xs: '2em', md: '2.5em', lg: '3em' }}
            fontWeight={700}
            lineHeight={1}
            textAlign={centerText ? 'center' : 'initial'}
            variant="h6"
          />
          <Title
            component="div"
            data={subtitle}
            fontSize={small ? '1rem' : '1.25em'}
            fontWeight={400}
            variant="h6"
          />
          {happy_at_work_index && (
            <Box alignItems="center" display="flex" gap={4}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Rating
                  readOnly
                  emptyIcon={<StarOutlinedIcon sx={{ color: '#fff' }} />}
                  icon={<StarIcon />}
                  precision={0.5}
                  size="large"
                  value={happy_at_work_index}
                />
                <Description
                  data={{ text: `${happy_at_work_index} / 5 Happy at work Index` }}
                  fontSize="1em"
                />
              </Box>
              <Box flexShrink={0}>
                <StaticImage
                  alt="Choose my company"
                  height={91.5}
                  layout="fixed"
                  placeholder="none"
                  src="../../assets/images/happy-index-at-work.png"
                />
              </Box>
            </Box>
          )}
          {'items' in dataProps && dataProps.items && dataProps.items.length > 0 && (
            <Box alignItems="center" display="flex" flexWrap="wrap" gap={2}>
              {dataProps.items.map(({ cta_link, cta_title, ...itemProps }, index) => (
                <CTA
                  color={('cta_color' in itemProps && itemProps.cta_color) || undefined}
                  key={index}
                  link={cta_link?.url ? cta_link : undefined}
                  onClick={() => {
                    if ('cta_anchor' in itemProps && itemProps.cta_anchor?.text)
                      document
                        .getElementById(itemProps.cta_anchor.text)
                        ?.scrollIntoView({ behavior: 'smooth' });
                    if (
                      'cta_tracking_category' in itemProps &&
                      'cta_tracking_name' in itemProps &&
                      itemProps.cta_tracking_category &&
                      itemProps.cta_tracking_name
                    )
                      trackEvent(
                        itemProps.cta_tracking_category as TEventCategory,
                        'Clicked',
                        itemProps.cta_tracking_name.text || undefined,
                      );
                  }}
                  title={cta_title}
                  variant={('cta_variant' in itemProps && itemProps.cta_variant) || undefined}
                />
              ))}
            </Box>
          )}
          {enableStores && (
            <Box
              alignItems="stretch"
              columnGap={4}
              display="flex"
              flexDirection={{ xs: 'column', lg: 'row' }}
              rowGap={1}
            >
              <Box alignItems="center" columnGap={4} display="flex" flexWrap="wrap" rowGap={1}>
                {app_store_rating && (
                  <StoreRating rating label="App Store" value={app_store_rating} />
                )}
                {google_play_rating && (
                  <StoreRating rating label="Google Play" value={google_play_rating} />
                )}
              </Box>
              {number_of_millions_of_downloads && (
                <>
                  <Box
                    borderLeft="1px solid #c0c7cc"
                    display={{ xs: 'none', lg: 'block' }}
                    height={20}
                  />
                  <StoreRating
                    label={t('commons.stats_types.downloads')}
                    value={
                      t('commons.stats.million', { count: number_of_millions_of_downloads }) || ''
                    }
                  />
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Banner;
