import { Box, Tab, Tabs } from '@mui/material';
import React, { ReactNode, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '../../../components/button';
import { MapIcon } from '../../../components/icons';
import { AppContext } from '../../../context';

import Header, { IHeaderProps } from './header';

interface IProps<TTab> {
  actions?: ReactNode;
  fullWidthActions?: boolean;
  children: ReactNode;
  disableContentPadding?: boolean;
  headerProps?: IHeaderProps;
  selectedTab?: TTab;
  selectTab?: (key: TTab) => void;
  subtitle?: ReactNode;
  tabs?: Array<{ disabled?: boolean; hidden?: boolean; key: TTab; label: ReactNode }>;
  title?: ReactNode;
}

function DetailsLayout<TTab extends string>({
  tabs,
  selectedTab,
  disableContentPadding,
  children,
  actions,
  fullWidthActions,
  selectTab,
  ...props
}: IProps<TTab>): JSX.Element {
  const {
    app: { smallDeviceContentShowed },
    actions: { setIsDetailsView, showSmallDeviceContent },
  } = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {
    setIsDetailsView(true);

    return () => {
      setIsDetailsView(false);
    };
  }, []);

  return (
    <Wrapper>
      <Header {...props} />
      {smallDeviceContentShowed && (
        <Box
          alignItems="center"
          display={{ xs: 'flex', md: 'none' }}
          flexShrink={0}
          height={56}
          justifyContent="flex-end"
          paddingY={2}
          sx={{ backgroundColor: '#ebebeb' }}
        >
          <Button
            color="primary"
            onClick={() => showSmallDeviceContent(false)}
            startIcon={<MapIcon />}
            variant="contained"
          >
            {t('commons.actions.show_map')}
          </Button>
        </Box>
      )}
      <StyledContainer>
        {tabs && (
          <StyledTabs
            aria-label="Details tabs"
            indicatorColor="primary"
            textColor="primary"
            value={selectedTab}
            variant="fullWidth"
          >
            {tabs
              .filter(({ hidden }) => !hidden)
              .map(({ key, label, disabled }) => (
                <StyledTab
                  disabled={disabled}
                  key={key}
                  label={label}
                  onClick={() => selectTab?.(key)}
                  value={key}
                />
              ))}
          </StyledTabs>
        )}
        <StyledContent className={disableContentPadding ? '' : 'padding'}>{children}</StyledContent>
        {actions && (
          <StyledActions className={fullWidthActions ? '' : 'centered'}>{actions}</StyledActions>
        )}
      </StyledContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const StyledContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
`;

const StyledTabs = styled(Tabs)`
  background-color: #ebebeb;
  flex-shrink: 0;
`;

const StyledTab = styled(Tab)`
  && {
    min-width: 0;
    text-transform: initial;
  }
`;

const StyledContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  &.padding {
    padding: 16px;
  }
`;

const StyledActions = styled.div`
  align-items: center;
  background-color: whitesmoke;
  display: flex;
  flex-shrink: 0;
  height: 56px;
  padding: 0 16px;

  button {
    margin: 0 8px;
  }

  &.centered {
    justify-content: center;
  }

  &:not(.centered) {
    justify-content: space-between;
  }
`;

export default DetailsLayout;
