import {
  BikeStation,
  ComputedRoute,
  Parking,
  Poi,
  Report,
  Route,
  Search,
  TPoiCategoryCode,
  UserPlace,
} from '@geovelo-frontends/commons';
import React, {
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { AppContext } from '../../context';
import { TRoutingFormWrapperRef } from '../../templates/home/form';

import BikeStations, { TBikeStationsRef } from './bike-stations';
import ParkingLots, { TParkingLotsRef } from './parking-lots';
import Pois, { TPoisRef } from './pois';
import Reports, { TReportsRef } from './reports';
import UserPlaces, { TUserPlacesRef } from './user-places';

import { Map } from '!maplibre-gl';

export type TMapMarkersRef = { hasPopupOpen: boolean; unselect: () => void };

function MapMarkers(
  {
    map: _map,
    path,
    disablePois,
    selectedPoiCategories,
    enableBikeStations,
    enableUserPlaces,
    search,
    routingFormRef,
    computedRoute,
    onPopupOpen,
    onAddPoiAsRideStep,
    onRemovePoiFromRideSteps,
  }: {
    computedRoute?: ComputedRoute | Route | null;
    disablePois?: boolean;
    enableBikeStations?: boolean;
    enableUserPlaces?: boolean;
    map?: Map | null;
    onAddPoiAsRideStep?: (poi: Poi) => void;
    onPopupOpen?: () => void;
    onRemovePoiFromRideSteps?: (poi: Poi) => void;
    path: string;
    routingFormRef?: TRoutingFormWrapperRef | null;
    search?: Search;
    selectedPoiCategories?: { [key in TPoiCategoryCode]?: boolean };
  },
  ref: Ref<TMapMarkersRef>,
): JSX.Element {
  const [selectedParkingLot, selectParkingLot] = useState<Parking | null>(null);
  const [selectedPoi, selectPoi] = useState<Poi | null>(null);
  const [selectedReport, selectReport] = useState<Report | null>(null);
  const [selectedBikeStation, selectBikeStation] = useState<BikeStation | null>(null);
  const [selectedUserPlace, selectUserPlace] = useState<UserPlace | null>(null);
  const {
    map: { current: contextMap },
  } = useContext(AppContext);
  const map = _map || contextMap;
  const parkingLotsRef = useRef<TParkingLotsRef>(null);
  const poisRef = useRef<TPoisRef>(null);
  const reportsRef = useRef<TReportsRef>(null);
  const bikeStationsRef = useRef<TBikeStationsRef>(null);
  const userPlacesRef = useRef<TUserPlacesRef>(null);

  useEffect(() => {
    function update() {
      poisRef.current?.update();
    }

    if (map) {
      update();
      map.on('moveend', update);
    }

    return () => {
      map?.off('moveend', update);
    };
  }, [map]);

  useEffect(() => {
    if (selectedParkingLot) {
      poisRef.current?.unselect();
      reportsRef.current?.unselect();
      bikeStationsRef.current?.unselect();
      userPlacesRef.current?.unselect();
    }
  }, [selectedParkingLot]);

  useEffect(() => {
    if (selectedPoi) {
      parkingLotsRef.current?.unselect();
      reportsRef.current?.unselect();
      bikeStationsRef.current?.unselect();
      userPlacesRef.current?.unselect();
    }
  }, [selectedPoi]);

  useEffect(() => {
    if (selectedReport) {
      poisRef.current?.unselect();
      parkingLotsRef.current?.unselect();
      bikeStationsRef.current?.unselect();
      userPlacesRef.current?.unselect();
    }
  }, [selectedReport]);

  useEffect(() => {
    if (selectedUserPlace) {
      poisRef.current?.unselect();
      parkingLotsRef.current?.unselect();
      reportsRef.current?.unselect();
      bikeStationsRef.current?.unselect();
    }
  }, [selectedUserPlace]);

  useEffect(() => {
    if (selectedBikeStation) {
      poisRef.current?.unselect();
      parkingLotsRef.current?.unselect();
      reportsRef.current?.unselect();
      userPlacesRef.current?.unselect();
    }
  }, [selectedBikeStation]);

  const hasPopupOpen =
    Boolean(selectedParkingLot) ||
    Boolean(selectedPoi) ||
    Boolean(selectedReport) ||
    Boolean(selectedBikeStation) ||
    Boolean(selectedUserPlace);

  useEffect(() => {
    if (hasPopupOpen) onPopupOpen?.();
  }, [selectedPoi, selectedParkingLot, selectedReport, selectedUserPlace, selectedBikeStation]);

  useImperativeHandle(ref, () => ({
    hasPopupOpen,
    unselect: () => {
      poisRef.current?.unselect();
      parkingLotsRef.current?.unselect();
      reportsRef.current?.unselect();
      bikeStationsRef.current?.unselect();
      userPlacesRef.current?.unselect();
    },
  }));

  return (
    <>
      <ParkingLots onSelectedParkingLotChange={selectParkingLot} ref={parkingLotsRef} />
      {!disablePois && (
        <Pois
          map={_map}
          onAddAsRideStep={onAddPoiAsRideStep}
          onRemoveFromRideSteps={onRemovePoiFromRideSteps}
          onSearchUpdated={routingFormRef ? () => routingFormRef.updateWayPoints(true) : undefined}
          onSelectedPoiChange={selectPoi}
          ref={poisRef}
          search={search}
          selectedPoiCategories={selectedPoiCategories}
        />
      )}
      <Reports
        onSelectedReportChange={selectReport}
        path={path}
        ref={reportsRef}
        route={computedRoute}
      />
      {enableBikeStations && (
        <BikeStations
          enabled={search && search.bikeType === 'shared' && !search.computable}
          onSearchUpdated={() => routingFormRef?.updateWayPoints(true)}
          onSelectedBikeStationChange={selectBikeStation}
          ref={bikeStationsRef}
          route={computedRoute}
          search={search}
        />
      )}
      {enableUserPlaces && (
        <UserPlaces
          onSearchUpdated={() => routingFormRef?.updateWayPoints(true)}
          onSelectedUserPlaceChange={selectUserPlace}
          ref={userPlacesRef}
          search={search}
        />
      )}
    </>
  );
}

export default forwardRef(MapMarkers);
