import { ComputedRoute, Route } from '@geovelo-frontends/commons';
import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import RoadTypeBar from './road-type-bar';
import RoadTypeLegend from './road-type-legend';

type TEntry = {
  color: string;
  key: string;
  labelKey: string;
};

const facilities: Array<TEntry> = [
  {
    key: 'recommendedRoads',
    color: '#1d4194',
    labelKey: 'commons.facilities_legend.dedicated_roads',
  },
  { key: 'normalRoads', color: '#5f8af0', labelKey: 'commons.facilities_legend.shared_roads' },
  { key: 'discouragedRoads', color: '#c6d4f0', labelKey: 'commons.facilities_legend.other_routes' },
];

function FacilitiesCard({
  size,
  layout,
  route,
}: {
  disableTitle?: boolean;
  layout?: 'row';
  route: ComputedRoute | Route | null | undefined;
  size?: 'small';
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Card
      sx={{ borderRadius: 4, width: layout === 'row' ? 'calc((100% - 16px) / 2)' : '100%' }}
      variant="outlined"
    >
      <CardContent sx={{ '&&': { display: 'flex', flexDirection: 'column', gap: 2, padding: 2 } }}>
        <Typography fontSize={size === 'small' ? '1rem' : '1.1rem'} fontWeight={600}>
          {route ? (
            t('commons.routing.distances.cycling_roads', {
              value: route.recommendedRoadsPercentage + route.normalRoadsPercentage,
            })
          ) : (
            <Skeleton variant="text" width={150} />
          )}
        </Typography>
        <RoadTypeBar
          entries={facilities}
          values={
            route
              ? [
                  route.recommendedRoadsPercentage,
                  route.normalRoadsPercentage,
                  route.discouragedRoadsRoadsPercentage,
                ]
              : undefined
          }
        />
        <RoadTypeLegend entries={facilities} />
      </CardContent>
    </Card>
  );
}

export default FacilitiesCard;
