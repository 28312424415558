import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

function LoadingPage({ loadingTextKey }: { loadingTextKey: string }) {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        height: '100vh',
        minHeight: '100vh',
        '@supports (-webkit-touch-callout: none)': {
          height: '-webkit-fill-available',
          minHeight: '-webkit-fill-available',
        },
      }}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={2}
        justifyContent="center"
        padding={3}
      >
        <CircularProgress />
        <Typography fontSize="1.125em">{t(loadingTextKey)}</Typography>
      </Box>
    </Box>
  );
}

export default LoadingPage;
