import {
  FileInput,
  Route,
  RouteService,
  Search,
  TFile,
  Trace,
  UserService,
  gpxErrors,
  useCancellablePromise,
  useGeoveloMap,
  useLayers,
  useRouting,
  useSource,
  useTracker,
} from '@geovelo-frontends/commons';
import {
  Box,
  Card,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Radio,
  TextField,
  Typography,
} from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { gpx } from '@tmcw/togeojson';
import { Link, navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '../../components/button';
import Dialog from '../../components/dialog';
import { AppContext } from '../../context';

import { LngLatBounds } from '!maplibre-gl';

export type TImportType = 'itinerary' | 'stats';

type TProps = Omit<DialogProps, 'onClose'> & {
  defaultImportType?: TImportType;
  onAdd?: (trace: Trace) => void;
  onClose: () => void;
};

const mapId = 'import-gpx-map';

function ImportGpxDialog({ defaultImportType, onAdd, onClose, ...props }: TProps): JSX.Element {
  const [importType, setImportType] = useState<TImportType>(defaultImportType || 'itinerary');
  const [file, setFile] = useState<TFile | undefined>();
  const [title, setTitle] = useState('');
  const [datetime, setDatetime] = useState('');
  const [route, setRoute] = useState<Route | null>(null);
  const [trace, setTrace] = useState<Trace | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    user: { routes: userRoutes },
    actions: { setUserRoutes },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const theme = useTheme();
  const { trackEvent } = useTracker();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const {
    map,
    init: initMap,
    destroy: destroyMap,
  } = useGeoveloMap({
    smallDevice: true,
    baseLayer: 'geovelo',
  });
  const {
    addGeoJSONSource: addGPXSource,
    updateGeoJSONSource: updateGPXSource,
    clearGeoJSONSource: clearGPXSource,
  } = useSource(map, 'imported-gpx');
  const { addLineLayer } = useLayers(map);
  const {
    initialized: routingInitialized,
    init: initRouting,
    updateWayPoints: updateWayPointsOnMap,
    showRoutes: showRoutesOnMap,
    destroy: destroyRouting,
  } = useRouting(theme, map, new Search(), {}, { readOnly: true, hidePopup: true }, false);

  useEffect(() => {
    if (!props.open) {
      setFile(undefined);
      setTitle('');
      setRoute(null);
      setTrace(null);
    }

    return () => {
      cancelPromises();
      setLoading(false);
    };
  }, [props.open]);

  useEffect(() => {
    let active = true;

    async function getDatetime() {
      if (file instanceof File) {
        const text = await file.text();
        const collection = gpx(new DOMParser().parseFromString(text, 'text/xml'));

        const time = collection.features[0]?.properties?.time;
        if (active && time) {
          setDatetime(moment(time).format('yyyy-MM-DDThh:mm:ss'));
          setTitle(file.name.substring(0, file.name.lastIndexOf('.')));
        }
      }
    }

    getDatetime();

    return () => {
      setDatetime('');
      active = false;
    };
  }, [file]);

  useEffect(() => {
    if (route) {
      initMap({
        center: { lng: 0.68484, lat: 47.394144 },
        container: mapId,
        customZoomControls: true,
        baseLayersControl: true,
        zoom: 15,
      });
    } else {
      destroyRouting();
      clearGPXSource();
      destroyMap();
    }
  }, [route]);

  useEffect(() => {
    async function showGPXOnMap() {
      if (file instanceof File) {
        const text = await file.text();
        updateGPXSource(gpx(new DOMParser().parseFromString(text, 'text/xml')));
      }
    }

    if (map) {
      addGPXSource();

      addLineLayer(
        'imported-gpx',
        'imported-gpx',
        { 'line-join': 'round', 'line-cap': 'round' },
        {
          'line-color': blueGrey[300],
          'line-width': 9,
        },
      );

      showGPXOnMap();

      initRouting();
    }
  }, [map]);

  useEffect(() => {
    if (routingInitialized && route) {
      updateWayPointsOnMap([route.wayPoints[0], route.wayPoints[route.wayPoints.length - 1]]);
      showRoutesOnMap([route], 0);
      if (route.bounds) {
        const { north, east, south, west } = route.bounds;
        map?.fitBounds(new LngLatBounds({ lat: south, lng: west }, { lat: north, lng: east }), {
          animate: false,
          padding: 50,
        });
      }
    }
  }, [routingInitialized, route]);

  async function importGPX() {
    if (!(file instanceof File) || !title) return;

    setLoading(true);

    if (importType === 'itinerary') {
      try {
        const result = await cancellablePromise(RouteService.getRouteFromGPX(file, title));
        setRoute(result);
        trackEvent('Upload a file', 'Upload GPX file', 'Routing');
      } catch (err) {
        if (err === 'TooManyPoints' || err === 'NoMatch' || err === 'NoSegment') {
          trackEvent('Upload a file', 'Upload GPX file failure', `Routing - ${err}`);
          enqueueSnackbar(t(gpxErrors[err]), { variant: 'error' });
        } else if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
          trackEvent('Upload a file', 'Upload GPX file failure', 'Routing');
          enqueueSnackbar(t('geovelo.gpx_dialog.server_error'), { variant: 'error' });
        }
      }
    } else {
      try {
        const trace = await cancellablePromise(UserService.importGPXTrace(file, title));
        trackEvent('Upload a file', 'Upload GPX file', 'Stats');
        onAdd?.(trace);
        setTrace(trace);
      } catch (err) {
        if (err === 'MissingData' || err === 'AlreadyExists') {
          trackEvent('Upload a file', 'Upload GPX file failure', `Stats - ${err}`);
          enqueueSnackbar(t(gpxErrors[err]), { variant: 'error' });
        } else if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
          trackEvent('Upload a file', 'Upload GPX file failure', `Stats`);
          enqueueSnackbar(t('geovelo.gpx_dialog.server_error'), { variant: 'error' });
        }
      }
    }

    setLoading(false);
  }

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      onClose={(_, reason) => reason !== 'backdropClick' && onClose()}
      scroll="body"
      {...props}
    >
      {!trace && <DialogTitle>{t('geovelo.gpx_dialog.title')}</DialogTitle>}
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {trace ? (
          <Box alignItems="center" display="flex" flexDirection="column" gap={2}>
            <StaticImage alt="" height={130} src="../../assets/images/success.svg" />
            <Typography align="center" fontSize="1.25em" fontWeight={700}>
              {t('geovelo.gpx_dialog.success_title')}
            </Typography>
            <Typography align="center">{t('geovelo.gpx_dialog.success_description')}</Typography>
          </Box>
        ) : !route ? (
          <>
            <FileInput disabled={loading} file={file} onChange={setFile} size="small" type="gpx" />
            <TextField
              fullWidth
              required
              disabled={loading}
              label={t('commons.title')}
              margin="none"
              onChange={({ target: { value } }) => setTitle(value)}
              size="small"
              value={title || ''}
              variant="outlined"
            />
            {importType === 'stats' && datetime && (
              <TextField
                disabled
                fullWidth
                label={t('commons.date')}
                margin="none"
                size="small"
                type="datetime-local"
                value={datetime}
                variant="outlined"
              />
            )}
            <Grid display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
              <StyledCard
                className={importType === 'itinerary' ? 'selected' : ''}
                elevation={0}
                onClick={() => setImportType('itinerary')}
              >
                <Radio disableRipple checked={importType === 'itinerary'} disabled={loading} />
                <Grid display="flex" flexDirection="column" gap={1}>
                  <Typography
                    color={importType === 'itinerary' ? 'primary' : 'textSecondary'}
                    lineHeight={1.2}
                    variant="subtitle1"
                  >
                    {t('geovelo.gpx_dialog.import_type.itinerary_title')}
                  </Typography>
                  <Typography variant="body2">
                    {t('geovelo.gpx_dialog.import_type.itinerary_description')}
                  </Typography>
                </Grid>
              </StyledCard>
              <StyledCard
                className={importType === 'stats' ? 'selected' : ''}
                elevation={0}
                onClick={() => setImportType('stats')}
              >
                <Radio checked={importType === 'stats'} />
                <Grid display="flex" flexDirection="column" gap={1}>
                  <Box alignItems="center" display="flex" gap={1}>
                    <Typography
                      color={importType === 'stats' ? 'primary' : 'textSecondary'}
                      lineHeight={1.2}
                      variant="subtitle1"
                    >
                      {t('geovelo.gpx_dialog.import_type.stats_title')}
                    </Typography>
                  </Box>
                  <Typography variant="body2">
                    {t('geovelo.gpx_dialog.import_type.stats_description')}
                  </Typography>
                </Grid>
              </StyledCard>
            </Grid>
          </>
        ) : (
          <>
            <Box
              borderRadius={2}
              height={300}
              id={mapId}
              margin="16px 0 16px"
              position="relative"
            />
            <Typography>{t('geovelo.gpx_dialog.adapted_route')}</Typography>
            <Typography>{t('geovelo.gpx_dialog.save_route')}</Typography>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ gap: 1 }}>
        {trace ? (
          <>
            <Button onClick={() => onClose()} size="small">
              {t('commons.actions.close')}
            </Button>
            <Button
              color="primary"
              component={Link}
              size="small"
              to={`/${currentLanguage}/user/traces/${trace.id}`}
              variant="contained"
            >
              {t('geovelo.gpx_dialog.actions.show_trace')}
            </Button>
          </>
        ) : !route ? (
          <>
            <Button onClick={() => onClose()} size="small">
              {t('commons.actions.cancel')}
            </Button>
            <Button
              color="primary"
              disabled={!file || !title || loading}
              onClick={() => importGPX()}
              startIcon={loading && <CircularProgress color="inherit" size={16} thickness={4} />}
              variant="contained"
            >
              {t('geovelo.actions.import')}
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => {
                RouteService.remove(route.id);
                onClose();
              }}
              size="small"
            >
              {t('commons.actions.cancel')}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                if (userRoutes) setUserRoutes([route, ...userRoutes]);
                navigate(`/${currentLanguage}/saved-route/${route.id}`);
              }}
              size="small"
              variant="contained"
            >
              {t('commons.actions.confirm')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

const StyledCard = styled(Card)`
  align-items: center;
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;
  max-width: calc(100% - 16px);
  padding: 8px;
  cursor: pointer;
  width: 400px;
  gap: 8px;

  &.selected {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export default ImportGpxDialog;
