import {
  Autocomplete,
  ComputedRoute,
  ComputedRouteService,
  Place,
  Search,
  TripService,
  UserPlace,
  useCancellablePromise,
  useTracker,
  useUnits,
} from '@geovelo-frontends/commons';
import { Box, Slider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PrismicRidesAndTripsDataBodyTripplannerPrimary,
  PrismicRidesAndTripsTripPlannerFragment,
} from '../../../graphql-types';
import { AppContext } from '../../context';
import Button from '../button';

import { maxWidth } from './consts';
import Description from './description';
import Title from './title';

const defaultDistancePerStep = 50_000;

export type TTripPlannerData = PrismicRidesAndTripsTripPlannerFragment;

function TripPlanner({ data: { primary } }: { data: TTripPlannerData }): JSX.Element {
  const [departure, setDeparture] = useState<Place | UserPlace | null>(null);
  const [arrival, setArrival] = useState<Place | UserPlace | null>(null);
  const [daysCount, setDaysCount] = useState(5);
  const [computedRoute, setComputedRoute] = useState<ComputedRoute | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    user: { current: currentUser },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const { palette } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { toDistance } = useUnits();
  const { trackEvent } = useTracker();

  useEffect(() => {
    computeRoute();
  }, [departure, arrival]);

  useEffect(() => {
    if (computedRoute) {
      setDaysCount(Math.ceil(computedRoute.distances.total / defaultDistancePerStep));
    } else setDaysCount(5);
  }, [computedRoute]);

  async function computeRoute() {
    cancelPromises();
    setComputedRoute(null);

    if (!departure || !arrival) return;

    try {
      const { computedRoutes } = await cancellablePromise(
        ComputedRouteService.compute(
          new Search({
            profile: 'touristic',
            bikeType: 'own',
            eBikeEnabled: false,
            transportModes: ['bike'],
            wayPoints: [departure, arrival],
          }),
          {
            showRideItinerary: true,
            bikeParkings: false,
            elevations: false,
            facilities: false,
            energy: false,
            geometry: false,
            instructions: false,
            showPushingBikeInstructions: false,
            singleResult: true,
          },
        ),
      );

      setComputedRoute(computedRoutes[0]);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('commons.routing.form.server_error'));
      }
    }
  }

  async function handleSubmit() {
    if (!departure || !arrival || daysCount === undefined) return;

    trackEvent('Calculates a trip', 'Clicked', 'Click on discover hub generate travel');

    if (!currentUser) {
      const params: string[] = [
        `from=${departure.point.coordinates.join(',')}`,
        `to=${arrival.point.coordinates.join(',')}`,
        `days=${daysCount}`,
      ];

      navigate(`/${currentLanguage}/trip-planner?${params.join('&')}`);

      return;
    }

    setLoading(true);

    try {
      const { trip } = await TripService.create(
        new Search({
          profile: 'touristic',
          bikeType: 'own',
          eBikeEnabled: false,
          transportModes: ['bike'],
          wayPoints: [departure, arrival],
        }),
        { title: t('geovelo.trip_planner.trip_title'), nbDays: daysCount },
      );

      navigate(`/${currentLanguage}/trips/${trip.id}`);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('geovelo.trip_planner.server_error'));
        setLoading(false);
      }
    }
  }

  if (!primary) return <></>;

  const {
    background_color,
    image: _image,
    title,
    description,
    departure_placeholder,
    arrival_placeholder,
    cta_title,
  } = primary as PrismicRidesAndTripsDataBodyTripplannerPrimary;

  const image = _image && getImage(_image);

  return (
    <Box
      alignSelf="stretch"
      display="flex"
      justifyContent="center"
      paddingTop={10}
      position="relative"
      sx={{ backgroundColor: background_color }}
    >
      <Box
        alignItems="flex-end"
        display="flex"
        maxWidth={{ xs: 'calc(100% - 64px)', md: 'calc(100% - 128px)', lg: 'calc(100% - 256px)' }}
        position="relative"
        width={maxWidth}
      >
        {image && (
          <Box
            display={{ xs: 'none', md: 'block' }}
            left={0}
            position="absolute"
            top={0}
            zIndex={2}
          >
            <GatsbyImage
              alt=""
              image={image}
              objectFit="contain"
              style={{ height: '100%', width: '100%' }}
            />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          marginLeft={{ xs: 0, md: `${(image?.width || 0) + 64}px` }}
          paddingBottom={10}
          width={{ xs: '100%', md: `calc(100% - ${(image?.width || 0) + 64}px)` }}
        >
          <Title component="h2" data={title} />
          <Description data={description} />
          <Box
            alignItems={{ xs: 'stretch', xl: 'center' }}
            display="flex"
            flexDirection={{ xs: 'column', xl: 'row' }}
            gap={2}
          >
            <Box
              alignItems={{ xs: 'stretch', sm: 'flex-start', lg: 'center' }}
              display="flex"
              flexDirection={{ xs: 'column', lg: 'row' }}
              flexGrow={1}
              gap={2}
            >
              <Box alignItems="center" alignSelf="stretch" display="flex" flexGrow={1} gap={2}>
                <Autocomplete
                  disableFloatingLabel
                  defaultValue={null}
                  disabled={loading}
                  inputProps={{
                    sx: {
                      '> div': {
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        '> fieldset': { border: '0 !important' },
                      },
                    },
                  }}
                  label={departure_placeholder?.text || 'commons.departure' || ''}
                  margin="none"
                  onSelect={setDeparture}
                  size="small"
                />
                <Autocomplete
                  disableFloatingLabel
                  defaultValue={null}
                  disabled={loading}
                  inputProps={{
                    sx: {
                      '> div': {
                        backgroundColor: '#fff',
                        borderRadius: 2,
                        '> fieldset': { border: '0 !important' },
                      },
                    },
                  }}
                  label={arrival_placeholder?.text || 'commons.arrival' || ''}
                  margin="none"
                  onSelect={setArrival}
                  size="small"
                />
              </Box>
              <Box
                alignItems="center"
                borderRadius={2}
                display="flex"
                gap={{ xs: 1, sm: 3 }}
                height={{ xs: 'auto', sm: 40 }}
                paddingX={2}
                paddingY={{ xs: 2, sm: 0 }}
                sx={{ backgroundColor: '#fff' }}
              >
                <Typography variant="body2">{t('commons.duration')}</Typography>
                <Box alignItems="center" display="flex" flexGrow={1} minWidth={80}>
                  <Slider
                    color="secondary"
                    componentsProps={{
                      rail: { style: { color: palette.primary.main, height: 2 } },
                      track: { style: { color: palette.primary.main, border: 0, height: 2 } },
                    }}
                    disabled={loading || !computedRoute}
                    max={30}
                    min={1}
                    onChange={(_, value) => typeof value === 'number' && setDaysCount(value)}
                    step={1}
                    value={daysCount}
                  />
                </Box>
                <Box
                  alignItems="flex-end"
                  display="flex"
                  flexDirection="column"
                  flexShrink={0}
                  width={60}
                >
                  <Typography lineHeight={1} variant="body2">
                    {t('geovelo.trip_planner.duration', { count: daysCount, nbDays: daysCount })}
                  </Typography>
                  {computedRoute && (
                    <Typography color="textSecondary" lineHeight={1} variant="caption">
                      {t('geovelo.trip_planner.distance_short', {
                        stepDistance: toDistance(computedRoute.distances.total / daysCount, true),
                      })}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Box flexShrink={0}>
              <Button
                color="primary"
                disabled={!computedRoute || loading}
                onClick={handleSubmit}
                size="medium"
                sx={{ height: 40 }}
                variant="contained"
              >
                {cta_title?.text || t('geovelo.actions.plan')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default TripPlanner;
