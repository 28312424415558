import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function PaginationPrevIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path
        d="M11.728 12L12.668 11.06L9.61464 8L12.668 4.94L11.728 4L7.72797 8L11.728 12Z"
        fill="currentColor"
        transform="translate(-3 0)"
      />
    </SvgIcon>
  );
}
