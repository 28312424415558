import {
  ComputedRoute,
  IPoint,
  RoutingForm,
  Search,
  TRoutingFormRef,
  TWayPoint,
} from '@geovelo-frontends/commons';
import { useTheme } from '@mui/material/styles';
import { PageProps } from 'gatsby';
import React, {
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import FormCard from '../../components/form-card';
import RoutingContextMenu from '../../components/routing/context-menu';
import { AppContext, mapPadding } from '../../context';
import { TParams } from '../../hooks/query-params';

export type TRoutingFormWrapperRef = {
  updateWayPoints: (showPreview: boolean) => void;
};

function RoutingFormWrapper(
  {
    mapInteractionsDisabled,
    search,
    wayPoints,
    computedRoutes,
    selectedComputedRouteIndex,
    setWayPoints,
    setComputedRoutes,
    selectComputedRouteIndex,
    updateQueryParams,
  }: {
    computedRoutes: ComputedRoute[] | null | undefined;
    mapInteractionsDisabled?: boolean;
    search: Search;
    selectComputedRouteIndex: (index: number) => void;
    selectedComputedRouteIndex: number;
    setComputedRoutes: (computedRoutes: ComputedRoute[] | null | undefined) => void;
    setWayPoints: (wayPoints: TWayPoint[]) => void;
    updateQueryParams: (params: TParams) => void;
    wayPoints: TWayPoint[];
  } & PageProps,
  ref: Ref<TRoutingFormWrapperRef>,
): JSX.Element {
  const [routingContextMenuState, setRoutingContextMenuState] = useState<{
    point: [number, number];
    position: IPoint;
    pointClickedId?: number;
  } | null>(null);
  const [wayPointClickedId, setWayPointClickedId] = useState<number>();
  const {
    map: { current: map, zoom },
    user: { places: userPlaces, geolocation },
  } = useContext(AppContext);
  const routingFormRef = useRef<TRoutingFormRef>(null);
  const theme = useTheme();

  useEffect(() => {
    if (map) routingFormRef.current?.updateWayPoints(true);
  }, [map]);

  useImperativeHandle(ref, () => ({
    updateWayPoints: (showPreview) => routingFormRef.current?.updateWayPoints(showPreview),
  }));

  function handleUpdate() {
    updateQueryParams({ search });
  }

  return (
    <>
      <FormCard>
        <RoutingForm
          retrieveElevations
          computedRoutes={computedRoutes}
          geolocation={geolocation}
          map={map}
          mapInteractionsDisabled={mapInteractionsDisabled}
          mapPadding={mapPadding}
          onContextMenuOpen={(
            event: { point: [number, number]; position: IPoint },
            id: number | undefined,
          ) => {
            setRoutingContextMenuState(event);
            setWayPointClickedId(id);
          }}
          onUpdate={handleUpdate}
          ref={routingFormRef}
          search={search}
          selectComputedRouteIndex={selectComputedRouteIndex}
          selectedComputedRouteIndex={selectedComputedRouteIndex}
          setComputedRoutes={setComputedRoutes}
          setWayPoints={setWayPoints}
          theme={theme}
          userPlaces={userPlaces}
          wayPoints={wayPoints}
          zoom={zoom}
        />
      </FormCard>
      <RoutingContextMenu
        keepMounted
        anchorPosition={
          routingContextMenuState
            ? {
                top: routingContextMenuState.point[1] + 64,
                left: routingContextMenuState.point[0] + 480,
              }
            : undefined
        }
        anchorReference="anchorPosition"
        onClose={() => setRoutingContextMenuState(null)}
        onSearchUpdated={() => routingFormRef.current?.updateWayPoints(true)}
        open={Boolean(routingContextMenuState)}
        point={routingContextMenuState?.position}
        pointClickedId={wayPointClickedId}
        search={search}
      />
    </>
  );
}

export default forwardRef(RoutingFormWrapper);
