import { Box } from '@mui/material';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PrismicBlogPostThemeFragment,
  PrismicMissionBlogPostListFragment,
} from '../../../graphql-types';
import BlogPostCard from '../../templates/blog/blog-post-card';

import { maxWidth } from './consts';
import CTA from './cta';
import Title from './title';

export type TBlogPostListData = PrismicMissionBlogPostListFragment;

function BlogPostList({
  data: { primary, items },
  themes,
}: {
  data: TBlogPostListData;
  themes?: PrismicBlogPostThemeFragment[];
}): JSX.Element {
  const {
    i18n: { language: currentLanguage },
  } = useTranslation();

  if (!primary) return <></>;

  const { baseline, cta_title } = primary;

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap={8}
      paddingY={10}
      width="100%"
      zIndex={1}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        gap={5}
        maxWidth={{ xs: 'calc(100% - 100px)', md: 'calc(100% - 100px)', lg: 'calc(100% - 128px)' }}
        width={maxWidth}
      >
        <Title align="center" data={baseline} fontSize="1.5rem" fontWeight={700} />
        <Box display="flex" flexWrap="wrap">
          {items.map((data) => {
            const blogPost = data?.blog_post?.document;
            if (!blogPost || !('uid' in blogPost)) return <Fragment key={data?.blog_post?.uid} />;

            return (
              <BlogPostCard card blogPost={blogPost} key={data?.blog_post?.uid} themes={themes} />
            );
          })}
        </Box>
        <CTA
          label={cta_title.text || ''}
          link={{ link_type: 'Document', url: `/${currentLanguage}/blog` }}
        />
      </Box>
    </Box>
  );
}

export default BlogPostList;
