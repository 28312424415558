import {
  BikeRoute,
  ComputedRoute,
  Loading,
  Place,
  Ride,
  RideService,
  RoutingForm,
  Search,
  TRoutingFormRef,
  TWayPoint,
  Trip,
  TripService,
  useCancellablePromise,
  useTracker,
  useUnits,
} from '@geovelo-frontends/commons';
import {
  IconButton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { Link, navigate } from 'gatsby';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { TripPlannerQuery } from '../../../graphql-types';
import Button from '../../components/button';
import { AddIcon, MinusIcon, RoutingIcon } from '../../components/icons';
import MapMarkers from '../../components/map-markers';
import Seo from '../../components/seo';
import { AppContext, defaultSmallDeviceShowDetailsAction, mapPadding } from '../../context';
import useQueryParams from '../../hooks/query-params';
import DetailsLayout from '../../layouts/app-content/details';
import { TPageProps, TPageState } from '../../page-props';
import { parseBikeRoute } from '../../utils/bike-route';

const initialDistancePerStep = 50_000;

function TripPlannerPage(props: TPageProps<TripPlannerQuery>): JSX.Element {
  const {
    location,
    path,
    data: { allBikeRoute },
  } = props;
  const [trip, setTrip] = useState<Trip>();
  const [bikeRoute, setBikeRoute] = useState<BikeRoute | null>();
  const [rides, setRides] = useState<Ride[]>();
  const [title, setTitle] = useState('');
  const [computedRoutes, setComputedRoutes] = useState<ComputedRoute[] | null | undefined>();
  const { get: getQueryParams, getFrom, getTo } = useQueryParams(location.search);
  const [wayPoints, setWayPoints] = useState<TWayPoint[]>([getFrom(), getTo()]);
  const [departureDate, setDepartureDate] = useState<Moment | null>(
    moment().startOf('day').add(1, 'day'),
  );
  const [nbDays, setNbDays] = useState<number | undefined>(() => {
    const { days: _days } = getQueryParams();
    const days = _days && parseInt(_days);

    return typeof days === 'number' ? days : undefined;
  });
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const {
    map: { current: map, zoom },
    user: { current: currentUser, trips: userTrips, places: userPlaces, geolocation },
    actions: { setUserTrips, setSmallDeviceShowDetailsAction },
  } = useContext(AppContext);
  const search = useRef(
    new Search({
      profile: 'touristic',
      bikeType: 'own',
      eBikeEnabled: false,
      transportModes: ['bike'],
      wayPoints: [...wayPoints],
    }),
  );
  const routingFormRef = useRef<TRoutingFormRef>(null);
  const theme = useTheme();
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const { toDistance } = useUnits();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = useTracker();

  useEffect(() => {
    setSmallDeviceShowDetailsAction({
      labelKey: 'geovelo.trip_planner.actions.plan',
      Icon: RoutingIcon,
    });

    getBikeRoute();

    return () => {
      setSmallDeviceShowDetailsAction(defaultSmallDeviceShowDetailsAction);
      cancelPromises();
    };
  }, []);

  useEffect(() => {
    if (bikeRoute || bikeRoute === null) {
      routingFormRef.current?.updateWayPoints(true);
    }
  }, [map, bikeRoute]);

  useEffect(() => {
    if ((initialized || !nbDays) && computedRoutes?.[0]) {
      setNbDays(Math.ceil(computedRoutes[0].distances.total / initialDistancePerStep));
    } else if (initialized) setNbDays(undefined);

    if (computedRoutes !== undefined) setInitialized(true);
  }, [computedRoutes]);

  useEffect(() => {
    if (trip) navigate(`/${currentLanguage}/trips/${trip.id}`);
  }, [trip]);

  async function getBikeRoute() {
    const { 'bike-route': bikeRouteParam } = getQueryParams();
    if (!bikeRouteParam) {
      setBikeRoute(null);
      setTitle(t('geovelo.trip_planner.trip_title') || '');
      return;
    }

    const bikeRouteId = parseInt(bikeRouteParam);
    try {
      const data = allBikeRoute.nodes.find(({ data }) => data?.id === bikeRouteId)?.data;
      if (!data) throw new Error('unknown bike route');

      const _bikeRoute = parseBikeRoute(data);

      const { rides: _rides } = await RideService.getRides({
        page: 1,
        pageSize: _bikeRoute.rides.length,
        ids: _bikeRoute.rides.map(({ rideId }) => rideId),
        query: '{id, title, geo_point_a, geo_point_a_title, geo_point_b, geo_point_b_title}',
      });

      const __rides = _bikeRoute.rides.reduce<Ride[]>((res, { rideId }) => {
        const _ride = _rides.find(({ id }) => rideId === id);

        if (_ride && _ride.from && _ride.to) res.push(_ride);

        return res;
      }, []);

      const wayPoints = __rides.map(({ from }) => from || undefined);
      wayPoints.push(__rides[__rides.length - 1]?.to || undefined);

      search.current.wayPoints = wayPoints.length > 0 ? wayPoints : [undefined, undefined];

      setBikeRoute(_bikeRoute);
      setRides(_rides);
      setTitle(_bikeRoute.title);
    } catch (err) {
      console.error(err);
      setBikeRoute(null);
    }
  }

  async function handleSubmit() {
    cancelPromises();

    if (!title || !nbDays || !departureDate) return;

    trackEvent('Generate a trip', 'Clicked', 'CTA on trip generation page');

    setLoading(true);

    try {
      const { trip: _trip } = await cancellablePromise(
        TripService.create(search.current, { title, nbDays, departureDate }),
      );

      if (userTrips) setUserTrips([_trip, ...userTrips]);

      setTrip(_trip);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('geovelo.trip_planner.server_error'));
        setLoading(false);
      }
    }
  }

  return (
    <>
      <Seo title={`${t('geovelo.pages_titles.trip_planner')}`} {...props} />
      <DetailsLayout
        disableContentPadding
        actions={
          <>
            {currentUser ? (
              <Button
                color="primary"
                disabled={
                  loading ||
                  !title ||
                  !computedRoutes ||
                  computedRoutes.length === 0 ||
                  !departureDate
                }
                onClick={handleSubmit}
                size="small"
                variant="contained"
              >
                {t('geovelo.trip_planner.actions.plan')}
              </Button>
            ) : (
              <Button<TPageState>
                color="primary"
                component={Link}
                disabled={
                  loading ||
                  !title ||
                  !computedRoutes ||
                  computedRoutes.length === 0 ||
                  !departureDate
                }
                size="small"
                state={{ prevPath: path }}
                to={`/${currentLanguage}/sign-in`}
                variant="contained"
              >
                {t('geovelo.trip_planner.actions.plan')}
              </Button>
            )}
          </>
        }
        title={t('geovelo.trip_planner.title')}
      >
        {bikeRoute === undefined ? (
          <Loading text={t('geovelo.trip_planner.loading_bike_routes')} />
        ) : loading ? (
          <Loading text={t('geovelo.trip_planner.loading')} />
        ) : (
          <StyledStepper orientation="vertical">
            <Step active expanded>
              <StepLabel>
                <Typography>{t('geovelo.trip_planner.navigation.title')}</Typography>
              </StepLabel>
              <StepContent>
                <StyledTextField
                  fullWidth
                  required
                  margin="dense"
                  onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
                    setTitle(value)
                  }
                  placeholder={t('geovelo.trip_planner.trip_title') || ''}
                  size="small"
                  value={title}
                  variant="outlined"
                />
              </StepContent>
            </Step>
            <Step active expanded>
              <StepLabel>
                <Typography>{t('geovelo.trip_planner.navigation.route')}</Typography>
              </StepLabel>
              <StepContent>
                <RoutingForm
                  disableBikeType
                  disableNewStepAddition
                  computedRoutes={computedRoutes}
                  defaultOptions={rides?.reduce<Place[]>((res, { from }) => {
                    if (from?.addressDetail) res.push(from.clone());
                    return res;
                  }, [])}
                  geolocation={geolocation}
                  map={map}
                  mapPadding={mapPadding}
                  ref={routingFormRef}
                  search={search.current}
                  selectedComputedRouteIndex={0}
                  setComputedRoutes={setComputedRoutes}
                  setWayPoints={setWayPoints}
                  theme={theme}
                  userPlaces={userPlaces}
                  wayPoints={wayPoints}
                  zoom={zoom}
                />
              </StepContent>
            </Step>
            <Step active expanded>
              <StepLabel>
                <Typography>{t('geovelo.trip_planner.navigation.departure_date')}</Typography>
              </StepLabel>
              <StepContent>
                <DatePicker
                  disablePast
                  onChange={setDepartureDate}
                  slotProps={{ textField: { margin: 'dense', size: 'small', variant: 'outlined' } }}
                  value={departureDate}
                />
              </StepContent>
            </Step>
            {computedRoutes?.[0] && nbDays && (
              <Step active expanded>
                <StepLabel>
                  <Typography variant="subtitle1">
                    {t('geovelo.trip_planner.navigation.duration')}
                  </Typography>
                </StepLabel>
                <StepContent>
                  <DurationWrapper>
                    <Tooltip
                      placement="top"
                      title={t('geovelo.trip_planner.actions.decrease_duration')}
                    >
                      <span>
                        <StyledIconButton
                          disabled={nbDays === 1}
                          onClick={() => setNbDays(nbDays - 1)}
                          size="small"
                        >
                          <MinusIcon />
                        </StyledIconButton>
                      </span>
                    </Tooltip>
                    <Typography align="center" className="value">
                      {t('geovelo.trip_planner.duration', { count: nbDays, nbDays })}
                    </Typography>
                    <Tooltip
                      placement="top"
                      title={t('geovelo.trip_planner.actions.increase_duration')}
                    >
                      <StyledIconButton onClick={() => setNbDays(nbDays + 1)} size="small">
                        <AddIcon />
                      </StyledIconButton>
                    </Tooltip>
                    <Typography className="caption" color="textSecondary" variant="caption">
                      {t('geovelo.trip_planner.distance', {
                        stepDistance: toDistance(computedRoutes[0].distances.total / nbDays, true),
                      })}
                    </Typography>
                  </DurationWrapper>
                </StepContent>
              </Step>
            )}
          </StyledStepper>
        )}
      </DetailsLayout>
      <MapMarkers path={path} />
    </>
  );
}

const StyledStepper = styled(Stepper)`
  && {
    padding: 16px;
    background-color: transparent;
  }
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    background-color: #fff;
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
`;

const DurationWrapper = styled.div`
  align-items: center;
  display: flex;

  .value {
    margin: 0 8px;
    width: 80px;
  }

  .caption {
    margin-left: 16px;
  }
`;

export default TripPlannerPage;
