import { ArrowCircleRightOutlined } from '@mui/icons-material';
import { ButtonProps, IconButton } from '@mui/material';
import { Crisp } from 'crisp-sdk-web';
import { Link } from 'gatsby';
import React, { useContext, useEffect } from 'react';

import { AppContext } from '../../context';
import environment from '../../environment';
import Button, { TLinkProps } from '../button';
import { ChevronRightIcon } from '../icons';

type TButtonProps<T> = TLinkProps<T> | ButtonProps;

function CTA<T = Record<string, never>>({
  link,
  label,
  color,
  variant,
  title,
  type,
  ...buttonProps
}: {
  color?: string | null;
  label?: string;
  link?: {
    link_type?: string | null;
    url?: string | null;
    slug?: string | null;
    target?: string | null;
  } | null;
  title?: { text?: string | null } | null;
  type?: string | null;
  variant?: string | null;
} & Omit<TButtonProps<T>, 'title' | 'variant' | 'color' | 'type'>): JSX.Element {
  const {
    user: { geolocation },
  } = useContext(AppContext);

  useEffect(() => {
    if (typeof document !== 'undefined' && type === 'Support') {
      Crisp.configure(environment.crisp?.websiteId || '');
    }
  }, []);

  const props:
    | { component: string; href?: string; onClick?: () => void; target?: string }
    | { component: typeof Link; to: string }
    | Record<string, never> = link
    ? link.link_type === 'Document'
      ? { component: Link, to: link.url || '' }
      : { component: 'a', href: link.url || '', target: link.target || undefined }
    : {};

  if (type === 'Support' && 'href' in props) {
    delete props.href;
    props.onClick = () => {
      if (!Crisp.chat.isVisible()) Crisp.chat.show();
      if (!Crisp.chat.isChatOpened()) Crisp.chat.open();
    };
  }

  if (link?.link_type === 'Document' && link.slug === 'rides' && 'to' in props) {
    props.to += `?c=${
      geolocation ? geolocation.point.coordinates.join(',') : '2.341400,48.857170'
    }&z=15`;
  }

  if (variant === 'IconButton') {
    return (
      <IconButton
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        color={
          color === 'Black'
            ? 'black'
            : color === 'White'
              ? 'white'
              : color === 'secondary'
                ? 'secondary'
                : 'primary'
        }
        sx={{ padding: 0 }}
        {...props}
        {...buttonProps}
      >
        <ArrowCircleRightOutlined fontSize="large" />
      </IconButton>
    );
  }

  return (
    <Button
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      color={
        color === 'Black'
          ? 'black'
          : color === 'White'
            ? 'white'
            : color === 'secondary'
              ? 'secondary'
              : 'primary'
      }
      endIcon={variant === 'Text' && <ChevronRightIcon />}
      size="large"
      variant={variant === 'Outlined' ? 'outlined' : variant === 'Text' ? 'text' : 'contained'}
      {...props}
      {...buttonProps}
    >
      {title?.text || label}
    </Button>
  );
}

export default CTA;
