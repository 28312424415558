import { Box, Typography } from '@mui/material';
import { graphql, navigate } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { EulaQuery } from '../../../graphql-types';
import Seo from '../../components/seo';
import { maxWidth } from '../../components/slices/consts';
import Title from '../../components/slices/title';
import { defaultLanguage } from '../../languages';
import { TPageProps } from '../../page-props';

function EulaPage(props: TPageProps<EulaQuery>) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.data.prismicEula)
      navigate(`/${defaultLanguage}${props.pageContext.slug.substring(3)}`);
  }, []);

  if (!props.data.prismicEula) return <></>;

  const { title, text } = props.data.prismicEula.data;

  return (
    <>
      <Seo title={`${title?.text || t('geovelo.pages_titles.eula')}`} {...props} />
      <Box alignItems="center" display="flex" flexDirection="column" paddingY={5}>
        <Box
          display="flex"
          flexDirection="column"
          gap={5}
          maxWidth={{ xs: 'calc(100% - 64px)', md: 'calc(100% - 128px)', lg: 'calc(100% - 256px)' }}
          paddingY={5}
          width={maxWidth}
        >
          <Title align="center" component="h1" data={title} fontSize="1.5em" />
          <StyledTypography
            component="div"
            dangerouslySetInnerHTML={text?.html ? { __html: text.html } : undefined}
            fontSize="1.125em"
            lineHeight={1.6}
          />
        </Box>
      </Box>
    </>
  );
}

const StyledTypography = styled(Typography)<{ component: 'div' }>`
  a {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const query = graphql`
  fragment EulaData on PrismicEulaData {
    title {
      text
      html
    }
    text {
      html
    }
  }
`;

export default withPrismicPreview(EulaPage);
