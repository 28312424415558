import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function ArrowInward(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 16 17" {...props}>
      <path
        d="M11.3333 4.50077C11.1565 4.50077 10.9869 4.57101 10.8619 4.69604C10.7369 4.82106 10.6666 4.99063 10.6666 5.16744V10.2274L5.13998 4.69411C5.01445 4.56857 4.84418 4.49805 4.66665 4.49805C4.48911 4.49805 4.31885 4.56857 4.19331 4.69411C4.06778 4.81964 3.99725 4.98991 3.99725 5.16744C3.99725 5.34498 4.06778 5.51524 4.19331 5.64077L9.72665 11.1674H4.66665C4.48984 11.1674 4.32027 11.2377 4.19524 11.3627C4.07022 11.4877 3.99998 11.6573 3.99998 11.8341C3.99998 12.0109 4.07022 12.1805 4.19524 12.3055C4.32027 12.4305 4.48984 12.5008 4.66665 12.5008H11.3333C11.4204 12.4997 11.5065 12.4816 11.5866 12.4474C11.7495 12.3798 11.879 12.2503 11.9466 12.0874C11.9808 12.0073 11.9989 11.9212 12 11.8341V5.16744C12 4.99063 11.9297 4.82106 11.8047 4.69604C11.6797 4.57101 11.5101 4.50077 11.3333 4.50077Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
