import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function PrevIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        d="M8.50001 12.8L14.2 18.4C14.6 18.8 15.2 18.8 15.6 18.4C16 18 16 17.4 15.6 17L10.7 12L15.6 6.99999C16 6.59999 16 5.99999 15.6 5.59999C15.4 5.39999 15.2 5.29999 14.9 5.29999C14.6 5.29999 14.4 5.39999 14.2 5.59999L8.50001 11.2C8.10001 11.7 8.10001 12.3 8.50001 12.8C8.50001 12.7 8.50001 12.7 8.50001 12.8Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
