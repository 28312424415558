import {
  Ride,
  RideService,
  TRideThemeKey,
  useCancellablePromise,
  useRides,
  useTracker,
} from '@geovelo-frontends/commons';
import { Add } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, navigate } from 'gatsby';
import debounce from 'lodash/debounce';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RidesPageQuery } from '../../../graphql-types';
import Button from '../../components/button';
import EmptyState from '../../components/empty-state';
import { DiscoverIcon, FavoriteIcon } from '../../components/icons';
import MapMarkers from '../../components/map-markers';
import Seo from '../../components/seo';
import { AppContext, defaultSmallDeviceShowDetailsAction } from '../../context';
import useQueryParams from '../../hooks/query-params';
import LeftPanelLayout from '../../layouts/app-content/left-panel';
import { TPageProps } from '../../page-props';

import CreateDialog from './create-dialog';
import FilterDialog, {
  IFilters,
  filterDistanceStep,
  maxFilterDistance,
  minFilterDistance,
  rideDifficulties,
} from './filter-dialog';
import Header from './header';
import RidesList from './rides-list';

type TSortKey = '-id' | 'distance' | '-distance' | 'duration' | '-duration' | 'distance_from_ride';

export const ridesMinZoom = 8;

const query =
  '{id, title, geo_point_a, geo_point_a_title, geo_point_b, geo_point_b_title, geo_point_center, geometry_condensed, duration, route_duration, distance, vertical_gain, difficulty, icon, photos{thumbnail, thumbnailSquare}, themes, partners}';

function RidesPage(props: TPageProps<RidesPageQuery>) {
  const {
    location,
    path,
    data: { allRide },
  } = props;
  const [allFilteredRides, setAllFilteredRides] = useState<Ride[]>();
  const [communityRides, setCommunityRides] = useState<Ride[]>();
  const [filteredRides, setFilteredRides] = useState<Ride[]>();
  const [search, setSearch] = useState('');
  const [searchResults, setSearchResults] = useState<Ride[]>();
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const [sorts] = useState<Array<{ key: TSortKey; label: string }>>();
  const [selectedSort, selectSort] = useState<TSortKey>('distance_from_ride');
  const [selectedRide, selectRide] = useState<Ride | null>(null);
  const [filters, setFilters] = useState<IFilters>();
  const [filtersDialogOpen, openFiltersDialog] = useState(false);
  const [createDialogOpen, openCreateDialog] = useState(false);
  const [mapInitialized, setMapInitialized] = useState(false);
  const {
    map: { current: map },
    user: { current: currentUser, rides: userRides, geolocation },
    ride: { themes: rideThemes },
    actions: { getUserRides, setSmallDeviceShowDetailsAction },
  } = useContext(AppContext);
  const fetch = useMemo(
    () =>
      debounce(async (search: string, callback: (rides: Ride[]) => void) => {
        const { rides } = await RideService.getRides({
          page: 1,
          pageSize: 3,
          search,
          query,
          publicationStatuses: ['PUBLISHED'],
        });

        callback(rides.filter(({ id }) => allRide.nodes.find(({ data }) => data?.id === id)));
      }, 700),
    [],
  );
  const { trackEvent } = useTracker();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const {
    initialized: ridesInitializedOnMap,
    init: initRidesOnMap,
    update: updateRidesMarkers,
    clear: clearRidesMarkers,
  } = useRides(map, theme, allFilteredRides, undefined, true, {
    onClick: (ride) => {
      trackEvent('Calculates a ride', 'Clicked', 'Click on ride ride page');
      selectRide(ride);
    },
  });
  const { get: getQueryParams, update: updateQueryParams } = useQueryParams(location.search);

  useEffect(() => {
    setSmallDeviceShowDetailsAction({ labelKey: 'geovelo.actions.show_rides', Icon: DiscoverIcon });

    return () => {
      cancelPromises();
      clearRidesMarkers();
      setSmallDeviceShowDetailsAction(defaultSmallDeviceShowDetailsAction);
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      if (!userRides) getUserRides();
    }
  }, [currentUser]);

  useEffect(() => {
    setMapInitialized(map !== null);
  }, [map]);

  useEffect(() => {
    if (mapInitialized && map) {
      if (map.getZoom() < ridesMinZoom && geolocation) {
        const [lng, lat] = geolocation.point.coordinates;
        setTimeout(() => map.flyTo({ center: { lat, lng }, zoom: ridesMinZoom }), 200);
      }

      initRidesOnMap();
    }

    return () => clearRidesMarkers();
  }, [mapInitialized]);

  useEffect(() => {
    if (ridesInitializedOnMap) {
      getRides();
      map?.on('moveend', getRides);
    }

    return () => {
      map?.off('moveend', getRides);
    };
  }, [ridesInitializedOnMap, rideThemes, filters, selectedSort]);

  useEffect(() => {
    let active = true;

    setSearchResults(undefined);
    if (!search) return;

    fetch(search, (rides: Ride[]) => {
      if (active) setSearchResults(rides);
    });

    return () => {
      active = false;
    };
  }, [search, fetch]);

  useEffect(() => {
    if (rideThemes && filters) {
      updateQueryParams({
        themes: rideThemes.filter(({ key }) => filters.themeKeys[key]).map(({ key }) => key),
        difficulties: rideDifficulties.filter((key) => filters.difficultyKeys[key]),
        maxDistance: filters.maxDistance,
      });
    }
  }, [rideThemes, filters]);

  useEffect(() => {
    if (map && filters) {
      if (selectedSort === 'distance_from_ride' && !geolocation) {
        navigator.geolocation.getCurrentPosition(
          () => undefined,
          () => {
            selectSort('-id');
            enqueueSnackbar(t('commons.location_error'), { variant: 'error' }) ||
              console.error('cannot get current position');
          },
        );
      } else {
        getRides();
      }
    }
  }, [map, selectedSort, filters, geolocation]);

  useEffect(() => {
    if (rideThemes) {
      const {
        themes: themeKeys,
        difficulties: difficultyKey,
        'max-distance': _maxDistance,
      } = getQueryParams();
      const themes = (themeKeys?.split(',') || []).reduce<{ [key: string]: boolean }>(
        (res, key) => {
          res[key] = true;

          return res;
        },
        {},
      );
      const difficulties = (difficultyKey?.split(',') || []).reduce<{ [key: string]: boolean }>(
        (res, key) => {
          res[key] = true;

          return res;
        },
        {},
      );
      const maxDistance = _maxDistance ? parseInt(_maxDistance) : undefined;

      setFilters({
        themeKeys: rideThemes.reduce<{ [key in TRideThemeKey]?: boolean }>((res, { key }) => {
          res[key] = !themeKeys || Boolean(themes[key]);

          return res;
        }, {}),
        maxDistance:
          maxDistance &&
          !isNaN(maxDistance) &&
          maxDistance >= minFilterDistance &&
          maxDistance <= maxFilterDistance
            ? Math.round(maxDistance / filterDistanceStep) * filterDistanceStep
            : 100000,
        difficultyKeys: {
          easy: !difficultyKey || Boolean(difficulties.easy),
          medium: !difficultyKey || Boolean(difficulties.medium),
          hard: !difficultyKey || Boolean(difficulties.hard),
        },
      });
    }
  }, [rideThemes]);

  useEffect(() => {
    setFilteredRides(allFilteredRides?.filter(({ partners }) => partners && partners.length > 0));
    setCommunityRides(
      allFilteredRides?.filter(({ partners }) => !partners || partners.length === 0),
    );
  }, [allFilteredRides]);

  useEffect(() => {
    if (map && allFilteredRides) updateRidesMarkers();
  }, [map, allFilteredRides]);

  useEffect(() => {
    if (map) map.setZoom(Math.min(map.getZoom(), 15));
  }, [map]);

  useEffect(() => {
    if (selectedRide) navigate(`/${currentLanguage}/rides/${selectedRide.id}`);
  }, [selectedRide]);

  async function getRides() {
    cancelPromises();
    clearRidesMarkers();
    setAllFilteredRides(undefined);

    if (!map || !rideThemes || !filters) return;
    if (map.getZoom() < ridesMinZoom) {
      setAllFilteredRides([]);
      return;
    }

    const [[west, south], [east, north]] = map.getBounds().toArray();

    try {
      const themes = rideThemes.filter(({ key }) => filters.themeKeys[key]).map(({ id }) => id);
      const allThemes = themes.length === rideThemes.length;
      const { rides } = await cancellablePromise(
        RideService.getRides({
          themes: allThemes ? undefined : themes,
          maxDistance: filters.maxDistance,
          difficulties: rideDifficulties.filter((key) => key),
          bounds: { north, east, south, west },
          page: 1,
          pageSize: 100,
          ordering: selectedSort,
          query,
          publicationStatuses: ['PUBLISHED'],
        }),
      );

      setAllFilteredRides(rides);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
        setAllFilteredRides([]);
      }
    }
  }

  function handleFiltersDialogClose(newFilters?: IFilters) {
    if (newFilters) setFilters({ ...newFilters });
    openFiltersDialog(false);
  }

  return (
    <>
      <Seo
        description={t('geovelo.pages_descriptions.rides') || null}
        title={`${t('geovelo.pages_titles.rides')}`}
        {...props}
      />
      <LeftPanelLayout title={t('geovelo.navigation.rides')} {...props}>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            justifyContent="stretch"
            paddingX={3}
            paddingY={2}
          >
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Header
                disableCard
                customSearchOptions={searchResults}
                onFiltersDialogOpen={filters ? () => openFiltersDialog(true) : undefined}
                onInputChange={setSearch}
                onSortChange={selectSort}
                searchLabel={t('geovelo.rides.actions.search')}
                selectedSort={selectedSort}
                sorts={sorts}
              />
            </Box>
            <Box alignItems="center" display="flex" gap={2}>
              <Button
                color="primary"
                component={Link}
                disabled={currentUser === undefined}
                startIcon={<FavoriteIcon />}
                state={currentUser === null ? { prevPath: `/${currentLanguage}/user-rides` } : {}}
                to={
                  currentUser === null
                    ? `/${currentLanguage}/sign-in`
                    : `/${currentLanguage}/user-rides`
                }
                variant="outlined"
              >
                {t('geovelo.rides.navigation.my_rides')}
              </Button>
              <Button
                color="primary"
                onClick={() => openCreateDialog(true)}
                startIcon={<Add />}
                sx={{ flexGrow: 1 }}
                variant="outlined"
              >
                {t('geovelo.rides.actions.create')}
              </Button>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" flexGrow={1} gap={2}>
            {!map ? (
              <></>
            ) : map.getZoom() < ridesMinZoom ? (
              <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                justifyContent="center"
                paddingX={3}
                paddingY={2}
              >
                <EmptyState
                  action={
                    <Button
                      color="primary"
                      component={Link}
                      size="small"
                      to={`/${currentLanguage}/trips`}
                      variant="contained"
                    >
                      {t('geovelo.rides_and_trips.bike_routes_list.action')}
                    </Button>
                  }
                  text={t('geovelo.rides.too_far_description')}
                  title={t('geovelo.rides.too_far_title')}
                />
              </Box>
            ) : (
              <>
                {filteredRides && filteredRides.length === 0 ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    flexGrow={1}
                    justifyContent="center"
                    paddingX={3}
                    paddingY={2}
                  >
                    <EmptyState
                      action={
                        <Button
                          color="primary"
                          onClick={() => openCreateDialog(true)}
                          size="small"
                          startIcon={<Add />}
                          variant="contained"
                        >
                          {t('geovelo.rides.actions.create')}
                        </Button>
                      }
                      text={t('geovelo.rides.empty_state_description')}
                      title={t('geovelo.rides.empty_state_title')}
                    />
                  </Box>
                ) : (
                  <Box display="flex" flexDirection="column">
                    <Box alignItems="center" display="flex" height={40} paddingX={3}>
                      <Typography fontWeight={600} variant="body2">
                        {t('geovelo.rides.label')}
                      </Typography>
                    </Box>
                    <RidesList path={path} rides={filteredRides} />
                  </Box>
                )}
                {(!communityRides || communityRides.length > 0) && (
                  <Box display="flex" flexDirection="column">
                    <Box alignItems="center" display="flex" height={40} paddingX={3}>
                      <Typography fontWeight={600} variant="body2">
                        {t('geovelo.rides.label_community')}
                      </Typography>
                    </Box>
                    <RidesList path={path} rides={communityRides} />
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </LeftPanelLayout>
      <FilterDialog
        currentFilters={filters}
        onClose={handleFiltersDialogClose}
        open={filtersDialogOpen}
      />
      <MapMarkers path={path} />
      <CreateDialog onClose={() => openCreateDialog(false)} open={createDialogOpen} />
    </>
  );
}

export default RidesPage;
