import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function ThumbUpIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 20 20" {...props}>
      <path
        d="M17.7513 8.4C17.5171 8.11887 17.2241 7.89256 16.893 7.73706C16.5618 7.58155 16.2005 7.50062 15.8346 7.5H12.0346L12.5013 6.30833C12.6954 5.78657 12.7601 5.2255 12.6897 4.67326C12.6193 4.12103 12.416 3.5941 12.0973 3.13769C11.7785 2.68127 11.3538 2.30899 10.8596 2.05278C10.3653 1.79657 9.81633 1.66408 9.25964 1.66666C9.09933 1.667 8.94253 1.71356 8.80803 1.80077C8.67352 1.88797 8.56703 2.01212 8.5013 2.15833L6.1263 7.5H4.16797C3.50493 7.5 2.86904 7.76339 2.4002 8.23223C1.93136 8.70107 1.66797 9.33696 1.66797 10V15.8333C1.66797 16.4964 1.93136 17.1323 2.4002 17.6011C2.86904 18.0699 3.50493 18.3333 4.16797 18.3333H14.7763C15.3611 18.3331 15.9274 18.1279 16.3766 17.7533C16.8257 17.3788 17.1294 16.8586 17.2346 16.2833L18.293 10.45C18.3584 10.0895 18.3438 9.71898 18.2502 9.36473C18.1566 9.01047 17.9863 8.68112 17.7513 8.4ZM5.83464 16.6667H4.16797C3.94696 16.6667 3.73499 16.5789 3.57871 16.4226C3.42243 16.2663 3.33464 16.0543 3.33464 15.8333V10C3.33464 9.77898 3.42243 9.56702 3.57871 9.41074C3.73499 9.25446 3.94696 9.16666 4.16797 9.16666H5.83464V16.6667ZM16.668 10.15L15.6096 15.9833C15.5741 16.1775 15.4709 16.3527 15.3183 16.4778C15.1657 16.603 14.9736 16.6699 14.7763 16.6667H7.5013V8.50833L9.76797 3.40833C10.0013 3.47635 10.218 3.592 10.4044 3.74798C10.5907 3.90396 10.7427 4.09689 10.8508 4.31457C10.9589 4.53226 11.0206 4.76999 11.0322 5.01275C11.0437 5.25551 11.0049 5.49802 10.918 5.725L10.4763 6.91666C10.3822 7.16854 10.3504 7.43945 10.3837 7.70627C10.417 7.97309 10.5143 8.2279 10.6674 8.44895C10.8205 8.66999 11.0248 8.85072 11.2629 8.9757C11.501 9.10067 11.7657 9.16619 12.0346 9.16666H15.8346C15.9571 9.16646 16.078 9.19324 16.1889 9.24509C16.2998 9.29694 16.398 9.37259 16.4763 9.46666C16.5566 9.55943 16.6154 9.66878 16.6485 9.78691C16.6816 9.90503 16.6883 10.029 16.668 10.15Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
