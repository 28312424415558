import { TLanguage, badgeUrls, useTracker } from '@geovelo-frontends/commons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import environment from '../../environment';

export function GooglePlayBadge({
  height,
  trackingName,
}: {
  height: number;
  trackingName: string;
}): JSX.Element {
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const { trackEvent } = useTracker();

  return (
    <Wrapper
      height={height}
      href={`${environment.android.storeUrls[currentLanguage]}&referrer=utm_source%3Dwebsite`}
      onClick={() => trackEvent('App store button', 'Clicked', trackingName)}
      rel="noreferrer"
      target="_blank"
    >
      <img
        alt={t('commons.app_badge_labels.google_play') || ''}
        height={height}
        src={badgeUrls[currentLanguage as TLanguage].android}
      />
    </Wrapper>
  );
}

const Wrapper = styled.a<{ height: number }>`
  display: inline-block;
  height: ${({ height }) => `${height}px`};
  overflow: hidden;

  img {
    height: ${({ height }) => `${height}px`};
    width: auto;
  }
`;
