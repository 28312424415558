import {
  Geoagglo,
  IPoint,
  Period,
  Place,
  Search,
  TRideDifficulty,
  TRideThemeKey,
} from '@geovelo-frontends/commons';
import moment from 'moment';
import { useQueryParamString } from 'react-use-query-param-string';

import { LngLatBoundsLike } from '!maplibre-gl';

type TKeys =
  | 'auth-token'
  | 'b'
  | 'bike-route'
  | 'bike-type'
  | 'c'
  | 'code'
  | 'ct'
  | 'days'
  | 'difficulties'
  | 'e-bike'
  | 'from'
  | 'max-distance'
  | 'p'
  | 'redirect-url'
  | 'redirect-params'
  | 'section'
  | 'steps'
  | 'tab'
  | 'theme'
  | 'themes'
  | 'type'
  | 'to'
  | 'user-id'
  | 'utm_campaign'
  | 'utm_source'
  | 'value'
  | 'z'
  | 'zone';

export type TParams = {
  code?: string;
  difficulties?: TRideDifficulty[];
  geoagglo?: Geoagglo | null;
  location?: { center: IPoint; zoom: number } | null;
  maxDistance?: number;
  page?: number;
  period?: Period;
  search?: Search;
  section?: string;
  tab?: string;
  theme?: string | null;
  themes?: TRideThemeKey[];
  type?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function useQueryParams(search: string): {
  get: () => { [key in TKeys]?: string };
  getFrom: () => Place | undefined;
  getMapInitialPosition: () =>
    | { center: IPoint; zoom: number }
    | { bounds: LngLatBoundsLike }
    | null;
  getPeriod: () => Period | undefined;
  getSteps: () => Place[];
  getTo: () => Place | undefined;
  update: (params: TParams) => void;
} {
  const [, setZone] = useQueryParamString('zone', '');
  const [, setC] = useQueryParamString('c', '');
  const [, setZ] = useQueryParamString('z', '');
  const [, setB] = useQueryParamString('b', '');
  const [, setFrom] = useQueryParamString('from', '');
  const [, setSteps] = useQueryParamString('steps', '');
  const [, setTo] = useQueryParamString('to', '');
  const [, setEBike] = useQueryParamString('e-bike', '');
  const [, setBikeType] = useQueryParamString('bike-type', '');
  const [, setTheme] = useQueryParamString('theme', '');
  const [, setThemes] = useQueryParamString('themes', '');
  const [, setDifficulties] = useQueryParamString('difficulties', '');
  const [, setMaxDistance] = useQueryParamString('max-distance', '');
  const [, setP] = useQueryParamString('p', '');
  const [, setTab] = useQueryParamString('tab', '');
  const [, setSection] = useQueryParamString('section', '');
  const [, setType] = useQueryParamString('type', '');
  const [, setPage] = useQueryParamString('page', '');
  const [, setCode] = useQueryParamString('code', '');

  function get(): { [key in TKeys]?: string } {
    if (typeof window === 'undefined') return {};

    const urlSearchParams = new URLSearchParams(window.location.search);

    const params: { [key in string]?: string } = {};
    for (const [key, value] of urlSearchParams.entries()) {
      params[key] = value;
    }

    return params;
  }

  function getPlace(param: string | undefined): Place | undefined {
    if (!param) return;

    const [_lng, _lat] = param.split(',');
    const lng = parseFloat(_lng);
    const lat = parseFloat(_lat);

    if (!isNaN(lng) && lng >= -180 && lng <= 180 && !isNaN(lat) && lat >= -90 && lat <= 90)
      return new Place(undefined, { type: 'Point', coordinates: [lng, lat] });
  }

  function getFrom(): Place | undefined {
    const { from } = get();

    return getPlace(from);
  }

  function getTo(): Place | undefined {
    const { to } = get();

    return getPlace(to);
  }

  function getSteps(): Place[] {
    const { steps } = get();
    if (!steps) return [];

    const params = steps.split(';');

    return params.reduce<Place[]>((res, param) => {
      const place = getPlace(param);
      if (place) res.push(place);

      return res;
    }, []);
  }

  function getPeriod(): Period | undefined {
    const { p } = get();
    if (!p) return;

    if (p.length === 4) {
      const date = moment(p, 'YYYY');
      if (!date.isValid() || date.startOf('year').isAfter(moment())) return;

      return new Period('year', date.startOf('year'), moment(date).endOf('year'));
    }

    if (p.length === 7) {
      const date = moment(p, 'MM-YYYY');
      if (!date.isValid() || date.startOf('month').isAfter(moment())) return;

      return new Period('month', date.startOf('month'), moment(date).endOf('month'));
    }
  }

  function getMapInitialPosition():
    | { center: IPoint; zoom: number }
    | { bounds: LngLatBoundsLike }
    | null {
    const { b, c, z } = get();
    if (c && z) {
      const [lng, lat] = c.split(',').map((coord) => parseFloat(coord));
      const zoom = parseFloat(z);
      if (
        !isNaN(lat) &&
        !isNaN(lng) &&
        !isNaN(zoom) &&
        lat <= 90 &&
        lat >= -90 &&
        lng <= 180 &&
        lng >= -180
      ) {
        return { center: { lat, lng }, zoom };
      }
    } else if (b) {
      const [north, east, south, west] = b.split(',').map((coord) => parseFloat(coord));
      if (
        !isNaN(north) &&
        !isNaN(east) &&
        !isNaN(south) &&
        !isNaN(west) &&
        north <= 90 &&
        north >= -90 &&
        south <= 90 &&
        south >= -90 &&
        east <= 180 &&
        east >= -180 &&
        west <= 180 &&
        west >= -180
      ) {
        return { bounds: [east, south, west, north] };
      }
    }

    update({ location: null });

    return null;
  }

  function update({
    geoagglo,
    location: mapLocation,
    search,
    theme,
    themes,
    difficulties,
    maxDistance,
    period,
    tab,
    section,
    type,
    page,
    code,
  }: TParams) {
    if (geoagglo) {
      setZone(geoagglo.code);
    } else if (geoagglo === null) {
      setZone('');
    } else if (mapLocation) {
      const {
        center: { lat, lng },
        zoom,
      } = mapLocation;
      setC(`${lng.toFixed(6)},${lat.toFixed(6)}`);
      setZ(zoom.toFixed(2));
      setB('');
    } else if (mapLocation === null) {
      setC('');
      setZ('');
      setB('');
    } else {
      if (search) {
        const { wayPoints, eBikeEnabled, bikeType } = search;

        setFrom(wayPoints[0]?.point.coordinates.map((c) => c.toFixed(6)).join(',') || '');
        setSteps(
          wayPoints
            .slice(1, -1)
            .map((wayPoint) => wayPoint?.point.coordinates.map((c) => c.toFixed(6)).join(','))
            .join(';'),
        );
        setTo(
          wayPoints[wayPoints.length - 1]?.point.coordinates.map((c) => c.toFixed(6)).join(',') ||
            '',
        );
        setEBike(eBikeEnabled ? 'true' : 'false');
        setBikeType(bikeType);
      } else {
        setFrom('');
        setSteps('');
        setTo('');
        setEBike('');
        setBikeType('');
      }

      if (theme) setTheme('theme');
      else setTheme('');

      if (themes) setThemes(themes.join(','));
      else setThemes('');

      if (difficulties) setDifficulties(difficulties.join(','));
      else setDifficulties('');

      if (maxDistance !== undefined) setMaxDistance(maxDistance.toFixed(0));
      else setMaxDistance('');

      if (period) {
        switch (period.type) {
          case 'year':
            setP(period.from.format('YYYY'));
            break;
          case 'month':
            setP(period.from.format('MM-YYYY'));
            break;
          default:
            setP('');
            break;
        }
      } else {
        setP('');
      }

      if (tab !== undefined) setTab(tab);
      else setTab('');

      if (section !== undefined) setSection(section);
      else setSection('');

      if (type !== undefined) setType(type);
      else setType('');

      if (page !== undefined) setPage(page.toFixed(0));
      else setPage('');

      if (code !== undefined) setCode(code);
      else setCode('');
    }
  }

  return { get, getFrom, getSteps, getTo, getPeriod, getMapInitialPosition, update };
}

export default useQueryParams;
