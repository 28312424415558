export const langKeys = ['de', 'en', 'es', 'fr'] as const;

export type TAppLanguage = (typeof langKeys)[number];

export const defaultLanguage = 'fr';

export const languagesMap: { [key: string]: TAppLanguage } = {
  'de-de': 'de',
  'en-gb': 'en',
  'es-es': 'es',
  'fr-fr': 'fr',
};

export const prismicLanguagesMap: { [key in TAppLanguage]: string } = {
  de: 'de-de',
  en: 'en-gb',
  es: 'es-es',
  fr: 'fr-fr',
};
