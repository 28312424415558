import { Box } from '@mui/material';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import {
  PrismicMissionDataBodyVideoPrimary,
  PrismicMissionVideoFragment,
} from '../../../graphql-types';

import { maxWidth } from './consts';
import CTA from './cta';
import Description from './description';
import Title from './title';

export type TVideoData = PrismicMissionVideoFragment;

function Video({ data: { primary } }: { data: TVideoData }): JSX.Element {
  if (!primary) return <></>;

  const {
    title,
    description,
    header_image,
    video_url,
    cta_background_color,
    cta_baseline,
    cta_button,
    cta_link,
    cta_right_image,
  } = primary as PrismicMissionDataBodyVideoPrimary;
  const headerImage = header_image && getImage(header_image);
  const rightImage = cta_right_image && getImage(cta_right_image);

  return (
    <Box alignItems="center" display="flex" flexDirection="column" width="100%">
      {headerImage && (
        <GatsbyImage alt="" image={headerImage} style={{ width: '100%', zIndex: 1 }} />
      )}
      <Box
        marginTop={{ xs: -6, sm: -10, md: -15, lg: -20 }}
        maxWidth={{ xs: 'calc(100% - 64px)', md: 'calc(100% - 128px)', lg: 'calc(100% - 256px)' }}
        width={maxWidth}
        zIndex={2}
      >
        <Box
          borderRadius="20px"
          display="flex"
          flexDirection="column"
          gap={3}
          padding={{ xs: '0 0 48px', sm: '48px 64px', md: '80px 98px' }}
          style={{ backgroundColor: '#fff' }}
        >
          <Box display="flex" flexDirection="column" gap={2} padding={{ xs: '24px 36px', sm: '0' }}>
            <Title component="h2" data={title} />
            <Description data={description} sx={{ '> p:not(:last-child)': { marginBottom: 4 } }} />
          </Box>
          {video_url?.url && (
            <iframe height="435px" src={video_url.url} style={{ borderRadius: 20 }} width="100%" />
          )}
          <Box
            alignItems="center"
            borderRadius="20px"
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            gap={{ xs: 3, sm: 6 }}
            padding={{ xs: '16px 24px', sm: '32px 64px' }}
            position="relative"
            style={{ backgroundColor: cta_background_color || '#8EEAC2' }}
            textAlign={{ xs: 'center', md: 'start' }}
          >
            {rightImage && (
              <Box bottom="12px" position="absolute" right={0} zIndex={0}>
                <GatsbyImage alt="" image={rightImage} />
              </Box>
            )}
            <Title component="div" data={cta_baseline} />
            <CTA link={cta_link} title={cta_button} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Video;
