import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import { PrismicHomeDividerFragment } from '../../../graphql-types';

export type TDividerData = PrismicHomeDividerFragment;

function Divider({
  data: {
    primary: { align, image: _image },
  },
}: {
  data: TDividerData;
}): JSX.Element {
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down('lg'));

  const image = _image && getImage(_image);
  if (!image) return <></>;

  const isOnRight = align === 'Right';
  const { width: _width, height: _height } = image;
  const height = ((smallDevice ? 48 : 64) * _height) / _width;

  return (
    <Box alignSelf="stretch" display={{ xs: 'none', md: 'block' }} position="relative" zIndex={0}>
      <Box
        height={height}
        left={!isOnRight ? 0 : undefined}
        position="absolute"
        right={isOnRight ? 0 : undefined}
        top={-(height / 2)}
        width={smallDevice ? 48 : 64}
      >
        <GatsbyImage alt="" image={image} style={{ height: '100%', width: '100%' }} />
      </Box>
    </Box>
  );
}

export default Divider;
